import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import "../App.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Stories from "react-insta-stories";

import { AiFillHeart, AiFillShop } from "react-icons/ai";
// import { CgProfile } from "react-icons/cg";
import { RiArrowRightSFill, RiArrowLeftSFill } from "react-icons/ri";
import { ImProfile } from "react-icons/im";
import { IoIosShareAlt } from "react-icons/io";
import useWindowDimensions from "../hooks/useWindowDimension";
import Footer from "../Components/Base/Footer";
import toast from "react-hot-toast";
import { APIURL } from "../backend";
import MachaHeader from "../Components/Base/MachaHeader";
import { getUser, likeStore, unlikeStore } from "../Helper/story";
import { isAuthenticated } from "../Helper/Auth";
import { useSearchParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { WhatsappIcon } from "react-share";
import { Helmet } from "react-helmet";

const StoriesView = () => {
  const [currentId, setCurrentId] = useState(0);
  const [swiper, setSwiper] = useState(null);
  const { width } = useWindowDimensions();
  const [viewStories, setViewStories] = useState([]);
  const [userLikedStores, setUserLikedStores] = useState([]);
  const swiperRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  let [isOpen, setIsOpen] = useState(false);
  console.log(searchParams.get("storeId"), "searchParams");
  const updateIndex = useCallback(
    () => setCurrentSlide(swiperRef.current.swiper.realIndex),
    []
  );

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    const swiperInstance = swiperRef.current.swiper;

    if (swiperInstance) {
      swiperInstance.on("slideChange", updateIndex);
    }

    return () => {
      if (swiperInstance) {
        swiperInstance.off("slideChange", updateIndex);
      }
    };
  }, [updateIndex]);

  async function getStories() {
    try {
      const res = await fetch(`${APIURL}/story/get-stories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("macha-user"))?.token,
        },
      });
      const response = await res.json();
      if (response.status_code === 200) {
        if (searchParams.get("storeId")) {
          const element = response.data.filter((obj) => {
            return obj._id.store_id._id === searchParams.get("storeId");
          });
          let sorted = response.data.filter((obj) => {
            return obj._id.store_id._id !== searchParams.get("storeId");
          });
          sorted.unshift(element[0]);
          setViewStories(sorted);
        } else {
          setViewStories(response.data);
        }
      } else {
        toast.error("Error fetching stories at the moment");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  }

  async function getLikedStores() {
    if (isAuthenticated()) {
      const res = await getUser();
      if (res.status_code === 200) {
        setUserLikedStores(res?.data?.liked_stores);
      }
    }
    return;
  }

  useEffect(() => {
    getStories();
    getLikedStores();
  }, []);

  async function markAsViewed(currIndex) {
    await fetch(`${APIURL}/story/mark-story-viewed`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          localStorage.getItem("macha-user") &&
          JSON.parse(localStorage.getItem("macha-user")).token,
      },
      body: JSON.stringify({
        story_id: viewStories[currIndex].stories[currentId]._id,
      }),
    });
  }

  async function handleLikeClick(story_id) {
    const res = await likeStore(story_id);
    if (res.status_code === 200) {
      toast("Store added to liked list");
      setUserLikedStores((oldArray) => [...oldArray, res.store_id]);
    } else {
      toast.error(res.message);
    }
  }

  async function handleUnlinkClick(story_id) {
    const res = await unlikeStore(story_id);
    if (res.status_code === 200) {
      toast("Store removed from liked list");
      console.log(res);
      setUserLikedStores((oldArray) =>
        oldArray.filter((store) => store !== res.store_id)
      );
    } else {
      toast.error(res.message);
    }
  }

  // const handleShare = () => {
  //   let sendImage = viewStories[currentSlide]?._id?.store_id?.storeLogo.replace(
  //     "http",
  //     "https"
  //   );
  //   console.log(sendImage, "image wat");
  //   const url = `https://machapoint.com/profile/${viewStories[currentSlide]?._id?.store_id?.storeName}/${viewStories[currentSlide]?._id?.store_id?._id}`;

  //   console.log(url, "url", url.replace(" ", "%20"));

  //   const text = encodeURIComponent("Check this Amazing Store on Machapoint "); // set the message text
  //   const shareUrl = `https://api.whatsapp.com/send?text=${text}%0A${url}`;

  //   window.open(shareUrl, "_blank");
  // };

  return (
    <div className="flex flex-col">
      {/* <Helmet>
        <meta
          property="og:image"
          content={`${viewStories[currentSlide]?._id?.store_id?.storeLogo}`}
        />
        <meta
          property="og:title"
          content="short title of your website/webpage"
        />
        <meta
          property="og:url"
          content={`https://machapoint.com/stories/?storeId=${viewStories[currentSlide]?._id?.store_id?._id}`}
        />
      </Helmet> */}
      <MachaHeader />
      <div className="flex h-screen py-6 items-start w-full h-full justify-center md:space-x-4">
        <div
          onClick={async () => {
            await setCurrentId(0);
            swiper.slidePrev();
          }}
          className="flex hidden md:block items-center"
        >
          <RiArrowLeftSFill className="text-6xl cursor-pointer hover:text-blue-600 text-gray-500" />
        </div>
        <div className="height  relative aspect-[22/40] bg-black drop-shadow-sm shadow-black  shadow-sm md:rounded-2xl">
          <Swiper
            onSwiper={setSwiper}
            className="w-full h-full"
            loop={false}
            spaceBetween={10}
            ref={swiperRef}
            slidesPerView={1}
            allowTouchMove={width > 640 ? false : true}
          >
            {viewStories?.length > 0 &&
              viewStories?.map((story, index) => {
                return (
                  <SwiperSlide className="w-full h-full " key={index}>
                    <Stories
                      storyContainerStyles={{
                        borderRadius: 14,
                        overflow: "hidden",
                      }}
                      stories={story.stories}
                      keyboardNavigation
                      currentIndex={currentId}
                      onStoryEnd={(s, st) => {
                        setCurrentId((currentId) => currentId + 1);
                      }}
                      onAllStoriesEnd={(s, st) => {
                        setCurrentId((currentId) => 0);
                        swiper.slideNext();
                        // markAsViewed(index);
                      }}
                      onStoryStart={(s, st) => {
                        markAsViewed(index);
                      }}
                      defaultInterval={10000}
                      width={"100%"}
                      height={"100%"}
                    />
                  </SwiperSlide>
                );
              })}
          </Swiper>
          <div
            style={{ zIndex: 4000 }}
            className=" md:hidden bg-black width py-1 flex absolute bottom-0   space-x-3 justify-between px-5 items-center"
          >
            <div
              onClick={() =>
                userLikedStores?.includes(
                  viewStories[currentSlide]?._id?.store_id?._id
                )
                  ? handleUnlinkClick(
                      viewStories[currentSlide].stories[currentId]._id
                    )
                  : handleLikeClick(
                      viewStories[currentSlide].stories[currentId]._id
                    )
              }
              className="flex hover:text-red-500 text-white rounded-full p-2 cursor-pointer  flex-col items-center justify-center"
            >
              <AiFillHeart
                className={
                  "text-2xl  " +
                  (userLikedStores?.includes(
                    viewStories[currentSlide]?._id?.store_id?._id
                  ) && "text-red-500")
                }
              />
            </div>
            {viewStories[currentSlide]?._id?.store_id?.template && (
              <a
                href={`/profile/${viewStories[
                  currentSlide
                ]?._id?.store_id?.storeName?.replace(/\s+/g, "-")}/${
                  viewStories[currentSlide]?._id?.store_id?._id
                }`}
                className="flex hover:text-red-500 text-white rounded-full p-2 cursor-pointer  flex-col items-center justify-center"
              >
                <ImProfile className="text-2xl" />
              </a>
            )}
            {viewStories[currentSlide]?._id?.store_id?.template && (
              <a
                href={`/profile/product/${viewStories[
                  currentSlide
                ]?._id?.store_id?.storeName?.replace(/\s+/g, "-")}/${
                  viewStories[currentSlide]?._id?.store_id?._id
                }`}
                className="flex hover:text-blue-500 text-white rounded-full p-2  cursor-pointer flex-col items-center justify-center"
              >
                <AiFillShop className="text-2xl" />
              </a>
            )}
            <div className="flex hover:text-blue-500 text-white rounded-full p-2  cursor-pointer flex-col items-center justify-center">
              <IoIosShareAlt className="text-2xl" onClick={openModal} />
            </div>
          </div>
        </div>
        <div className="flex  height justify-center relative hidden md:flex items-start">
          <div
            onClick={async () => {
              await setCurrentId(0);
              swiper.slideNext();
            }}
          >
            <RiArrowRightSFill className="text-6xl cursor-pointer hover:text-blue-600 text-gray-500" />
          </div>
          <div className=" hidden md:flex absolute bottom-0 ml-3 flex-col space-y-3 items-center">
            <div
              onClick={() =>
                userLikedStores?.includes(
                  viewStories[currentSlide]?._id?.store_id?._id
                )
                  ? handleUnlinkClick(
                      viewStories[currentSlide].stories[currentId]._id
                    )
                  : handleLikeClick(
                      viewStories[currentSlide].stories[currentId]._id
                    )
              }
              className="flex hover:text-red-500  rounded-full p-2 cursor-pointer  flex-col items-center justify-center text-xs"
            >
              <AiFillHeart
                className={
                  "text-2xl  " +
                  (userLikedStores?.includes(
                    viewStories[currentSlide]?._id?.store_id?._id
                  ) && "text-red-500")
                }
              />
              <span>Like</span>
            </div>
            {viewStories[currentSlide]?._id?.store_id?.template && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`/profile/${viewStories[
                  currentSlide
                ]?._id?.store_id?.storeName?.replace(/\s+/g, "-")}/${
                  viewStories[currentSlide]?._id?.store_id?._id
                }`}
                className="flex hover:text-green-500 rounded-full p-2 cursor-pointer  flex-col items-center justify-center text-xs"
              >
                <ImProfile className="text-2xl " />
                <span>Profile</span>
              </a>
            )}
            {viewStories[currentSlide]?._id?.store_id?.template && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`/profile/product/${viewStories[
                  currentSlide
                ]?._id?.store_id?.storeName?.replace(/\s+/g, "-")}/${
                  viewStories[currentSlide]?._id?.store_id?._id
                }`}
                className="flex hover:text-blue-500 rounded-full p-2  cursor-pointer flex-col items-center justify-center text-xs"
              >
                <AiFillShop className="text-2xl " />
                <span>Shop</span>
              </a>
            )}
            <div className="flex hover:text-indigo-500 rounded-full p-2  cursor-pointer flex-col items-center justify-center text-xs">
              <IoIosShareAlt className="text-2xl" onClick={openModal} />
              <span>Share</span>
            </div>
          </div>
        </div>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mt-2 flex justify-between ">
                    <a
                      href={`whatsapp://send?text=Check this Amazing Store on Machapoint: https://machapoint.com/profile/${viewStories[
                        currentSlide
                      ]?._id?.store_id?.storeName.replace(/ /g, "")}/${
                        viewStories[currentSlide]?._id?.store_id?._id
                      } + https://www.machapoint.com `}
                    >
                      <WhatsappIcon size={32} round={true} />
                    </a>
                    {/* 
                    <WhatsappIcon
                      size={32}
                      round={true}
                      onClick={handleShare}
                    /> */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Footer />
    </div>
  );
};

export default StoriesView;
