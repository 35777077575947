import { Link, Navigate } from "react-router-dom";
import { useState } from "react";

import axios from "../../api/Axios";
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import Login from "../Login";
import { GetToken } from "../Login";
import { IoHomeOutline } from "react-icons/io5";
import { FRONTENDAPIURL } from "../../backend";

const passChangeURL = `${FRONTENDAPIURL}/user/change-password`;

const Password = () => {
  const [errMsg, setErrMsg] = useState("");

  const [newValues, setNewValues] = useState({
    password: "",
    showPassword: false,
  });
  const [oldValues, setOldValues] = useState({
    password: "",
    showPassword: false,
  });
  const [conValues, setConValues] = useState({
    password: "",
    showPassword: false,
  });
  const handleShowNewPassword = () => {
    setNewValues({ ...newValues, showPassword: !newValues.showPassword });
  };
  const handleShowOldPassword = () => {
    setOldValues({ ...oldValues, showPassword: !oldValues.showPassword });
  };
  const handleShowConPassword = () => {
    setConValues({ ...conValues, showPassword: !conValues.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOldPasswordChange = (prop) => (event) => {
    setOldValues({ ...oldValues, [prop]: event.target.value });
  };
  const handleNewPasswordChange = (prop) => (event) => {
    setNewValues({ ...newValues, [prop]: event.target.value });
  };
  const handleConPasswordChange = (prop) => (event) => {
    setConValues({ ...conValues, [prop]: event.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log("password ", conValues.password);

      console.log(
        "token = ",
        JSON.parse(localStorage.getItem("macha-user"))["token"]
      );
      const response = await axios.post(
        passChangeURL,
        { new_pswd: conValues.password, previous_pswd: oldValues.password },
        {
          headers: {
            Authorization:
              localStorage.getItem("macha-user") &&
              JSON.parse(localStorage.getItem("macha-user")).token,
          },
        }
      );
      console.log("this is response", response);
      const accessToken = response?.data?.accessToken;
      const roles = response?.data?.roles;
      alert("success");
      console.log("location", window.location.pathname);
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      // errRef.current.focus();
    }
  };
  if (localStorage.getItem("macha-user") === null)
    return <Navigate to="/" replace={false} />;

  return (
    <div>
      <div className="lg:w-4/6 2xl:w-8/12 sm:mt-6 max-sm:px-3 lg:mt-0 sm:ml-6">
        <h2 className=" text-lg md:text-xl xl:text-2xl font-bold text-heading mb-6 xl:mb-8">
          Change Password
        </h2>
        <div className="w-full flex  h-full lg:w-8/12 flex-col relative">
          <form
            onSubmit={handleSubmit}
            className="w-full mx-auto flex flex-col justify-center "
          >
            <div className="flex flex-col space-y-3">
              <div className="mb-4">
                <label
                  for="oldPassword"
                  class="block text-gray-600 font-semibold text-sm leading-none mb-3 cursor-pointer"
                >
                  Old Password*
                </label>
                <Input
                  className="w-full block border-black border-t-2 text-sm px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                  type={oldValues.showPassword ? "text" : "password"}
                  onChange={handleOldPasswordChange("password")}
                  value={oldValues.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleShowOldPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {oldValues.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
              <div className="mb-4">
                <label
                  for="oldPassword"
                  class="block text-gray-600 font-semibold text-sm leading-none mb-3 cursor-pointer"
                >
                  New Password*
                </label>

                <Input
                  className="w-full block border-black border-t-2  text-sm px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                  type={newValues.showPassword ? "text" : "password"}
                  onChange={handleNewPasswordChange("password")}
                  value={newValues.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {newValues.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
              <div className="mb-4">
                <label
                  for="oldPassword"
                  class="block text-gray-600 font-semibold text-sm leading-none mb-3 cursor-pointer"
                >
                  Confirm Password*
                </label>

                <Input
                  className="w-full block border-black border-t-2 text-sm px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                  type={conValues.showPassword ? "text" : "password"}
                  onChange={handleConPasswordChange("password")}
                  value={conValues.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleShowConPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {conValues.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
              <div className="relative">
                <button
                  data-variant="flat"
                  type="submit"
                  className="text-[13px] mb-3 bg-gray-300 md:text-sm leading-4 inline-flex items-center cursor-pointer transition ease-in-out duration-300 font-semibold font-body text-center justify-center border-0 border-transparent rounded-md placeholder-white focus-visible:outline-none focus:outline-none focus:bg-opacity-80 bg-heading text-black px-5 md:px-6 lg:px-8 py-4 md:py-3.5 lg:py-4 hover:text-white hover:bg-gray-600 hover:shadow-cart h-13 mt-3"
                >
                  Change Password
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Password;
