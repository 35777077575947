import { Switch } from "@headlessui/react";
import React from "react";

const SwitchComp = ({ enabled, setEnabled }) => {
  return (
    <Switch
      checked={enabled}
      onChange={() => setEnabled(!enabled)}
      className={`${enabled ? "bg-green-500" : "bg-red-500"}
    relative inline-flex h-[20px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
    >
      <span
        aria-hidden="true"
        className={`${enabled ? "translate-x-7" : "translate-x-0"}
      pointer-events-none  inline-block h-[16px] mb-2 w-[16px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
      />
    </Switch>
  );
};

export default SwitchComp;
