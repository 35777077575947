import React from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import "@fontsource/dancing-script";
import { useRef, useState, useEffect, useContext } from "react";
import {
  IoHomeOutline,
  IoPersonOutline,
  IoLogOutOutline,
  IoAddOutline,
  IoHeartOutline,
} from "react-icons/io5";
import { FiSettings, FiPhone, FiShoppingCart } from "react-icons/fi";
import { BiStore, BiShoppingBag } from "react-icons/bi";

import Address from "./UserPanel/address";
import Password from "./UserPanel/change-password";
import Dashboard from "./UserPanel/dashboard";
import Contact from "./UserPanel/contact";
import Orders from "./UserPanel/orders";
import Wishlist from "./UserPanel/wishlist";
import MachaHeader from "../Components/Base/MachaHeader";
import Footer from "../Components/Base/Footer";
import { getUserInfo, signout } from "../Helper/Auth";
import { toast } from "react-hot-toast";
import { AiOutlineMenuUnfold } from "react-icons/ai";

import CreateStoreForm from "./WebsiteBuilder/createStoreForm";
import { LoginToast } from "../Toasts/LoginToast";
import Drawer from "../Components/Base/Drawer";
import { RxCross2 } from "react-icons/rx";
import { getOrganisation } from "../Helper/organisation";
import { getStore } from "../Helper/store";
import OrganisationHeader from "../Components/Base/OrganisationHeader";
import Header from "../Components/SellerPage/Header";

const UserPanel = () => {
  const handleElement = (category) => {
    switch (category) {
      case "address":
        return <Address />;
      case "applyforseller":
        return <CreateStoreForm />;
      case "change-password":
        return <Password />;
      case "dashboard":
        return <Dashboard />;
      case "contact":
        return <Contact />;
      case "orders":
        return <Orders />;
      case "wishlist":
        return <Wishlist />;
      default:
        break;
    }
  };
  const [curOpt, setOpt] = useState("dashboard");
  const [userState, setUserState] = useState(null);
  let [isOpenMenu, setIsOpenMenu] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const redirectFrom = searchParams.get("redirectFrom");
  const [template, setTemplate] = useState(null);

  async function getStoreData() {
    if (searchParams.get("redirectFromId") !== null) {
      await getStore(searchParams.get("redirectFromId"))
        .then((data, err) => {
          if (data.status_code === 200) {
            setTemplate(data.data);
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((err) => console.log(err));
    }
  }

  async function fetchOrganisation() {
    if (searchParams.get("redirectFromId") !== null) {
      const res = await getOrganisation(searchParams.get("redirectFromId"));
      if (res.status_code === 200) {
        setTemplate(res.data);
      } else {
        toast.error("Something went wrong");
      }
    }
  }

  useEffect(() => {
    if (redirectFrom === "seller") {
      getStoreData();
    } else if (redirectFrom === "organisation") {
      fetchOrganisation();
    }
  }, [redirectFrom]);

  async function getUser() {
    const user = await getUserInfo();
    console.log(user);
    if (!user) {
      toast.error(LoginToast);
    } else if (user.status_code === 200) {
      setUserState(user.data);
    }
  }

  console.log(curOpt);

  useEffect(() => {
    getUser();
  }, []);
  const active =
    "bg-gray-200 font-semibold flex items-center cursor-pointer text-sm lg:text-base text-heading py-3.5 px-4 lg:px-5 rounded mb-2";
  const style =
    "flex items-center cursor-pointer text-sm lg:text-base text-heading py-3.5 px-4 lg:px-5 rounded mb-2";
  if (!localStorage.getItem("macha-user"))
    return <Navigate to="/" replace={false} />;
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        {redirectFrom === "organisation" ? (
          <OrganisationHeader
            organisationId={searchParams.get("redirectFromId")}
            page={""}
            logo={template !== null && template?.organisation_logo}
          />
        ) : redirectFrom === "seller" ? (
          <Header
            storeName={template?.storeName}
            selected={
              template?.template !== null &&
              template?.template?.chosenColor !== null
                ? template?.template?.chosenColor
                : "#730dc9"
            }
            page={""}
            logo={template !== null && template.storeLogo}
            id={
              searchParams.get("redirectFromId") !== undefined &&
              searchParams.get("redirectFromId")
            }
            template={template?.template}
          />
        ) : (
          <MachaHeader />
        )}{" "}
        <div classNameName="mx-auto max-h-screen px-4 md:px-8 2xl:px-16">
          <h1 class="text-4xl pt-3 text-center font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-black via-slate-500 to-slate-100">
            My Account
          </h1>
          <div className="p-3 sm:hidden">
            <AiOutlineMenuUnfold
              className="text-[25px]"
              onClick={() => setIsOpenMenu(!isOpenMenu)}
            />
          </div>
          <div className=" sm:py-5 lg:py-5 px-0 xl:max-w-screen-xl mx-auto flex  md:flex-row w-full ">
            <div className="flex flex-col lg:flex-row w-full">
              <div className="lg:block flex-shrink-0 w-full md:w-[20%] pb-0 md:pb-0 max-sm:hidden ">
                <nav className="flex flex-col mr-6">
                  <button
                    className={curOpt === "dashboard" ? active + style : style}
                    onClick={(e) => setOpt("dashboard")}
                  >
                    <span className="flex align-center">
                      <IoHomeOutline className="mr-2 h-6" />
                      Dashboard
                    </span>
                  </button>
                  <button
                    className={curOpt === "wishlist" ? active + style : style}
                    onClick={(e) => setOpt("wishlist")}
                  >
                    <span className="flex align-center">
                      <IoHeartOutline className="mr-2 h-6" />
                      Wishlist
                    </span>
                  </button>
                  <button
                    className={curOpt === "orders" ? active + style : style}
                    onClick={(e) => setOpt("orders")}
                  >
                    <span className="flex align-center">
                      <FiShoppingCart className="mr-2 h-6" />
                      Orders
                    </span>
                  </button>
                  <button
                    className={curOpt === "address" ? active + style : style}
                    onClick={(e) => setOpt("address")}
                  >
                    <span className="flex align-center">
                      <IoPersonOutline className="mr-2 h-6" />
                      Address
                    </span>
                  </button>
                  <button
                    className={curOpt === "contact" ? active + style : style}
                    onClick={(e) => setOpt("contact")}
                  >
                    <span className="flex align-center">
                      <FiPhone className="mr-2 h-6" />
                      Personal Info
                    </span>
                  </button>
                  <button
                    className={
                      curOpt === "change-password" ? active + style : style
                    }
                    onClick={(e) => setOpt("change-password")}
                  >
                    <span className="flex align-center">
                      <FiSettings className="mr-2 h-6" />
                      Change Password
                    </span>
                  </button>
                  {userState?.user_type === "customer" && (
                    <button
                      className={
                        curOpt === "applyforseller" ? active + style : style
                      }
                      onClick={(e) => setOpt("applyforseller")}
                    >
                      <span className="flex align-center">
                        <BiStore className="mr-2 h-6" />
                        Apply for store
                      </span>
                    </button>
                  )}
                  {userState?.user_type === "seller" && (
                    <a href="/seller-panel" className={style}>
                      <span className="flex align-center">
                        <BiStore className="mr-2 h-6" />
                        Seller Panel
                      </span>
                    </a>
                  )}
                  {userState?.user_type === "organisation" && (
                    <a href="/organisation-panel" className={style}>
                      <span className="flex align-center">
                        <BiStore className="mr-2 h-6" />
                        Organisation Panel
                      </span>
                    </a>
                  )}

                  <button
                    onClick={() => {
                      signout().then((res) => {
                        window.location.reload();
                      });
                    }}
                    className={curOpt === "logout" ? active : style}
                  >
                    <span className="flex align-center">
                      <IoLogOutOutline className="mr-2 h-6" />
                      Log Out
                    </span>
                  </button>
                </nav>
              </div>
              <div class="lg:w-4/6 2xl:w-8/12 mt-0">
                {handleElement(curOpt)}
              </div>
            </div>
          </div>
          {/* <div className="px-5 sm:px-8 md:px-16 2xl:px-24 bg-gray-200 py-10 md:py-14 lg:py-16"></div> */}
        </div>
        <Drawer
          isOpen={isOpenMenu}
          setIsOpen={setIsOpenMenu}
          lg={true}
          clip={"full"}
          direction="left"
        >
          <div className="absolute right-0 px-3">
            <RxCross2 size={25} onClick={() => setIsOpenMenu(false)} />
          </div>
          <div className="lg:block flex-shrink-0 w-full md:w-[20%] pb-0 md:pb-0  ">
            <nav className="flex flex-col mr-6">
              <button
                className={curOpt === "dashboard" ? active + style : style}
                onClick={(e) => setOpt("dashboard")}
              >
                <span className="flex align-center">
                  <IoHomeOutline className="mr-2 h-6" />
                  Dashboard
                </span>
              </button>
              <button
                className={curOpt === "wishlist" ? active + style : style}
                onClick={(e) => setOpt("wishlist")}
              >
                <span className="flex align-center">
                  <IoHeartOutline className="mr-2 h-6" />
                  Wishlist
                </span>
              </button>
              <button
                className={curOpt === "orders" ? active + style : style}
                onClick={(e) => setOpt("orders")}
              >
                <span className="flex align-center">
                  <FiShoppingCart className="mr-2 h-6" />
                  Orders
                </span>
              </button>
              <button
                className={curOpt === "address" ? active + style : style}
                onClick={(e) => setOpt("address")}
              >
                <span className="flex align-center">
                  <IoPersonOutline className="mr-2 h-6" />
                  Address
                </span>
              </button>
              <button
                className={curOpt === "contact" ? active + style : style}
                onClick={(e) => setOpt("contact")}
              >
                <span className="flex align-center">
                  <FiPhone className="mr-2 h-6" />
                  Personal Info
                </span>
              </button>
              <button
                className={
                  curOpt === "change-password" ? active + style : style
                }
                onClick={(e) => setOpt("change-password")}
              >
                <span className="flex align-center">
                  <FiSettings className="mr-2 h-6" />
                  Change Password
                </span>
              </button>
              {userState?.user_type === "customer" && (
                <button
                  className={
                    curOpt === "applyforseller" ? active + style : style
                  }
                  onClick={(e) => setOpt("applyforseller")}
                >
                  <span className="flex align-center">
                    <BiStore className="mr-2 h-6" />
                    Apply for store
                  </span>
                </button>
              )}
              {userState?.user_type === "seller" && (
                <a href="/seller-panel" className={style}>
                  <span className="flex align-center">
                    <BiStore className="mr-2 h-6" />
                    Seller Panel
                  </span>
                </a>
              )}
              {userState?.user_type === "organisation" && (
                <a href="/organisation-panel" className={style}>
                  <span className="flex align-center">
                    <BiStore className="mr-2 h-6" />
                    Organisation Panel
                  </span>
                </a>
              )}

              <button
                onClick={() => {
                  signout().then((res) => {
                    window.location.reload();
                  });
                }}
                className={curOpt === "logout" ? active : style}
              >
                <span className="flex align-center">
                  <IoLogOutOutline className="mr-2 h-6" />
                  Log Out
                </span>
              </button>
            </nav>
          </div>
        </Drawer>
      </div>
      <Footer />
    </div>
  );
};

export default UserPanel;
