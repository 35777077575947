import React from "react";
import ProductCard2 from "../homePage/productCard2";

const LikedProducts = ({ wishlist }) => {
  return (
    <>
      {wishlist?.length === 0 && (
        <div className="text-center text-md font-semibold py-3">
          No Products In Your WIshlist
        </div>
      )}

      <div className="grid sm:grid-cols-3 grid-cols-2 gap-2">
        {wishlist?.map((item) => {
          return <ProductCard2 item={item !== undefined && item} />;
        })}
        <ProductCard2 />
      </div>
    </>
  );
};

export default LikedProducts;
