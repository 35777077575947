import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { BiRupee } from "react-icons/bi";
import { RxCrossCircled } from "react-icons/rx";

const CartProduct = ({
  item,
  removeFromCartHandler,
  IN,
  cartData,
  setCartData,
  updateQuantity,
  update = false,
}) => {
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    setQuantity(item.quantity);
  }, [item]);

  const updateCartArray = async () => {
    cartData[IN].quantity = quantity;
    setCartData([...cartData]);
  };

  useEffect(() => {
    updateCartArray();
  }, [quantity]);

  return (
    <div className="border-b border-t border-3 cartProduct">
      <div className="flex items-center justify-between py-3">
        <div
          className="w-[30%] flex justify-center relative parent "
          onClick={() => removeFromCartHandler(item?.product_id?._id)}
        >
          <img
            className="h-[112px]   hover:opacity-50 rounded-lg "
            alt=""
            src={item?.product_id?.productImageUrls[0]?.imgURL}
          />

          <RxCrossCircled
            className="absolute my-auto bottom-[50px] bg-white rounded-full  parentHover"
            size={20}
          />
        </div>
        <div className="w-[40%] pl-1">
          <div className="truncate text-sm text-heading mb-1.5 -mt-1">
            <a
              href={`/product/${item?.product_id?.productName.replace(
                /\s+/g,
                "-"
              )}/${item?.product_id?._id}`}
              target={"_blank"}
              rel="noreferrer"
            >
              {item?.product_id?.productName}
            </a>
          </div>
          <div className="flex items-center text-sm text-gray-400 mb-1.5">
            <span>Unit Price :</span>
            <div className="flex items-center pl-1">
              <BiRupee /> {item?.product_id?.productPrice}
            </div>
          </div>
          <div className="flex items-center justify-between bg-black w-[60%]  p-0 rounded-lg ">
            <div className="hover:bg-gray-600 flex justify-center w-[33%] py-3 rounded-lg ">
              <AiOutlineMinus
                className="text-white "
                onClick={() => {
                  if (quantity === 1) {
                    removeFromCartHandler(item?.product_id?._id);
                  }
                  setQuantity(quantity - 1);
                  if (update === true) {
                    updateQuantity(item, "-");
                  }
                }}
              />
            </div>
            <div className="w-[34%] text-center ">
              <span className="text-white">{quantity}</span>
            </div>

            <div className="hover:bg-gray-600  w-[33%] flex justify-center py-3 rounded-lg ">
              <AiOutlinePlus
                className="text-white"
                onClick={() => {
                  setQuantity(quantity + 1);
                  if (update === true) {
                    updateQuantity(item, "+");
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="w-[30%] flex items-center justify-center">
          <div className="flex items-center font-bold">
            <BiRupee /> {item?.product_id?.productPrice * quantity}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartProduct;
