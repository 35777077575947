import { Link, Navigate } from "react-router-dom";
import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "@fontsource/dancing-script";
import { IoHomeOutline } from "react-icons/io5";
import { APIURL } from "../../backend";

const userDetURL = `${APIURL}/user/get-user-details`;
const Contact = () => {
  const [userData, setUserData] = useState({});
  const [check, setCheck] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    console.log("hi");
    axios
      .get(userDetURL, {
        headers: {
          Authorization:
            localStorage.getItem("macha-user") &&
            JSON.parse(localStorage.getItem("macha-user")).token,
        },
      })
      .then((response) => {
        const userDetails = response.data.data.filter((user) => {
          return (
            user._id === JSON.parse(localStorage.getItem("macha-user"))["uid"]
          );
        });
        setUserData(userDetails[0]);
        console.log("user = ", userDetails[0]);
        setName(userDetails[0].name);
        setEmail(userDetails[0].email);
        setPhone(userDetails[0]?.phone_number);
        setCheck(1);
      });
  }, []);
  const handleSubmit = () => {
    console.log("email = ", email);
    console.log("phone = ", phone);
  };
  if (localStorage.getItem("macha-user") === null)
    return <Navigate to="/" replace={false} />;

  return (
    <div>
      <div class="w-full sm:mt-6 max-sm:px-3 lg:mt-0 sm:ml-6">
        <div class="w-full flex flex-col">
          <form onSubmit={handleSubmit} className="w-full justify-center mr-8">
            <div className="">
              <div className="mb-4">
                <label className="block text-gray-600 font-semibold text-sm leading-none mb-3 cursor-pointer">
                  Name
                </label>
                <input
                  className="w-2/3 lg:w-96 border-black border-t-2 text-sm px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-600 font-semibold text-sm leading-none mb-3 cursor-pointer">
                  Email
                </label>

                <input
                  className="w-2/3 lg:w-96 border-black border-t-2  text-sm px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={userData.signinType === "email"}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-600 font-semibold text-sm leading-none mb-3 cursor-pointer">
                  Contact Number
                </label>

                <input
                  className="w-2/3 lg:w-96 border-black border-t-2 text-sm px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  disabled={userData.signinType === "phone"}
                />
              </div>
              <div className="relative">
                <button
                  data-variant="flat"
                  type="button"
                  onClick={handleSubmit}
                  className="text-[13px] mb-3 bg-gray-300 md:text-sm leading-4 inline-flex items-center cursor-pointer transition ease-in-out duration-300 font-semibold font-body text-center justify-center border-0 border-transparent rounded-md placeholder-white focus-visible:outline-none focus:outline-none focus:bg-opacity-80 bg-heading text-black px-5 md:px-6 lg:px-8 py-4 md:py-3.5 lg:py-4 hover:text-white hover:bg-gray-600 hover:shadow-cart h-13 mt-3"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
