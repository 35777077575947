import React from "react";
import { BiRupee } from "react-icons/bi";

const SellerProduct = ({ item, handleID }) => {
  return (
    <div className=" pb-3 h-[330px] sm:h-[425px] hover:shadow-xl transition duration-200 ease-in-out transform hover:-translate-y-1 md:hover:-translate-y-1.5">
      <div className="overflow-hidden h-[80%]">
        {/* <img
          className="h-full w-80 hover:scale-110 ease-in-out duration-300	"
          alt=""
          src={item?.productImageUrls[0]?.imgURL}
        /> */}
        <img
          className="h-full w-80 	"
          alt=""
          src={item?.productImageUrls[0]?.imgURL}
        />
      </div>
      <div className="h-[20%]">
        <div className="py-3   h-[50%]">
          <button
            type="button"
            className="inline-block  bg-primary   text-heading text-[12px] sm:text-[18px] lg:text-[20px] uppercase "
            data-te-toggle="tooltip"
            data-te-placement="bottom"
            data-te-ripple-init
            data-te-ripple-color="light"
            title={item?.productName}
          >
            {item?.productName.substring(0, 16)}...
          </button>
        </div>
        <div className="flex items-center">
          <div className="text-[18px] font-[700] pt-3 pb-6 flex items-center pr-3">
            <BiRupee /> {item?.offerPrice}
          </div>
          <div className="text-[18px] font-[500] pt-3 pb-6 flex items-center ltr:pl-2.5 rtl:pr-2.5 -mt-0.5 md:mt-0 relative text-gray-400">
            <span className="w-full bg-black border border-gray-400 absolute "></span>
            <BiRupee /> {item?.productPrice}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerProduct;
