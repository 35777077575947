import React from "react";
import FirstInfoSection from "../../Components/WebsiteView/SellerPage/FirstInfoSection";
import FirstRightInfoSection from "../../Components/WebsiteView/SellerPage/FirstRightInfoSection";
import Header from "../../Components/WebsiteView/SellerPage/Header";
import KeyPoints from "../../Components/WebsiteView/SellerPage/KeyPoints";
import SecondLineInfoSection from "../../Components/WebsiteView/SellerPage/SecondLineInfoSection";
import SlideShow from "../../Components/WebsiteView/SellerPage/SlideShow";
import Testimonials from "../../Components/WebsiteView/SellerPage/Testimonials";
import { Helmet } from "react-helmet";
import Footer from "../../Components/WebsiteView/SellerPage/Footer";
import StickyIcons from "../../Components/WebsiteView/SellerPage/StickyIcons";
import smallicon from "../../images/smallicon.png";
import tarang from "../../images/tarang.png";
import storelogo from "../../images/test/storelogo.jpg";

const SellerPage = ({
  sliderImages,
  convertedContent6,
  convertedContent7,
  convertedContent8,
  createMarkup,
  homeImage,
  homeImage2,
  footerData,
  headings,
  testimonialState,
  statisticsState,
  basecolor,
  selected,
  EnableValues,
  showSample,
}) => {
  function checkEmpty(x) {
    return x.name === "" || x.description === "";
  }

  let images =
    sliderImages?.length === 0 || showSample
      ? [
          "https://encrypted-tbn0.gstatic.com/imagesq=tbn:ANd9GcRkZj5FQtsUjntIvhbHgVj28ey20A88HzDyvpJ2GkreIw&ec=48600112",
          "https://i.ytimg.com/vi/ECXYtD4hVTU/maxresdefault.jpg",
        ]
      : sliderImages;

  let testimonials =
    testimonialState.filter(checkEmpty).length === 0 && !showSample
      ? testimonialState
      : [
          {
            user_profile:
              "https://expertphotography.b-cdn.net/wp-content/uploads/2018/10/cool-profile-pictures-retouching-1.jpg",
            description:
              "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis por incididunt ut labore et dolore mas",
            name: "lisa",
          },
          {
            user_profile:
              "https://expertphotography.b-cdn.net/wp-content/uploads/2018/10/cool-profile-pictures-retouching-1.jpg",
            description:
              "incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis por incididunt ut labore et dolore mas",
            name: "john",
          },
          {
            user_profile:
              "https://expertphotography.b-cdn.net/wp-content/uploads/2018/10/cool-profile-pictures-retouching-1.jpg",
            description:
              "Consectetur adipiscing elit, sed do eiusmodet dolore mas",
            name: "morgan",
          },
        ];

  function checkEmpty2(x) {
    return x.number === "" || x.title === "";
  }

  const sellerData =
    statisticsState.filter(checkEmpty2).length === 0 && !showSample
      ? statisticsState
      : [
          {
            number: "4+",
            title: "years in business",
          },
          {
            number: "40+",
            title: "packages served",
          },
          {
            number: "150+",
            title: "customers served",
          },
          {
            number: "15+",
            title: "product varieties",
          },
        ];

  console.log(homeImage, "homeImage");
  return (
    <div className="flex flex-col">
      <Header
        basecolor={basecolor}
        page={"home"}
        logo={
          selected?.storeLogo
            ? selected?.storeLogo
            : "https://global-uploads.webflow.com/5e157548d6f7910beea4e2d6/610cd6de3ce0531d38b728fb_2.png"
        }
      />
      <StickyIcons icon={smallicon} orgicon={tarang} />

      {(EnableValues.enabled || showSample) && <SlideShow images={images} />}

      {(EnableValues.enabled2 || showSample) && (
        <FirstInfoSection
          image={
            homeImage === null || homeImage === undefined || showSample
              ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgOxjOe0OBPCqY33K8eI89TW-nZrNaAi6eJmZiOMghsw&ec=48600112"
              : URL.createObjectURL(homeImage)
          }
          heading={
            headings?.Heading1 === "" || showSample
              ? "Lorem ipsum dolor sit amet"
              : headings?.Heading1
          }
          subheading={
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vel lectus in purus pellentesque volutpat id nec massa. Donec ultrices dapibus cursus. Suspendisse potenti. Etiam sed turpis a justo rhoncus sollicitudin aliquam ut purus. Aenean sed augue sit amet enim vestibulum congue vitae sed neque. Sed pulvinar, sem non ornare accumsan, odio enim facilisis risus, ac bibendum dui lorem sed enim"
          }
          convertedContent6={!showSample && convertedContent6}
          createMarkup={createMarkup}
        />
      )}
      {(EnableValues.enabled4 || showSample) && (
        <FirstRightInfoSection
          image={
            homeImage2 === null || homeImage2 === undefined || showSample
              ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdrzyfmWhrKI88z-Bq6hcZCF112bZ5XxABHU8BGAQ1EA&ec=48600112"
              : URL.createObjectURL(homeImage2)
          }
          heading={
            headings?.Heading2 === "" || showSample
              ? "Lorem ipsum dolor sit amet"
              : headings?.Heading2
          }
          subheading={
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vel lectus in purus pellentesque volutpat id nec massa. Donec ultrices dapibus cursus. Suspendisse potenti. Etiam sed turpis a justo rhoncus sollicitudin aliquam ut purus. Aenean sed augue sit amet enim vestibulum congue vitae sed neque. Sed pulvinar, sem non ornare accumsan, odio enim facilisis risus, ac bibendum dui lorem sed enim"
          }
          convertedContent7={!showSample && convertedContent8}
          createMarkup={createMarkup}
        />
      )}
      {(EnableValues.enabled3 || showSample) && (
        <SecondLineInfoSection
          basecolor={basecolor}
          infoLine={"Lorem ipsum dolor sit amet, consectetur adipiscing elit"}
          convertedContent={!showSample && convertedContent7}
          createMarkup={createMarkup}
        />
      )}
      {(EnableValues.statsEnabled || showSample) && (
        <KeyPoints labelValueData={sellerData} />
      )}

      {(EnableValues.testimonialsEnabled || showSample) && (
        <Testimonials thoughts={testimonials} />
      )}
      <Footer
        email={
          footerData.email === "" || showSample
            ? "machapoint.com"
            : footerData.email
        }
        phone={
          footerData.phone === "" || showSample
            ? "9999999999"
            : footerData.phone
        }
        orgname={!showSample && "WeTarang"}
        sellername={
          selected?.storeName && !showSample
            ? selected?.storeName
            : "Lorem ipsum"
        }
        insta={
          footerData.instagramLink === "" || showSample
            ? "https://google.com"
            : footerData.instagramLink
        }
        facebooklink={
          footerData.instagramLink === "" || showSample
            ? "https://google.com"
            : footerData.instagramLink
        }
      />
    </div>
  );
};

export default SellerPage;
