import React, { useEffect } from "react";
import { Router, useParams } from "react-router-dom";
import Footer from "../Base/Footer";
import { generateInvoice } from "../../Helper/Order";
import { useState } from "react";
import MachaHeader from "../Base/MachaHeader";

const DisplayLine = ({ keyname, value }) => {
  return (
    <div className="flex items-center w-full font-light">
      <div className="w-5/12 flex items-center justify-between">
        <p>{keyname}</p>
        <span>:</span>
      </div>
      <div className="w-7/12 text-gray-700">{value}</div>
    </div>
  );
};

const ChipDetail = ({ keyname, value }) => {
  return (
    <div className="rounded-md flex-1 bg-gray-100 flex flex-col space-y-4 px-3 m-0 py-5">
      <p className="text-gray-600 text-[14px]">{keyname}</p>
      <p className=" text-black text-[17px] font-semibold ">{value}</p>
    </div>
  );
};

const ItemStrip = ({
  image,
  productName,
  itemprice,
  itemquantity,
  totalprice,
  item,
}) => {
  return (
    <div className="flex items-center px-6 py-4">
      <div className="w-6/12 flex items-center space-x-2">
        <img className="w-16 h-16 rounded-sm " src={image} alt="" />
        <div className="flex flex-col space-y-1">
          <p className="font-light text-gray-600">
            <a
              href={`/product/${item?.product_id?.productName.replace(
                /\s+/g,
                "-"
              )}/${item?.product_id?._id}`}
              target={"_blank"}
              rel="noreferrer"
            >
              {productName}x
            </a>

            <span className="font-semibold text-black">{itemquantity} pc</span>
          </p>
          <p className="text-black font-semibold">₹{itemprice}</p>
        </div>
      </div>
      <div className="w-3/12 text-center">{itemquantity}</div>
      <div className="w-3/12 text-center">₹{totalprice}</div>
    </div>
  );
};

const OrderDetail = () => {
  const params = useParams();
  const { orderId } = params;
  const [order, setOrder] = useState({});

  async function getOrder() {
    const response = await generateInvoice(orderId);

    if (response?.status_code === 200) {
      setOrder(response?.data);
    }
  }

  console.log(order, "order");
  useEffect(() => {
    if (orderId) {
      getOrder();
    }
  }, []);

  console.log(order);

  return (
    <div>
      <MachaHeader />
      <div className="sm:w-4/6 w-full mx-auto py-4 px-3 sm:py-12 textcolor">
        <div className="flex items-center w-full justify-between">
          <div className="flex items-center space-x-2">
            <p>Status:</p>
            <div className="px-4 py-1 rounded-full backgroundcolor text-white">
              {order?.order_status}
            </div>
          </div>
          <a className=" underline" href="/user-panel">
            Back to home
          </a>
        </div>
        <div className="sm:flex items-stretch sm:space-x-4 my-4 w-full justify-between">
          <ChipDetail keyname={"Order number"} value={order?._id} />
          <ChipDetail
            keyname={"Date"}
            value={order?.order_date?.substring(0, 10)}
          />
          <ChipDetail keyname={"Total"} value={"₹" + order?.total_amount} />
          <ChipDetail keyname={"Payment method"} value={order?.payment_type} />
        </div>
        <div className="flex w-full my-8  relative items-stretch ">
          <div className="flex flex-col flex-1  rounded-sm border px-3 py-5 space-y-4">
            <h2 className="text-2xl font-semibold">Total Amount</h2>
            <DisplayLine
              keyname={"Subtotal"}
              value={"₹" + order?.total_amount}
            />
            <DisplayLine keyname={"Shipping Charge"} value={"₹0"} />
            <DisplayLine keyname={"Tax"} value={"₹0"} />
            <DisplayLine keyname={"Discount"} value={"₹0"} />
            <DisplayLine keyname={"Total"} value={"₹" + order?.total_amount} />
          </div>
          <div className="flex flex-col flex-1 rounded-sm border px-3 py-5 space-y-4">
            <h2 className="text-2xl font-semibold">Order details</h2>
            <DisplayLine
              keyname={"Total Item"}
              value={`${order?.products?.length} + Items`}
            />
            <DisplayLine
              keyname={"Shipping Address"}
              value={order?.address_id?.main_address}
            />
            {/* <DisplayLine
              keyname={"Billing Address"}
              value={"fds, fds, ds, ds, sdf"}
            /> */}
          </div>
        </div>
        <div className="flex flex-col border rounded-md w-full">
          <div className="w-full px-6 py-4 flex items-center bg-gray-100">
            <div className="w-6/12 font-semibold">Product</div>
            <div className="w-3/12 text-center font-semibold">Quantity</div>
            <div className="w-3/12 text-center font-semibold">Price</div>
          </div>
          <div className="flex flex-col ">
            {order &&
              order?.products?.map((item) => {
                return (
                  <ItemStrip
                    item={item}
                    image={item?.product_id?.productImageUrls[0]?.imgURL}
                    productName={item?.product_id?.productName}
                    itemprice={item?.product_id?.offerPrice}
                    itemquantity={item?.quantity}
                    totalprice={order?.total_amount}
                  />
                );
              })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OrderDetail;
