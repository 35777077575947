import React, { useState, useEffect } from "react";
import axios from "axios";
import ManageHeader from "../Manage/ManageHeader";
import { MdCalendarToday, MdAccessTimeFilled, MdEdit } from "react-icons/md";
import { AddSubCat } from "./AddSubCat";
import { APIURL } from "../../backend";
const red = "bg-red-400 rounded-md px-2 py-1 text-white";
const green = "bg-green-400 rounded-md px-2 py-1 text-white";
const addnew =
  "mb-2 text-xs bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded";
const back =
  "mb-2 text-xs bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded mr-6";
const date = {
  aDate: "Apr 11th 2022",
  aTime: "10:18 PM",
  uDate: "Jun 11th 2022",
  uTime: "10:22 PM",
};
const updSubCatURL = `${APIURL}/category/update-subcategory`;
const getCatURL = `${APIURL}/category/get-all-categories`;

export const SubCategories = () => {
  const [check, setCheck] = useState(0);
  const [subCheck, setSubCheck] = useState(0);

  const [catData, setData] = useState({});
  const handleClick = () => {
    setAdd(!add);
    if (name === "+ Add New") setName("Back");
    else setName("+ Add New");
  };
  const [add, setAdd] = useState(0);
  const getSubCatURL = `${APIURL}/category/get-all-subcategories`;
  const [subCatData, setSubData] = useState({});
  const [name, setName] = useState("+ Add New");
  const [subDesc, setSubDesc] = useState("+ Add New");
  const [status, setStatus] = useState("+ Add New");
  const [subID, setSubID] = useState("+ Add New");
  const [subName, setSubName] = useState("+ Add New");
  const [modal, setModal] = useState(0);
  const [catID, setCatId] = useState("");

  const handleCat = (val) => {
    axios.get(getSubCatURL + "?categoryID=" + val).then((response) => {
      setSubData(response.data.data);
      setSubCheck(1);
      setCatId(val);
    });
  };

  const handleUpd = () => {
    axios
      .post(
        updSubCatURL,
        {
          subcategoryName: subName,
          subcategoryDescription: subDesc,
          subcategoryStatus: status,
          subcategoryID: subID,
        },
        {
          headers: {
            Authorization: "f5bcb965-7324-4544-914c-d69d5b16bc92",
          },
        }
      )
      .then((res) => {
        handleCat(catID);
      });
  };
  useEffect(() => {
    axios.get(getCatURL, {}).then((response) => {
      setData(response.data.data);
      setCheck(1);
    });
  }, []);

  const updVal = (data) => {
    setModal(1);
    setSubDesc(data.subcategoryDescription);
    setStatus(data.subcategoryStatus);
    setSubID(data._id);
    setSubName(data.subcategoryName);
  };
  return (
    <div>
      <div className="text-right">
        <button
          onClick={handleClick}
          className={name === "Back" ? back : addnew}
        >
          {name}
        </button>
      </div>
      {name === "+ Add New" ? (
        <div>
          <ManageHeader heading={"Sub Categories"} />
          <div class="form-group">
            <label className="">Product Category:</label>
            <select
              onChange={(e) => handleCat(e.target.value)}
              className="mt-2 w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
            >
              <option default disabled selected>
                Please Select
              </option>
              {check === 1 &&
                catData.map((data, index) => (
                  <option value={data._id}>{data.categoryName}</option>
                ))}
            </select>
          </div>
          <div className="my-6 w-full rounded-md border border-gray-200">
            <table className="table-auto  w-full  bg-[#f7f8f9] rounded-lg">
              <thead>
                <tr className="text-sm font-thin">
                  <th className="font-normal py-3">ID</th>
                  <th className="font-normal py-3">Category ID</th>
                  <th className="font-normal py-3">Image</th>
                  <th className="font-normal py-3">Details</th>
                  <th className="font-normal py-3">Status</th>
                  <th className="font-normal py-3">Featured</th>
                  <th className="font-normal py-3">Added On/ Updated On</th>
                  <th className="font-normal py-3">Actions</th>
                </tr>
              </thead>
              {subCheck === 1 &&
                subCatData.map((data, index) => (
                  <tbody className="bg-white divide-y">
                    <tr className="text-xs h-32 items-center">
                      <td className="text-center  text-gray-500 py-3">
                        {index + 1}
                      </td>
                      <td className="text-center  text-gray-500 ">
                        {data._id}{" "}
                      </td>
                      <td className=" text-gray-500">{} </td>
                      <td className="text-left  text-gray-500 w-[450px]">
                        <span className="font-bold"> Name: </span>
                        <span>{data.subcategoryName}</span>
                        <br />
                        <br />
                        <span className="font-bold mt-96"> Description: </span>
                        <span>{data.subcategoryDescription}</span>
                        <br />
                        <br />

                        <span className="font-bold mt-4"> Parent: </span>
                        <span>{data.category.categoryName}</span>
                        <br />
                        <br />
                      </td>

                      <td className="text-center  text-gray-500 ">
                        <span className={data.subcategoryStatus ? green : red}>
                          {data.subcategoryStatus ? "Active" : "Inactive"}
                        </span>
                      </td>
                      <td className="text-center  text-gray-500 ">
                        <span className="bg-red-400 rounded-md px-2 py-1 text-white">
                          No
                        </span>
                      </td>
                      <td className=" text-gray-500 w-32">
                        <span className="flex text-center items-center">
                          <MdCalendarToday className="" />
                          {date.aDate}
                        </span>
                        <span className="flex text-center items-center mt-1">
                          <MdAccessTimeFilled className="" />
                          {date.aTime}
                        </span>
                        <span className="flex text-center items-center mt-1">
                          <MdCalendarToday className="" />
                          {date.uDate}
                        </span>
                        <span className="flex text-center items-center mt-1">
                          <MdAccessTimeFilled className="" />
                          {date.uTime}
                        </span>
                      </td>
                      <td className="text-center">
                        <button onClick={() => updVal(data)}>
                          <MdEdit className="text-blue-500" />
                        </button>
                      </td>
                      {modal === 1 ? (
                        <>
                          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-full my-6 mx-auto max-w-3xl">
                              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                  <h3 className="text-3xl font-semibold">
                                    Edit Subcategory: {subName}
                                  </h3>
                                  <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => setModal(false)}
                                  ></button>
                                </div>
                                <div className="relative p-6 flex-auto">
                                  <div className="mt-4">
                                    <label>Description</label>
                                    <input
                                      type="text"
                                      className="w-full block border-black border-t-2 text-sm px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                                      value={subDesc}
                                      onChange={(e) =>
                                        setSubDesc(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div class="form-group mt-4">
                                    <label class="relative inline-flex items-center cursor-pointer">
                                      <input
                                        type="checkbox"
                                        value={status}
                                        class="sr-only peer"
                                        onChange={(e) => setStatus(!status)}
                                        checked={status}
                                      />
                                      <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                      <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                                        Status
                                      </span>
                                    </label>
                                  </div>
                                  <div class="form-group mb-6"></div>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                  <button
                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => setModal(false)}
                                  >
                                    Close
                                  </button>
                                  <button
                                    className="bg-slate-500 text-white active:bg-slate-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={(e) => {
                                      handleUpd();
                                      setModal(0);
                                    }}
                                  >
                                    Save Changes
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="opacity-10 fixed inset-0 z-40 bg-black"></div>
                        </>
                      ) : null}
                    </tr>
                  </tbody>
                ))}
            </table>
          </div>
        </div>
      ) : (
        <AddSubCat />
      )}
    </div>
  );
};
