import React, { useEffect, useState, Fragment } from "react";
import { FaShoppingCart, FaUserAlt } from "react-icons/fa";
import { Transition, Menu } from "@headlessui/react";
import { isAuthenticated, signout } from "../../Helper/Auth";
import { useNavigate } from "react-router-dom";
import { RxHamburgerMenu, RxCross2 } from "react-icons/rx";
import Drawer from "./Drawer";
import { toast } from "react-hot-toast";
import { getMyCart, removeFromCart } from "../../Helper/Order";
import CartProduct from "../Cart/CartProduct";
import { Helmet } from "react-helmet";

const OrganisationHeader = ({
  organisationId,
  page,
  logo,
  selected = "blue-500",
}) => {
  const [isAuth, setIsAuth] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [cartData, setCartData] = useState([]);
  const [cartOpen, setCartOpen] = React.useState(false);

  async function getMyCartData() {
    let user = await isAuthenticated();

    if (user.token) {
      const response = await getMyCart();
      console.log(response, "response cart");
      if (response?.status_code === 200) {
        // console.log(response.data, "response carts");

        setCartData(response.data);
      } else {
        toast.error("error fetching cart data");
      }
    }
  }

  async function removeFromCartHandler(id) {
    const response = await removeFromCart({ productID: id });

    if (response.status_code === 200) {
      toast.success("Product removed from cart");
      getMyCartData();
    } else {
      toast.error("Something went wrong");
    }
  }

  const toCheckout = () => {
    navigator("/checkout", { state: cartData });
  };

  useEffect(() => {
    getMyCartData();
  }, [cartOpen]);

  async function handleLogout() {
    await isAuthenticated().then((data) => {
      setIsAuth(data);
    });
  }
  const navigator = useNavigate();

  async function handleSignout() {
    await signout().then((data, err) => {
      setIsAuth(false);
      navigator("/");
    });
  }

  useEffect(() => {
    handleLogout();
  }, []);
  return (
    <div className="w-full h-full shadow-lg flex flex-col ">
      <div className="flex items-center justify-between lg:py-6 sm:py-4 py-4  lg:px-10 sm:px-6 px-3 xl:px-20">
        <div className=" flex items-center">
          <a href={`/organisation/wetarang/${organisationId}`}>
            <div className="  lg:h-8   h-5 ">
              <img src={logo} alt="" className=" h-full " />
            </div>
          </a>
        </div>
        {/* <div className="lg:w-2/4 px-1  w-[40%] sm:w-[40%] flex items-center justify-center">
          <div className="w-[100%] flex items-center ">
            <input
              className="w-full sm:w-[90%] drop-shadow-sm	border-b focus:border-b outline-none focus:border-[] border-[#3D3C3C] rounded-sm"
              alt=""
            />
            <div className="">
              <AiOutlineSearch className="text-[20px]" />
            </div>
          </div>
        </div> */}
        <div className="flex items-center justify-between space-x-6 max-lg:hidden">
          <div className="max-sm:hidden ">
            <a href={`/organisation/wetarang/${organisationId}`}>
              <button
                className={
                  page === "home"
                    ? `w-full text-center   text-[#454598] font-[700]`
                    : `w-full text-center  hover:text-[#454598]`
                }
              >
                Discover
              </button>
            </a>
          </div>
          <div className="max-sm:hidden">
            <a href={`/organisation/shop/wetarang/${organisationId}`}>
              <button
                className={
                  page === "shop"
                    ? `w-full text-center   text-[#454598] font-[700]`
                    : `w-full text-center hover:text-[#454598] `
                }
              >
                Store
              </button>
            </a>
          </div>
          <div className="max-sm:hidden">
            <a href={`/organisation/about/wetarang/${organisationId}`}>
              <button
                className={
                  page === "story"
                    ? `w-full text-center   text-[#454598] font-[700]   `
                    : `w-full text-center hover:text-[#454598] `
                }
              >
                {" "}
                About
              </button>
            </a>
          </div>
          <div className=" flex items-center max-sm:justify-between  space-x-6">
            {isAuth === false ? (
              <div className="  text-right">
                <a href="https://machapoint.com/login">
                  <button className="w-full text-center">SignIn</button>
                </a>
              </div>
            ) : (
              <div className="  text-right">
                <Menu
                  style={{ zIndex: 10000 }}
                  as="div"
                  className="relative inline-block text-left"
                >
                  <div>
                    <Menu.Button className="inline-flex w-full justify-center rounded-md  px-4 py-2 text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                      <FaUserAlt
                        size={20}
                        onMouseOver={({ target }) =>
                          (target.style.color = "#454598")
                        }
                        onMouseOut={({ target }) =>
                          (target.style.color = "black")
                        }
                        className="text-red hover:text-[#454598]"
                        style={{ color: "#3D3C3C" }}
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="px-1 py-1 ">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="/user-panel"
                              className={`${
                                active
                                  ? "text-white hover:bg-[#454598]"
                                  : "text-gray-900"
                              } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            >
                              My Profile
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                      <div className="px-1 py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={handleSignout}
                              className={`${
                                active
                                  ? "hover:bg-[#454598] text-white"
                                  : "text-gray-900"
                              } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            >
                              Logout
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            )}
            <div>
              <FaShoppingCart
                size={20}
                style={{ color: "#3D3C3C" }}
                onMouseOver={({ target }) => (target.style.color = "#454598")}
                onMouseOut={({ target }) => (target.style.color = "black")}
                onClick={() => setCartOpen(true)}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-6 lg:hidden ">
          <div>
            <FaShoppingCart
              size={20}
              style={{ color: "#3D3C3C" }}
              onMouseOver={({ target }) => (target.style.color = "#454598")}
              onMouseOut={({ target }) => (target.style.color = "black")}
              onClick={() => setCartOpen(true)}
            />
          </div>
          <button
            className="bg-blue-600 block lg:hidden text-white rounded px-4 py-1"
            onClick={() => setIsOpen(true)}
          >
            <RxHamburgerMenu />
          </button>
        </div>
        <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
          <div className="flex  flex-col justify-between h-full relative">
            <div className="flex flex-col divide-y space-y-2 ">
              <a
                href={`/organisation/wetarang/${organisationId}`}
                className="px-3 py-1 text-blue-600"
              >
                Discover
              </a>
              <a
                href={`/organisation/shop/wetarang/${organisationId}`}
                className="px-3 py-1 text-blue-600"
              >
                Store
              </a>
              <a
                href={`/organisation/about/wetarang/${organisationId}`}
                className="px-3 py-1 text-blue-600"
              >
                About
              </a>
            </div>
            {isAuth === false && (
              <a href="/login">
                <div className=" py-2 text-white bg-blue-600 text-center relative">
                  Signin
                </div>
              </a>
            )}
          </div>
        </Drawer>
        <Drawer
          isOpen={cartOpen}
          setIsOpen={setCartOpen}
          lg={true}
          clip={"full"}
        >
          <div className=" h-full  overflow-hidden">
            <div className="flex items-center justify-between mx-6">
              <div className="font-bold text-xl md:text-2xl m-0 text-heading">
                Shopping Cart
              </div>
              <RxCross2
                size={20}
                className=""
                onClick={() => setCartOpen(false)}
              />
            </div>
            {cartData.length === 0 && (
              <div className="text-[20px] font-sans px-6 pt-6">
                No Products Added to Cart
              </div>
            )}
            <div className="pt-6 overflow-y-scroll h-[562px]">
              {cartData &&
                cartData.length > 0 &&
                cartData.map((item, index) => {
                  return (
                    <CartProduct
                      item={item}
                      IN={index}
                      cartData={cartData}
                      setCartData={setCartData}
                      removeFromCartHandler={removeFromCartHandler}
                    />
                  );
                })}
            </div>
          </div>
          <div className="py-8 w-full relative   botton-0 px-6  ">
            {cartData.length > 0 && (
              <button
                className=" bg-black text-white hover:bg-gray-600 w-full py-4 rounded-lg"
                onClick={() => {
                  toCheckout();
                }}
              >
                <div>Proceed to Checkout</div>
              </button>
            )}
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default OrganisationHeader;
