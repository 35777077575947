import React, { useEffect, useState } from "react";
import { EditorState, convertFromRaw } from "draft-js";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";

const FirstInfo = ({ infoImage, infoData }) => {
  const [convertedContent, setConvertedContent] = useState(null);
  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  useEffect(() => {
    if (infoData !== null) {
      const blocks = infoData && infoData.blocks;

      const entityMap = {};

      const contentState = convertFromRaw({ blocks, entityMap });
      const editorState = EditorState.createWithContent(contentState);
      let html = convertToHTML(editorState.getCurrentContent());
      setConvertedContent(html);
    }
  }, [infoData]);

  return (
    <div className="w-full flex flex-col md:flex-row relative  items-center justify-center  md:pt-24 pt-4  xl:px-20 lg:px-10 px-6 ">
      <div className="w-4/5 mx-auto md:w-[50%] mr-10 rightcover origin-left  relative h-full px-3 ">
        <img
          src={infoImage}
          alt=""
          className="w-full mx-auto h-full object-fill"
        />
      </div>
      <div className="w-full mx-auto md:w-[50%]  leftcover origin-right  bg-white text-2xl font-medium   flex flex-col space-y-4 items-center justify-center">
        <p className="text-gray-600 text-center md:text-left xl:text-2xl lg:text-xl md:text-lg text-sm   my-4 md:my-0">
          <div
            className="p-6"
            dangerouslySetInnerHTML={createMarkup(convertedContent)}
          ></div>
        </p>
      </div>
    </div>
  );
};

export default FirstInfo;
