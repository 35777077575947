import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";

import "@fontsource/dancing-script";
import { IoHomeOutline } from "react-icons/io5";
import { getMyOrders } from "../../Helper/Order";
import { toast } from "react-hot-toast";
import { BiRupee } from "react-icons/bi";

const Orders = () => {
  const [orders, setorders] = useState([]);

  async function getOrders() {
    const orders = await getMyOrders();
    console.log(orders, "orders");
    if (orders.status_code === 200) {
      setorders(orders.data);
    } else {
      toast.error(orders.message);
    }
  }

  useEffect(() => {
    getOrders();
  }, []);

  if (localStorage.getItem("macha-user") === null) {
    return <Navigate to="/" replace={false} />;
  }

  return (
    <div>
      <div class="w-full sm:mt-6 lg:mt-0 sm:ml-6 textcolor">
        <div class="w-full mt-6 lg:mt-0">
          <h2 class="text-lg md:text-xl xl:text-2xl font-bold text-heading mb-6 xl:mb-8 mr-32 pl-3 sm:pl-0">
            Orders
          </h2>

          <div class="w-full flex flex-col">
            <table>
              <thead class="text-sm lg:text-base">
                <tr>
                  <th class="bg-gray-100 p-4 text-heading max-sm:hidden  font-semibold ltr:text-left rtl:text-right ltr:first:rounded-tl-md rtl:first:rounded-tr-md w-24">
                    Order
                  </th>
                  <th class="bg-gray-100 p-4 text-heading font-semibold ltr:text-left rtl:text-right lg:text-center w-40 xl:w-56">
                    Date
                  </th>
                  <th class="bg-gray-100 p-4 text-heading font-semibold ltr:text-left rtl:text-right lg:text-center w-36 xl:w-44">
                    Status
                  </th>
                  <th class="bg-gray-100 p-4 text-heading font-semibold ltr:text-left rtl:text-right lg:text-center">
                    Total
                  </th>
                  <th class="bg-gray-100 p-4 text-heading font-semibold ltr:text-left rtl:text-right ltr:lg:text-right rtl:lg:text-left ltr:last:rounded-tr-md rtl:last:rounded-tl-md w-24">
                    Actions
                  </th>
                </tr>
              </thead>
              {orders && orders?.length === 0 && (
                <div className="p-3">No orders Yet</div>
              )}
              {orders &&
                orders?.length > 0 &&
                orders?.map((order, index) => {
                  return (
                    <tbody class="text-sm lg:text-base">
                      <tr class="border-b border-gray-300 last:border-b-0">
                        <td class="px-4 py-5 ltr:text-left rtl:text-right hidden sm:block ">
                          <a
                            class="underline hover:no-underline text-body"
                            href="/my-account/orders/tX6aAqopk3yk"
                          >
                            <div className="flex items-center ">
                              {order?._id}
                            </div>
                          </a>
                        </td>
                        <td class="ltr:text-left rtl:text-right max-sm:text-[10px] lg:text-center px-4 py-5 text-heading">
                          {order?.order_date.substring(0, 10)}
                        </td>
                        <td class="ltr:text-left rtl:text-right lg:text-center max-sm:text-[10px] px-4 py-5 text-heading">
                          {order?.order_status}
                        </td>
                        <td class="ltr:text-left rtl:text-right lg:text-center px-4 py-5 text-heading ">
                          <div className="sm:text-[15px] text-[10px] font-[700]   pr-3">
                            <div className="flex  items-center">
                              <BiRupee /> {order?.total_amount}
                            </div>{" "}
                            <div>for {order?.products.length} items</div>
                          </div>
                        </td>
                        <td class="ltr:text-right rtl:text-left px-4 py-5 text-heading">
                          <a
                            class="text-sm leading-4 bg-heading text-white backgroundcolor px-4 py-2.5 inline-block rounded-md hover:text-white hover:bg-gray-600"
                            href={`/my-account/orders/` + order?._id}
                          >
                            view
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
