import React from "react";

const FirstRightInfoSection = ({
  image,
  heading,
  subheading,
  convertedContent7,
  createMarkup,
}) => {
  return (
    <div className="w-full flex-col-reverse md:flex-row flex items-center md::h-[500px] mb-20 px-4 md:px-28">
      <div className="md:w-1/2 w-full   p-6 grow  text-center flex flex-col items-center justify-center space-y-6">
        <h1 className="capitalize lg:text-3xl md:text-3xl text-2xl xl:text-4xl  font-bold text-black">
          {heading}
        </h1>
        {!convertedContent7 ||
        createMarkup(convertedContent7).__html.toString() === "<p></p>" ? (
          <div>
            {subheading && (
              <h1 className="text-2xl font-medium   text-gray-700">
                {subheading}
              </h1>
            )}
          </div>
        ) : (
          <div
            className="p-6"
            dangerouslySetInnerHTML={createMarkup(convertedContent7)}
          ></div>
        )}
      </div>
      <img
        className="min-h-[300px] md:min-h-[500px] md:h-full sm:ml-10 w-4/5 mx-auto md:min-w-[30%] md:max-w-[40%] object-fill"
        src={image}
        alt=""
        crossOrigin="anonymous"
      />
    </div>
  );
};

export default FirstRightInfoSection;
