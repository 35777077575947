import React from "react";

const ShowCategories = ({ category }) => {
  return (
    <div className=" bg-white  px-6 2xl:py-3 xl:py-2 py-1 rounded-[10px] hover:bg-gray-300 w-[95%] xl:w-full">
      <a href={`/category/${category._id}`}>
        <div className="flex items-center  justify-between">
          <div className="flex items-center space-x-2">
            {/* <img
            src="https://static.toiimg.com/photo/90399663.cms"
            alt=""
            className="rounded-full w-[4rem] h-[4rem]"
          /> */}

            <div className="text-md hover:underline cursor-pointer  pl-4">
              {category && category.categoryName}
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default ShowCategories;
