import React from "react";
import Block from "../Builder/Block";
import Headline from "../Builder/Headline";
import TextInput from "../Builder/TextInput";

const FooterData = ({ handleFooterData, footerData }) => {
  return (
    <Block>
      <Headline headline={"Footer Data"} />
      <div className="grid sm:grid-cols-4 grid-cols-2  gap-6 ">
        <div className="basis-1/2">
          <TextInput
            label={"Email"}
            tagname={"Email"}
            placeholder={"Enter email"}
            handleChange={handleFooterData}
            value={footerData.email}
            type="obj"
            thing={"email"}
          />
        </div>
        <div className="basis-1/2">
          <TextInput
            label={"Phone"}
            tagname={"Phone"}
            placeholder={"Enter Phone"}
            handleChange={handleFooterData}
            value={footerData.phone}
            type="obj"
            thing={"phone"}
          />
        </div>
        <div className="basis-1/2">
          <TextInput
            label={"facebook"}
            tagname={"Facebook link"}
            placeholder={"Enter Facebooklink"}
            handleChange={handleFooterData}
            value={footerData.facebookLink}
            type="obj"
            thing={"facebookLink"}
          />
        </div>
        <div className="basis-1/2">
          <TextInput
            label={"instagram"}
            tagname={"Instagram Link"}
            placeholder={"Enter instagramlink"}
            handleChange={handleFooterData}
            value={footerData.instagramLink}
            type="obj"
            thing={"instagramLink"}
          />
        </div>
      </div>
    </Block>
  );
};

export default FooterData;
