import React, { useState, useEffect, Fragment } from "react";
import { toast } from "react-hot-toast";
import {
  createOrganisationJoinRequest,
  getAllOrgRequests,
  getAllOrganisations,
  getOrgRequestByUser,
} from "../../Helper/organisation";
import { Transition, Listbox, Dialog } from "@headlessui/react";
import { leaveOrganisation } from "../../Helper/store";

const AddStoresToOrganisation = ({ products = [], stores, getStores }) => {
  const [organisation, setOrganisation] = useState([]);

  const [selected, setSelected] = useState();
  const [orgRequest, setOrgRequest] = useState([]);
  const [updatedStores, setUpdatedStores] = useState([]);
  let [isOpen, setIsOpen] = useState(false);
  let [selectedOrg, setSelectedOrg] = useState();

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  async function getOrganisations() {
    const data = await getAllOrganisations();

    if (data.status_Code === 200) {
      setOrganisation(data.data);
      setSelected(data.data[0]);
    } else {
      toast.error(data.message);
    }
  }
  async function getOrgRequest() {
    const data = await getAllOrgRequests();

    // if (data.status_code === 200) {
    //   setOrgRequest(data.data);
    // } else if (data.status_code === 400) {
    //   toast.error(data.message);
    // }
  }

  useEffect(() => {
    getOrganisations();
    getOrgRequest();
  }, []);

  async function leaveOrganisationHandler() {
    if (selectedOrg) {
      const data = await leaveOrganisation({
        store_id: selectedOrg._id,
      });

      if (data.status_code === 200) {
        toast.success(data.message);
        await getStores();
        await closeModal();
        // setTimeout(() => {
        //   window.location.reload(false);
        // }, 2500);
      } else {
        toast.error(data.message);
      }
    }
  }

  async function createRequest(store_id) {
    const data = await createOrganisationJoinRequest({
      store_id: store_id,
      organisation_id: selected._id,
    });
    console.log(data, "Important Data 222");

    if (data.status_code === 200) {
      toast.success(data.message);
      // setTimeout(() => {
      //   window.location.reload(false);
      // }, 2500);
    } else {
      toast.error(data.message);
    }
  }

  async function updateStores() {
    let newArr = [];

    if (orgRequest && orgRequest?.length > 0) {
      for (let i = 0; i < stores.length; i++) {
        const element = stores[i];

        const data = await orgRequest?.find(
          (item) => item.requester_id._id.toString() === element._id
        );

        if (data) {
          Object.assign(element, { orgStat: "alredy requested" });
        } else {
          Object.assign(element, { orgStat: "not requested" });
        }
        newArr.push(element);
      }

      setUpdatedStores(newArr);
    }
  }
  useEffect(() => {
    updateStores();
  }, [orgRequest]);

  // let newOrgArray = organisation.unshift(newObj);

  return (
    <div>
      <div className="sm:p-3 text-black text-[22px] font-[700]">
        Add Stores To Organisation
      </div>
      <div className="py-3">
        <div className="my-6 w-full rounded-md border border-gray-200">
          <table className="table-auto  w-full  bg-[#f7f8f9] rounded-lg">
            <thead>
              <tr className="sm:text-sm text-[12px] font-thin ">
                <th className="font-normal py-3">Store Logo</th>
                <th className="font-normal py-3">Store Name</th>
                <th className="font-normal py-3">Store Status</th>

                <th className="font-normal py-3">Store Organisation</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y">
              {updatedStores?.length === 0 &&
                stores?.map((org) => {
                  return (
                    <tr className="text-center ">
                      <td className="flex items-center justify-center w-full h-full py-10 ">
                        <img
                          className="sm:w-20 w-10  rounded-md "
                          alt=""
                          src={org?.storeLogo}
                        />
                      </td>
                      <td className="text-sm text-gray-500 ">
                        {org?.storeName}
                      </td>
                      <td className="text-sm text-gray-500">{org?.status}</td>
                      <td className="text-sm text-gray-500">
                        {org?.organisation === null ? (
                          org?.status === "pending" ? (
                            <div className="h-[158px] flex items-center justify-center">
                              <div className="font-[500] text-sm">
                                Wait till Admin Accepts
                              </div>
                            </div>
                          ) : org?.orgStat === "alredy requested" ? (
                            <div>Already requested</div>
                          ) : (
                            <div className="py-6">
                              <div className="font-[500] text-sm">
                                Select Organisation
                              </div>
                              <Listbox
                                value={selected}
                                onChange={setSelected}
                                style={{ zIndex: 10000 }}
                              >
                                <div className="relative mt-1 ">
                                  <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                    <span className="block truncate">
                                      {selected?.organisation_name}
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"></span>
                                  </Listbox.Button>
                                  <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                      {organisation &&
                                        organisation.map(
                                          (person, personIdx) => (
                                            <Listbox.Option
                                              key={personIdx}
                                              className={({ active }) =>
                                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                  active
                                                    ? "bg-amber-100 text-amber-900"
                                                    : "text-gray-900"
                                                }`
                                              }
                                              value={person}
                                            >
                                              {({ selected }) => (
                                                <>
                                                  <span
                                                    className={`block truncate ${
                                                      selected
                                                        ? "font-medium"
                                                        : "font-normal"
                                                    }`}
                                                  >
                                                    {person.organisation_name}
                                                  </span>
                                                  {selected ? (
                                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"></span>
                                                  ) : null}
                                                </>
                                              )}
                                            </Listbox.Option>
                                          )
                                        )}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </Listbox>
                              <div className="pt-3">
                                <button
                                  className="bg-black text-white px-4 py-2 rounded-md"
                                  onClick={() => createRequest(org._id)}
                                >
                                  Submit Request
                                </button>
                              </div>
                            </div>
                          )
                        ) : (
                          <div>
                            {org?.organisation?.organisation_name}
                            <div className="py-3">
                              <button
                                className="bg-black text-white p-2 rounded-lg"
                                onClick={() => {
                                  setSelectedOrg(org);
                                  openModal();
                                }}
                              >
                                Leave Organisation
                              </button>
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              {updatedStores?.map((org) => {
                return (
                  <tr className="text-center">
                    <td className="flex items-center justify-center pt-14">
                      <img
                        className="w-10 h-10 rounded-md"
                        alt=""
                        src={org?.storeLogo}
                      />
                    </td>
                    <td className="text-sm text-gray-500">{org?.storeName}</td>
                    <td className="text-sm text-gray-500">{org?.status}</td>
                    <td className="text-sm text-gray-500">
                      {org?.organisation === null ? (
                        org?.status === "pending" ? (
                          <div className="h-[158px] flex items-center justify-center">
                            <div className="font-[500] text-sm">
                              Wait till Admin Accepts
                            </div>
                          </div>
                        ) : org?.orgStat === "alredy requested" ? (
                          <div>Already requested</div>
                        ) : (
                          <div className="py-6">
                            <div className="font-[500] text-sm">
                              Select Organisation
                            </div>
                            <Listbox
                              value={selected}
                              onChange={setSelected}
                              style={{ zIndex: 10000 }}
                            >
                              <div className="relative mt-1 ">
                                <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                  <span className="block truncate">
                                    {selected?.organisation_name}
                                  </span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"></span>
                                </Listbox.Button>
                                <Transition
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {organisation &&
                                      organisation.map((person, personIdx) => (
                                        <Listbox.Option
                                          key={personIdx}
                                          className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                              active
                                                ? "bg-amber-100 text-amber-900"
                                                : "text-gray-900"
                                            }`
                                          }
                                          value={person}
                                        >
                                          {({ selected }) => (
                                            <>
                                              <span
                                                className={`block truncate ${
                                                  selected
                                                    ? "font-medium"
                                                    : "font-normal"
                                                }`}
                                              >
                                                {person.organisation_name}
                                              </span>
                                              {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"></span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </Listbox>
                            <div className="pt-3">
                              <button
                                className="bg-black text-white px-4 py-2 rounded-md"
                                onClick={() => createRequest(org._id)}
                              >
                                Submit Request
                              </button>
                            </div>
                          </div>
                        )
                      ) : (
                        org?.organisation
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to leave this organisation?
                    </p>
                  </div>

                  <div className="mt-4 flex items-center">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => leaveOrganisationHandler()}
                    >
                      Leave
                    </button>
                    <button
                      type="button"
                      className="inline-flex ml-3 justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default AddStoresToOrganisation;
