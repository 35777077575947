import React from "react";
import storelogo from "../../../images/test/storelogo.jpg";
import {
  AiOutlineHome,
  AiOutlineShop,
  AiOutlineInfoCircle,
  AiOutlineShoppingCart,
} from "react-icons/ai";

const Header = ({ page, logo, basecolor = "blue-500" }) => {
  return (
    <div
      style={{ zIndex: 1000 }}
      className="w-full sticky bg-white shadow-xl top-0 flex items-center px-3 py-2 justify-between"
    >
      <img
        src={logo}
        alt=""
        className="xl:h-24 lg:h-20  h-16"
        crossOrigin="anonymous"
      />
      <div className=" flex items-center lg:space-x-12 md:space-x-8 space-x-4 text-indigo-800 font-medium">
        <a
          style={{ backgroundColor: page === "home" && basecolor }}
          className={
            "group hover:text-black rounded-md lg:px-4 sm:px-2 px-1 py-2 " +
            (page === "home" && `text-white border  hover:text-white`)
          }
        >
          <span className="hidden md:block">Home</span>
          <AiOutlineHome className="block md:hidden text-xl" />
          <span
            class={
              "block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-black " +
              (page === "home" && "w-0")
            }
          ></span>
        </a>
        <a
          style={{ backgroundColor: page === "story" && basecolor }}
          className={
            "group hover:text-black  rounded-md lg:px-4 sm:px-2 px-1 py-2 " +
            (page === "story" && `text-white border  hover:text-white`)
          }
        >
          <span className="hidden md:block">About</span>
          <AiOutlineInfoCircle className="block md:hidden text-xl" />
          <span
            class={
              "block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-black " +
              (page === "story" && "w-0")
            }
          ></span>{" "}
        </a>
        <a className="group hover:text-black lg:px-4 sm:px-2 px-1">
          <span className="hidden md:block">Products</span>
          <AiOutlineShop className="block md:hidden text-xl" />

          <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-black"></span>
        </a>
        <button className="pr-6 md:text-3xl text-xl py-2  rounded-md text-gray-600 hover:text-gray-900 lg:px-4 sm:px-2 px-1">
          <AiOutlineShoppingCart className="text-indigo-800" />
        </button>
      </div>
    </div>
  );
};

export default Header;
