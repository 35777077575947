import React from "react";

const Intro = ({
  introwords,
  createMarkup,
  convertedContent,
  basecolor = "blue-500",
}) => {
  return (
    <div
      style={{ backgroundColor: basecolor }}
      className={`w-full py-8 my-4 px-4 md:mt-28   flex items-end justify-between`}
    >
      <p className="lg:text-3xl md:text-3xl text-2xl font-bold text-white text-center w-full md:w-3/5 mx-auto tracking-wide">
        {!convertedContent ||
        createMarkup(convertedContent).__html.toString() === "<p></p>" ? (
          <div>{introwords}</div>
        ) : (
          <div
            className="p-6"
            dangerouslySetInnerHTML={createMarkup(convertedContent)}
          ></div>
        )}
      </p>
    </div>
  );
};

export default Intro;
