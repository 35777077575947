import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-hot-toast";
import ReactPaginate from "react-paginate";

import Header from "../Components/SellerPage/Header";
import SellerProduct from "../Components/SellerPage/SellerProduct";
import { getProductByStoreId } from "../Helper/product";
import { getStore } from "../Helper/store";
import { useParams } from "react-router-dom";
import StickyIcons from "../Components/SellerPage/StickyIcons";
import smallicon from "../images/smallicon.png";
import tarang from "../images/tarang.png";
import { Helmet } from "react-helmet";
import Footer from "../Components/SellerPage/Footer";
import PopupProduct from "../Components/popupProduct";

import "../App.css";
import useWindowDimensions from "../hooks/useWindowDimension";
import { addToCart } from "../Helper/Order";
import { LoginToast } from "../Toasts/LoginToast";

const SellerProducts = () => {
  const [products, setProducts] = useState([]);
  const { id } = useParams();
  const { width } = useWindowDimensions();
  const [activeId, setActiveId] = useState(null);
  const [pop, setPop] = useState(false);
  const [cartDisabled, setCartDisabled] = useState(false);

  const [template, setTemplate] = useState(null);

  const handleID = async (prodId) => {
    await setActiveId(prodId);
    if (width > 895) {
      await setPop(true);
    }
  };
  async function getStoreData() {
    if (id !== undefined) {
      await getStore(id)
        .then((data, err) => {
          if (data.status_code === 200) {
            console.log(`data`, data);
            setTemplate(data.data);
          } else {
            console.log(err);
          }
        })
        .catch((err) => console.log(err));
    }
  }
  const [currentPage, setCurrentPage] = useState(0);

  const PER_PAGE = 12;
  const offset = currentPage * PER_PAGE;
  const currentPageData = products
    .slice(offset, offset + PER_PAGE)
    .sort()
    .map((item, index) => {
      return width > 895 ? (
        <div
          onClick={() => {
            handleID(item._id);
          }}
        >
          <SellerProduct item={item} handleID={handleID} />{" "}
        </div>
      ) : (
        <a
          href={`/product/${item.productName?.replace(/\s+/g, "-")}/${
            item._id
          }/?redirectFrom=seller&redirectFromId=${id !== undefined && id}`}
        >
          <div
            onClick={() => {
              handleID(item._id);
            }}
          >
            <SellerProduct item={item} handleID={handleID} />{" "}
          </div>
        </a>
      );
    });

  const pageCount = Math.ceil(products.length / PER_PAGE);

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  useEffect(() => {
    getStoreData();
  }, []);

  async function getProducts() {
    if (id !== undefined) {
      const data = await getProductByStoreId(id);

      if (data.status_code === 200) {
        setProducts(data.data);
      } else {
        toast.error(data.message);
      }
    }
  }

  useEffect(() => {
    getProducts();
  }, []);

  const ref = useRef(null);

  useEffect(() => {
    if (setPop) {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setPop(false);
          setActiveId(null);
        }
      };
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    }
  }, [pop]);

  async function addToCartHandler(pro, toggle) {
    if (localStorage.getItem("macha-user")) {
      addToCart({ productID: pro, quantity: toggle }).then((response) => {
        if (response.status_code === 200) {
          toast.success("Product added to cart");
          setCartDisabled(true);
        } else {
          toast.error("Something went wrong");
        }
      });
    } else {
      toast.error(LoginToast);
    }
  }

  return (
    <div>
      <Helmet>
        <title>{template?.storeName}</title>
        <meta name="description" content={template?.storeDescription} />
      </Helmet>
      <Header
        storeName={template?.storeName}
        selected={
          template?.template !== null &&
          template?.template?.chosenColor !== null
            ? template?.template?.chosenColor
            : "#730dc9"
        }
        page={"products"}
        logo={template !== null && template.storeLogo}
        id={id !== undefined && id}
        template={template?.template}
      />
      <StickyIcons
        organisation={template?.organisation ? template?.organisation : null}
        icon={smallicon}
        orgicon={tarang}
      />

      <div className=" pt-10 px-10 grid lg:grid-cols-4 lg:gap-4 md:grid-cols-3 md:gap-4 sm:grid-cols-2 sm:gap-4 sxs:grid-cols-1 sxs:gap-0">
        {/* {products?.map((item, index) => {
          return (
            <div
              onClick={() => {
                handleID(item._id);
              }}
            >
              <SellerProduct item={item} handleID={handleID} />{" "}
            </div>
          );
        })} */}
        {currentPageData}
        {products.length === 0 && (
          <div className="text-[20px] font-[700] text-center">No Products</div>
        )}
        {pop === true && activeId && (
          <div
            style={{ zIndex: 100 }}
            className="fixed w-screeen h-screen top-0 left-0 right-0 bg-gray-800 bg-opacity-50 flex items-center justify-center"
          >
            <div
              ref={ref}
              className="  drop-shadow-lg rounded-[20px] w-[65%] h-[65%] bg-white flex justify-center items-center "
            >
              <PopupProduct
                addOnRedirect={"seller"}
                addToCartHandler={addToCartHandler}
                addOnRedirectValue={id !== undefined && id}
                setPop={setPop}
                product={
                  products[products.findIndex((x) => x._id === activeId)]
                }
              />
            </div>
          </div>
        )}
      </div>
      <div>
        <ReactPaginate
          containerClassName={"pagination"}
          breakLabel="..."
          nextLabel=">"
          pageRangeDisplayed={3}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          pageClassName={"page"}
          activeClassName={"active"}
          nextClassName={"next"}
          previousClassName={"previous"}
          breakClassName={"break"}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      </div>
      <Footer
        email={template?.template?.footer?.footerData?.emailData}
        enabled={template && template.template.footer.footerEnabled}
        phone={template?.template?.footer?.footerData?.phoneNumberData}
        orgname={template?.organisation !== null && "WeTarang"}
        organisation={template?.organisation ? template?.organisation : null}
        sellername={template?.storeName}
        insta={template?.template?.footer?.socialLinksData?.instagramData}
        facebooklink={template?.template?.footer?.socialLinksData?.facebookData}
      />
    </div>
  );
};

export default SellerProducts;
