import { Link, Navigate } from "react-router-dom";
import React from "react";
import { useEffect, useState } from "react";
import "@fontsource/dancing-script";
import ProductCard2 from "../../Components/homePage/productCard2";
import { APIURL } from "../../backend";
import { getUserInfoWithPopulate } from "../../Helper/Auth";
import LikedProducts from "../../Components/UserPanel/likedProducts";
import LikedStores from "../../Components/UserPanel/likedStores";

const userDetURL = `${APIURL}/user/get-user-details`;
const navSel = "w-1/2 py-4 px-2 text-gray-500 border-gray-500 font-semibold";
const navSelActive =
  "w-1/2 py-4 px-2 text-gray-500 border-gray-500 font-semibold border-b-4";

const Wishlist = () => {
  const [active, setActive] = useState(1);

  const [wishlist, setWishlist] = useState([]);
  const [menu, setMenu] = useState("likedStores");

  async function getWishlist() {
    const user = await getUserInfoWithPopulate();

    if (user.status_code === 200) {
      setWishlist(user.data.wishlist);
    }
  }
  useEffect(() => {
    getWishlist();
  }, []);

  // useEffect(() => {
  //   console.log("hi");
  // }, []);
  // if (localStorage.getItem("macha-user") === null)
  //   return <Navigate to="/" replace={false} />;

  return (
    <div>
      <div className="w-full lg:mt-6 md:mt-0 lg:ml-6 md:ml-0">
        <nav className="bg-white ">
          <div className=" mx-auto px-4 md:pt-0">
            <div className="justify-between mb-8">
              <div className="md:flex">
                <button
                  onClick={() => {
                    setActive(1);
                    setMenu("likedStores");
                  }}
                  className={active === 1 ? navSelActive : navSel}
                >
                  Liked Stores
                </button>
                <button
                  onClick={() => {
                    setActive(2);
                    setMenu("likedProducts");
                  }}
                  className={active === 2 ? navSelActive : navSel}
                >
                  Liked Products
                </button>
              </div>
              {menu === "likedStores" && <LikedStores />}
              {menu === "likedProducts" && (
                <LikedProducts wishlist={wishlist} />
              )}
            </div>
          </div>
        </nav>
        <div class="w-full flex flex-col"></div>
      </div>
    </div>
  );
};

export default Wishlist;
