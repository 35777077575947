import React from "react";
import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
import facebook from "../../images/facebook.png";
import instagram from "../../images/instagram.png";
import logo from "../../images/logo.png";

const Footer = ({
  email,
  phone,
  insta,
  facebooklink,
  orgname,
  sellername,
  organisation,
  enabled,
}) => {
  return (
    <div className="flex xl:mt-8 flex-col py-8 xl:px-40 lg:px-40 md:px-28 px-10 bg-black bg-opacity-80 text-slate-100 md:space-y-8">
      {enabled && (
        <div className="flex flex-col md:flex-row items-center justify-between space-y-2">
          <div className="flex flex-col items-center md:items-start">
            {phone && (
              <a href={`tel:${phone}`} className="flex items-center space-x-2">
                <AiOutlinePhone className="text-2xl" />
                <p className="md:text-lg text-sm">{phone}</p>
              </a>
            )}
            {email && (
              <a
                href={`mailto:${email}`}
                className="flex items-center space-x-2"
              >
                <AiOutlineMail className="text-2xl" />
                <p className="md:text-lg text-sm">{email}</p>
              </a>
            )}
          </div>
          <div className="flex items-center space-x-4">
            {facebooklink && (
              <a href={facebooklink}>
                <img src={facebook} alt="" className="w-8" />
              </a>
            )}
            {insta && (
              <a href={insta}>
                <img src={instagram} alt="" className="w-8" />
              </a>
            )}
          </div>
        </div>
      )}

      <div className="flex flex-col md:flex-row items-center md:justify-between space-y-2 md:space-y-0 mt-4 md:mt-0 ">
        <div className=" flex flex-col  my-4 md:my-0  space-y-2">
          {orgname && (
            <p className="md:text-lg sm:text-sm text-xs font-medium flex items-center max-md:justify-center space-x-2">
              <span className="block ">Part of </span>{" "}
              <a
                href={`/organisation/${organisation?.organisation_name}/${organisation?._id}`}
                target={"_blank"}
                rel="noreferrer"
                className="hover:text-[15px]"
              >
                {" "}
                {orgname}
              </a>
            </p>
          )}
          <p className="md:text-lg sm:text-sm text-xs  font-medium flex items-center space-x-2">
            <span className="block">Partnered with</span>{" "}
            <a href={"/"} target={"_blank"} rel="noreferrer">
              <img src={logo} alt="" className="w-36 hover:w-40" />
            </a>
          </p>
        </div>
        <div className="md:text-right text-center font-medium block  sm:text-lg text-xs text-gray-300">
          2023 All Rights Reserved {sellername}
        </div>
      </div>
    </div>
  );
};

export default Footer;
