import React from "react";
import { Helmet } from "react-helmet";
import FirstInfo from "./FirstInfo";
import Intro from "./Intro";
import MainColumn from "./MainColumn";
import Team from "./Team";
import TypeWriterColumn from "./TypeWriterColumn";
import Footer from "../../Components/Base/Footer";
import Header from "../../Components/Base/MachaHeader";

const HomeAboutPage = () => {
  const strings = [
    "reach more people",
    "build its brand",
    "grow to the next level",
    "make more money",
  ];
  const members = [
    {
      name: "Nilesh Aggarwal",
      position: "Fullstack dev",
      image:
        "https://res.cloudinary.com/dqicwkkcp/image/upload/v1676658089/machapointaboutpage/50d206c1-1d64-45cb-b7e6-7ebafe8156d0_rvzjza.jpg",
    },
    {
      name: "Harsh Gawas",
      position: "Fullstack dev",
      image:
        "https://res.cloudinary.com/dqicwkkcp/image/upload/v1676658064/machapointaboutpage/d92111a6-830b-42d7-b8c8-ce79049d728e_gl7yjm.jpg",
    },
    {
      name: "Shaantanu Kulkarni",
      position: "Backend dev",
      image:
        "https://res.cloudinary.com/dqicwkkcp/image/upload/v1676657919/machapointaboutpage/d149ea57-8484-41aa-9ddb-09e6d2abf1d1_mnlisz.jpg",
    },
    {
      name: "Aryan Dubey",
      position: "Developer",
      image:
        "https://res.cloudinary.com/dqicwkkcp/image/upload/v1682244281/e322d5bb-d16b-443f-ac90-cfb43509c696_xpfjrw.jpg",
    },
  ];

  const teamData = [
    "We are a dedicated, energetic, focused team working tirelessly with one mission in mind ... to help the local small business owners, the micro-entrepreneurs, the dreamers grow their businesses 10 times!",
  ];

  let storeName = "MachaPoint";
  let metaDescription = "Machapoint:world's biggest enterpreneur community";

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>{storeName}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <Header
        page={"story"}
        logo={
          "http://wetarang.com/images/store/16610025471659620466Raachi%20NewBorn%20Essentials%20Logo%20-%20Ruchi%20Parkar.jpg"
        }
      />

      <TypeWriterColumn
        strings={strings}
        heading={"Join MachaPoint so that your business can"}
      />
      <MainColumn
        text={
          "We at MachaPoint want to help those who are just starting, those who are passionate, those who want to prove themselves and those who want to make money. We are here standing with you to work together and grow together. We support all local businesses, women entrepreneurs, student entrepreneurs to make their businesses and their dreams into a reality. #vocalforlocal"
        }
        backgroundImage={
          "https://res.cloudinary.com/dqicwkkcp/image/upload/v1676657766/machapointaboutpage/e6e474a7-e69b-4a5d-a393-b9af7ff7747c_klvmgt.jpg"
        }
      />
      <Intro introwords={"Get your own website! Save Cost, Grow Fast!"} />
      <FirstInfo
        infoImage={
          "https://res.cloudinary.com/dqicwkkcp/image/upload/v1676657835/machapointaboutpage/a557c247-142e-4a05-9df2-d50dcdd50219_a9ljpq.jpg"
        }
        infoData={
          "Get your own website in a day! No technical knowledge required! Be a part of the community.  We dont even charge commissions on your sale. The money you earn is yours to keep. When you grow, the community grows and when the community grows, we grow. So lets help each other and be successful. We are here for you whenever and for whatever you need. Lets show the world what we can do!"
        }
      />
      <Team team={members} startupName={"Machapoint"} teamData={teamData} />
      <Footer
        email={"raachi@machapoint.com"}
        phone={"9999999999"}
        orgname={"WeTarang"}
        sellername={"Raachi Clothing"}
        insta={"https://google.com"}
        facebooklink={"https://google.com"}
      />
    </div>
  );
};

export default HomeAboutPage;
