import React, { useState, useEffect, Fragment } from "react";
import { toast } from "react-hot-toast";
import { AiFillCloseCircle, AiFillEye } from "react-icons/ai";
import ManageHeader from "../../Components/Manage/ManageHeader";
import UpdateProduct from "../../Components/SellerPanel/UpdateProduct";
import {
  getMyProducts,
  deleteProduct,
  getProductById,
} from "../../Helper/product";
import { Dialog, Transition } from "@headlessui/react";

const ManageProducts = () => {
  const [products, setProducts] = useState([]);
  const [updateProduct, setUpdateProduct] = useState(null);
  let [isOpen, setIsOpen] = useState(false);
  const [orgId, setOrgId] = useState("");

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  // const [productData, setProductData] = useState({});
  // async function getProductData() {
  //   const result = await getProductById(productUpdate);
  //   console.log(result, "result 1");
  //   if (result.status_code === 200) {
  //     setProductData(result.data);
  //   } else {
  //     toast.error("Product not found");
  //   }
  // }
  // console.log(productData, "productData 1");
  // useEffect(() => {
  //   if (productUpdate !== "") {
  //     getProductData();
  //   }
  // }, [productUpdate]);

  async function getProducts() {
    const res = await getMyProducts();
    console.log(res, "res");
    if (!res) {
      toast.error("Unable to get organisations");
    } else {
      if (res.status_code === 200) {
        setProducts(res.data);
      } else {
        toast.error(res.error);
      }
    }
  }

  async function deleteProductById(id) {
    const res = await deleteProduct({ productID: id });
    console.log(res, "res delete");
    if (!res) {
      toast.error("Unable to get organisations");
    } else {
      if (res.status_code === 200) {
        toast.success("Product Deleted Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error(res.message);
      }
    }
  }

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div>
      <ManageHeader
        heading={updateProduct === null ? "Manage Products" : "Edit Product"}
        setProductUpdate={setUpdateProduct}
        productUpdate={updateProduct}
        display={"Manage"}
      />

      {updateProduct === null ? (
        <div className="sm:my-6 w-full rounded-md border border-gray-200">
          <table className="table-auto  w-full  bg-[#f7f8f9] rounded-lg">
            <thead>
              <tr className="text-sm font-thin">
                <th className="font-normal py-3">Image</th>
                <th className="font-normal py-3"> Name</th>
                <th className="font-normal py-3"> Price</th>

                <th className="font-normal py-3"> Category</th>
                <th className="font-normal py-3">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y">
              {products?.map((org) => {
                return (
                  <tr className="text-center">
                    <td className="flex items-center justify-center py-4">
                      <img
                        className="w-10 h-10 rounded-md"
                        alt=""
                        src={org?.productImageUrls[0]?.imgURL}
                      />
                    </td>
                    <td className="text-sm text-gray-500">
                      {org?.productName}
                    </td>
                    <td className="text-sm text-gray-500">
                      {org?.productPrice}
                    </td>
                    <td className="text-sm text-gray-500">
                      {org?.productCategory?.categoryName}
                    </td>
                    <td className="text-sm text-gray-500   ">
                      <span className="border px-3 py-1 rounded-full bg-black text-white">
                        {org?.status ? "Active" : "Inactive"}
                      </span>
                    </td>
                    <td>
                      <div className="flex items-center space-x-1 justify-center">
                        <AiFillCloseCircle
                          onClick={() => {
                            openModal();
                            setOrgId(org._id);
                          }}
                          // className="text-xl text-red-500 hover:text-red-600 cursor-pointer"
                          type="button"
                          className="inline-block  bg-primary text-red-500 cursor-pointer    text-heading text-[18px] lg:text-[20px] uppercase "
                          data-te-toggle="tooltip"
                          data-te-placement="bottom"
                          data-te-ripple-init
                          data-te-ripple-color="light"
                          title={"Delete product"}
                        />
                        <AiFillEye
                          type="button"
                          className="inline-block  bg-primary cursor-pointer   text-heading text-[18px] lg:text-[20px] uppercase "
                          data-te-toggle="tooltip"
                          data-te-placement="bottom"
                          data-te-ripple-init
                          data-te-ripple-color="light"
                          title={"Update product"}
                          onClick={() => setUpdateProduct(org)}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Are you sure you want to delete this product?
                      </Dialog.Title>

                      <div className="mt-4 flex items-center">
                        <button
                          type="button"
                          className="mr-3 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          onClick={() => deleteProductById(orgId)}
                        >
                          Yes
                        </button>
                        <button
                          type="button "
                          className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          onClick={closeModal}
                        >
                          No
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </div>
      ) : (
        <>
          <UpdateProduct productData={updateProduct} />
        </>
      )}
    </div>
  );
};

export default ManageProducts;
