import React from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import Block from "../Builder/Block";
import EditorInput from "../Builder/EditorInput";
import Headline from "../Builder/Headline";
import ImageInput from "../Builder/ImageInput";
import SplitBox from "../Builder/SplitBox";
import TextInput from "../Builder/TextInput";

const AboutPage = ({
  EnableFunctions,
  EnableValues,
  EditorFunctions,
  EditorValues,
  AboutFunctions,
  AboutValues,
  handArrayObjectChange,
  handleImages,
  HeadingFunctions,
  HeadingValues,
  removeTeamMemberImage,
}) => {
  return (
    <div className="py-4 flex flex-col items-center space-y-4 divide-y w-full">
      <Block>
        <Headline
          enabled={EnableValues.enabled5}
          setEnabled={EnableFunctions.setEnabled5}
          headline={"Section 1 * (Page Header+TypeWriter)"}
        />
        <EditorInput
          editorState={EditorValues.editorState3}
          setEditorState={EditorFunctions.setEditorState3}
        />
        <label className="block my-2 text-lg font-medium text-gray-900 ">
          TypeWriter Data
        </label>
        {AboutValues?.typewriter?.length > 0 &&
          AboutValues?.typewriter?.map((testimonial, index) => {
            return (
              <div className="flex w-full items-center space-x-3 my-2">
                <div className="w-[60%]">
                  <TextInput
                    tagname={"Sales"}
                    label={"TypeWriter String"}
                    placeholder={"Enter Typewriter string"}
                    handleChange={handArrayObjectChange}
                    value={testimonial.string}
                    type={"arrayobj"}
                    index={index}
                    thing={"string"}
                    setFunction={AboutFunctions.setTypewriter}
                    oldValue={AboutValues.typewriter}
                  />
                </div>
                {index > 0 && (
                  <div className="flex items-center justify-start grow">
                    <div
                      onClick={() => {
                        AboutFunctions.setTypewriter(
                          AboutValues.typewriter.filter(
                            (item, i) => i !== index
                          )
                        );
                      }}
                      className="flex items-center justify-center"
                    >
                      <AiFillCloseCircle className="text-red-500 text-2xl cursor-pointer hover:text-red-700" />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        <div className="flex items-center justify-between">
          <button
            disabled={AboutValues?.typewriter?.length > 3 ? true : false}
            onClick={() =>
              AboutFunctions.setTypewriter(() => [
                ...AboutValues.typewriter,
                { string: "" },
              ])
            }
            className="my-2 border bg-blue-500 text-white hover:bg-blue-700 rounded-md py-2 px-6 mx-auto disabled:bg-gray-400"
          >
            + Add TypeWriter String
          </button>
        </div>
      </Block>
      <Block>
        <Headline
          enabled={EnableValues.enabled7}
          setEnabled={EnableFunctions.setEnabled7}
          headline={
            "Section 2 * (Select An Image and Content for Your Website)"
          }
        />
        <SplitBox
          editorState={EditorValues.editorState2}
          setEditorState={EditorFunctions.setEditorState2}
          handleImageChange={handleImages}
          handlerfunction={AboutFunctions.setContentImage}
          handlerValue={AboutValues.contentImage}
          textValue={HeadingValues.Heading3}
          handleTextChange={HeadingFunctions.setHeading3}
          rowReversed={true}
        />
      </Block>
      <Block>
        <Headline
          enabled={EnableValues.enabled6}
          setEnabled={EnableFunctions.setEnabled6}
          headline={"Section 3 * (Write Main Central Line)"}
        />
        <EditorInput
          editorState={EditorValues.editorState}
          setEditorState={EditorFunctions.setEditorState}
        />
      </Block>

      <Block>
        <Headline
          enabled={EnableValues.enabled8}
          setEnabled={EnableFunctions.setEnabled8}
          headline={
            "Section 4 * (Select An Image and Content for Your Website)"
          }
        />
        <SplitBox
          editorState={EditorValues.editorState4}
          setEditorState={EditorFunctions.setEditorState4}
          handleImageChange={handleImages}
          handlerfunction={AboutFunctions.setContentImage2}
          handlerValue={AboutValues.contentImage2}
          textValue={HeadingValues.Heading4}
          handleTextChange={HeadingFunctions.setHeading4}
        />
      </Block>
      <Block>
        <Headline
          enabled={EnableValues.enabled9}
          setEnabled={EnableFunctions.setEnabled9}
          headline={
            "Section 5 * (Write About The Founders And Upload thier Pictures with Name)"
          }
        />
        <EditorInput
          editorState={EditorValues.editorState5}
          setEditorState={EditorFunctions.setEditorState5}
        />
        {AboutValues?.founderData?.length > 0 &&
          AboutValues?.founderData?.map((stats, index) => {
            return (
              <div className="flex w-full items-center space-x-3 my-2">
                <div className="w-[45%]">
                  <TextInput
                    tagname={"foundername"}
                    label={"Founder name"}
                    placeholder={"Enter founder name"}
                    handleChange={handArrayObjectChange}
                    value={stats.name}
                    type={"arrayobj"}
                    index={index}
                    thing={"name"}
                    setFunction={AboutFunctions.setFounderData}
                    oldValue={AboutValues.founderData}
                  />
                </div>
                <div className="w-[45%] flex items-center justify-center">
                  <div className="h-36 aspect-square	">
                    <ImageInput
                      handleChange={handArrayObjectChange}
                      text={"Choose founders image"}
                      handlerfunction={AboutFunctions.setFounderData}
                      handlerValue={AboutValues.founderData}
                      type={"arrayobj"}
                      index={index}
                      thing={"image"}
                    />
                  </div>
                  {stats?.image && (
                    <div className="h-36 aspect-square	relative">
                      <img
                        src={URL.createObjectURL(stats?.image)}
                        alt=""
                        className="w-full h-full object-cover"
                      />
                      <div
                        onClick={(e) => {
                          removeTeamMemberImage(
                            e,
                            index,
                            AboutFunctions.setFounderData,
                            AboutValues.founderData,
                            "image"
                          );
                        }}
                        className="absolute -top-2 -right-2 "
                      >
                        <AiFillCloseCircle className="text-red-500 text-2xl cursor-pointer hover:text-red-700" />
                      </div>{" "}
                    </div>
                  )}
                </div>
                {index > 0 && (
                  <div className="flex items-center justify-center grow">
                    <div
                      onClick={() => {
                        AboutFunctions.setFounderData(
                          AboutValues.founderData.filter(
                            (item, i) => i !== index
                          )
                        );
                      }}
                      className="flex items-center justify-center"
                    >
                      <AiFillCloseCircle className="text-red-500 text-2xl cursor-pointer hover:text-red-700" />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        <div className="flex items-center justify-between">
          <button
            disabled={AboutValues?.founderData?.length > 3 ? true : false}
            onClick={() =>
              AboutFunctions.setFounderData(() => [
                ...AboutValues.founderData,
                { name: "", image: "" },
              ])
            }
            className="my-2 border bg-blue-500 text-white hover:bg-blue-700 rounded-md py-2 px-6 mx-auto disabled:bg-gray-400"
          >
            + Add Team Member
          </button>
        </div>
      </Block>
    </div>
  );
};

export default AboutPage;
