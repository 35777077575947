import React from "react";
import Typewriter from "typewriter-effect";

const MainColumn = ({ backgroundImage, text }) => {
  return (
    <div className="w-full flex flex-col-reverse md:flex-row   items-center justify-center md:pt-24 md:pb-3 py-8   ">
      <div className="w-4/5 mx-auto md:w-[50%]  origin-right  bg-white   justify-center">
        <p className="text-gray-600 xl:text-2xl lg:text-xl md:text-lg text-sm font-medium lg:px-6 md:px-4 sm:px-3 my-4 md:my-0 text-center">
          {text}
        </p>
      </div>
      <div className="w-4/5 md:w-[50%]  md:mx-auto mx-3  origin-left  relative md:px-5">
        <img
          src={backgroundImage}
          alt=""
          className="w-full h-full object-fill"
        />
      </div>
    </div>
  );
};

export default MainColumn;
