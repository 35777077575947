import React from "react";
import { IoMdImages } from "react-icons/io";
const ImageInput = ({
  handleChange,
  text,
  handlerfunction,
  handlerValue,
  multiple = false,
  type = "simple",
  index,
  thing,
}) => {
  return (
    <div className="relative w-full h-full border rounded-md border-dotted	border-blue-400">
      <input
        type={"file"}
        id="file_input"
        onChange={(e) =>
          type === "arrayobj"
            ? handleChange(
                e,
                index,
                thing,
                handlerfunction,
                handlerValue,
                "array"
              )
            : handleChange(e, handlerfunction, handlerValue, multiple)
        }
        multiple={multiple}
        className="w-full h-full relative block opacity-0 cursor-pointer"
        style={{ zIndex: 200 }}
      />
      <div className=" w-full h-full  absolute top-0 left-0  rounded-md  flex flex-col items-center justify-center space-y-1 bg-blue-100 text-blue-500 hover:text-blue-700">
        <IoMdImages className="text-sm" />
        {text && <p className="text-xs ">{text}</p>}
      </div>
    </div>
  );
};

export default ImageInput;
