import React from "react";
import Typewriter from "typewriter-effect";

const TypeWriterColumn = ({
  strings,
  heading,
  convertedContent,
  createMarkup,
}) => {
  return (
    <div className="w-[100%]  pt-10 lg:pt-20 origin-right  bg-white  lg:text-3xl md:text-3xl text-2xl xl:text-4xl  h-full flex flex-col space-y-4 items-center justify-center">
      {!convertedContent &&
      createMarkup(convertedContent).__html.toString() !== "<p></p>" ? (
        <div>{heading}</div>
      ) : (
        <div
          className="px-3"
          dangerouslySetInnerHTML={createMarkup(convertedContent)}
        ></div>
      )}

      <div className="font-bold capitalize">
        <Typewriter
          options={{
            strings: strings,
            autoStart: true,
            loop: true,
          }}
        />
      </div>
    </div>
  );
};

export default TypeWriterColumn;
