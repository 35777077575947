import React, { useEffect, useRef, useState } from "react";
import MachaHeader from "../Components/Base/MachaHeader";
import { getAllStores, getFilteredStores } from "../Helper/store";
import SellerBlock from "../Components/SellerBlock";
import StoriesSlider from "../Components/StoriesSlider";
import { toast } from "react-hot-toast";
import { APIURL } from "../backend";
import { getAllCategories } from "../Helper/product";
import { IoFilterSharp } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import Drawer from "../Components/Base/Drawer";

const ShopPage = () => {
  const [stores, setStores] = useState([]);
  const [viewStories, setViewStories] = useState([]);
  const [viewedStories, setViewedStories] = useState([]);
  const [catData, setCatData] = useState({});
  const [catSave, setCatSave] = useState([]);
  const [prodData, setData] = useState([]);
  const [catOpen, setCatOpen] = React.useState(false);

  const [myFilters, setMyFilters] = useState({
    filters: {
      storeCategory: [],
      offerPrice: [],
      artist: [],
      productSubcategory: [],
    },
  });

  const ref = useRef(null);
  const ref2 = useRef([]);

  async function checkIfSelected() {
    for (let i = 0; i < catData?.length; i++) {
      let currentCategoryId = myFilters?.filters?.storeCategory.indexOf(
        catData[i]?._id
      );

      if (currentCategoryId === -1) {
        ref2.current[i].checked = false;
      } else {
        ref2.current[i].checked = true;
      }
    }
  }

  useEffect(() => {
    if (catData?.length > 0 && myFilters?.filters?.storeCategory?.length > 0) {
      checkIfSelected();
    }
    if (
      myFilters?.filters?.storeCategory?.length === 0 &&
      catSave.length === 0
    ) {
      for (let i = 0; i < catData?.length; i++) {
        ref2.current[i].checked = false;
      }
    }
  }, [catSave]);

  const allCat = () => {
    getAllCategories().then((data) => {
      if (data.status_code === 200) {
        setCatData(data.data);
      } else {
        toast.error("Unable to fetch categories at the moment");
      }
    });
  };
  useEffect(() => {
    allCat();
  }, []);

  async function getStories() {
    try {
      const res = await fetch(`${APIURL}/story/get-stories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("macha-user"))?.token,
        },
      });
      const response = await res.json();
      if (response.status_code === 200) {
        setViewStories(response.data);
      } else {
        console.log(response);
        toast.error("Error fetching stories at the moment");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  }
  async function getStores() {
    await getAllStores().then((data, err) => {
      if (data.status_code === 200) {
        setStores(data.data);
      } else {
        console.log(err, "error");
      }
    });
  }
  useEffect(() => {
    getStores();
    getStories();
  }, []);

  async function loadFilteredProducts(filters) {
    getFilteredStores(filters).then((data) => {
      if (data.status_code === 200) {
        setData(data.data);
      } else {
        toast.error("Unable to fetch products at the moment");
      }
    });
  }

  const handleFilters = (filters, filterBy) => {
    const newFilters = { ...myFilters };
    newFilters.filters[filterBy] = filters;

    loadFilteredProducts(myFilters.filters);
    setMyFilters(newFilters);
  };

  const handleToggle = (c) => () => {
    let currentCategoryId = myFilters?.filters?.storeCategory.indexOf(c);
    const newCategoryChecked = [...myFilters?.filters?.storeCategory];
    let saveCategoryObj = [...catSave];
    if (currentCategoryId === -1) {
      newCategoryChecked.push(c);

      let obj = catData?.find((o) => o._id === c);

      if (obj) {
        saveCategoryObj.push(obj);
        setCatSave(saveCategoryObj);
      }
    } else {
      newCategoryChecked.splice(currentCategoryId, 1);
      const filteredcat = saveCategoryObj.filter((item) => item._id !== c);
      setCatSave(filteredcat);
    }
    handleFilters(newCategoryChecked, "storeCategory");
  };

  async function handleClick(c) {
    let currentCategoryId = myFilters?.filters?.storeCategory.indexOf(c);
    const newCategoryChecked = [...myFilters?.filters?.storeCategory];
    let saveCategoryObj = [...catSave];
    if (currentCategoryId === -1) {
      newCategoryChecked.push(c);

      let obj = catData?.find((o) => o._id === c);

      if (obj) {
        saveCategoryObj.push(obj);
        setCatSave(saveCategoryObj);
      }
    } else {
      newCategoryChecked.splice(currentCategoryId, 1);
      const filteredcat = saveCategoryObj.filter((item) => item._id !== c);
      setCatSave(filteredcat);
    }
    handleFilters(newCategoryChecked, "storeCategory");
  }

  useEffect(() => {
    loadFilteredProducts(myFilters.filters);
  }, []);

  console.log(prodData, "prodData", myFilters);

  return (
    <div>
      <MachaHeader />

      <StoriesSlider stories={viewStories} viewedStories={viewedStories} />
      <div className=" md:flex   w-full h-full  justify-center px-10 md:px-20 py-10">
        <div className="mb-8 md:hidden">
          <div className=" block flex items-center">
            <IoFilterSharp onClick={() => setCatOpen(true)} />
            <div className="text-[15px] pl-3 font-[500]">Filter</div>
          </div>
          <div className="flex items-center overflow-x-scroll noscrollbar">
            <button
              className="text-[15px] bg-gray-100 px-1 py-1 flex items-center rounded-[10px]"
              onClick={() => {
                setCatSave([]);
                setMyFilters({
                  ...myFilters,
                  filters: {
                    storeCategory: [],
                    offerPrice: [],
                    artist: [],
                    productSubcategory: [],
                  },
                });
              }}
            >
              <RxCross2 />
              Clear filters
            </button>
            {catSave?.length > 0 &&
              catSave?.map((data, index) => {
                return (
                  <button
                    onClick={() => handleClick(data._id)}
                    className="text-[15px]  bg-gray-100 px-1 py-1 flex items-center rounded-[10px] mx-1 w-max"
                  >
                    <RxCross2 />
                    {data.categoryName}
                  </button>
                );
              })}
          </div>
        </div>
        <div className="md:w-1/4  md:pr-10">
          <div className="hidden md:block pt-10 flex flex-col lg:flex-row text-left  justify-between border-b-2 border-gray-200">
            <div className="text-left font-semibold text-heading text-xl ">
              Filters
            </div>
          </div>
          <div className="py-5 border-b-2 border-gray-200  hidden md:block">
            <div className="text-heading text-[20px] font-[600]">Category</div>
            {catData &&
              catData.length > 0 &&
              catData?.map((data, index) => (
                <div className="flex items-center mb-4 mt-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    ref={ref}
                    onChange={handleToggle(data._id)}
                    value={data._id}
                    className="w-4 h-4 mr-3 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="default-checkbox"
                    className="text-base text-black font-[600]"
                  >
                    {data.categoryName}
                  </label>
                </div>
              ))}
          </div>
        </div>

        <div className="w-full">
          <div className="grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-2 gap-10 items-center justify-center  h-full w-full">
            {prodData?.length > 0 &&
              prodData?.map((store, index) => {
                return <SellerBlock store={store} key={index} />;
              })}
          </div>
        </div>
      </div>
      <Drawer isOpen={catOpen} setIsOpen={setCatOpen} direction="left">
        <div className="text-heading text-[20px] font-[600] px-3">Category</div>
        {catData &&
          catData.length > 0 &&
          catData?.map((data, index) => (
            <div className="flex items-center mb-4 mt-4 px-3">
              <input
                id="default-checkbox"
                type="checkbox"
                onChange={handleToggle(data._id)}
                ref={(element) => {
                  ref2.current[index] = element;
                }}
                value={data._id}
                className="w-4 h-4 mr-3 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="default-checkbox"
                className="text-base text-black font-[500] text-[15px]"
              >
                {data.categoryName}
              </label>
            </div>
          ))}
      </Drawer>
    </div>
  );
};

export default ShopPage;
