import { Dialog, Transition } from "@headlessui/react";
import DOMPurify from "dompurify";
import { convertToHTML } from "draft-convert";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import React, { Fragment, useState, useEffect } from "react";
import { BlockPicker } from "react-color";
import toast from "react-hot-toast";
import { MdCancel } from "react-icons/md";
import {
  updateOrganisationWebsite,
  updateOrganisationWebsiteImages,
} from "../../Helper/organisation";
import SellerAboutPage from "../../Pages/UpdateSellerPages/SellerAboutPage";
import Block from "../Builder/Block";
import Headline from "../Builder/Headline";
import Loader from "../Loader";
import FooterData from "../Template1/FooterData";
import AboutPage from "../updateTemplateSeller/AboutPage";
import { BaseColors } from "../updateTemplateSeller/basecolors";

const UpdateOrganisationWebsite = ({ selected }) => {
  //busy state
  const [busy, setBusy] = useState(false);
  //Preview Website state
  const [showPreview, setShowPreview] = useState(false);
  //Website Theme color
  const [chosenColor, setChosenColor] = useState(
    selected?.template?.chosenColor
  );
  const [showPicker, setShowPicker] = useState(false);

  //AboutPage Enables
  const [enabled5, setEnabled5] = useState(
    selected?.template?.aboutPage?.typeWriterEnabled
  );

  const [removedFounder, setRemovedFounder] = useState([]);

  const [enabled6, setEnabled6] = useState(
    selected?.template?.aboutPage?.headingEnabled
  );
  const [enabled7, setEnabled7] = useState(
    selected?.template?.aboutPage?.section1Enabled
  );
  const [enabled8, setEnabled8] = useState(true);

  const [enabled9, setEnabled9] = useState(
    selected?.template?.aboutPage?.teamEnabled
  );
  const [enabled10, setEnabled10] = useState(true);

  //AboutPageData
  const [contentImage, setContentImage] = useState(
    selected?.template?.aboutPage?.section1Data?.sectionImage
  );
  const [contentImage2, setContentImage2] = useState(
    selected?.template?.aboutPage?.section2Data?.sectionImage
  );
  const [founderData, setFounderData] = useState(
    selected?.template?.aboutPage?.teamData?.teamMembers
  );

  let newTypeArray = [];

  for (
    let i = 0;
    i < selected?.template?.aboutPage?.typeWriterData?.length;
    i++
  ) {
    newTypeArray.push({
      string: selected?.template?.aboutPage?.typeWriterData[i],
    });
  }
  const [typewriter, setTypewriter] = useState(newTypeArray);

  //AboutPage Editors
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [editorState2, setEditorState2] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorState3, setEditorState3] = useState(() =>
    EditorState.createEmpty()
  );

  const [editorState4, setEditorState4] = useState(() =>
    EditorState.createEmpty()
  );

  const [editorState5, setEditorState5] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (selected && selected?.template?.aboutPage?.headingData) {
      let blocks = selected?.template?.aboutPage?.headingData?.blocks;
      const entityMap = {};
      const formatted = convertFromRaw({ blocks, entityMap });

      setEditorState(EditorState.createWithContent(formatted));
    }
  }, []);

  useEffect(() => {
    if (selected && selected?.template?.aboutPage?.section1Data?.sectionText) {
      let blocks =
        selected?.template?.aboutPage?.section1Data?.sectionText.blocks;
      const entityMap = {};
      const formatted = convertFromRaw({ blocks, entityMap });

      setEditorState2(EditorState.createWithContent(formatted));
    }
  }, []);

  useEffect(() => {
    if (selected && selected?.template?.aboutPage?.mainHeadingData) {
      let blocks = selected?.template?.aboutPage?.mainHeadingData?.blocks;
      const entityMap = {};
      const formatted = convertFromRaw({ blocks, entityMap });

      setEditorState3(EditorState.createWithContent(formatted));
    }
  }, []);

  useEffect(() => {
    if (selected && selected?.template?.aboutPage?.section2Data) {
      let blocks =
        selected?.template?.aboutPage?.section2Data?.sectionText?.blocks;
      const entityMap = {};
      const formatted = convertFromRaw({ blocks, entityMap });

      setEditorState4(EditorState.createWithContent(formatted));
    }
  }, []);

  useEffect(() => {
    if (selected && selected?.template?.aboutPage?.teamData) {
      let blocks = selected?.template?.aboutPage?.teamData?.teamHeading?.blocks;
      const entityMap = {};
      const formatted = convertFromRaw({ blocks, entityMap });

      setEditorState5(EditorState.createWithContent(formatted));
    }
  }, []);

  //AboutPage Headings
  const [Heading3, setHeading3] = useState(
    selected?.template?.aboutPage?.section1Data?.sectionHeading?.text
  );
  const [Heading4, setHeading4] = useState(
    selected?.template?.aboutPage?.section2Data?.sectionHeading?.text
  );

  //Enable Function and Values modularised
  const EnableFunction = {
    setEnabled5,
    setEnabled6,
    setEnabled7,
    setEnabled8,
    setEnabled9,
    setEnabled10,
  };
  const EnableValues = {
    enabled5,
    enabled6,
    enabled7,
    enabled8,
    enabled9,
    enabled10,
  };

  const AboutFunctions = {
    setContentImage,
    setContentImage2,
    setFounderData,
    setTypewriter,
  };

  const AboutValues = {
    contentImage,
    contentImage2,
    founderData,
    typewriter,
  };

  //Editor Functions and Value modularised
  const EditorFunctions = {
    setEditorState,
    setEditorState2,
    setEditorState3,
    setEditorState4,
    setEditorState5,
  };
  const EditorValues = {
    editorState,
    editorState2,
    editorState3,
    editorState4,
    editorState5,
  };

  //Heading Function and Value modularised
  const HeadingFunctions = {
    setHeading3,
    setHeading4,
  };
  const HeadingValues = { Heading3, Heading4 };

  //Footer Data
  const [footerData, setFooterData] = useState({
    email: selected?.template?.footer?.footerData?.emailData,
    phone: selected?.template?.footer?.footerData?.phoneNumberData,
    facebookLink: selected?.template?.footer?.socialLinksData?.facebookData,
    instagramLink: selected?.template?.footer?.socialLinksData?.instagramData,
  });

  const handleFooterData = (event, thing) => {
    event.preventDefault();

    let value = event.target.value;
    setFooterData({ ...footerData, [thing]: value });
  };

  async function handleImages(e, setFunction, oldValue, array = false) {
    if (array) {
      let filesArray = [...oldValue];

      for (let i = 0; i < e.target.files.length; i++) {
        await filesArray.push({ image: e.target.files[i] });
      }
      await setFunction(filesArray);
    } else {
      await setFunction(e.target.files[0]);
    }
  }

  async function handArrayObjectChange(
    e,
    index,
    key,
    setFunction,
    oldValue,
    type = "text"
  ) {
    e.preventDefault();
    if (oldValue[index]) {
      let newArray = [...oldValue];
      newArray[index][key] =
        type === "text" ? e.target.value : e.target.files[0];
      await setFunction(newArray);
    }
  }

  async function removeTeamMemberImage(e, index, setFunction, oldValue, key) {
    e.preventDefault();
    console.log(oldValue[index], "oldValue[index]");
    if (oldValue[index]) {
      let newArray = [...oldValue];
      newArray[index][key] = "";
      await setFunction(newArray);
    }
  }

  //Converted content for preview of sites
  const [convertedContent, setConvertedContent] = useState(null);
  const [convertedContent2, setConvertedContent2] = useState(null);
  const [convertedContent3, setConvertedContent3] = useState(null);
  const [convertedContent4, setConvertedContent4] = useState(null);
  const [convertedContent5, setConvertedContent5] = useState(null);

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
  }, [editorState]);
  useEffect(() => {
    let html = convertToHTML(editorState2.getCurrentContent());
    setConvertedContent2(html);
  }, [editorState2]);
  useEffect(() => {
    let html = convertToHTML(editorState3.getCurrentContent());
    setConvertedContent3(html);
  }, [editorState3]);
  useEffect(() => {
    let html = convertToHTML(editorState4.getCurrentContent());
    setConvertedContent4(html);
  }, [editorState4]);
  useEffect(() => {
    let html = convertToHTML(editorState5.getCurrentContent());
    setConvertedContent5(html);
  }, [editorState5]);

  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  async function createStore() {
    if (busy) {
      toast("Update request already in process");
      return;
    }
    setBusy(true);
    let formData = new FormData();

    let founderDataToSend = [];

    formData.append("aboutsection1Enabled", enabled7);
    if (enabled7 && !contentImage?.imgURL) {
      formData.append("aboutsection1Image", contentImage);
    }

    formData.append("aboutsection2Enabled", enabled8);
    if (enabled8) {
      formData.append("aboutsection2Image", contentImage2);
    }

    formData.append("teamEnabled", enabled9);
    for (let i = 0; i < founderData.length; i++) {
      if (!founderData[i].image.imgURL) {
        formData.append("teamImages", founderData[i].image);
      } else {
        founderDataToSend.push(founderData[i]);
      }
    }

    let response = await updateOrganisationWebsiteImages(formData);
    console.log(response);
    if (response.status === 200) {
      for (let i = 0; i < response.data.teamImages.length; i++) {
        founderDataToSend.push({
          name: founderData[i].name,
          image: response.data.teamImages[i],
        });
      }
      founderDataToSend.forEach(function (v) {
        delete v._id;
      });

      let newTypewriter = typewriter?.map((item) => item.string);
      console.log(AboutValues);
      let templateData = {
        template: {
          chosenColor: chosenColor,

          aboutPage: {
            typeWriterEnabled: enabled5,
            mainHeadingData: convertToRaw(editorState3.getCurrentContent()),
            typeWriterData: newTypewriter,
            section1Enabled: enabled7,
            section1Data: {
              sectionImage: !response?.data?.aboutsection1Image.imgURL
                ? AboutValues.contentImage
                : response.data.aboutsection1Image,
              sectionText: convertToRaw(editorState2.getCurrentContent()),
              sectionHeading: {
                font: 16,
                text: Heading3,
              },
            },
            section2Enabled: enabled8,
            section2Data: {
              sectionImage: !response?.data?.aboutsection2Image.imgURL
                ? AboutValues.contentImage2
                : response.data.aboutsection2Image,
              sectionText: convertToRaw(editorState4.getCurrentContent()),
              sectionHeading: {
                font: 16,
                text: Heading4,
              },
            },
            headingEnabled: enabled6,
            headingData: convertToRaw(editorState.getCurrentContent()),
            teamEnabled: enabled9,
            teamData: {
              teamHeading: convertToRaw(editorState5.getCurrentContent()),
              teamSubheading: "Our Team",
              teamMembers: founderDataToSend,
            },
          },
          footer: {
            footerEnabled: true,
            footerData: {
              phoneNumberEnabled: true,
              phoneNumberData: footerData.phone,
              emailEnabled: true,
              emailData: footerData.email,
            },
            socialLinksEnabled: true,
            socialLinksData: {
              facebookData: footerData.facebookLink,
              instagramData: footerData.instagramLink,
            },
          },
        },
        organisation_id: selected?._id,
      };
      console.log(templateData);

      let response2 = await updateOrganisationWebsite(templateData);
      console.log(response2);
      if (response2.status_code === 200) {
        toast.success("Website Update");
        setBusy(false);
        setTimeout(() => {
          window.location.reload(false);
        }, 2500);
      } else {
        setBusy(false);
        toast.error(response2.message);
      }
    } else {
      setBusy(false);
      toast.error("Error in Adding Images");
    }
  }

  return (
    <>
      <div className="flex items-center justify-center pb-4">
        <button
          onClick={() => setShowPreview(true)}
          style={{ zIndex: 1000 }}
          className="fixed px-3 py-1 rounded-md hover:bg-amber-500 hover:text-white bg-amber-300"
        >
          Preview
        </button>
      </div>
      <Transition appear show={showPreview} as={Fragment}>
        <Dialog
          style={{ zIndex: 20000 }}
          as="div"
          className="relative z-10"
          onClose={() => setShowPreview(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-11/12 mx-auto cursor-pointer   h-[80%] relative">
                  <div
                    style={{ zIndex: 21000 }}
                    className=" flex items-center relative fixed justify-end "
                  >
                    <MdCancel
                      style={{ zIndex: 21000 }}
                      size={30}
                      onClick={() => setShowPreview(false)}
                    />
                  </div>
                  <div className="overflow-y-scroll bg-white h-full w-full">
                    <SellerAboutPage
                      template={selected && selected?.template?.aboutPage}
                      createMarkup={createMarkup}
                      convertedContent={convertedContent}
                      convertedContent2={convertedContent2}
                      convertedContent3={convertedContent3}
                      convertedContent4={convertedContent4}
                      convertedContent5={convertedContent5}
                      founderData={founderData}
                      contentImage={contentImage}
                      contentImage2={contentImage2}
                      typewriter={typewriter}
                      basecolor={chosenColor}
                      footerData={footerData}
                      selected={selected}
                      EnableValues={EnableValues}
                    />
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <div className="w-full bg-white rounded-md">
        <div className="border-b">
          <Block>
            <Headline headline={"Choose a theme color"} />
            <div className="flex space-x-2 justify-center">
              <button
                onMouseEnter={() => setShowPicker(true)}
                onMouseLeave={() => setShowPicker(false)}
                className="px-4 py-1 relative rounded-md bg-blue-500 hover:bg-blue-700 text-white"
              >
                Choose color
                {showPicker && (
                  <div
                    className="absolute -translate-x-1/2 left-1/2 top-full"
                    style={{ zIndex: 500 }}
                  >
                    <BlockPicker
                      colors={BaseColors}
                      color={chosenColor}
                      onChange={(e) => setChosenColor(e.hex)}
                    />
                  </div>
                )}
              </button>
              <div className="flex items-center space-x-2">
                <span style={{ color: chosenColor }}>Chosen color:</span>
                <div
                  className={`h-4 w-4 border rounded-full `}
                  style={{ backgroundColor: chosenColor }}
                ></div>
              </div>
            </div>
          </Block>
        </div>
        <AboutPage
          removedFounder={removedFounder}
          setRemovedFounder={setRemovedFounder}
          EnableFunctions={EnableFunction}
          EnableValues={EnableValues}
          EditorFunctions={EditorFunctions}
          EditorValues={EditorValues}
          handleImages={handleImages}
          AboutFunctions={AboutFunctions}
          AboutValues={AboutValues}
          handArrayObjectChange={handArrayObjectChange}
          HeadingFunctions={HeadingFunctions}
          HeadingValues={HeadingValues}
          removeTeamMemberImage={removeTeamMemberImage}
        />
        <div className="border-t">
          <FooterData
            handleFooterData={handleFooterData}
            footerData={footerData}
          />
        </div>
        <div className="border-t w-full mt-4">
          <button
            onClick={() => !busy && createStore()}
            className={
              "py-3 w-full bg-black text-white " +
              (!busy && "hover:bg-blue-600")
            }
          >
            {busy ? <Loader size={"24px"} /> : "Update website"}
          </button>
        </div>
      </div>
    </>
  );
};

export default UpdateOrganisationWebsite;
