import React, { useState, useEffect } from "react";
import { ImBin2 } from "react-icons/im";
import { EditorState, RichUtils } from "draft-js";
import { Editor, EditorUtils } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../App.css";
import { convertToHTML } from "draft-convert";
import axios from "axios";
import { APIURL } from "../../backend";
const MAX_SIZE = 512;
const catURL = `${APIURL}/category/add-new-category`;

export const AddCat = () => {
  const [isChecked, setIsChecked] = useState(0);
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [key, setKey] = useState(1);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [convertedContent, setConvertedContent] = useState(null);

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
  }, [editorState]);

  const handleChange = () => {
    console.log("The checkbox was toggled = ", isChecked);
    setIsChecked(!isChecked);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const paragraph = editorState.getCurrentContent().getPlainText();
    console.log("name = ", name);
    console.log("desc = ", paragraph);
    setDesc(paragraph);
    try {
      const response = await axios.post(
        catURL,
        {
          categoryName: name,
          categoryDescription: paragraph,
          categoryStatus: isChecked,
        },
        {
          headers: {
            Authorization: "f5bcb965-7324-4544-914c-d69d5b16bc92",
          },
        }
      );
      console.log("response = ", response);
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response");
      }
    }
  };
  const customStyleMap = {
    FONT_SIZE_40: {
      fontSize: "80px",
    },
  };
  const handleReset = () => {
    setName("");
    setDesc("");
    setIsChecked(0);
    console.log("hi");
    console.log(editorState.getCurrentContent().getPlainText());
    // setConvertedContent(null);
    // setEditorState(null);
  };
  return (
    <div className="px-6 font-sans bg-gray-100">
      <div class="block p-6 rounded-lg shadow-lg bg-white max-w-full">
        <form onSubmit={handleSubmit} className="w-full">
          <div class="grid grid-cols-1 gap-4">
            <div class="form-group mb-6">
              <label>Category:</label>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                class="mt-2 form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="exampleInput124"
                aria-describedby="emailHelp124"
                placeholder="Enter Category Name"
              />
            </div>
          </div>
          <div className="border-black">Description:</div>

          <div className="mt-2 py-2 pl-2 pr-2 border-gray-300 border h-48 mb-6 ">
            <Editor
              key={key}
              editorState={editorState}
              onEditorStateChange={setEditorState}
              customStyleMap={customStyleMap}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
            />
          </div>

          <div class="grid grid-cols-1 gap-4 mb-6">
            <form>
              <label>Choose Category Image:</label>
              <label class="mt-3 block">
                <span class="sr-only">Choose File</span>
                <input
                  id="file"
                  type="file"
                  className="block w-full text-sm text-black bg-gray-200 file:mr-4 file:py-2 file:px-4  file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                  accept=".jpg,.png,.jpeg"
                />
              </label>
            </form>
          </div>

          <div class="grid grid-cols-3 gap-4 mt-2">
            <div class="form-group mb-6">
              <label class="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value={isChecked}
                  class="sr-only peer"
                  onChange={handleChange}
                  checked={isChecked}
                />
                <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Status
                </span>
              </label>
            </div>
            <div class="form-group mb-6"></div>
          </div>

          <button
            onClick={(e) => handleReset()}
            type="button"
            className="px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
          >
            Reset
          </button>
          <button
            onClick={(e) => handleSubmit()}
            type="submit"
            className="ml-6 px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          >
            Create
          </button>
        </form>
      </div>
    </div>
  );
};
