import React from "react";

const FirstInfo = ({ infoImage, infoData }) => {
  return (
    <div className="w-full flex flex-col md:flex-row relative  items-center justify-center  md:mt-24 py-8 ">
      <div className="w-4/5 mx-auto md:w-1/2 sm:mr-10 rightcover origin-left  relative sm:px-5">
        <img
          src={infoImage}
          alt=""
          className="w-full mx-auto h-full object-fill"
        />
      </div>
      <div className="w-4/5 mx-auto md:w-1/2 md:px-6 leftcover origin-right  bg-white text-2xl font-medium  h-full flex flex-col space-y-4 items-center justify-center">
        <p className="text-gray-600 text-center md:text-left xl:text-2xl lg:text-xl md:text-lg text-sm lg:px-6 md:px-4 sm:px-3 my-4 md:my-0">
          {infoData}
        </p>
      </div>
    </div>
  );
};

export default FirstInfo;
