import React, { useState, Fragment, useEffect } from "react";
import { uploadStoreImage, createStoreRequest } from "../../Helper/store";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../../Components/Loader";
import { Listbox, Transition } from "@headlessui/react";
import { getAllCategories } from "../../Helper/product";
import ImageInput from "../../Components/Builder/ImageInput";

const CreateStoreForm = () => {
  const [logoImage, setLogoImage] = useState(null);
  const [busy, setBusy] = useState(false);

  const [storeData, setStoreData] = useState({
    storeName: "",
    storeDescription: "",
    storeAddress: "",
  });

  const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useState();

  async function getCategories() {
    let res = await getAllCategories();

    if (res.status_code === 200) {
      console.log(res);
      setCategories(res.data);
    } else {
      toast.error("Something went wrong");
    }
  }

  useEffect(() => {
    getCategories();
  }, []);

  var { storeName, storeDescription, storeAddress } = storeData;

  const onSubmit = () => {
    let formData = new FormData();
    formData.append("storeLogo", logoImage);
    setBusy(true);
    uploadStoreImage(formData).then((data) => {
      console.log(data, "data1");
      setBusy(false);
      if (data.message === "success") {
        let storeObject = {
          storeName: storeName,
          storeDescription: storeDescription,
          storeAddress: storeAddress,
          storeLogo: data.data.storeLogo.imgURL,
          storeCategory: selected._id,
          user: JSON.parse(localStorage.getItem("macha-user"))?.uid,
        };

        console.log(storeObject, "storeObject", data.data.storeLogo);
        createStoreRequest(storeObject).then((data2, err) => {
          console.log(data2, "data2");
          if (data2.status_code === 200) {
            toast.success("Store Created Successfully");
            setTimeout(() => {
              window.location.reload(false);
            }, 2500);
          } else {
            toast.error("Error in creating store");
          }
        });
      } else {
        toast.err("error in  uploading logo");
      }
    });
  };

  const handleChange = (name) => (event) => {
    event.preventDefault();
    let value = event.target.value;
    setStoreData({ ...storeData, [name]: value });
  };
  console.log(categories);
  return busy ? (
    <div className="w-full h-full flex items-center">
      <Loader />
    </div>
  ) : (
    <div className="bg-white w-full flex flex-col  px-3 sm:px-6 sm:py-6 rounded-md">
      <h2 className="font-bold text-3xl mb-4">Create Store</h2>
      <p className="font-semibold text-lg mb-3">Store Name</p>
      <input
        type="text"
        className="px-2 py-2 rounded-md border"
        onChange={handleChange("storeName")}
        value={storeData.storeName}
      />
      <p className="font-semibold text-lg mb-3 mt-6">Store Description </p>
      <input
        type="text"
        className="px-2 py-2 rounded-md border"
        onChange={handleChange("storeDescription")}
        value={storeData.storeDescription}
      />
      <p className="font-semibold text-lg mb-3 mt-6">Store Address</p>
      <input
        type="text"
        className="px-2 py-2 rounded-md border"
        onChange={handleChange("storeAddress")}
        value={storeData.storeAddress}
      />
      <p className="font-semibold text-lg mb-3 mt-6">Store Logo</p>
      <input
        type="file"
        accept="image/*"
        className="px-2 py-2 rounded-md border"
        onChange={(e) => setLogoImage(e.target.files[0])}
      />
      <p className="font-semibold text-lg mb-3 mt-6">
        Select Category for Store
      </p>
      <div className=" w-full">
        <Listbox value={selected} onChange={setSelected}>
          <div className="relative mt-1 ">
            <Listbox.Button className="relative w-full cursor-default border rounded-lg  bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
              <span className="block truncate">{selected?.categoryName}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"></span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {categories &&
                  categories.map((person, personIdx) => (
                    <Listbox.Option
                      key={personIdx}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active
                            ? "bg-amber-100 text-amber-900"
                            : "text-gray-900"
                        }`
                      }
                      value={person}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {person.categoryName}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"></span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
      <button className="py-3 bg-black text-white mt-8" onClick={onSubmit}>
        Submit
      </button>
    </div>
  );
};

export default CreateStoreForm;
