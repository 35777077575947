import React from "react";
import SwitchComp from "./Switch";

const Headline = ({ headline, enabled, setEnabled }) => {
  return (
    <div className="flex items-center justify-between mb-6">
      <span className="font-semibold">{headline}</span>
      {enabled !== undefined && (
        <SwitchComp enabled={enabled} setEnabled={setEnabled} />
      )}{" "}
    </div>
  );
};

export default Headline;
