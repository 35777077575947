import React, { useState } from "react";
import ImageInput from "./ImageInput";
import TextInput from "./TextInput";
import EditorInput from "./EditorInput";
import { AiFillCloseCircle } from "react-icons/ai";

const SplitBox = ({
  editorState,
  setEditorState,
  handleImageChange,
  handlerfunction,
  handlerValue,
  textValue,
  handleTextChange,
  rowReversed = false,

  setIsOpen,
  setIndex,
  setSelectedHandler,
  text,
}) => {
  return (
    <div
      className={
        "sm:flex items-center justify-between " +
        (rowReversed && "flex-row-reverse	")
      }
    >
      <div className="flex basis-1/2 items-center sm:justify-center space-x-2">
        <div className="h-36 aspect-square	">
          <ImageInput
            handleChange={handleImageChange}
            text={"Choose image"}
            handlerfunction={handlerfunction}
            handlerValue={handlerValue}
          />
        </div>

        {handlerValue && (
          <div className="h-36 aspect-square	relative">
            <img
              src={
                handlerValue.imgURL
                  ? handlerValue.imgURL
                  : URL.createObjectURL(handlerValue)
              }
              alt=""
              className="w-full h-full object-cover"
            />
            <div
              // onClick={() => {
              //   handlerfunction("");
              // }}
              className="absolute -top-2 -right-2 "
            >
              <AiFillCloseCircle
                className="text-red-500 text-2xl cursor-pointer hover:text-red-700"
                onClick={() => {
                  setIsOpen(true);
                  setSelectedHandler(text);
                }}
              />
            </div>{" "}
          </div>
        )}
      </div>
      <div className="flex basis-1/2 flex-col  space-y-3">
        <TextInput
          label={"Heading"}
          tagname={"heading"}
          placeholder={"Enter a heading value"}
          handleChange={handleTextChange}
          value={textValue}
        />
        <label className="block mb-2 text-sm font-medium text-gray-900 ">
          Description
        </label>
        <EditorInput
          editorState={editorState}
          setEditorState={setEditorState}
        />
      </div>
    </div>
  );
};

export default SplitBox;
