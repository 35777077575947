import { APIURL } from "../backend";

export const signout = (next) => {
  if (typeof window !== "undefined") {
    console.log(JSON.parse(localStorage.getItem("macha-user"))?.token);
    return fetch(`${APIURL}/auth/logout`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: ` ${
          JSON.parse(localStorage.getItem("macha-user"))?.token
        }`,
      },
    })
      .then(async (response) => {
        let res = await response.json();
        console.log(res.status_code);
        if (res.status_code === 200) {
          localStorage.removeItem("macha-user");
          return;
        } else {
          localStorage.removeItem("macha-user");
          return;
        }
      })
      .catch((err) => console.log(err));
  }
};

export const isAuthenticated = async () => {
  if (typeof window === "undefined") {
    return false;
  }
  const user = await localStorage.getItem("macha-user");
  if (user) {
    return JSON.parse(user);
  } else {
    return false;
  }
};

export const getUserInfo = () => {
  return fetch(`${APIURL}/user/get-user-info`, {
    method: "GET",

    headers: {
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
      Accept: "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getUserInfoWithPopulate = () => {
  return fetch(`${APIURL}/user/get-user-info-populate`, {
    method: "GET",

    headers: {
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
      Accept: "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const PasswordResetRequest = (data) => {
  console.log(data, "data");
  return fetch(`${APIURL}/auth/password-reset-request`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const resetPasswordResolve = (data) => {
  console.log(data, "data");
  return fetch(`${APIURL}/auth/password-reset-resolve`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const loginFromMobile = (data) => {
  console.log(data, "data");
  return fetch(`${APIURL}/auth/mobile-login`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const verifyMobileLogin = (data) => {
  console.log(data, "data");
  return fetch(`${APIURL}/auth/mobile-login-verify`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const resendPasswordResetLink = (data) => {
  return fetch(`${APIURL}/auth/resend-password-reset-link`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const VerifyLogin = async (user_id, secure_code) => {
  return fetch(`${APIURL}/auth/registration-verify-email`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user_id: user_id,
      secure_code: secure_code,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const resendVerificationOTPEmail = async (user_id) => {
  return fetch(`${APIURL}/auth/resend-verification-email`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user_id: user_id,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
