import React from "react";

const TextInput = ({
  tagname,
  label,
  placeholder,
  handleChange,
  value,
  type = "simple",
  index,
  thing,
  setFunction,
  oldValue,
}) => {
  return (
    <div className="w-full pt-3 sm:pt-0">
      {label && (
        <label
          for={tagname}
          className="block mb-2 text-sm font-medium text-gray-900 "
        >
          {label}
        </label>
      )}
      <input
        type="text"
        id={tagname}
        value={value}
        className=" border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        placeholder={placeholder}
        onChange={(e) =>
          type === "arrayobj"
            ? handleChange(e, index, thing, setFunction, oldValue)
            : type === "obj"
            ? handleChange(e, thing)
            : handleChange(e.target.value)
        }
      />
    </div>
  );
};

export default TextInput;
