// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCj9scNpzAeNYsecuggvbCBO84DLpHJpyM",
  authDomain: "phone-auth-31af2.firebaseapp.com",
  projectId: "phone-auth-31af2",
  storageBucket: "phone-auth-31af2.appspot.com",
  messagingSenderId: "796316843036",
  appId: "1:796316843036:web:0ff119a5ae08cbd75773d9",
  measurementId: "G-XXRGN58KXT",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
