import React from "react";
import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
import facebook from "../../../images/facebook.png";
import instagram from "../../../images/instagram.png";
import logo from "../../../images/logo.png";

const Footer = ({ email, phone, insta, facebooklink, orgname, sellername }) => {
  return (
    <div className="flex flex-col mt-6  py-6 md:py-8 xl:px-48 lg:px-40 md:px-28 px-10 bg-black bg-opacity-80 text-slate-100 md:space-y-8">
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="flex flex-col items-center md:items-start">
          <a href={`tel:${phone}`} className="flex items-center space-x-2">
            <AiOutlinePhone className="text-2xl" />
            <p className="md:text-lg text-sm">{phone}</p>
          </a>
          <a href={`mailto:${email}`} className="flex items-center space-x-2">
            <AiOutlineMail className="text-2xl" />
            <p className="md:text-lg text-sm">{email}</p>
          </a>
        </div>
        <div className="flex flex-col items-center  my-4 md:my-0 space-y-2">
          <div className="flex items-center space-x-4 ">
            <a href={facebooklink}>
              <img src={facebook} alt="" className="w-8" />
            </a>
            <a href={insta}>
              <img src={instagram} alt="" className="w-8" />
            </a>
          </div>
          {orgname && (
            <p className="md:text-lg sm:text-sm text-xs font-medium flex items-center space-x-2">
              <span className="block pr-2">Part of </span> {orgname}
            </p>
          )}
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center md:justify-between space-y-2 md:space-y-0 mt-4 md:mt-0 ">
        <p className="md:text-lg sm:text-sm text-xs  font-medium flex items-center space-x-2">
          <span className="block">Partnered with</span>{" "}
          <img src={logo} alt="" className="w-36 ml-2" />
        </p>
        <div className="md:text-right text-center font-medium md:text-lg text-sm text-gray-300">
          2023 All Rights Reserved {sellername}
        </div>
      </div>
    </div>
  );
};

export default Footer;
