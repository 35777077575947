import React from "react";

const KeyPoints = ({ labelValueData }) => {
  return (
    <div className="w-full bg-white lg:mb-10 md:mb-8 mb-0 md:py-10 py-8 text-black">
      <div className="w-4/5 mx-auto grid md:grid-cols-4 grid-cols-2 xl:gap-x-20 md:gap-x-10 gap-x-5 gap-y-4">
        {labelValueData?.map((data, index) => {
          return (
            <div
              className="border-2 lg:p-3 p-2 border-gray-200 flex lg:py-10 md:py-8 py-6 flex-col items-center justify-center space-y-2   shadow-lg hover:shadow-xl rounded-md"
              key={index}
            >
              <p className="text-5xl text-center font-black">{data.number}</p>
              <p className="text-lg text-center font-light capitalize text-gray-600">
                {data.title}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default KeyPoints;
