export const LoginToast = () => (
  <div>
    <a
      target="_blank"
      rel="noreferrer"
      href={`/login/?path=${
        window.location.pathname !== "/" && window.location.href
      }`}
    >
      Please Login to continue.
      <span className="font-semibold underline">Click here to login</span>
    </a>
  </div>
);
