import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  getOrganisationJoinRequestsForOrganisation,
  resolveStoreJoinRequest,
} from "../../Helper/organisation";
import ManageHeader from "../Manage/ManageHeader";
import moment from "moment";
import { TfiWorld } from "react-icons/tfi";
import { BsCheckLg } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";

const StoreRequests = () => {
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    getOrganisationJoinRequestsForOrganisation().then((res) => {
      if (res.status_code === 200) {
        setRequests(res.data);
      } else {
        toast.error("Error fetching requests at the moment");
      }
    });
  }, []);

  const resolveRequest = async (request_id, action, request_status) => {
    if (request_status === "pending") {
      const data = {
        request_id: request_id,
        request_action: action,
      };
      const res = await resolveStoreJoinRequest(data);
      if (res.status_code === 200) {
        let requestscheck = await requests;
        let index = await requestscheck.findIndex((x) => x._id === request_id);
        requestscheck[index].request_status = await action;
        setRequests(requestscheck);
        toast.success(`Store request ${action} successfully`);
      } else {
        toast.error(res.message);
      }
    } else {
      toast("Request already resolved");
    }
  };
  console.log(requests);
  return (
    <div>
      <ManageHeader heading={"Store Requests"} />
      <div className="my-6 w-full rounded-md border border-gray-200">
        <table className="table-auto  w-full  bg-[#f7f8f9] rounded-lg">
          <thead>
            <tr className="text-sm font-thin">
              <th className="font-normal py-3 max-sm:hidden">Logo</th>
              <th className="font-normal py-3">Store Name</th>
              <th className="font-normal py-3  max-sm:hidden">Store Address</th>
              <th className="font-normal py-3">Requested on</th>
              <th className="font-normal py-3">Status</th>
              <th className="font-normal py-3">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y">
            {requests?.length > 0 &&
              requests?.map((request, index) => {
                return (
                  <tr className="text-sm text-center">
                    <td className="flex items-center justify-center py-4  max-sm:hidden">
                      <img
                        className="w-12 h-12 rounded-md"
                        alt=""
                        src={request?.requester_id?.storeLogo}
                      />
                    </td>
                    <td className="text-sm text-gray-500">
                      {request?.requester_id?.storeName}
                    </td>
                    <td className="text-sm text-gray-500  max-sm:hidden">
                      {request?.requester_id?.storeAddress}
                    </td>
                    <td className="text-sm text-gray-500">
                      {moment(request?.created_at).format("MMM Do YY")}
                    </td>
                    <td
                      className={
                        "text-sm  " +
                        (request?.request_status === "accepted"
                          ? "text-green-600"
                          : "text-red-600")
                      }
                    >
                      {request?.request_status}
                    </td>
                    <td className="text-center">
                      {request?.request_status === "pending" && (
                        <div className="flex items-center justify-center space-x-2">
                          {request?.requester_id?.template && (
                            <a
                              target={"_blank"}
                              rel="noreferrer"
                              href={`/profile/${request?.requester_id?.storeName.replace(
                                /\s+/g,
                                "-"
                              )}/${request?.requester_id?._id}`}
                            >
                              <TfiWorld
                                data-te-toggle="tooltip"
                                data-te-placement="bottom"
                                data-te-ripple-init
                                data-te-ripple-color="light"
                                title={"See website"}
                                className="text-lg hover:text-blue-600"
                              />
                            </a>
                          )}
                          <RxCross2
                            data-te-toggle="tooltip"
                            data-te-placement="bottom"
                            data-te-ripple-init
                            data-te-ripple-color="light"
                            title={"Reject request"}
                            onClick={() =>
                              resolveRequest(
                                request?._id,
                                "rejected",
                                request.request_status
                              )
                            }
                            className="text-lg hover:text-red-600 cursor-pointer"
                          />
                          <BsCheckLg
                            onClick={() =>
                              resolveRequest(
                                request?._id,
                                "accepted",
                                request.request_status
                              )
                            }
                            data-te-toggle="tooltip"
                            data-te-placement="bottom"
                            data-te-ripple-init
                            data-te-ripple-color="light"
                            title={"Accept Request"}
                            className="text-lg hover:text-green-600 cursor-pointer"
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StoreRequests;
