import React, { useState, useEffect } from "react";
import { ImBin2 } from "react-icons/im";

import "../../App.css";
import axios from "axios";
import { APIURL } from "../../backend";
import toast from "react-hot-toast";
import { uploadProduct, uploadProductImages } from "../../Helper/product";
import Loader from "../Builder/Loader";

const getCatURL = `${APIURL}/category/get-all-categories`;
const getSubCatURL = `${APIURL}/category/get-all-subcategories`;

const MAX_SIZE = 5125554545;
const FormData = require("form-data");

export const AddNew = ({ selected }) => {
  const [productName, setProductName] = useState("");
  const [productCat, setProductCat] = useState("");
  const [productSubCat, setProductSubCat] = useState("");
  const [price, setPrice] = useState("");
  const [offerPrice, setOfferPrice] = useState("");
  const [freeShipping, setFreeShipping] = useState(0);
  const [cod, setCod] = useState(0);
  const [returnAvailable, setreturnAvailable] = useState(0);
  const [status, setStatus] = useState(0);
  const [files, setFiles] = useState([]);
  const [check, setCheck] = useState(0);
  const [checkSub, setSubCheck] = useState(0);
  const [catData, setData] = useState({});
  const [subCatData, setSubData] = useState({});
  const [filteredList, setFilteredList] = useState({});
  const [loading, setLoading] = useState(false);
  const [description, setDiscription] = useState("");

  useEffect(() => {
    axios.get(getCatURL, {}).then((response) => {
      setData(response.data.data);
      setFilteredList(response.data.data);
      setCheck(1);
      console.log("res = ", response.data.data);
      console.log(catData);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleFiles(e) {
    if (e.target.files.length > 5) {
      toast.error("Only 5 files allowed");
      return;
    }
    for (let i = 0; i < e.target.files.length; i++) {
      console.log(e.target.files[i].size);
      if (e.target.files[i].size > MAX_SIZE) {
        toast.error("File size should be less than 500KB");
        return;
      } else {
        setFiles((files) => [...files, e.target.files[i]]);
      }
    }
  }

  const handleCat = (val) => {
    const filteredCat = filteredList.filter((car) => car.categoryName === val);

    setProductCat(filteredCat[0]._id);
    axios
      .get(getSubCatURL + "?categoryID=" + filteredCat[0]._id)
      .then((response) => {
        setSubData(response.data.data);
        setSubCheck(1);
      });
  };
  const handleSub = (val) => {
    const filteredSub = subCatData.filter((sub) => sub.subcategoryName === val);

    setProductSubCat(filteredSub[0]._id);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    const img_data = new FormData();
    for (let i = 0; i < files.length; i++) {
      img_data.append("files", files[i]);
    }
    const result = await uploadProductImages(img_data);
    console.log(result, "result 1");
    if (result.error !== false) {
      toast.error(result.message);
      return;
    }
    let productImageFilenames = result.data.upfilenames;

    const sendbody = {
      productName: productName,
      productDescription: description,
      status: status,
      productPrice: price,
      productCategory: productCat,
      productSubcategory: productSubCat,
      cod: cod ? true : false,
      freeShipping: freeShipping ? true : false,
      returnAvailable: returnAvailable ? true : false,
      offerPrice: offerPrice,
      productImageFilenames: productImageFilenames,
      store: selected?._id,
    };

    try {
      const resp = await uploadProduct(sendbody);
      console.log(resp, "resp 2");
      if (resp.status_code === 200) {
        toast.success("Uploaded successfully");
        setLoading(false);
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      } else {
        toast.error(resp.message);
        setLoading(false);
      }
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response");
      }
    }
  };

  const customStyleMap = {
    FONT_SIZE_40: {
      fontSize: "80px",
    },
  };

  const removeImage = (image) => {
    setFiles(files.filter((file) => file !== image));
  };

  return (
    <div className="sm:px-6 font-sans bg-gray-100">
      <div class="block sm:p-6 rounded-lg shadow-lg bg-white max-w-full">
        <form onSubmit={handleSubmit} className="w-full">
          <div class="lg:grid lg:grid-cols-2 gap-4">
            <div class="form-group mb-6">
              <label>Product Name(*):</label>
              <input
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                type="text"
                className="mt-2  form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="exampleInput124"
                aria-describedby="emailHelp124"
                placeholder="Enter Product Name here"
              />
            </div>
          </div>
          <div className="border-black">Product Description(*):</div>

          <div className="mt-2  ">
            <textarea
              placeholder="Enter product description here"
              className="w-full border px-2 py-1"
              rows={6}
              value={description}
              onChange={(e) => setDiscription(e.target.value)}
            />
          </div>
          <div class="sm:grid sm:grid-cols-3 gap-4 my-6">
            <div class="form-group sm:pt-0 pt-3">
              <label className="w-full">Product Category(*):</label>
              <select
                onChange={(e) => handleCat(e.target.value)}
                className="mt-2 w-[80%] sm:w-full  p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
              >
                <option default disabled selected>
                  Please Select
                </option>
                {check === 1 &&
                  catData.map((data, index) => (
                    <option>{data.categoryName}</option>
                  ))}
                {/* <option>Fashion</option>
                <option>Beauty</option> */}
              </select>
            </div>
            <div class="form-group sm:pt-0 pt-3">
              <label className="">Product Subcategory:</label>
              <select
                onChange={(e) => handleSub(e.target.value)}
                className="mt-2 w-[80%] sm:w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
              >
                <option disabled selected>
                  Please Select
                </option>
                {checkSub === 1 &&
                  subCatData.map((data, index) => (
                    <option>{data.subcategoryName}</option>
                  ))}
              </select>
            </div>
            <div class="form-group sm:pt-0 pt-3">
              <label className="">Child Category:</label>
              <select className=" mt-2 w-[80%] sm:w-full  p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600">
                <option disabled selected>
                  Please Select
                </option>
              </select>
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-2 gap-4">
            <div class="form-group mb-6">
              <label>Product Tags:</label>
              <input
                type="text"
                class="form-control
            block w-full px-3 py-1.5 text-base font-normaltext-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 mt-2 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="exampleInput124"
                aria-describedby="emailHelp124"
                placeholder="Enter product tags by comma"
              />
            </div>
            <div className="relative w-full">
              <label className="">Size Chart:</label>
              <select className="w-[80%] sm:w-full p-2.5 mt-2 text-gray-500 h-10 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600">
                <option disabled selected>
                  Please Select
                </option>
                <option>T-Shirts (Olivet)</option>
              </select>
            </div>
          </div>
          <div class="grid sm:grid-cols-4 grid-cols-2 gap-4 mb-6 pt-3 sm:pt-0">
            <div class="form-group ">
              <label>Price(*):</label>
              <input
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                type="text"
                class="form-control block w-full px-3 py-1.5 text-base font-normaltext-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 mt-2 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="exampleInput124"
                aria-describedby="emailHelp124"
                placeholder="0"
              />
            </div>
            <div class="form-group ">
              <label>Offer Price(*):</label>
              <input
                value={offerPrice}
                onChange={(e) => setOfferPrice(e.target.value)}
                type="text"
                class="form-control block w-full px-3 py-1.5 text-base font-normaltext-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 mt-2 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="exampleInput124"
                aria-describedby="emailHelp124"
                placeholder="0"
              />
            </div>
            <div class="form-group ">
              <label>Tax:</label>
              <input
                step="0.01"
                type="number"
                class="form-control block w-full px-3 py-1.5 text-base font-normaltext-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 mt-2 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="exampleInput124"
                aria-describedby="emailHelp124"
                placeholder="0"
              />
            </div>
            <div class="form-group ">
              <label>Tax Name:</label>
              <input
                type="text"
                class="form-control block w-full px-3 py-1.5 text-base font-normaltext-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 mt-2 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="exampleInput124"
                aria-describedby="emailHelp124"
                placeholder="Enter tax name"
              />
            </div>
          </div>
          <div class="sm:grid sm:grid-cols-2 gap-4 mb-6">
            <form>
              <label>Choose Product Images:</label>
              <label class="mt-3 block">
                <span class="sr-only">Choose File</span>
                <input
                  id="file"
                  onChange={(e) => {
                    // setGroundTruthFile(e.target.files[0]);
                    handleFiles(e);
                  }}
                  type="file"
                  className="block w-full text-sm text-black bg-gray-200 file:mr-4 file:py-2 file:px-4  file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                  accept=".jpg,.png,.jpeg"
                  multiple
                />
              </label>
              <p className="text-blue-500 text-xs">
                * Upto 5 images can be selected. Click on Upload after selecting
                the images.
              </p>
            </form>
            <div class="flex flex-row space-x-2 sm:pt-0 pt-3">
              {files &&
                files?.length > 0 &&
                files.map((file, index) => {
                  return (
                    <div className="relative  ">
                      <img
                        className=" sm:w-20 w-16 h-20 object-cover rounded-md"
                        src={URL.createObjectURL(file)}
                        alt=""
                      />
                      <button
                        onClick={(e) => removeImage(file)}
                        className="text-red-500 absolute -top-2 -right-2"
                      >
                        <ImBin2 className="w-3" />
                      </button>
                    </div>
                  );
                })}
            </div>
          </div>

          <div class="grid sm:grid-cols-4 grid-cols-2 gap-4 mt-6 ">
            <div class="form-group mb-6">
              <label class="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value={freeShipping}
                  class="sr-only peer"
                  onChange={(e) => setFreeShipping(!freeShipping)}
                  checked={freeShipping}
                />
                <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Free Shipping
                </span>
              </label>
            </div>
            <div class="form-group mb-6">
              <label class="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value={cod}
                  class="sr-only peer"
                  onChange={(e) => setCod(!cod)}
                  checked={cod}
                />
                <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  COD
                </span>
              </label>
            </div>
            <div className="relative w-full mb-6">
              <label class="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value={returnAvailable}
                  class="sr-only peer"
                  onChange={(e) => setreturnAvailable(!returnAvailable)}
                  checked={returnAvailable}
                />
                <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Return
                </span>
              </label>
            </div>
            <div class="form-group mb-6">
              <label class="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value={status}
                  class="sr-only peer"
                  onChange={(e) => setStatus(!status)}
                  checked={status}
                />
                <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Status
                </span>
              </label>
            </div>
          </div>
          {/* <div class="grid sm:grid-cols-3 grid-cols-2 gap-4 mt-2">
            <div class="form-group mb-6">
              <label class="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value={status}
                  class="sr-only peer"
                  onChange={(e) => setStatus(!status)}
                  checked={status}
                />
                <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Status
                </span>
              </label>
            </div>

            <div class="form-group mb-6"></div>
          </div> */}

          <button
            type="submit"
            className="px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
          >
            Reset
          </button>
          <button
            type="submit"
            className="ml-6 px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          >
            {loading ? <Loader size={"16px"} /> : "Create"}
          </button>
        </form>
      </div>
    </div>
  );
};
