import React, { useEffect, useState } from "react";
import { EditorState, convertFromRaw } from "draft-js";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";

const SecondLineInfoSection = ({ infoLine, selected }) => {
  console.log(`infoLine`, infoLine);
  const [convertedContent, setConvertedContent] = useState(null);
  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }
  useEffect(() => {
    if (infoLine !== null) {
      const blocks = infoLine && infoLine.blocks;

      const entityMap = {};

      const contentState = convertFromRaw({ blocks, entityMap });
      const editorState = EditorState.createWithContent(contentState);
      let html = convertToHTML(editorState.getCurrentContent());
      setConvertedContent(html);
    }
  }, [infoLine]);

  return (
    <div
      style={{ backgroundColor: selected }}
      className={`w-full py-8 text-center bg-${selected} text-white lg:text-3xl md:text-3xl text-xl xl:text-3xl  font-bold mb-10`}
    >
      <p className="text-center tracking-wide">
        <div
          className="p-6"
          dangerouslySetInnerHTML={createMarkup(convertedContent)}
        ></div>
      </p>
    </div>
  );
};

export default SecondLineInfoSection;
