import React from "react";
import OrganisationMainPage from "./Pages/MainPage";
import ViewOrder from "./Pages/ViewOrder";
import { AddNew } from "./Components/OrganisationPanel/AddNew";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import PhoneAuth from "./Pages/phoneSignUp";
import StoriesView from "./Pages/StoriesView";
import SellerAboutPage from "./Pages/SellerAboutPage";
import SellerPage from "./Pages/SellerPage";
import UserPanel from "./Pages/userPanel";
import ExplorePage from "./Pages/ExplorePage";
import ProductDetails from "./Pages/ProductDetails";
import Mainpage from "./Pages/WebsiteBuilder/MainPage";
import OrganisationPage from "./Pages/Organisation/OrganisationPage";
import OrganisationAboutPage from "./Pages/Organisation/OrganisationAboutPage";
import OgranisationWebsiteBuilder from "./Components/OrganisationWebsiteBuilder/OgranisationWebsiteBuilder";
import HomeAboutPage from "./Pages/HomePage/HomeAboutPage";
import Discover from "./Pages/Discover";
import SellerProducts from "./Pages/SellerProducts";
import ResetPassword from "./Pages/ResetPassword";
import OrganisationShopPage from "./Pages/Organisation/OrganisationShopPage";

import SellerWebsite from "./Pages/WebsiteBuilder/SellerWebsiteTemplate1";
import CheckoutPage from "./Pages/checkoutPage";
import OrderDetail from "./Components/Orders/OrderDetail";
import OrderConfirmation from "./Pages/OrderConfirmation";
import CategoryProducts from "./Pages/CategoryProducts";
import ShopPage from "./Pages/ShopPage";

const RoutesFile = () => {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" exact element={<HomePage />} /> */}
        <Route path="/" exact element={<Discover />} />

        <Route path="/about" exact element={<HomeAboutPage />} />
        <Route path="/shop" exact element={<ShopPage />} />

        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/phoneAuth" element={<PhoneAuth />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/confirmation" element={<OrderConfirmation />} />

        <Route path="/stories" exact element={<StoriesView />} />
        <Route path="/profile/:sellerName/:id" exact element={<SellerPage />} />
        <Route
          path="/profile/product/:sellerName/:id"
          exact
          element={<SellerProducts />}
        />

        <Route
          path="/profile/story/:sellerName/:id"
          exact
          element={<SellerAboutPage />}
        />
        <Route path="/user-panel" element={<UserPanel />} />
        <Route path="/explore" exact element={<ExplorePage />} />
        <Route
          path="/category/:categoryId"
          exact
          element={<CategoryProducts />}
        />
        <Route
          path="/product/:productName/:productId"
          exact
          element={<ProductDetails />}
        />
        <Route path="/seller-panel" element={<Mainpage />} />
        <Route path="/order/:orderId" exact element={<ViewOrder />} />
        <Route path="/add-new-products" exact element={<AddNew />} />
        <Route
          path="/organisation/:organisationName/:organisation_id"
          exact
          element={<OrganisationPage />}
        />
        <Route
          path="/organisation/about/:organisationName/:organisation_id"
          exact
          element={<OrganisationAboutPage />}
        />
        <Route
          path="/organisation/shop/:organisationName/:organisation_id"
          exact
          element={<OrganisationShopPage />}
        />
        <Route
          path="/organisationbuilder"
          exact
          element={<OgranisationWebsiteBuilder />}
        />
        <Route
          path="/organisation-panel"
          exact
          element={<OrganisationMainPage />}
        />
        <Route path="/create" exact element={<SellerWebsite />} />
        <Route
          path="/my-account/orders/:orderId"
          exact
          element={<OrderDetail />}
        />
      </Routes>
    </Router>
  );
};

export default RoutesFile;
