import React, { useRef, useState } from "react";
import { resetPasswordResolve } from "../Helper/Auth";
import Input from "@material-ui/core/Input";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";

const ResetPassword = () => {
  const userRef = useRef();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const query = new URLSearchParams(window.location.search);
  const token = query.get("token");

  async function passwordResolve() {
    let email = await localStorage.getItem("forgot-password-request-email");
    if (password === confirmPassword) {
      const response = await resetPasswordResolve({
        email: email,
        new_password: password,
        token: token,
      });
      console.log(response, "response");
      if (response.status_code === 200) {
        toast.success("Password updated successfully");
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error("Password does not match");
    }
  }

  return (
    <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl max-w-xl">
      <div className="mt-6">
        <div className="mb-2">
          <label
            for="email"
            className=" block text-sm font-semibold text-gray-800"
          >
            Password
          </label>
          <Input
            type="text"
            id="username"
            ref={userRef}
            autoComplete="off"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            required
            className="w-full block text-sm px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
          />
        </div>
        <div className="mb-2">
          <label
            for="email"
            className=" block text-sm font-semibold text-gray-800"
          >
            Confirm Password
          </label>
          <Input
            type="text"
            id="username"
            ref={userRef}
            autoComplete="off"
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
            required
            className="w-full block text-sm px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
          />
        </div>

        <div className="mt-6">
          <button
            onClick={passwordResolve}
            className="text-sm w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-black rounded-md hover:bg-slate-700 focus:outline-none focus:bg-slate-700"
          >
            Send New Password
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
