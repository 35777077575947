import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import useWindowDimensions from "../../../hooks/useWindowDimension";

const TeamMember = ({ name, position, image }) => {
  return (
    <div className="flex relative flex-col items-center justify-center mx-8 w-full">
      <img src={image} className="w-full object-fill" alt="" />
      <div className="w-full absolute bottom-0 left-0 bg-black bg-opacity-70 py-4 flex flex-col items-center justify-center space-y-1">
        <p className="text-white text-md font-semibold capitalize">{name}</p>
        <p className="text-gray-100 text-sm font-thin capitalize">{position}</p>
      </div>
    </div>
  );
};

const Team = ({
  team,
  startupName,
  teamData,
  createMarkup,
  convertedContent,
  info,
  basecolor = "blue-500",
}) => {
  const { width } = useWindowDimensions();

  function checkEmpty2(x) {
    return x.name === "" || x.image === "";
  }
  return (
    <div
      style={{ backgroundColor: basecolor }}
      className={`w-full md:mt-24  py-16 `}
    >
      <div className="flex w-4/5 mx-auto flex-col xl:space-y-9 lg:space-y-8 md:space-y-6 space-y-4 text-white">
        <p className="text-center lg:text-3xl md:text-3xl text-2xl xl:text-4xl font-bold ">
          Team behind {startupName}
        </p>
        <p>
          {createMarkup(convertedContent).__html.toString() === "<p></p>" ? (
            <div className="text-center font-[600] text-[30px]">%{info}%</div>
          ) : (
            <p
              className="p-6 text-white text-center text-xl font-medium"
              dangerouslySetInnerHTML={createMarkup(convertedContent)}
            ></p>
          )}
        </p>

        <p className="text-center text-2xl font-medium text-slate-200"></p>

        {team?.length < 4 ? (
          <div className="flex  items-center justify-center w-full mx-auto xl:space-x-12 lg:space-x-10 md:space-x-8 space-x-4">
            {team?.map((member, index) => {
              console.log(team, "team");

              return (
                <div className="lg:max-w-[25%] md:max-w-[35%] max-w-[50%]">
                  <TeamMember
                    key={index}
                    name={member.name}
                    // position={member.position}
                    image={
                      typeof member?.image === "string"
                        ? member?.image
                        : member?.image.imgURL
                        ? member?.image.imgURL
                        : URL.createObjectURL(member?.image)
                    }
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <Swiper
            spaceBetween={width > 640 ? 50 : 0}
            slidesPerView={width > 640 ? 3 : 1}
            modules={[Autoplay]}
            className="w-full flex items-center justify-center w-full mx-auto"
            loop={true}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
          >
            {team?.map((member, index) => {
              console.log(team, "team");
              return (
                <SwiperSlide className="w-full h-full px-4" key={index}>
                  <TeamMember
                    key={index}
                    name={member.name}
                    position={member.position}
                    image={member?.image}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default Team;
