import React, { useState, useEffect, Fragment } from "react";
import { addNewStory, getStories, deleteStory } from "../Helper/store";
import { AiFillDelete } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-hot-toast";

const AddStories = ({ storeSelected }) => {
  const [sliderImages, setSliderImages] = useState(null);
  const [stories, setStories] = useState([]);
  const [story, setStory] = useState();

  async function getStoriesData() {
    await getStories()
      .then((res, err) => {
        if (res.status_code === 200) {
          var filtered = res.data.filter((obj) => {
            if (obj._id.store_id !== null) {
              console.log(obj._id.store_id._id, "obj");
              return obj._id.store_id._id === storeSelected?._id;
            }
          });

          setStories(filtered[0].stories);
        } else {
          toast.error(res.message);
        }
      })
      .then((err) => {
        console.log(err, "err");
      });
  }

  console.log(stories, "stories");
  useEffect(() => {
    getStoriesData();
  }, []);

  async function deleteStoryMain(story) {
    let body = {
      story_id: story._id,
    };

    await deleteStory(body)
      .then((res) => {
        if (res.status_code === 200) {
          toast.success("Story deleted Successfully");
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }
  async function uploadStory(e) {
    e.preventDefault();
    let formData = new FormData();

    formData.append("storyfile", sliderImages);
    formData.append("store_id", storeSelected._id);

    await addNewStory(formData)
      .then((res) => {
        if (res.status_code === 200) {
          toast.success("Story Uploaded Successfully");
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  const transitionComponent = (item) => {
    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Are you sure you want to delete this card?
                  </Dialog.Title>

                  <div className="mt-4 flex">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        deleteStoryMain(story);
                        closeModal();
                      }}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      NO
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  return (
    <div className="w-full h-full">
      <div className="text-[25px] font-[600] sm:py-3 flex items-center justify-between ">
        Add Cards For Your Store
      </div>
      <div className="text-[20px] font-[500] py-3 flex items-center justify-center w-full ">
        Please Add Photos For Your Store
      </div>

      <div className="border-gray-200">
        <div className="flex items-center  justify-center py-6">
          <input
            type="file"
            accept="image/*"
            className=""
            onChange={(e) => setSliderImages(e.target.files[0])}
          />
          {sliderImages && (
            <div>
              <img
                src={URL.createObjectURL(sliderImages)}
                alt=""
                className="h-[100px]"
              />
            </div>
          )}
        </div>
      </div>
      <div className=" text-center">
        <button
          onClick={uploadStory}
          className="bg-[#FF2143] text-white rounded-[10px] sm:w-[20%] w-[30%] py-3"
        >
          Submit Card
        </button>
      </div>
      <div className="text-[25px] font-[600] py-3 flex items-center justify-between ">
        Your Cards
      </div>
      <div className=" mb-5 grid lg:grid-cols-4 sm:grid-cols-3 gap-10">
        {stories &&
          stories.length > 0 &&
          stories.map((item, index) => {
            return (
              <div className=" relative sm:w-max w-full">
                <div className="absolute right-0">
                  <AiFillDelete
                    className="cursor-pointer"
                    size={30}
                    style={{ color: "red", zIndex: 100 }}
                    onClick={() => {
                      setIsOpen(true);
                      setStory(item);
                    }}
                  />
                </div>
                <img src={item.url} alt="" className="mx-auto h-[400px]  " />
              </div>
            );
          })}
      </div>
      {stories.length === 0 && (
        <div className="text-[20px] font-[500] py-3  ">No Stories Added</div>
      )}
      {transitionComponent()}
    </div>
  );
};

export default AddStories;
