import React, { useState, useRef, useEffect, Fragment } from "react";
import PopupProduct from "../Components/popupProduct";
import MachaHeader from "../Components/Base/MachaHeader";
import Footer from "../Components/Base/Footer";
import { getAllCategories, getFilteredResults } from "../Helper/product";
import toast from "react-hot-toast";
import StoriesSlider from "../Components/StoriesSlider";
import { APIURL } from "../backend";
import useWindowDimensions from "../hooks/useWindowDimension";
import SellerProduct from "../Components/SellerPage/SellerProduct";
import { IoFilterSharp } from "react-icons/io5";
import Drawer from "../Components/Base/Drawer";
import { RxCross2 } from "react-icons/rx";
import { addToCart } from "../Helper/Order";
import { LoginToast } from "../Toasts/LoginToast";

const ExplorePage = () => {
  const [myFilters, setMyFilters] = useState({
    filters: {
      productCategory: [],
      offerPrice: [],
      artist: [],
      productSubcategory: [],
    },
  });
  const [pop, setPop] = useState(false);
  const [prodData, setData] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [catData, setCatData] = useState({});
  const [viewStories, setViewStories] = useState([]);
  const [viewedStories, setViewedStories] = useState([]);
  const [catOpen, setCatOpen] = React.useState(false);
  const [catSave, setCatSave] = useState([]);
  const { width } = useWindowDimensions();
  const [cartDisabled, setCartDisabled] = useState(false);

  const ref = useRef(null);
  const ref2 = useRef([]);

  const allCat = () => {
    getAllCategories().then((data) => {
      if (data.status_code === 200) {
        setCatData(data.data);
      } else {
        toast.error("Unable to fetch categories at the moment");
      }
    });
  };

  async function checkIfSelected() {
    for (let i = 0; i < catData?.length; i++) {
      let currentCategoryId = myFilters?.filters?.productCategory.indexOf(
        catData[i]?._id
      );

      if (currentCategoryId === -1) {
        ref2.current[i].checked = false;
      } else {
        ref2.current[i].checked = true;
      }
    }
  }

  useEffect(() => {
    if (
      catData?.length > 0 &&
      myFilters?.filters?.productCategory?.length > 0
    ) {
      checkIfSelected();
    }
    if (
      myFilters?.filters?.productCategory?.length === 0 &&
      catSave.length === 0
    ) {
      for (let i = 0; i < catData?.length; i++) {
        ref2.current[i].checked = false;
      }
    }
  }, [catSave]);

  async function getStories() {
    try {
      const res = await fetch(`${APIURL}/story/get-stories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("macha-user"))?.token,
        },
      });
      const response = await res.json();
      if (response.status_code === 200) {
        setViewStories(response.data);
      } else {
        console.log(response);
        toast.error("Error fetching stories at the moment");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  }

  async function getViewedStories() {
    if (localStorage.getItem("macha-user")) {
      try {
        const res = await fetch(`${APIURL}/story/get-viewed-stories`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("macha-user"))
              ?.token,
          },
        });
        const response = await res.json();
        if (response.status_code === 200) {
          setViewedStories(response.data);
        } else {
          console.log(response);
          toast.error("Error fetching stories at the moment");
        }
      } catch (error) {
        toast.error("Something went wrong");
      }
    } else {
      return;
    }
  }

  async function setUp() {
    await getStories();

    await getViewedStories();
  }

  useEffect(() => {
    setUp();
  }, []);

  async function loadFilteredProducts(filters) {
    getFilteredResults(filters).then((data) => {
      if (data.status_code === 200) {
        setData(data.data);
      } else {
        toast.error("Unable to fetch products at the moment");
      }
    });
  }

  const handleFilters = (filters, filterBy) => {
    const newFilters = { ...myFilters };
    newFilters.filters[filterBy] = filters;

    loadFilteredProducts(myFilters.filters);
    setMyFilters(newFilters);
  };

  const handleToggle = (c) => () => {
    let currentCategoryId = myFilters?.filters?.productCategory.indexOf(c);
    const newCategoryChecked = [...myFilters?.filters?.productCategory];
    let saveCategoryObj = [...catSave];
    if (currentCategoryId === -1) {
      newCategoryChecked.push(c);

      let obj = catData?.find((o) => o._id === c);

      if (obj) {
        saveCategoryObj.push(obj);
        setCatSave(saveCategoryObj);
      }
    } else {
      newCategoryChecked.splice(currentCategoryId, 1);
      const filteredcat = saveCategoryObj.filter((item) => item._id !== c);
      setCatSave(filteredcat);
    }
    handleFilters(newCategoryChecked, "productCategory");
  };

  async function handleClick(c) {
    let currentCategoryId = myFilters?.filters?.productCategory.indexOf(c);
    const newCategoryChecked = [...myFilters?.filters?.productCategory];
    let saveCategoryObj = [...catSave];
    if (currentCategoryId === -1) {
      newCategoryChecked.push(c);

      let obj = catData?.find((o) => o._id === c);
      console.log(obj, "obj");
      if (obj) {
        saveCategoryObj.push(obj);
        setCatSave(saveCategoryObj);
      }
    } else {
      newCategoryChecked.splice(currentCategoryId, 1);
      const filteredcat = saveCategoryObj.filter((item) => item._id !== c);
      setCatSave(filteredcat);
    }
    handleFilters(newCategoryChecked, "productCategory");
  }

  useEffect(() => {
    allCat();
    loadFilteredProducts(myFilters.filters);
  }, []);

  useEffect(() => {
    if (setPop) {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setPop(false);
          setActiveId(null);
        }
      };
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    }
  }, [pop]);

  async function addToCartHandler(pro, toggle) {
    if (localStorage.getItem("macha-user")) {
      addToCart({ productID: pro, quantity: toggle }).then((response) => {
        if (response.status_code === 200) {
          toast.success("Product added to cart");
          setCartDisabled(true);
        } else {
          toast.error("Something went wrong");
        }
      });
    } else {
      toast.error(LoginToast);
    }
  }

  return (
    <>
      <MachaHeader />
      <StoriesSlider stories={viewStories} viewedStories={viewedStories} />

      <div className="px-10 md:px-20 py-10 h-full w-full ">
        <div className="mb-8 md:hidden">
          <div className=" block flex items-center">
            <IoFilterSharp onClick={() => setCatOpen(true)} />
            <div className="text-[15px] pl-3 font-[500]">Filter</div>
          </div>
          <div className="flex items-center overflow-x-scroll noscrollbar">
            <button
              className="text-[15px] bg-gray-100 px-1 py-1 flex items-center rounded-[10px]"
              onClick={() => {
                setCatSave([]);
                setMyFilters({
                  ...myFilters,
                  filters: {
                    productCategory: [],
                    offerPrice: [],
                    artist: [],
                    productSubcategory: [],
                  },
                });
              }}
            >
              <RxCross2 />
              Clear filters
            </button>
            {catSave?.length > 0 &&
              catSave?.map((data, index) => {
                return (
                  <button
                    onClick={() => handleClick(data._id)}
                    className="text-[15px]  bg-gray-100 px-1 py-1 flex items-center rounded-[10px] mx-1 w-max"
                  >
                    <RxCross2 />
                    {data.categoryName}
                  </button>
                );
              })}
          </div>
        </div>
        <div className="flex md:flex-row  justify-center ">
          <div className="md:w-1/4  md:pr-10">
            <div className="hidden md:block pt-10 flex flex-col lg:flex-row text-left  justify-between border-b-2 border-gray-200">
              <div className="text-left font-semibold text-heading text-xl ">
                Filters
              </div>
            </div>
            <div className="py-5 border-b-2 border-gray-200 hidden md:block">
              <div className="text-heading text-[20px] font-[600]">
                Category
              </div>
              {catData &&
                catData.length > 0 &&
                catData?.map((data, index) => (
                  <div className="flex items-center mb-4 mt-4">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      ref={ref}
                      onChange={handleToggle(data._id)}
                      value={data._id}
                      className="w-4 h-4 mr-3 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="default-checkbox"
                      className="text-base text-black font-[600]"
                    >
                      {data.categoryName}
                    </label>
                  </div>
                ))}
            </div>
          </div>

          <div className="w-full">
            <div className="flex items-center justify-between">
              <div className="pr-40 ">
                <div className="text-xl font-bold text-heading hidden md:inline-flex pb-1 ">
                  {prodData?.length + " Items"}
                </div>
              </div>
            </div>
            <div className=" grid lg:grid-cols-4 lg:gap-4 md:grid-cols-3 md:gap-4 grid-cols-2 gap-4 sxs:grid-cols-1 sxs:gap-0">
              {prodData &&
                prodData?.map((data, index) => {
                  return width > 800 ? (
                    <div
                      onClick={() => {
                        setActiveId(data._id);

                        setPop(true);
                      }}
                      key={index}
                    >
                      <SellerProduct item={data} />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setActiveId(data._id);
                        if (width > 800) {
                          setPop(true);
                        }
                      }}
                      key={index}
                    >
                      <a href={`/product/${data.productName}/${data._id}`}>
                        <SellerProduct item={data} />
                      </a>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {pop === true && setActiveId && (
          <div
            style={{ zIndex: 100 }}
            className="fixed w-screeen h-screen top-0 left-0 right-0 bg-gray-800 bg-opacity-50 flex items-center justify-center"
          >
            <div
              ref={ref}
              className="  drop-shadow-lg rounded-[20px] w-[65%] h-[65%] bg-white flex justify-center items-center "
            >
              <PopupProduct
                setPop={setPop}
                addToCartHandler={addToCartHandler}
                product={
                  prodData[prodData.findIndex((x) => x._id === activeId)]
                }
              />
            </div>
          </div>
        )}
      </div>
      <Drawer isOpen={catOpen} setIsOpen={setCatOpen} direction="left">
        <div className="text-heading text-[20px] font-[600] px-3">Category</div>
        {catData &&
          catData.length > 0 &&
          catData?.map((data, index) => (
            <div className="flex items-center mb-4 mt-4 px-3">
              <input
                id="default-checkbox"
                type="checkbox"
                onChange={handleToggle(data._id)}
                ref={(element) => {
                  ref2.current[index] = element;
                }}
                value={data._id}
                className="w-4 h-4 mr-3 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="default-checkbox"
                className="text-base text-black font-[500] text-[15px]"
              >
                {data.categoryName}
              </label>
            </div>
          ))}
      </Drawer>
      <Footer />
    </>
  );
};

export default ExplorePage;
