import toast from "react-hot-toast";
import { APIURL } from "../backend";

export const likeStore = (story_id) => {
  return fetch(`${APIURL}/story/like-story`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: JSON.stringify({ story_id: story_id }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const unlikeStore = (story_id) => {
  return fetch(`${APIURL}/story/unlike-story`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${JSON.parse(localStorage.getItem("macha-user"))?.token}`,
    },
    body: JSON.stringify({ story_id: story_id }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getUser = () => {
  return fetch(`${APIURL}/user/get-user-info`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getStoriesByOrganisation = async (organisation_id) => {
  try {
    const res = await fetch(
      `${APIURL}/story/get-stories-by-organization/?organisationID=${organisation_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const response = await res.json();
    return response;
  } catch (error) {
    toast.error("Something went wrong");
  }
};
