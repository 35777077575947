import React, { useState, useEffect, Fragment } from "react";
import Carousel from "nuka-carousel/lib/carousel";
import { Router, useParams, useSearchParams } from "react-router-dom";
import MachaHeader from "../Components/Base/MachaHeader";
import Footer from "../Components/Base/Footer";
import { getSimilarProducts, getProductById } from "../Helper/product";
import toast from "react-hot-toast";
import SellerFooter from "../Components/SellerPage/Footer";
import OrganisationFooter from "../Components/WebsiteView/SellerPage/Footer";
// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper";
//
import { BsHeart, BsFillHeartFill } from "react-icons/bs";
import { addToWishlist, removeFromWishlist } from "../Helper/wishlist";
import { getUserInfo, isAuthenticated } from "../Helper/Auth";
import { BiRupee } from "react-icons/bi";
import SellerProduct from "../Components/SellerPage/SellerProduct";
import { addToCart } from "../Helper/Order";
import { getStore } from "../Helper/store";
import { getOrganisation } from "../Helper/organisation";
import Header from "../Components/SellerPage/Header";
import OrganisationHeader from "../Components/Base/OrganisationHeader";
import { LoginToast } from "../Toasts/LoginToast";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";

const ProductDetails = (props) => {
  const { productId } = useParams();
  const [isFav, setIsFav] = useState(false);
  const [cartDisabled, setCartDisabled] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const redirectFrom = searchParams.get("redirectFrom");
  const [template, setTemplate] = useState(null);

  async function getUser() {
    const user = await getUserInfo();

    if (user.status_code === 200) {
      if (user.data.wishlist.includes(productId)) {
        setIsFav(true);
      }
    }
  }

  async function getStoreData() {
    if (searchParams.get("redirectFromId") !== null) {
      await getStore(searchParams.get("redirectFromId"))
        .then((data, err) => {
          if (data.status_code === 200) {
            setTemplate(data.data);
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((err) => console.log(err));
    }
  }

  async function fetchOrganisation() {
    if (searchParams.get("redirectFromId") !== null) {
      const res = await getOrganisation(searchParams.get("redirectFromId"));
      if (res.status_code === 200) {
        setTemplate(res.data);
      } else {
        toast.error("Something went wrong");
      }
    }
  }

  useEffect(() => {
    if (redirectFrom === "seller") {
      getStoreData();
    } else if (redirectFrom === "organisation") {
      fetchOrganisation();
    }
  }, [redirectFrom]);

  useEffect(() => {
    getUser();
  }, [isFav]);

  const [toggle, setToggle] = useState(1);
  const [prodData, setData] = useState([]);
  const [data, setProductData] = useState({});

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  async function WishlistHandler() {
    if (isFav) {
      removeFromWishlist({ productID: productId }).then((response) => {
        if (response.status_code === 200) {
          setIsFav(false);
          toast.success("Product removed from wishlist");
        } else {
          toast.error(LoginToast);
        }
      });
    } else {
      addToWishlist({ productID: productId }).then((response) => {
        if (response.status_code === 200) {
          setIsFav(true);
          toast.success("Product added to wishlist");
        } else {
          toast.error(LoginToast);
        }
      });
    }
  }
  async function getProductData() {
    getProductById(productId).then((response) => {
      if (response.status_code === 200) {
        setProductData(response.data);
      } else {
        toast.error("Something went wrong");
      }
    });
  }

  async function addToCartHandler() {
    if (localStorage.getItem("macha-user")) {
      console.log("add to cart", productId, toggle);
      addToCart({ productID: productId, quantity: toggle }).then((response) => {
        if (response.status_code === 200) {
          toast.success("Product added to cart");
          setCartDisabled(true);
        } else {
          toast.error("Something went wrong");
        }
      });
    } else {
      toast.error(LoginToast);
    }
  }

  async function getSimilarProductsManager() {
    if (productId) {
      getSimilarProducts(productId).then((data) => {
        if (data.status_code === 200) {
          setData(data.data);
        } else {
          toast.error("Unable to fetch products at the moment");
        }
      });
    }
  }

  async function setUp() {
    await getProductData();
    await getSimilarProductsManager();
  }

  useEffect(() => {
    setUp();
  }, []);

  return (
    <>
      {redirectFrom === "organisation" ? (
        <OrganisationHeader
          organisationId={searchParams.get("redirectFromId")}
          page={""}
          logo={template !== null && template?.organisation_logo}
        />
      ) : redirectFrom === "seller" ? (
        <Header
          storeName={template?.storeName}
          selected={
            template?.template !== null &&
            template?.template?.chosenColor !== null
              ? template?.template?.chosenColor
              : "#730dc9"
          }
          page={""}
          logo={template !== null && template.storeLogo}
          id={
            searchParams.get("redirectFromId") !== undefined &&
            searchParams.get("redirectFromId")
          }
        />
      ) : (
        <MachaHeader refresh={true} />
      )}

      <div className="rounded-md lg:px-20 px-8 productDetails">
        <div className="flex lg:flex-row flex-col items-center py-10  text-lg space-x-2">
          <a href="/">
            {" "}
            <span>MachaPoint</span>
          </a>
          {data?.store !== null && (
            <>
              {data?.store?.organisation && (
                <a
                  href={`/organisation/${data?.store?.organisation?.organisation_name}/${data?.store?.organisation?._id}`}
                >
                  <span>
                    {" "}
                    / {data?.store?.organisation?.organisation_name} /
                  </span>
                </a>
              )}
              <a
                href={`/profile/${data?.store?.storeName.replace(
                  /\s+/g,
                  "-"
                )}/${data?.store?._id}`}
              >
                <span> {data?.store?.storeName} /</span>
              </a>
            </>
          )}
        </div>
        <div className="max-sm:pb-3 grid md:grid-cols-2 md:gap-2 grid-cols-1 gap-0 justify-center  pt-0 ">
          <div className="h-max product-carousal">
            <div className="">
              <Swiper
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "#fff",
                }}
                loop={true}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2"
              >
                {data?.productImageUrls?.length > 0 &&
                  data.productImageUrls.map((image) => {
                    return (
                      <SwiperSlide>
                        <img
                          className="max-h-[400px] aspect-auto mx-auto"
                          alt="sdfdf"
                          src={image?.imgURL}
                        />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
              <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={10}
                slidesPerView={
                  data?.productImageUrls?.length !== undefined
                    ? data?.productImageUrls?.length
                    : 3
                }
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper sm:pt-3 "
                style={{ width: "200px" }}
              >
                {data?.productImageUrls?.length > 0 &&
                  data.productImageUrls.map((image) => {
                    return (
                      <SwiperSlide>
                        <img
                          className="h-[70px] w-[95px]   aspect-auto mx-auto"
                          alt="sdfdf"
                          src={image.imgURL}
                        />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
          <div className=" sm:pl-6 mt-6">
            <div className="text-[35px] font-[700] textcolor flex justify-between items-center">
              <div>{data?.productName}</div>
              <div className="">
                {isFav ? (
                  <BsFillHeartFill
                    className="text-[30px] bg-red "
                    color="red"
                    onClick={() => WishlistHandler()}
                  />
                ) : (
                  <BsHeart
                    className="text-[30px] "
                    onMouseOver={({ target }) => (target.style.color = "red")}
                    onMouseOut={({ target }) => (target.style.color = "black")}
                    onClick={() => WishlistHandler()}
                  />
                )}
              </div>
            </div>

            {/* <div className="text-[18px]  text-grey-200 pt-3">
              {showMore
                ? data?.productDescription?.split("\n").map((c, index) => {
                    return <p key={index}> {c} </p>;
                  })
                : data?.productDescription
                    ?.slice(0, 300)
                    ?.split("\n")
                    .map((c, index) => {
                      return <p key={index}> {c} </p>;
                    })}
              {data?.productDescription?.length > 300 && (
                <button
                  onClick={() => setShowMore(!showMore)}
                  className="text-[18px]  text-white bg-black  ml-2 rounded-md px-1"
                >
                  {showMore ? "Show Less" : "Show More"}
                </button>
              )}
            </div> */}
            <div className="flex items-center">
              <div className="text-[24px] font-[700] pt-3 pb-6 flex items-center pr-3">
                <BiRupee /> {data?.offerPrice}
              </div>
              <div className="text-[24px] font-[700] pt-3 pb-6 flex items-center ltr:pl-2.5 rtl:pr-2.5 -mt-0.5 md:mt-0 relative text-gray-400">
                <span className="w-full bg-black border border-gray-400 absolute "></span>
                <BiRupee /> {data?.productPrice}
              </div>
            </div>
            <div className="">
              <Disclosure as="div" className="py-3 border-t  border-grey-200">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-white px-4 py-2 text-left text-xl font-medium textcolor  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                      <span>Product Details</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                      {data?.productDescription?.split("\n").map((c, index) => {
                        return <p key={index}> {c} </p>;
                      })}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            <div className="flex items-center justify-center py-10 border-y-2 border-grey-200">
              <div className="w-1/2 flex border border-grey-200 rounded-[10px]">
                <button
                  onClick={() => {
                    if (toggle > 1) {
                      setToggle(toggle - 1);
                    }
                  }}
                  className="p-4 w-1/4 text-[20px] hover:bg-black hover:text-white hover:rounded-l-[10px] font-[600]"
                >
                  -
                </button>
                <button className="p-4 w-2/4 border-x-2 border-grey-200 text-[20px] font-[600]">
                  {toggle}
                </button>

                <button
                  onClick={() => {
                    setToggle(toggle + 1);
                  }}
                  className="p-4 w-1/4 hover:bg-black hover:text-white hover:rounded-r-[10px] font-[600]"
                >
                  +
                </button>
              </div>
              <div className="w-1/2 pl-3">
                <button
                  onClick={addToCartHandler}
                  className="backgroundcolor text-white rounded-[10px] w-full p-4 sm:text-[20px] sm:font-[600] sxs:text-sm sxs:font-medium sxs:px-2 sxs-py-3  hover:bg-gray-200 hover:text-black"
                >
                  Add to Cart
                </button>
              </div>
            </div>
            {data?.productSubcategory && (
              <div className="pt-6">
                <div className="flex items-center ">
                  <span className="text-[20px] font-[600] pr-1">
                    Subcategory:
                  </span>
                  <span>{data?.productSubcategory?.subcategoryName}</span>
                </div>
              </div>
            )}
            <div className="pt-6">
              <div className="flex items-center ">
                <span className="text-[20px] font-[600] pr-1">Category:</span>
                <a
                  href={`https://machapoint.com/category/${data?.productCategory?._id}`}
                >
                  <span>{data?.productCategory?.categoryName}</span>
                </a>
              </div>
            </div>
            {/* <div className="pt-6">
            <div className="flex items-center ">
              <span className="text-[20px] font-[600] pr-1">Tags:</span>
              <span>Electronics ,Gadgets</span>
            </div>
          </div> */}
          </div>
        </div>
        <div className="py-6 text-[25px]  md:text-[30px] font-[600] px-6 mt-16 md:mt-0 sxs:mt-16 sxs:text-center sm:text-left">
          Related Products
        </div>
        <div className="grid lg:grid-cols-4 lg:gap-4 md:grid-cols-3 md:gap-2 sm:grid-cols-2 sm:gap-4 grid-cols-1 gap-0 px-6 justify-center">
          {prodData &&
            prodData?.map((data, index) => (
              <a
                href={`/product/${data?.productName?.replace(/\s+/g, "-")}/${
                  data?._id
                }`}
              >
                <SellerProduct item={data} />{" "}
                {/* <div className=" drop-shadow-xl pb-3 ">
                <div className="overflow-hidden">
                  <img
                    className="max-h-80 w-80 hover:scale-110 ease-in-out duration-300	"
                    alt=""
                    src={data?.productImageUrls[0]?.imgURL}
                  />
                </div>
                <div className="py-3 text-heading text-[25px] font-bold">
                  {data?.productName}
                </div>
                <div className="text-heading text-lg md:text-xl lg:text-2xl 2xl:text-3xl xl:leading-10 font-bold">
                  {data?.offerPrice}
                </div>
              </div> */}
              </a>
            ))}
        </div>
      </div>
      {redirectFrom === "organisation" ? (
        <OrganisationFooter
          email={template?.template?.footer?.footerData?.emailData}
          phone={template?.template?.footer?.footerData?.phoneNumberData}
          sellername={template?.organisation_name}
          insta={template?.template?.footer?.socialLinksData?.instagramData}
          facebooklink={
            template?.template?.footer?.socialLinksData?.facebookData
          }
        />
      ) : redirectFrom === "seller" ? (
        <SellerFooter
          email={template?.template?.footer?.footerData?.emailData}
          enabled={template && template.template.footer.footerEnabled}
          phone={template?.template?.footer?.footerData?.phoneNumberData}
          orgname={template?.organisation !== null && "WeTarang"}
          organisation={template?.organisation ? template?.organisation : null}
          sellername={template?.storeName}
          insta={template?.template?.footer?.socialLinksData?.instagramData}
          facebooklink={
            template?.template?.footer?.socialLinksData?.facebookData
          }
        />
      ) : (
        <Footer />
      )}
    </>
  );
};

export default ProductDetails;
