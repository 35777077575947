import React, { Fragment, useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import CreateStoreForm from "./createStoreForm";
import { getStoresByUser } from "../../Helper/store";
import AddStories from "../AddStories";
import MachaHeader from "../../Components/Base/MachaHeader";
import Footer from "../../Components/Base/Footer";
import { AddNew } from "../../Components/OrganisationPanel/AddNew";
import ManageProducts from "./ManageProducts";
import AddStoresToOrganisation from "./AddStoresToOrganisation";
import EditStore from "./EditStore";
import SellerWebsite from "./SellerWebsiteTemplate1";
import UpdateSellerWebsite from "./updateSellerWebsite";
import SellerOrderManagement from "../SellerOrderManagement";
import SellerPanelOrder from "../SellerPanelOrder";
import { getInvoicePage } from "../../Helper/Order";
import { toast } from "react-hot-toast";
import useWindowDimensions from "../../hooks/useWindowDimension";
import DesktopWarning from "../../Components/DesktopWarning";
import { AiOutlineMenuFold } from "react-icons/ai";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import Drawer from "../../Components/Base/Drawer";
import { RxCross2 } from "react-icons/rx";
import { Disclosure, Listbox, Transition } from "@headlessui/react";
import { BiStore } from "react-icons/bi";
import { FaStore } from "react-icons/fa";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import { BsDashLg } from "react-icons/bs";
import { GiSpiderWeb } from "react-icons/gi";
import { MdInventory } from "react-icons/md";
import Pop from "../../Components/Base/Pop";
import { APIURL } from "../../backend";
const Option = ({ chosenMenu, value, option, setChosenMenu, icon }) => {
  async function changeLocation(value) {
    await localStorage.setItem("gotovalue", value);
    window.location.assign("/");
  }

  return (
    <div className="py-3 px-3 ">
      <button
        onClick={() => {
          setChosenMenu ? setChosenMenu(value) : changeLocation(value);
        }}
        className={
          "flex items-center justify-start space-x-1  hover:text-blue-500 " +
          (value === chosenMenu ? "text-blue-600" : "text-gray-600")
        }
      >
        {icon}
        <p>{option}</p>
      </button>
    </div>
  );
};

const MainPage = () => {
  const [chosenMenu, setChosenMenu] = useState("editStore");
  const [stores, setStores] = useState([]);
  const [selected, setSelected] = useState();
  const [orderSelected, setOrderSelected] = useState();
  const { width } = useWindowDimensions();
  let [isOpen, setIsOpen] = useState(false);
  let [isOpenMenu, setIsOpenMenu] = useState(false);

  let [isOpen2, setIsOpen2] = useState(false);

  async function getStores() {
    let id = await JSON.parse(localStorage.getItem("macha-user")).uid;

    let stores = await getStoresByUser(id);

    if (stores.status_code === 200) {
      console.log(stores.data, "stores in main page");
      setStores(stores.data);
      setSelected(stores.data[0]);
    } else {
      console.log("No Stores Found");
    }
  }

  async function handleInvoice(id) {
    await window.open(
      `${APIURL}/order/get-invoice-page/?order_id=${id}`,
      "_blank"
    );
    // let response = await getInvoicePage(id);

    // console.log(response, "response invoice");

    // if (response?.status === 200) {
    //   var win = await window.open(
    //     `${APIURL}/order/get-invoice-page/?order_id=${id}`,
    //     "_blank"
    //   );
    //   win && win.focus();
    // } else {
    //   toast.error("Something went wrong");
    // }
  }

  useEffect(() => {
    getStores();
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <MachaHeader />
        {width > 1154 ? (
          <Layout
            chosenMenu={chosenMenu}
            setChosenMenu={setChosenMenu}
            isOpen={isOpen}
            isOpen2={isOpen2}
            stores={stores}
            selected={selected}
            setSelected={setSelected}
          >
            <div className="py-6">
              {chosenMenu === "addstorestoorganisation" && (
                <AddStoresToOrganisation
                  stores={stores}
                  getStores={getStores}
                />
              )}
              {chosenMenu === "manageproducts" && <ManageProducts />}
              {chosenMenu === "sellerorders" && !orderSelected && (
                <SellerOrderManagement
                  setOrderSelected={setOrderSelected}
                  type="seller"
                  handleInvoice={handleInvoice}
                />
              )}
              {orderSelected && (
                <SellerPanelOrder
                  setOrderSelected={setOrderSelected}
                  orderSelected={orderSelected}
                  handleInvoice={handleInvoice}
                />
              )}

              {chosenMenu === "addproduct" && <AddNew selected={selected} />}
              {chosenMenu === "createweb" && (
                <SellerWebsite selected={selected} />
              )}
              {chosenMenu === "updateweb" && (
                <UpdateSellerWebsite selected={selected} />
              )}

              {chosenMenu === "StoreForm" && <CreateStoreForm />}
              {selected && chosenMenu === "editStore" && (
                <EditStore storeSelected={selected} />
              )}

              {chosenMenu === "Stories" && (
                <AddStories storeSelected={selected} />
              )}
            </div>
          </Layout>
        ) : (
          // <DesktopWarning />\
          <div className="px-3">
            <div className="py-3">
              <AiOutlineMenuUnfold
                className="text-[25px]"
                onClick={() => setIsOpenMenu(!isOpenMenu)}
              />
            </div>
            <div className="py-3">
              {selected && chosenMenu === "editStore" && (
                <EditStore storeSelected={selected} />
              )}
              {chosenMenu === "addstorestoorganisation" && (
                <AddStoresToOrganisation
                  stores={stores}
                  getStores={getStores}
                />
              )}
              {chosenMenu === "manageproducts" && <ManageProducts />}
              {chosenMenu === "sellerorders" && !orderSelected && (
                <SellerOrderManagement
                  setOrderSelected={setOrderSelected}
                  type="seller"
                  handleInvoice={handleInvoice}
                />
              )}
              {orderSelected && (
                <SellerPanelOrder
                  setOrderSelected={setOrderSelected}
                  orderSelected={orderSelected}
                  handleInvoice={handleInvoice}
                />
              )}

              {chosenMenu === "addproduct" && <AddNew selected={selected} />}
              {chosenMenu === "createweb" && (
                <SellerWebsite selected={selected} />
              )}
              {chosenMenu === "updateweb" && (
                <UpdateSellerWebsite selected={selected} />
              )}

              {chosenMenu === "StoreForm" && <CreateStoreForm />}

              {chosenMenu === "Stories" && (
                <AddStories storeSelected={selected} />
              )}
            </div>
            <Drawer
              isOpen={isOpenMenu}
              setIsOpen={setIsOpenMenu}
              lg={true}
              clip={"full"}
              direction="left"
            >
              <div className="absolute right-0 px-3">
                <RxCross2 size={25} onClick={() => setIsOpenMenu(false)} />
              </div>
              <div className=" pb-3 px-3">
                <div className="font-[700] text-xl ">Select Store</div>
                <Listbox
                  value={selected}
                  onChange={setSelected}
                  style={{ zIndex: 10000 }}
                >
                  <div className="relative mt-1 ">
                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                      <span className="block truncate">
                        {selected?.storeName}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"></span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {stores &&
                          stores.map((person, personIdx) => (
                            <Listbox.Option
                              key={personIdx}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                  active
                                    ? "bg-amber-100 text-amber-900"
                                    : "text-gray-900"
                                }`
                              }
                              value={person}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selected ? "font-medium" : "font-normal"
                                    }`}
                                  >
                                    <div className="flex items-center">
                                      <BiStore /> {person.storeName}
                                    </div>
                                  </span>
                                  {selected ? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"></span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={
                        "py-2 px-3 w-full text-left flex justify-between items-center "
                      }
                    >
                      <div className="flex items-center">
                        <FaStore className="mr-1 text-2xl" />
                        <span>My Store</span>
                      </div>
                      {open ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                    </Disclosure.Button>
                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel static className="text-gray-500">
                        <Option
                          chosenMenu={chosenMenu}
                          icon={<BsDashLg className="text-xs ml-4" />}
                          setChosenMenu={setChosenMenu}
                          value="editStore"
                          option={"Edit Store"}
                        />
                        <Option
                          chosenMenu={chosenMenu}
                          icon={<BsDashLg className="text-xs ml-4" />}
                          setChosenMenu={setChosenMenu}
                          value="Stories"
                          option={"Cards"}
                        />
                        <Option
                          chosenMenu={chosenMenu}
                          icon={<BsDashLg className="text-xs ml-4" />}
                          setChosenMenu={setChosenMenu}
                          value="addstorestoorganisation"
                          option={"Join Organisation"}
                        />

                        <Option
                          chosenMenu={chosenMenu}
                          icon={<BsDashLg className="text-xs ml-4" />}
                          setChosenMenu={setChosenMenu}
                          value="StoreForm"
                          option={"Create New Store"}
                        />
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
              {!selected?.template ? (
                <Option
                  chosenMenu={chosenMenu}
                  icon={<GiSpiderWeb className="text-2xl" />}
                  setChosenMenu={setChosenMenu}
                  value="createweb"
                  option={"Website Builder"}
                />
              ) : (
                <Option
                  chosenMenu={chosenMenu}
                  icon={<GiSpiderWeb className="text-2xl" />}
                  setChosenMenu={setChosenMenu}
                  value="updateweb"
                  option={"Update Website "}
                />
              )}

              {/*Change bottom value to updateweb to make it work */}
              <Option
                chosenMenu={chosenMenu}
                icon={<GiSpiderWeb className="text-2xl" />}
                setChosenMenu={setChosenMenu}
                value="sellerorders"
                option={"Order Management"}
              />

              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={
                        "py-2 px-3 w-full text-left flex justify-between items-center "
                      }
                    >
                      <div className="flex items-center">
                        <MdInventory className="mr-1 text-2xl" />
                        <span> Product Management</span>
                      </div>
                      {open ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                    </Disclosure.Button>
                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel static className="text-gray-500">
                        <Option
                          chosenMenu={chosenMenu}
                          icon={<BsDashLg className="text-xs ml-4" />}
                          setChosenMenu={setChosenMenu}
                          value="addproduct"
                          option={"Add Product"}
                        />
                        <Option
                          chosenMenu={chosenMenu}
                          icon={<BsDashLg className="text-xs ml-4" />}
                          setChosenMenu={setChosenMenu}
                          value="manageproducts"
                          option={"Manage Products"}
                        />
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            </Drawer>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default MainPage;
