import React, { useState, Fragment, useEffect } from "react";
import logo from "../../images/logo.png";
import { Transition, Menu } from "@headlessui/react";
import { FaShoppingCart } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { isAuthenticated, signout } from "../../Helper/Auth";
import { async } from "@firebase/util";
import { AiOutlineHome, AiOutlineSearch, AiOutlineShop } from "react-icons/ai";
import { Navigate, useNavigate } from "react-router-dom";
import { BsFileEarmarkImageFill } from "react-icons/bs";
import { RxCross2, RxHamburgerMenu } from "react-icons/rx";
import Drawer from "./Drawer";
import { getAllCategories } from "../../Helper/product";
import CartProduct from "../Cart/CartProduct";
import {
  getMyCart,
  removeFromCart,
  updateCartProductQuantity,
} from "../../Helper/Order";
import { toast } from "react-hot-toast";
import { getUser } from "../../Helper/story";

const MachaHeader = ({ refresh = false }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [cartOpen, setCartOpen] = React.useState(false);
  const [cartData, setCartData] = useState([]);

  const [categories, setCategories] = useState([]);

  async function getCategories() {
    await getAllCategories().then((data, err) => {
      if (data?.status_code === 200) {
        setCategories(data.data);
      } else {
        toast.error("error fetching categories");
      }
    });
  }

  async function updateQuantity(item, action) {
    let body = {
      productID: item.product_id._id,
      quantity: 1,
      action: action,
    };

    let response = await updateCartProductQuantity(body);

    if (response?.status_code === 200) {
      toast.success("Cart Updated");
      getMyCartData();
    } else {
      toast.error(response?.message);
    }

    console.log(response, "response update cart");
  }

  async function getMyCartData() {
    let user = await isAuthenticated();

    if (user.token) {
      const response = await getMyCart();

      if (response?.status_code === 200) {
        // console.log(response.data, "response carts");

        setCartData(response.data);
      } else {
        toast.error("error fetching cart data");
      }
    }
  }

  async function removeFromCartHandler(id) {
    const response = await removeFromCart({ productID: id });

    if (response.status_code === 200) {
      toast.success("Product removed from cart");
      getMyCartData();
    } else {
      toast.error("Something went wrong");
    }
  }

  async function getUserData() {
    if (localStorage.getItem("macha-user")) {
      let userRes = await getUser();

      if (userRes?.status_code === 401) {
        localStorage.removeItem("macha-user");
        window.location.reload(false);
      }
    }
  }

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    getMyCartData();
  }, [cartOpen]);

  const navigator = useNavigate();

  const toCheckout = () => {
    navigator("/checkout", { state: cartData });
  };

  async function handleLogout() {
    await isAuthenticated().then((data) => {
      setIsAuth(data);
    });
  }

  async function handleSignout() {
    await signout().then((data, err) => {
      setIsAuth(false);
      navigator("/");
    });
  }

  useEffect(() => {
    handleLogout();
  }, []);

  async function setUp() {
    await getCategories();
  }

  useEffect(() => {
    setUp();
  }, []);

  return (
    <div className="w-full h-full shadow-lg flex flex-col ">
      <div className="flex items-center justify-between lg:py-6 sm:py-4 py-4  lg:px-10 sm:px-6 px-3 xl:px-20">
        <div className=" flex items-center">
          <a href="/">
            <div className="  lg:h-8   h-5 ">
              <img src={logo} alt="" className=" h-full " />
            </div>
          </a>
        </div>
        {/* <div className=" flex items-center justify-center ">
          <div className="xl:w-[32rem] lg:w-[24rem] sm:w-[20rem]  flex items-center max-sm:hidden relative">
            <input
              className="w-full  drop-shadow-sm	border-b focus:border-b outline-none focus:border-[] border-[#3D3C3C] rounded-sm"
              alt=""
            />
            <div className="absolute right-0">
              <AiOutlineSearch className="text-[20px]" />
            </div>
          </div>
        </div> */}
        <div className="flex items-center justify-between space-x-6 max-lg:hidden">
          <div className="max-sm:hidden ">
            <a href="/">
              <button
                className={
                  window.location.pathname.toString() === "/"
                    ? `w-full text-center   text-[#454598] font-[700]`
                    : `w-full text-center  hover:text-[#454598]`
                }
              >
                Discover
              </button>
            </a>
          </div>
          <div className="max-sm:hidden">
            <a href="/explore">
              <button
                className={
                  window.location.pathname.toString() === "/explore"
                    ? `w-full text-center   text-[#454598] font-[700]`
                    : `w-full text-center hover:text-[#454598] `
                }
              >
                Products
              </button>
            </a>
          </div>
          {/* <div className="max-sm:hidden">
            <a href="/shop">
              <button
                className={
                  window.location.pathname.toString() === "/shop"
                    ? `w-full text-center   text-[#454598] font-[700]`
                    : `w-full text-center hover:text-[#454598] `
                }
              >
                Shop
              </button>
            </a>
          </div> */}
          <div className="max-sm:hidden">
            <a href="/about">
              <button
                className={
                  window.location.pathname.toString() === "/homeaboutpage"
                    ? `w-full text-center   text-[#454598] font-[700]   `
                    : `w-full text-center hover:text-[#454598] `
                }
              >
                {" "}
                About
              </button>
            </a>
          </div>
          <div className=" flex items-center max-sm:justify-between  space-x-6">
            {isAuth === false ? (
              <div className="  text-right">
                <a href="/login">
                  <button className="w-full text-center">SignIn</button>
                </a>
              </div>
            ) : (
              cartOpen === false && (
                <div className="  text-right">
                  <Menu
                    style={{ zIndex: 10000 }}
                    as="div"
                    className="relative inline-block text-left"
                  >
                    <div>
                      <Menu.Button className="inline-flex w-full justify-center rounded-md  px-4 py-2 text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                        <FaUserAlt
                          size={20}
                          onMouseOver={({ target }) =>
                            (target.style.color = "#454598")
                          }
                          onMouseOut={({ target }) =>
                            (target.style.color = "black")
                          }
                          className="text-red hover:text-[#454598]"
                          style={{ color: "#3D3C3C" }}
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="/user-panel"
                                className={`${
                                  active
                                    ? "text-white hover:bg-[#454598]"
                                    : "text-gray-900"
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              >
                                My Profile
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                        <div className="px-1 py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={handleSignout}
                                className={`${
                                  active
                                    ? "hover:bg-[#454598] text-white"
                                    : "text-gray-900"
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              >
                                Logout
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              )
            )}

            <div className="">
              {cartData.length > 0 && (
                <div class=" absolute top-5 text-center font-semibold ml-2 flex items-center justify-center text-xs h-4 w-4 rounded-full border-black border">
                  {cartData.length}
                </div>
              )}

              <FaShoppingCart
                size={20}
                style={{ color: "#3D3C3C" }}
                onMouseOver={({ target }) => (target.style.color = "#454598")}
                onMouseOut={({ target }) => (target.style.color = "black")}
                onClick={() => setCartOpen(true)}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-6 lg:hidden ">
          {localStorage.getItem("macha-user") && (
            <div>
              <a href="/user-panel">
                <FaUserAlt
                  size={20}
                  style={{ color: "#3D3C3C" }}
                  onMouseOver={({ target }) => (target.style.color = "#454598")}
                  onMouseOut={({ target }) => (target.style.color = "black")}
                />{" "}
              </a>
            </div>
          )}
          <div>
            <FaShoppingCart
              size={20}
              style={{ color: "#3D3C3C" }}
              onMouseOver={({ target }) => (target.style.color = "#454598")}
              onMouseOut={({ target }) => (target.style.color = "black")}
              onClick={() => setCartOpen(true)}
            />
          </div>
          <button
            className="bg-blue-600 block lg:hidden text-white rounded px-4 py-1"
            onClick={() => setIsOpen(true)}
          >
            <RxHamburgerMenu />
          </button>
        </div>
        <Drawer isOpen={cartOpen} setIsOpen={setCartOpen} lg={true}>
          <div className=" h-full  overflow-hidden noscrollbar">
            <div className="flex items-center justify-between mx-6">
              <div className="font-bold text-xl md:text-2xl m-0 text-heading">
                Shopping Cart
              </div>
              <RxCross2
                size={20}
                className=""
                onClick={() => setCartOpen(false)}
              />
            </div>
            {cartData.length === 0 && (
              <div className="text-[20px] font-sans px-6 pt-6">
                No Products Added to Cart
              </div>
            )}
            <div className="pt-6 overflow-y-scroll noscrollbar h-[562px]">
              {cartData &&
                cartData.length > 0 &&
                cartData.map((item, index) => {
                  return (
                    <CartProduct
                      update={true}
                      updateQuantity={updateQuantity}
                      item={item}
                      IN={index}
                      cartData={cartData}
                      setCartData={setCartData}
                      removeFromCartHandler={removeFromCartHandler}
                    />
                  );
                })}
            </div>
          </div>
          <div className="py-8 w-full relative   botton-0 px-6  ">
            {cartData.length > 0 && (
              <button
                className=" bg-black text-white hover:bg-gray-600 w-full py-4 rounded-lg"
                onClick={() => {
                  toCheckout();
                }}
              >
                <div>Proceed to Checkout</div>
              </button>
            )}
          </div>
        </Drawer>
        <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
          <div className="flex  flex-col justify-between h-full relative">
            <div className="flex flex-col divide-y space-y-2 ">
              <a href="/" className="px-3 py-1 text-blue-600">
                Discover
              </a>
              <a href="/explore" className="px-3 py-1 text-blue-600">
                Products
              </a>
              <a href="/about" className="px-3 py-1 text-blue-600">
                About
              </a>

              {categories?.length > 0 &&
                categories.map((category, index) => {
                  return (
                    <a href={`/category/${category._id}`} className="px-3 py-1">
                      {category.categoryName}
                    </a>
                  );
                })}
            </div>
            {isAuth === false && (
              <a href="/login">
                <div className=" py-2 text-white bg-blue-600 text-center relative">
                  Signin
                </div>
              </a>
            )}
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default MachaHeader;
