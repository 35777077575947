import React, { useState, useEffect } from "react";
import FirstInfoSection from "../Components/SellerPage/FirstInfoSection";
import FirstRightInfoSection from "../Components/SellerPage/FirstRightInfoSection";
import Header from "../Components/SellerPage/Header";
import KeyPoints from "../Components/SellerPage/KeyPoints";
import SecondLineInfoSection from "../Components/SellerPage/SecondLineInfoSection";
import SlideShow from "../Components/SellerPage/SlideShow";
import Testimonials from "../Components/SellerPage/Testimonials";
import { Helmet } from "react-helmet";
import Footer from "../Components/SellerPage/Footer";
import StickyIcons from "../Components/SellerPage/StickyIcons";
import smallicon from "../images/smallicon.png";
import tarang from "../images/tarang.png";
import { getStore } from "../Helper/store";
import { useParams } from "react-router-dom";

const SellerPage = () => {
  const [template, setTemplate] = useState(null);

  const { id } = useParams();

  async function getStoreData() {
    if (id !== undefined) {
      await getStore(id)
        .then((data, err) => {
          if (data.status_code === 200) {
            console.log(`data`, data);
            setTemplate(data.data);
          } else {
            console.log(err);
          }
        })
        .catch((err) => console.log(err));
    }
  }

  useEffect(() => {
    getStoreData();
  }, []);

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>{template?.storeName}</title>
        <meta name="description" content={template?.storeDescription} />
      </Helmet>
      <Header
        storeName={template?.storeName}
        selected={
          template?.template !== null &&
          template?.template?.chosenColor !== null
            ? template?.template?.chosenColor
            : "#730dc9"
        }
        page={"home"}
        logo={template !== null && template.storeLogo}
        id={id !== undefined && id}
        template={template?.template}
      />

      <StickyIcons
        organisation={template?.organisation ? template?.organisation : null}
        icon={smallicon}
        orgicon={tarang}
      />
      {template &&
        template.template.homepage.sliderImages.length > 0 &&
        template.template.homepage.sliderEnabled && (
          <SlideShow
            images={template && template.template.homepage.sliderImages}
          />
        )}
      {template && template.template.homepage.section1Enabled && (
        <FirstInfoSection
          image={
            template &&
            template?.template?.homepage?.section1Data?.sectionImage?.imgURL
          }
          heading={
            template &&
            template?.template?.homepage?.section1Data?.sectionHeading?.text
          }
          subheading={
            template &&
            template?.template?.homepage?.section1Data?.sectionDescription
          }
        />
      )}
      {template && template.template.homepage.section2Enabled && (
        <FirstRightInfoSection
          image={
            template &&
            template?.template?.homepage?.section2Data?.sectionImage?.imgURL
          }
          heading={
            template &&
            template?.template?.homepage?.section2Data?.sectionHeading?.text
          }
          subheading={
            template &&
            template?.template?.homepage?.section2Data?.sectionDescription
          }
        />
      )}
      {template && template.template.homepage.headingEnabled && (
        <SecondLineInfoSection
          selected={
            template?.template !== null &&
            template?.template?.chosenColor !== null
              ? template.template?.chosenColor
              : "#730dc9"
          }
          infoLine={template && template.template.homepage.headingData}
        />
      )}
      {template && template.template.homepage.keyMetricsEnabled && (
        <KeyPoints labelValueData={template?.template?.homepage?.keyMetrics} />
      )}
      {template && template.template.homepage.testimonialsEnabled && (
        <Testimonials thoughts={template?.template?.homepage?.testimonials} />
      )}

      <Footer
        email={template?.template?.footer?.footerData?.emailData}
        enabled={template && template.template.footer.footerEnabled}
        phone={template?.template?.footer?.footerData?.phoneNumberData}
        orgname={template?.organisation !== null && "WeTarang"}
        organisation={template?.organisation ? template?.organisation : null}
        sellername={template?.storeName}
        insta={template?.template?.footer?.socialLinksData?.instagramData}
        facebooklink={template?.template?.footer?.socialLinksData?.facebookData}
      />
    </div>
  );
};

export default SellerPage;
