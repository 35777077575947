const { APIURL } = require("../backend");

export const addForm = (form) => {
  return fetch(`${APIURL}/organisation/create-organisation`, {
    method: "POST",
    body: form,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getMyOrganisation = () => {
  return fetch(`${APIURL}/organisation/get-my-organisation`, {
    method: "GET",
    headers: {
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

//create-organisation-website
export const initiateOrganisationRequest = (data) => {
  return fetch(`${APIURL}/organisation/upload-image-organisation-create`, {
    method: "POST",
    headers: {
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: data,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const createOrganisationTemplate = (data) => {
  return fetch(`${APIURL}/organisation/create-organisation-website`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getOrganisation = (organisation_id) => {
  return fetch(
    `${APIURL}/organisation/get-organisation-website/?organisation_id=${organisation_id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: ` ${
          JSON.parse(localStorage.getItem("macha-user"))?.token
        }`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getAllOrgRequests = () => {
  return fetch(`${APIURL}/organisation/get-organisation-requests-by-user`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllOrganisations = () => {
  return fetch(`${APIURL}/organisation/get-all`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const createOrganisationJoinRequest = (data) => {
  return fetch(`${APIURL}/organisation/create-organisation-join-request`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const changeOraganisationStatus = (data) => {
  return fetch(`${APIURL}/organisation/change-status`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getOrgRequestByUser = () => {
  return fetch(`${APIURL}/organisation/get-organisation-requests-by-user`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getOrganisationJoinRequestsForOrganisation = async () => {
  return fetch(`${APIURL}/organisation/get-organisation-requests-to-me`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${JSON.parse(localStorage.getItem("macha-user"))?.token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const resolveStoreJoinRequest = (data) => {
  console.log(data);
  return fetch(`${APIURL}/organisation/resolve-organisation-join-request`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${JSON.parse(localStorage.getItem("macha-user"))?.token}`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateOrganisationWebsiteImages = (data) => {
  return fetch(`${APIURL}/organisation/update-organisation-website-images`, {
    method: "POST",
    headers: {
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: data,
  })
    .then((response) => {
      console.log(response.json);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const updateOrganisationWebsite = (data) => {
  return fetch(`${APIURL}/organisation/update-organisation-website`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",

      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      console.log(response.json);
      return response.json();
    })
    .catch((err) => console.log(err));
};
