import React, { useEffect, useState } from "react";
import Header from "../Components/Header";

import ManageOrganisation from "../Components/OrganisationPanel/ManageOrganisation";
import ManageSellers from "../Components/OrganisationPanel/ManageSellers";
import MachaPointMembers from "../Components/OrganisationPanel/MachaPointMembers";
import Users from "../Components/OrganisationPanel/Users";
import {
  Orders,
  CendingOrders,
  CancelledOrders,
  ReturnedOrders,
} from "../Components/OrganisationPanel/Orders";
import PendingPayouts from "../Components/OrganisationPanel/PendingPayouts";
import CompletedPayouts from "../Components/OrganisationPanel/CompletedPayouts";
import Dashboard from "../Components/OrganisationPanel/Dashboard";
import SupportTickets from "../Components/OrganisationPanel/SupportTickets";
import Layout from "../Components/OrganisationLayout";
import { Categories } from "../Components/OrganisationPanel/Categories";
import { SubCategories } from "../Components/OrganisationPanel/Subcategories";

import { SimpleProducts } from "../Components/OrganisationPanel/SimpleProducts";
import { AddNew } from "../Components/OrganisationPanel/AddNew";
import MachaHeader from "../Components/Base/MachaHeader";
import Footer from "../Components/Base/Footer";
import ManageStores from "../Components/OrganisationPanel/ManageStores";
import StoresStory from "../Components/OrganisationPanel/StoresStory";
import OgranisationWebsiteBuilder from "../Components/OrganisationWebsiteBuilder/OgranisationWebsiteBuilder";
import StoreRequests from "../Components/OrganisationPanel/StoreRequests";
import UpdateOrganisationWebsite from "../Components/OrganisationWebsiteBuilder/UpdateOrganisationWebsite";
import { getMyOrganisation } from "../Helper/organisation";
import toast from "react-hot-toast";
import { isAuthenticated } from "../Helper/Auth";
import SellerOrderManagement from "./SellerOrderManagement";
import SellerPanelOrder from "./SellerPanelOrder";
import useWindowDimensions from "../hooks/useWindowDimension";
import { AiOutlineMenuFold } from "react-icons/ai";
import Drawer from "../Components/Base/Drawer";
import { GiSpiderWeb } from "react-icons/gi";
import { GoDashboard } from "react-icons/go";
import { Disclosure, Transition } from "@headlessui/react";
import { BiStore } from "react-icons/bi";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import { BsDashLg } from "react-icons/bs";
import { MdManageAccounts } from "react-icons/md";

const MainPage = () => {
  const [chosenMenu, setChosenMenu] = useState("dashboard");
  const [organisationData, setOrganisationData] = useState(null);
  const [orderSelected, setOrderSelected] = useState();
  let [isOpenMenu, setIsOpenMenu] = useState(false);

  async function getOrganisation() {
    const res = await getMyOrganisation();
    if (res.status_code === 200) {
      setOrganisationData(res.data);
    } else {
      toast.error("Invalid login.Please try again");
      await localStorage.removeItem("macha-user");
      setTimeout(() => {
        window.location.href = "/login";
      }, 2000);
    }
  }

  const Option = ({ chosenMenu, value, option, setChosenMenu, icon }) => {
    async function changeLocation(value) {
      await localStorage.setItem("gotovalue", value);
      window.location.assign("/");
    }

    return (
      <div className="py-3 px-3 ">
        <button
          onClick={() => {
            setChosenMenu ? setChosenMenu(value) : changeLocation(value);
          }}
          className={
            "flex items-center justify-start space-x-1  hover:text-blue-500 " +
            (value === chosenMenu ? "text-blue-600" : "text-gray-600")
          }
        >
          {icon}
          <p>{option}</p>
        </button>
      </div>
    );
  };
  async function loadUp() {
    let authenticated = await isAuthenticated();
    if (authenticated) {
      await getOrganisation();
    } else {
      toast.error("Invalid login.Please try again");
      await localStorage.removeItem("macha-user");
      setTimeout(() => {
        window.location.href = "/login";
      }, 2000);
    }
  }

  useEffect(() => {
    loadUp();
  }, []);
  const { width } = useWindowDimensions();

  console.log(organisationData);

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <MachaHeader />
        {width > 1154 ? (
          organisationData !== null && (
            <Layout chosenMenu={chosenMenu} setChosenMenu={setChosenMenu}>
              {/* {chosenMenu === "manageSellers" && <ManageSellers />} */}
              {chosenMenu === "dashboard" && <Dashboard />}
              {chosenMenu === "storesrequest" && <StoreRequests />}
              {/* {chosenMenu === "orders" && <Orders ordertype={chosenMenu} />}
    {chosenMenu === "pendingorders" && <Orders ordertype={chosenMenu} />}
    {chosenMenu === "cancelledorders" && (
      <CancelledOrders ordertype={chosenMenu} />
    )}
    {chosenMenu === "returnedorders" && (
      <ReturnedOrders ordertype={chosenMenu} />
    )}
    {chosenMenu === "pendingpayouts" && <PendingPayouts />}
    {chosenMenu === "storesstory" && <StoresStory />}
    {chosenMenu === "subcategories" && <SubCategories />} */}
              {chosenMenu === "managestores" && <ManageStores />}
              {chosenMenu === "createweb" && <OgranisationWebsiteBuilder />}
              {chosenMenu === "updateweb" && (
                <UpdateOrganisationWebsite selected={organisationData} />
              )}
              {chosenMenu === "sellerorders" && !orderSelected && (
                <SellerOrderManagement
                  setOrderSelected={setOrderSelected}
                  type="organisation"
                />
              )}
              {orderSelected && (
                <SellerPanelOrder
                  setOrderSelected={setOrderSelected}
                  orderSelected={orderSelected}
                />
              )}
              {/* {chosenMenu === "addproducts" && <AddNew />}
    {chosenMenu === "supporttickets" && <SupportTickets />}
    {chosenMenu === "rolesandpermissions" && <CompletedPayouts />} */}
            </Layout>
          )
        ) : (
          <div>
            <div className="px-3">
              <div className="py-3">
                <AiOutlineMenuFold
                  className="text-[25px]"
                  onClick={() => setIsOpenMenu(!isOpenMenu)}
                />
              </div>
              {chosenMenu === "dashboard" && <Dashboard />}
              {chosenMenu === "storesrequest" && <StoreRequests />}
              {chosenMenu === "managestores" && <ManageStores />}
              {chosenMenu === "createweb" && <OgranisationWebsiteBuilder />}
              {chosenMenu === "updateweb" && (
                <UpdateOrganisationWebsite selected={organisationData} />
              )}
              {chosenMenu === "sellerorders" && !orderSelected && (
                <SellerOrderManagement
                  setOrderSelected={setOrderSelected}
                  type="organisation"
                />
              )}
              {orderSelected && (
                <SellerPanelOrder
                  setOrderSelected={setOrderSelected}
                  orderSelected={orderSelected}
                />
              )}
            </div>
            <Drawer
              isOpen={isOpenMenu}
              setIsOpen={setIsOpenMenu}
              lg={true}
              clip={"full"}
              direction="left"
            >
              <div className=" h-full bg-white flex flex-col overflow-y-auto">
                <Option
                  chosenMenu={chosenMenu}
                  icon={<GoDashboard className="text-2xl" />}
                  setChosenMenu={setChosenMenu}
                  value="dashboard"
                  option={"Dashboard"}
                />

                <Option
                  chosenMenu={chosenMenu}
                  icon={<GiSpiderWeb className="text-2xl" />}
                  setChosenMenu={setChosenMenu}
                  value="createweb"
                  option={"Website Builder"}
                />
                <Option
                  chosenMenu={chosenMenu}
                  icon={<GiSpiderWeb className="text-2xl" />}
                  setChosenMenu={setChosenMenu}
                  value="updateweb"
                  option={"Update Website"}
                />

                <label className="ml-3 mt-3 mb-3 font-bold font-mono">
                  STORES & PRODUCTS
                </label>
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className={
                          "py-2 px-3 w-full text-left flex justify-between items-center " +
                          (open && "bg-purple-100")
                        }
                      >
                        <div className="flex items-center">
                          <BiStore className="mr-1 text-2xl" />
                          <span>Stores</span>
                        </div>
                        {open ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                      </Disclosure.Button>
                      <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Disclosure.Panel static className="text-gray-500">
                          <Option
                            chosenMenu={chosenMenu}
                            icon={<BsDashLg className="text-xs ml-4" />}
                            setChosenMenu={setChosenMenu}
                            value="managestores"
                            option={"Stores"}
                          />
                          <Option
                            chosenMenu={chosenMenu}
                            icon={<BsDashLg className="text-xs ml-4" />}
                            setChosenMenu={setChosenMenu}
                            value="storesrequest"
                            option={"Store Requests"}
                          />

                          <Option
                            chosenMenu={chosenMenu}
                            icon={<BsDashLg className="text-xs ml-4" />}
                            setChosenMenu={setChosenMenu}
                            value="storesstory"
                            option={"Stores Story"}
                          />
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>

                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className={
                          "py-2 px-3 w-full text-left flex justify-between items-center " +
                          (open && "bg-purple-100")
                        }
                      >
                        <div className="flex items-center">
                          <MdManageAccounts className="mr-1 text-2xl" />
                          <span>Products Management</span>
                        </div>
                        {open ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                      </Disclosure.Button>
                      <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Disclosure.Panel static className="text-gray-500">
                          <Option
                            chosenMenu={chosenMenu}
                            icon={<BsDashLg className="text-xs ml-4" />}
                            setChosenMenu={setChosenMenu}
                            value="simpleproducts"
                            option={"Products"}
                          />
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
                <label className="ml-3 mt-3 mb-3 font-bold font-mono">
                  Orders
                </label>
                <Option
                  chosenMenu={chosenMenu}
                  icon={<GiSpiderWeb className="text-2xl" />}
                  setChosenMenu={setChosenMenu}
                  value="sellerorders"
                  option={"Order Management"}
                />
              </div>
            </Drawer>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default MainPage;
