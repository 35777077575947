import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { resendVerificationOTPEmail, VerifyLogin } from "../../Helper/Auth";

const OtpComp = ({ email, user_id, setSuccess, type = "register" }) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [disableResend, setDisableResend] = useState(false);

  async function handleChange(e, index) {
    e.preventDefault();
    let number = parseInt(e.target.value);
    if (typeof number === "number") {
      let newotp = await [...otp];
      newotp[index] = await number;
      setOtp(newotp);
    } else {
      let newotp = otp;
      newotp[index] = "";
      setOtp(newotp);
    }
  }

  async function verifyOTP() {
    if (otp) {
      console.log(otp);
      for (let i = 0; i < 6; i++) {
        if (typeof parseInt(otp[i]) !== "number") {
          return;
        }
      }
      let answer = otp.join("");
      console.log(answer);
      const res = await VerifyLogin(user_id, answer);
      if (res.status_code === 200) {
        toast.success("Account verified successfully.Please login now");
        if (type === "signin") {
          window.location.reload(false);
        } else {
          setSuccess(true);
        }
      } else {
        toast.error("Invalid OTP");
      }
    }
  }
  useEffect(() => {
    verifyOTP();
  }, [JSON.stringify(otp)]);

  async function resendOTP() {
    setDisableResend(true);
    const res = await resendVerificationOTPEmail(user_id);
    if (res.status_code === 200) {
      toast.success("OTP has been resent successfully");
    } else {
      toast.error("Failed to resend OTP,try again");
    }
    setTimeout(() => {
      setDisableResend(false);
    }, 10000);
  }

  return (
    <div className="container mx-auto">
      <Toaster />
      <div className="max-w-sm mx-auto md:max-w-lg">
        <div className="w-full">
          <div className="bg-white h-64 py-3 rounded text-center">
            <h1 className="text-2xl font-bold">OTP Verification</h1>
            <div className="text-xs flex flex-col mt-4">
              <span>Enter the OTP you received at</span>
              <span className="font-bold text-xs">{email}</span>
            </div>

            <div
              id="otp"
              className="flex flex-row justify-center text-center px-2 mt-5"
            >
              <input
                onChange={(e) => handleChange(e, 0)}
                className="m-2 border h-10 w-10 text-center form-control rounded"
                type="text"
                id="first"
                maxlength="1"
              />
              <input
                onChange={(e) => handleChange(e, 1)}
                className="m-2 border h-10 w-10 text-center form-control rounded"
                type="text"
                id="second"
                maxlength="1"
              />
              <input
                onChange={(e) => handleChange(e, 2)}
                className="m-2 border h-10 w-10 text-center form-control rounded"
                type="text"
                id="third"
                maxlength="1"
              />
              <input
                onChange={(e) => handleChange(e, 3)}
                className="m-2 border h-10 w-10 text-center form-control rounded"
                type="text"
                id="fourth"
                maxlength="1"
              />
              <input
                onChange={(e) => handleChange(e, 4)}
                className="m-2 border h-10 w-10 text-center form-control rounded"
                type="text"
                id="fifth"
                maxlength="1"
              />
              <input
                onChange={(e) => handleChange(e, 5)}
                className="m-2 border h-10 w-10 text-center form-control rounded"
                type="text"
                id="sixth"
                maxlength="1"
              />
            </div>

            <div className="flex space-x-4 justify-center text-center mt-5">
              <button
                disabled={disableResend}
                onClick={resendOTP}
                className="flex disabled:cursor-disabled disabled:opacity-0.5 items-center text-blue-700 hover:text-blue-900 cursor-pointer"
              >
                <span className="font-bold">Resend OTP</span>
                <i className="bx bx-caret-right ml-1"></i>
              </button>
              <button
                onClick={() => verifyOTP()}
                className="flex disabled:cursor-disabled disabled:opacity-0.5 items-center text-blue-700 hover:text-blue-900 cursor-pointer"
              >
                <span className="font-bold">Submit</span>
                <i className="bx bx-caret-right ml-1"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpComp;
