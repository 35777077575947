import React, { useEffect, Fragment } from "react";
import { Disclosure, Transition, Listbox } from "@headlessui/react";
import { BsDashLg } from "react-icons/bs";
import { GiSpiderWeb } from "react-icons/gi";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import { BiStore } from "react-icons/bi";
import { MdInventory } from "react-icons/md";
import { FaStore } from "react-icons/fa";

const Option = ({ chosenMenu, value, option, setChosenMenu, icon }) => {
  async function changeLocation(value) {
    await localStorage.setItem("gotovalue", value);
    window.location.assign("/");
  }

  return (
    <div className="py-3 px-3 ">
      <button
        onClick={() => {
          setChosenMenu ? setChosenMenu(value) : changeLocation(value);
        }}
        className={
          "flex items-center justify-start space-x-1  hover:text-blue-500 " +
          (value === chosenMenu ? "text-blue-600" : "text-gray-600")
        }
      >
        {icon}
        <p>{option}</p>
      </button>
    </div>
  );
};

const Layout = ({
  children,
  chosenMenu,
  setChosenMenu,
  isOpen,
  isOpen2,
  stores,
  setSelected,
  selected,
}) => {
  useEffect(() => {
    let gotovalue = localStorage.getItem("gotovalue");
    if (gotovalue) {
      setChosenMenu(gotovalue);
      localStorage.removeItem("gotovalue");
    }
  }, []);

  return (
    <div className="flex flex-col h-screen ">
      <div className="w-full h-full grow bg-gray-100 flex divide-x-2 divide-gray-300 overflow-y-hidden">
        <div
          style={{ zIndex: 15000 }}
          className={
            isOpen || isOpen2
              ? "w-2/12 bg-black bg-opacity-20  flex flex-col"
              : "w-2/12 bg-white flex flex-col"
          }
        >
          <div className=" pb-3 px-3">
            <div className="font-[700] text-xl ">Select Store</div>
            <Listbox
              value={selected}
              onChange={setSelected}
              style={{ zIndex: 10000 }}
            >
              <div className="relative mt-1 ">
                <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                  <span className="block truncate">{selected?.storeName}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"></span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {stores &&
                      stores.map((person, personIdx) => (
                        <Listbox.Option
                          key={personIdx}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active
                                ? "bg-amber-100 text-amber-900"
                                : "text-gray-900"
                            }`
                          }
                          value={person}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                <div className="flex items-center">
                                  <BiStore /> {person.storeName}
                                </div>
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"></span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>

          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button
                  className={
                    "py-2 px-3 w-full text-left flex justify-between items-center "
                  }
                >
                  <div className="flex items-center">
                    <FaStore className="mr-1 text-2xl" />
                    <span>My Store</span>
                  </div>
                  {open ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel static className="text-gray-500">
                    <Option
                      chosenMenu={chosenMenu}
                      icon={<BsDashLg className="text-xs ml-4" />}
                      setChosenMenu={setChosenMenu}
                      value="editStore"
                      option={"Edit Store"}
                    />
                    <Option
                      chosenMenu={chosenMenu}
                      icon={<BsDashLg className="text-xs ml-4" />}
                      setChosenMenu={setChosenMenu}
                      value="Stories"
                      option={"Cards"}
                    />
                    <Option
                      chosenMenu={chosenMenu}
                      icon={<BsDashLg className="text-xs ml-4" />}
                      setChosenMenu={setChosenMenu}
                      value="addstorestoorganisation"
                      option={"Join Organisation"}
                    />

                    <Option
                      chosenMenu={chosenMenu}
                      icon={<BsDashLg className="text-xs ml-4" />}
                      setChosenMenu={setChosenMenu}
                      value="StoreForm"
                      option={"Create New Store"}
                    />
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
          {!selected?.template ? (
            <Option
              chosenMenu={chosenMenu}
              icon={<GiSpiderWeb className="text-2xl" />}
              setChosenMenu={setChosenMenu}
              value="createweb"
              option={"Website Builder"}
            />
          ) : (
            <Option
              chosenMenu={chosenMenu}
              icon={<GiSpiderWeb className="text-2xl" />}
              setChosenMenu={setChosenMenu}
              value="updateweb"
              option={"Update Website "}
            />
          )}

          {/*Change bottom value to updateweb to make it work */}
          <Option
            chosenMenu={chosenMenu}
            icon={<GiSpiderWeb className="text-2xl" />}
            setChosenMenu={setChosenMenu}
            value="sellerorders"
            option={"Order Management"}
          />

          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button
                  className={
                    "py-2 px-3 w-full text-left flex justify-between items-center "
                  }
                >
                  <div className="flex items-center">
                    <MdInventory className="mr-1 text-2xl" />
                    <span> Product Management</span>
                  </div>
                  {open ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel static className="text-gray-500">
                    <Option
                      chosenMenu={chosenMenu}
                      icon={<BsDashLg className="text-xs ml-4" />}
                      setChosenMenu={setChosenMenu}
                      value="addproduct"
                      option={"Add Product"}
                    />
                    <Option
                      chosenMenu={chosenMenu}
                      icon={<BsDashLg className="text-xs ml-4" />}
                      setChosenMenu={setChosenMenu}
                      value="manageproducts"
                      option={"Manage Products"}
                    />
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        </div>
        <div className="w-10/12 px-8 bottom-0 overflow-y-scroll flex flex-col space-y-4 relative ">
          {children}
          <div className="w-full flex items-center justify-center text-gray-500 bg-white py-2 ">
            © 2023 | WETARANG | RIGHTS BY MACHAPOINT
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
