import React from "react";
import Typewriter from "typewriter-effect";

const TypeWriterColumn = ({ strings, heading }) => {
  return (
    <div className="w-[100%] md:mt-12 mt-4  origin-right  bg-white  lg:text-3xl md:text-3xl text-2xl xl:text-4xl  h-full flex flex-col space-y-4 items-center justify-center">
      <p className="text-gray-600 font-semibold max-sm:text-center">
        {heading}
      </p>
      <div className="font-bold capitalize">
        <Typewriter
          options={{
            strings: strings,
            autoStart: true,
            loop: true,
          }}
        />
      </div>
    </div>
  );
};

export default TypeWriterColumn;
