import { APIURL } from "../backend";

export const getAllCategories = () => {
  return fetch(`${APIURL}/category/get-all-categories`, {
    method: "GET",
    headers: {
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
export const getSimilarProducts = (productId) => {
  return fetch(
    `${APIURL}/product/get-similar-products/?productID=${productId}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getFilteredResults = (filters) => {
  try {
    const data = {
      filters,
    };

    return fetch(`${APIURL}/product/get-products-filter`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((response) => {
      return response.json();
    });
  } catch (error) {
    return { error: "Something went wrong", success: false };
  }
};

export const getProductById = (id) => {
  try {
    return fetch(`${APIURL}/product/get-product-by-id/?productID=${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }).then((response) => {
      return response.json();
    });
  } catch (error) {
    return { error: "Something went wrong", success: false };
  }
};

export const getProductByStoreId = (id) => {
  try {
    return fetch(`${APIURL}/product/get-product-by-store-id/?storeID=${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }).then((response) => {
      return response.json();
    });
  } catch (error) {
    return { error: "Something went wrong", success: false };
  }
};

export const getMyProducts = () => {
  try {
    return fetch(`${APIURL}/product/get-my-products`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: ` ${
          JSON.parse(localStorage.getItem("macha-user"))?.token
        }`,
      },
    }).then((response) => {
      return response.json();
    });
  } catch (error) {
    return { error: "Something went wrong", success: false };
  }
};

export const uploadProductImages = (Images) => {
  try {
    return fetch(`${APIURL}/product/upload-product-image`, {
      method: "POST",
      headers: {
        Authorization: ` ${
          JSON.parse(localStorage.getItem("macha-user"))?.token
        }`,
      },
      body: Images,
    }).then((response) => {
      return response.json();
    });
  } catch (error) {
    return { error: "Something went wrong", success: false };
  }
};

export const addProductImage = (data, id) => {
  try {
    return fetch(`${APIURL}/product/add-product-image/?productID=${id}`, {
      method: "POST",
      headers: {
        Authorization: ` ${
          JSON.parse(localStorage.getItem("macha-user"))?.token
        }`,
      },
      body: data,
    }).then((response) => {
      return response.json();
    });
  } catch (error) {
    return { error: "Something went wrong", success: false };
  }
};

export const uploadProduct = (data) => {
  try {
    return fetch(`${APIURL}/product/add-new-product`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: ` ${
          JSON.parse(localStorage.getItem("macha-user"))?.token
        }`,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      return response.json();
    });
  } catch (error) {
    return { error: "Something went wrong", success: false };
  }
};

export const deleteProductImage = (data) => {
  try {
    return fetch(`${APIURL}/product/delete-product-image`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: ` ${
          JSON.parse(localStorage.getItem("macha-user"))?.token
        }`,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      return response.json();
    });
  } catch (error) {
    return { error: "Something went wrong", success: false };
  }
};

export const updateProduct = (data) => {
  try {
    return fetch(`${APIURL}/product/update-product`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: ` ${
          JSON.parse(localStorage.getItem("macha-user"))?.token
        }`,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      return response.json();
    });
  } catch (error) {
    return { error: "Something went wrong", success: false };
  }
};

export const deleteProduct = (data) => {
  try {
    return fetch(`${APIURL}/product/delete-product`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: ` ${
          JSON.parse(localStorage.getItem("macha-user"))?.token
        }`,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      return response.json();
    });
  } catch (error) {
    return { error: "Something went wrong", success: false };
  }
};
