import React, { useState, useRef, useEffect, Fragment } from "react";
import PopupProduct from "../Components/popupProduct";
import MachaHeader from "../Components/Base/MachaHeader";
import Footer from "../Components/Base/Footer";
import { getAllCategories, getFilteredResults } from "../Helper/product";
import toast from "react-hot-toast";
import StoriesSlider from "../Components/StoriesSlider";
import { APIURL } from "../backend";
import useWindowDimensions from "../hooks/useWindowDimension";
import SellerProduct from "../Components/SellerPage/SellerProduct";
import { IoFilterSharp } from "react-icons/io5";
import Drawer from "../Components/Base/Drawer";
import { RxCross2 } from "react-icons/rx";
import { addToCart } from "../Helper/Order";
import { useParams } from "react-router-dom";
import { LoginToast } from "../Toasts/LoginToast";

const CategoryProducts = () => {
  const { categoryId } = useParams();
  const [pop, setPop] = useState(false);
  const [prodData, setData] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [viewStories, setViewStories] = useState([]);
  const [viewedStories, setViewedStories] = useState([]);
  const [catOpen, setCatOpen] = React.useState(false);
  const [catSave, setCatSave] = useState([]);
  const { width } = useWindowDimensions();
  const ref = useRef(null);
  const ref2 = useRef([]);
  async function getStories() {
    try {
      const res = await fetch(`${APIURL}/story/get-stories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("macha-user"))?.token,
        },
      });
      const response = await res.json();
      if (response.status_code === 200) {
        setViewStories(response.data);
      } else {
        console.log(response);
        toast.error("Error fetching stories at the moment");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  }

  async function getViewedStories() {
    if (localStorage.getItem("macha-user")) {
      try {
        const res = await fetch(`${APIURL}/story/get-viewed-stories`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("macha-user"))
              ?.token,
          },
        });
        const response = await res.json();
        if (response.status_code === 200) {
          setViewedStories(response.data);
        } else {
          console.log(response);
          toast.error("Error fetching stories at the moment");
        }
      } catch (error) {
        toast.error("Something went wrong");
      }
    } else {
      return;
    }
  }

  async function loadFilteredProducts() {
    if (!categoryId) return;
    let filters = {
      filters: {
        productCategory: [categoryId],
        offerPrice: [],
        artist: [],
        productSubcategory: [],
      },
    };
    getFilteredResults(filters.filters).then((data) => {
      if (data.status_code === 200) {
        setData(data.data);
      } else {
        toast.error("Unable to fetch products at the moment");
      }
    });
  }

  async function setUp() {
    await getStories();

    await getViewedStories();
  }

  useEffect(() => {
    setUp();
  }, []);

  useEffect(() => {
    loadFilteredProducts();
  }, [categoryId]);

  useEffect(() => {
    if (setPop) {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setPop(false);
          setActiveId(null);
        }
      };
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    }
  }, [pop]);

  async function addToCartHandler(pro, toggle) {
    if (localStorage.getItem("macha-user")) {
      addToCart({ productID: pro, quantity: toggle }).then((response) => {
        if (response.status_code === 200) {
          toast.success("Product added to cart");
        } else {
          toast.error("Something went wrong");
        }
      });
    } else {
      toast.error(LoginToast);
    }
  }

  return (
    <>
      <MachaHeader />
      <StoriesSlider stories={viewStories} viewedStories={viewedStories} />
      <div className="flex md:flex-row justify-center px-4">
        <div className="w-full">
          <div className="flex items-center justify-between">
            <div className="pr-40 ">
              <div className="text-xl font-bold text-heading hidden md:inline-flex pb-1 ">
                {prodData?.length + " Items"}
              </div>
            </div>
          </div>
          <div className=" grid lg:grid-cols-4 lg:gap-4 md:grid-cols-3 md:gap-4 grid-cols-2 gap-4 sxs:grid-cols-1 sxs:gap-0">
            {prodData &&
              prodData?.map((data, index) => {
                return width > 800 ? (
                  <div
                    onClick={() => {
                      setActiveId(data._id);

                      setPop(true);
                    }}
                    key={index}
                  >
                    <SellerProduct item={data} />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setActiveId(data._id);
                      if (width > 800) {
                        setPop(true);
                      }
                    }}
                    key={index}
                  >
                    <a
                      href={`/product/${data.productName.replace(
                        /\s+/g,
                        "-"
                      )}/${data._id}`}
                    >
                      <SellerProduct item={data} />
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
        {pop === true && setActiveId && (
          <div
            style={{ zIndex: 100 }}
            className="fixed w-screeen h-screen top-0 left-0 right-0 bg-gray-800 bg-opacity-50 flex items-center justify-center"
          >
            <div
              ref={ref}
              className="  drop-shadow-lg rounded-[20px] w-[65%] h-[65%] bg-white flex justify-center items-center "
            >
              <PopupProduct
                setPop={setPop}
                addToCartHandler={addToCartHandler}
                product={
                  prodData[prodData.findIndex((x) => x._id === activeId)]
                }
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CategoryProducts;
