import { useRef, useState, useEffect } from "react";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Navigate } from "react-router-dom";
import waLogo from "../img/whatsapp.png";
import OtplessSdk from "otpless-js-sdk";
import { Link } from "react-router-dom";
import { FaMobile } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "../api/Axios";
import logo from "../images/logo.png";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
// import { GoogleLogin, GoogleLogout } from "react-google-login";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React from "react";
import MachaHeader from "../Components/Base/MachaHeader";
import Footer from "../Components/Base/Footer";
import { APIURL, FRONTENDAPIURL } from "../backend";
import OtpComp from "../Components/Registration/OtpComp";
import Loader from "../Components/Builder/Loader";

const USER_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const REGISTER_URL = `${APIURL}/auth/signup`;

const Register = () => {
  const userRef = useRef();
  const errRef = useRef();

  const [isValidLogin, setValidLogin] = useState("pending");

  const [user, setUser] = useState("");
  const [user_id, setUser_id] = useState("");
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [pwd, setPwd] = useState("");

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  // useEffect(() => {
  // 	userRef.current.focus();
  // }, []);

  useEffect(() => {
    setValidName(USER_REGEX.test(user));
  }, [user]);

  useEffect(() => {
    setValidMatch(values.password === matchPwd);
  }, [values.password, matchPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd, matchPwd]);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const v1 = USER_REGEX.test(user);
    if (!v1) {
      setErrMsg("Invalid Entry");
      return;
    }
    try {
      const response = await axios.post(REGISTER_URL, {
        email: user,
        password: values.password,
      });
      console.log(response);
      console.log(response, response.status);
      if (response.data.status_code === 200) {
        setLoading(false);
        console.log(response.data.data._id);
        setUser_id(response.data.data._id);
        setPwd("");
        setMatchPwd("");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 409) {
        setErrMsg("Username Taken");
      } else {
        setErrMsg("Registration Failed");
      }
      errRef.current.focus();
    }
  };
  const sdkIntance = new OtplessSdk({
    appId: "OTPLess:MBVZXOYJPGHZZKHRWVWIMYVSXKMNVCME",
    enableErrorLogging: true,
  });

  useEffect(() => {
    axios
      .post(
        `${APIURL}/auth/check-login`,
        {},
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("macha-user"))
              ?.token,
          },
        }
      )
      .then((res) => {
        if (res.data.status_code === 400) {
          setValidLogin(false);
        } else {
          setValidLogin(true);
          // localStorage.setItem('macha-user',JSON.stringify(res.data.data));
        }
      })
      .catch((err) => {
        setValidLogin(false);
      });
  }, []);

  if (isValidLogin === "pending") {
    return <span>Loading...</span>;
  }

  console.log(user_id);

  return (
    <>
      {success ? (
        <Navigate to="/login" replace={true} />
      ) : (
        <div>
          {/* <MachaHeader /> */}
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "absolute opacity-0"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
            <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl max-w-xl">
              {/* <img className="mx-auto w-40" src={logo} alt="img" /> */}
              <div className="text-center text-[35px] font-semibold text-gray-800">
                Welcome
              </div>

              <div className="text-sm text-center pt-2">Register Here!</div>
              {!user_id ? (
                <>
                  <form className="mt-6" onSubmit={!loading && handleSubmit}>
                    <div className="mb-2">
                      <label
                        htmlFor="username"
                        className=" block text-sm font-semibold text-gray-800"
                      >
                        Email:
                        <FontAwesomeIcon
                          icon={faCheck}
                          className={
                            validName ? "text-green-500 ml-1" : "hidden"
                          }
                        />
                        <FontAwesomeIcon
                          icon={faTimes}
                          className={
                            validName || !user ? "hidden" : "text-red-500 ml-1"
                          }
                        />
                      </label>
                    </div>
                    <Input
                      type="text"
                      id="username"
                      ref={userRef}
                      autoComplete="off"
                      onChange={(e) => setUser(e.target.value)}
                      value={user}
                      required
                      aria-invalid={validName ? "false" : "true"}
                      aria-describedby="uidnote"
                      onFocus={() => setUserFocus(true)}
                      onBlur={() => setUserFocus(false)}
                      className="w-full block text-sm px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <p
                      id="uidnote"
                      className={
                        userFocus && user && !validName
                          ? "text-xs rounded-lg bg-black text-white p-1 relative -bottom-2.5"
                          : "absolute opacity-0"
                      }
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                      More than 4 characters.
                      <br />
                      Must begin with a letter.
                      <br />
                      Letters, numbers, underscores, hyphens allowed.
                    </p>

                    <label
                      htmlFor="password"
                      className=" block text-sm font-semibold text-gray-800"
                    >
                      Password:
                    </label>
                    <Input
                      className="w-full block  text-sm px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                      type={values.showPassword ? "text" : "password"}
                      onChange={handlePasswordChange("password")}
                      value={values.password}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />

                    <label
                      htmlFor="confirm_pwd"
                      className=" block text-sm font-semibold text-gray-800"
                    >
                      Confirm Password:
                    </label>
                    <Input
                      type="password"
                      id="confirm_pwd"
                      onChange={(e) => setMatchPwd(e.target.value)}
                      value={matchPwd}
                      required
                      onFocus={() => setMatchFocus(true)}
                      onBlur={() => setMatchFocus(false)}
                      className="w-full block text-sm px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <p className="text-center text-xs mt-6">
                      By using this platform you agree to our{" "}
                      <a
                        target="_blank"
                        href="https://docs.google.com/document/d/e/2PACX-1vR4-dDC3vwhFDgpjAA88rM6oKfHwxxivp5G0x8zmXFJR4g8mSVAJasqGuk_C6_BZXF_JverQ1NPci_U/pub"
                        rel="noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        Privacy Policy
                      </a>{" "}
                      and{" "}
                      <a
                        target="_blank"
                        href="https://docs.google.com/document/d/e/2PACX-1vRa15KhCzylYy_CEuR1lBo5qVBR_eAYyP-LaJe5mK7-AhZdoYMkQ_nT1mMf8e0bUn57BI5Rivw_9pYk/pub"
                        rel="noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        Terms and conditions
                      </a>{" "}
                    </p>
                    <div className="mt-6">
                      <button
                        className="text-sm w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-black rounded-md hover:bg-slate-700 focus:outline-none focus:bg-slate-700"
                        disabled={!validName || !validMatch ? true : false}
                      >
                        {loading ? <Loader size={12} /> : "Sign Up"}
                      </button>
                    </div>
                  </form>

                  <div className="relative flex items-center justify-center  mt-6 border border-t">
                    <div className="absolute px-5 bg-white">Or</div>
                  </div>
                  <div className="mt-4 gap-x-2">
                    <Link to="/phoneAuth">
                      <button className="text-sm mt-4 flex bg-black w-full text-white items-center justify-center  p-2 border border-gray-600 rounded-md focus:ring-2 focus:ring-offset-1 focus:ring-slate-700">
                        <FaMobile className="mr-1" />
                        Singup With Mobile Number
                      </button>
                    </Link>
                  </div>
                  {/* <div>
                    <button
                      onClick={sdkIntance.createGetIntentOnClick({
                        redirectionURL: `${FRONTENDAPIURL}/register`,
                      })}
                      className="text-sm mt-4 flex bg-green-500 w-full text-white items-center justify-center  p-2 border border-gray-600 rounded-md focus:ring-2 focus:ring-offset-1 focus:ring-slate-700"
                    >
                      <img className="mr-2 w-10" src={waLogo} alt="img" />
                      Singup with WhatsApp
                    </button>
                  </div> */}
                  <p className="mt-8 text-xs font-light text-center text-gray-700">
                    {" "}
                    Already have an account?{" "}
                    <Link
                      to="/login"
                      className="font-medium text-black hover:underline"
                    >
                      Sign In
                    </Link>
                  </p>
                </>
              ) : (
                <OtpComp
                  email={user}
                  user_id={user_id}
                  setSuccess={setSuccess}
                />
              )}
              <div className="flex justify-center items-center pt-5">
                <div className="pr-1 font-[300]">Powered by</div>
                <div className="  lg:h-5   h-3 ">
                  <img src={logo} alt="" className=" h-full " />
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      )}
    </>
  );
};

export default Register;
