import React, { useEffect, useState } from "react";
import ManageHeader from "../Manage/ManageHeader";
import { AiFillCloseCircle, AiFillEye } from "react-icons/ai";

import { toast } from "react-hot-toast";
import { getStoresByOrganisation } from "../../Helper/store";
import {
  getAllOrganisations,
  changeOraganisationStatus,
} from "../../Helper/organisation";

const ManageStores = () => {
  const [stores, setStores] = useState();

  async function getOrganisation() {
    const id = JSON.parse(localStorage.getItem("macha-user"))?.uid;

    const res = await getAllOrganisations();
    console.log(res, "res");
    if (!res) {
      toast.error("Unable to get organisations");
    } else {
      if (res.status_Code === 200) {
        var result = res.data.filter((obj) => {
          return obj.userId === id;
        });
        if (result.length > 0) {
          getStoresHandler(result[0]._id);
        }
      } else {
        toast.error(res.error);
      }
    }
  }

  async function getStoresHandler(id) {
    const res = await getStoresByOrganisation(id);
    console.log(res, "res");
    if (!res) {
      toast.error("Unable to get organisations");
    } else {
      if (res.status_code === 200) {
        setStores(res.data);
      } else {
        toast.error(res.error);
      }
    }
  }

  async function updateOrganisationStatusHandler(org) {
    console.log(org, "org");
    let body = {
      orgID: org._id,
      status: !org.status,
    };

    const res = await changeOraganisationStatus(body);
    console.log(res, "res", body);

    if (!res) {
      toast.error("Unable to update organisation status");
    } else {
      if (res.status_code === 200) {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    }
  }

  useEffect(() => {
    // getAllOrganisations();
    getOrganisation();
  }, []);

  return (
    <div>
      <ManageHeader heading={"Manage Stores"} />
      <div className="my-6 w-full rounded-md border border-gray-200">
        <table className="table-auto  w-full  bg-[#f7f8f9] rounded-lg">
          <thead>
            <tr className="text-sm font-thin">
              <th className="font-normal py-3">Logo</th>
              <th className="font-normal py-3">Organisation Name</th>
              <th className="font-normal py-3">Chosen Template</th>

              <th className="font-normal py-3">User Id</th>
              <th className="font-normal py-3">Store status</th>
              <th className="font-normal py-3">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y">
            {stores?.map((org) => {
              return (
                <tr className="text-center">
                  <td className="flex items-center justify-center py-4">
                    <img
                      className="w-10 h-10 rounded-md"
                      alt=""
                      src={org?.storeLogo}
                    />
                  </td>
                  <td className="text-sm text-gray-500">{org?.storeName}</td>
                  <td className="text-sm text-gray-500">
                    {org?.chosenTemplate}
                  </td>
                  <td className="text-sm text-gray-500">{org?.user?._id}</td>
                  <td className="text-sm text-gray-500   ">
                    <span
                      className={
                        "border px-3 py-1 rounded-full bg-black " +
                        (org?.status === "accepted"
                          ? "text-green-300"
                          : "text-red-300")
                      }
                    >
                      {org?.status}
                    </span>
                  </td>
                  <td>
                    <div className="flex items-center space-x-1 justify-center">
                      <AiFillCloseCircle
                        data-te-toggle="tooltip"
                        data-te-placement="bottom"
                        data-te-ripple-init
                        data-te-ripple-color="light"
                        title={"Remove from organisation"}
                        onClick={() => toast("Under development")}
                        className="text-xl text-red-500 hover:text-red-600
                      cursor-pointer"
                      />
                      <AiFillEye
                        data-te-toggle="tooltip"
                        data-te-placement="bottom"
                        data-te-ripple-init
                        data-te-ripple-color="light"
                        title={"View store"}
                        onClick={() => toast("Under development")}
                        className="text-2xl text-gray-500 hover:text-gray-700 cursor-pointer"
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageStores;
