import React, { useState, useEffect } from "react";
import MachaHeader from "../Components/Base/MachaHeader";
import { getUserInfo } from "../Helper/Auth";
import { toast } from "react-hot-toast";
import { checkOrderPayment } from "../Helper/Order";
import { useSearchParams } from "react-router-dom";

const OrderConfirmation = () => {
  const [userInfo, setUserInfo] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [payment, setPayment] = useState();

  console.log(searchParams.get("cod"), "searchParams");

  async function getUser() {
    const user = await getUserInfo();
    console.log(user, "user");
    if (user?.status_code === 200) {
      setUserInfo(user.data);
    } else {
      toast.error("Something went wrong");
    }
  }

  async function checkOrderPaymentHandler(id) {
    const data = await checkOrderPayment(id);

    if (data?.status_code === 200) {
      toast.success("Payment Successful");
      setPayment(data.data);
    } else {
      toast.error("Something went wrong");
    }
  }

  useEffect(() => {
    if (searchParams.get("order_id")) {
      checkOrderPaymentHandler(searchParams.get("order_id"));
    }
  }, []);

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div>
      <MachaHeader />
      {/* {payment && payment?.txStatus === "SUCCESS" ? (
        <div className="text-center px-[400px]">
          <div className="pt-8 pb-4 text-[50px] font-[600]">THANK YOU!</div>
          <div className=" pb-6 text-[23px] font-[500]">
            We are getting started on your order right away and you will receive
            a confirmation email shortly on {userInfo?.email}
          </div>
          <div className="pb-10 text-[25px] font-[500]">
            <button className="bg-black text-white font-[400]  py-2 px-4 rounded-md">
              VIEW ORDER CONFIRMATION
            </button>
          </div>
          <div className="pb-4 text-[18px] font-[500]  underline underline-offset-1">
            Read about our return policy
          </div>
        </div>
      ) : (
        <div className="pt-8 pb-4 text-[50px] font-[600]">
          {payment?.message}
        </div>
      )} */}
      {searchParams.get("cod") === "true" ? (
        <div className="text-center px-[400px]">
          <div className="pt-8 pb-4 text-[50px] font-[600]">THANK YOU!</div>
          <div className=" pb-6 text-[23px] font-[500]">
            We are getting started on your order right away and you will receive
            a confirmation email shortly on {userInfo?.email}
          </div>
          <div className="pb-10 text-[25px] font-[500]">
            <a
              href={"/"}
              className="bg-black text-white font-[400]  py-2 px-4 rounded-md"
            >
              Go back Home
            </a>
          </div>
          <div className="pb-4 text-[18px] font-[500]  underline underline-offset-1">
            Read about our return policy
          </div>
        </div>
      ) : (
        <div className="pt-8 pb-4 text-[50px] font-[600]">
          Sorry the Order Initiation is not successful
        </div>
      )}
    </div>
  );
};

export default OrderConfirmation;
