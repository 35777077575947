import React from "react";
import { Editor } from "react-draft-wysiwyg";

const EditorInput = ({ editorState, setEditorState }) => {
  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={setEditorState}
      editorStyle={{ backgroundColor: "#EBECF0" }}
      wrapperClassName="wrapper-class"
      editorClassName="editor-class"
      toolbarClassName="toolbar-class"
    />
  );
};

export default EditorInput;
