import React, { useState, useEffect } from "react";
import { EditorState, convertFromRaw } from "draft-js";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";

const MainColumn = ({ backgroundImage, text, heading }) => {
  const [convertedContent, setConvertedContent] = useState(null);
  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  useEffect(() => {
    if (text !== null) {
      const blocks = text && text.blocks;
      const entityMap = {};
      const contentState = convertFromRaw({ blocks, entityMap });
      const editorState = EditorState.createWithContent(contentState);
      let html = convertToHTML(editorState.getCurrentContent());
      setConvertedContent(html);
    }
  }, [text]);

  return (
    <div className="w-full flex flex-col-reverse md:flex-row xl:px-20 lg:px-10 px-6     items-center justify-center md:py-12 py-6 ">
      <div className="w-full mx-auto md:w-[50%]  origin-right  bg-white   flex flex-col space-y-4 items-center justify-center">
        {heading && (
          <h1 className="capitalize pt-3 px-6 sm:p-6 lg:text-3xl md:text-3xl text-2xl xl:text-4xl  font-bold text-black">
            {heading}
          </h1>
        )}
        <div
          className="px-6"
          dangerouslySetInnerHTML={createMarkup(convertedContent)}
        ></div>
      </div>
      <div className="w-full mx-auto md:w-[50%]  origin-left  relative px-3">
        <img
          src={backgroundImage}
          alt=""
          className="w-full h-full object-fill"
        />
      </div>
    </div>
  );
};

export default MainColumn;
