import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../firebase";
import MachaHeader from "../Components/Base/MachaHeader";
import Footer from "../Components/Base/Footer";
import { loginFromMobile, verifyMobileLogin } from "../Helper/Auth";
import { toast } from "react-hot-toast";

const PhoneSignUp = () => {
  const [error, setError] = useState("");
  const [number, setNumber] = useState("+91");
  const [flag, setFlag] = useState(false);
  const [otp, setOtp] = useState("");
  const [result, setResult] = useState("");

  const navigate = useNavigate();

  async function mobileLoginHandler() {
    let response = await loginFromMobile({ phone_number: number });
    if (response.status_code === 200) {
      setFlag(true);
      toast.success("OTP sent successfully");
    } else {
      toast.error(response.message);
    }
  }

  async function reSend() {
    let response = await loginFromMobile({ phone_number: number });
    if (response.status_code === 200) {
      toast.success("OTP sent successfully");
    } else {
      toast.error(response.message);
    }
  }
  const verifyOtp = async (e) => {
    e.preventDefault();
    let response = await verifyMobileLogin({
      phone_number: number,
      verification_code: otp,
    });
    console.log(response, "response");
    if (response.status_code === 200) {
      await localStorage.setItem("macha-user", JSON.stringify(response.data));
      toast.success("Login Successful");

      setTimeout(() => {
        navigate("/");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <MachaHeader />
        <div className="p-4 box align-center text-center">
          <h2 className="mb-3">Phone Number Authentication</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <div className="" style={{ display: !flag ? "block" : "none" }}>
            <Form.Group
              className="mb-3 align-center text-center"
              controlId="formBasicEmail"
            >
              <input
                type="tel"
                class="form-control w-60 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                defaultCountry="IN"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                placeholder="Enter Phone Number"
              />
              <div className="mt-2" id="captcha"></div>
            </Form.Group>
            <div className="button-right">
              <Link to="/">
                <button
                  variant="secondary"
                  class="mr-16 text-sm bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-2 border border-red-500 hover:border-transparent rounded"
                >
                  Cancel
                </button>
              </Link>
              &nbsp;
              <button
                onClick={mobileLoginHandler}
                variant="primary"
                class="ml-8 text-sm bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-2 border border-blue-500 hover:border-transparent rounded"
              >
                Send OTP
              </button>
            </div>
          </div>

          <div
            onSubmit={verifyOtp}
            style={{ display: flag ? "block" : "none" }}
          >
            <Form.Group className="mb-3" controlId="formBasicOtp">
              <input
                class="form-control w-60 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                type="otp"
                placeholder="Enter OTP"
                onChange={(e) => setOtp(e.target.value)}
              />
            </Form.Group>

            <div className="button-right">
              <button
                variant="secondary"
                onClick={reSend}
                class="mr-16 text-sm bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-2 border border-green-500 hover:border-transparent rounded"
              >
                Resend
              </button>
              &nbsp;
              <button
                onClick={verifyOtp}
                variant="primary"
                class="ml-10 text-sm bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-2 border border-blue-500 hover:border-transparent rounded"
              >
                Submit OTP
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PhoneSignUp;
