import React, { useState, useRef, useEffect, Fragment } from "react";
import { toast } from "react-hot-toast";
import MachaHeader from "../Components/Base/MachaHeader";
import AddAddress from "../Components/CheckoutPage/addAddress";
import Product from "../Components/CheckoutPage/product";

import { useLocation, useNavigate } from "react-router-dom";
import { BiRupee } from "react-icons/bi";
import { APIURL } from "../backend";
import axios from "axios";
import { AiFillDelete } from "react-icons/ai";
import { initiateOrders, generateCheckoutDetails } from "../Helper/Order";
import { Dialog, Transition } from "@headlessui/react";
import { ImCross } from "react-icons/im";

const CheckoutPage = () => {
  const [pop, setPop] = useState(false);
  const [total, setTotal] = useState(0);
  const [addData, setData] = useState({});
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [pin, setPin] = useState("");
  const [city, setCity] = useState("");
  const [name, setName] = useState("");
  const [selectedAdd, setSelectedAdd] = useState();
  const [addtype, setAddType] = useState();
  const [phone, setPhone] = useState("");
  const [checkoutDetails, setCheckoutDetails] = useState();
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const location = useLocation();
  const addURL = `${APIURL}/user/add-new-address`;
  const getURL = `${APIURL}/user/get-address`;
  const delURL = `${APIURL}/user/remove-address`;

  const ref = useRef(null);

  useEffect(() => {
    axios
      .get(getURL, {
        headers: {
          Authorization:
            localStorage.getItem("macha-user") &&
            JSON.parse(localStorage.getItem("macha-user")).token,
        },
      })
      .then((response) => {
        setSelectedAdd(response.data.data[0]._id);
        setData(response.data.data);
      });
  }, []);

  async function addAddress() {
    const response = await axios.post(
      addURL,
      {
        main_address: address,
        postal_code: pin,
        name: name,
        city: city,
        state: state,
        type: addtype,
        phone_number: phone,
      },
      {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("macha-user"))[
            "token"
          ],
        },
      }
    );

    if (response.status === 200) {
      toast.success("Address Added Successfully");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }

  let cartData = location?.state;

  let checkCod = cartData.find((o) => o?.product_id?.cod === true);

  async function getMyCartData() {
    const response = await cartData;

    for (let i = 0; i < response.length; i++) {
      let SubTotal = response[i].product_id.offerPrice * response[i].quantity;
      setTotal((prev) => prev + SubTotal);
    }
  }
  useEffect(() => {
    if (cartData && cartData.length > 0) {
      getMyCartData();
    }
  }, []);

  useEffect(() => {
    if (setPop) {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setPop(false);
        }
      };
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    }
  }, [pop]);

  const deleteAdd = async (id, index) => {
    setData(addData.filter((v, i) => i !== index));
    const response = await axios.post(
      delURL,
      { id: id },
      {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("macha-user"))[
            "token"
          ],
        },
      }
    );

    if (response.status === 200) {
      toast.success("Address Deleted Successfully");
    }
  };

  const handleCheckoutDetails = async () => {
    let newArr = [];

    for (let i = 0; i < cartData.length; i++) {
      let obj = {
        product_id: cartData[i].product_id._id,
        quantity: cartData[i].quantity,
        payment_type: cartData[i].product_id.cod ? "cod" : "online",
      };
      newArr.push(obj);
    }

    let body = {
      cart: newArr,
    };

    let response = await generateCheckoutDetails(body);

    if (response.status_code === 200) {
      setCheckoutDetails(response.data);
    }
  };

  useEffect(() => {
    handleCheckoutDetails();
  }, []);

  const navigator = useNavigate();

  const toConfirmation = () => {
    navigator("/confirmation/?cod=true");
  };

  const handleOrder = async (pay) => {
    let newArr = [];
    if (pay === "online") {
      for (let i = 0; i < cartData.length; i++) {
        let obj = {
          product_id: cartData[i].product_id._id,
          quantity: cartData[i].quantity,
          payment_type: "online",
        };
        newArr.push(obj);
      }
    } else if (pay === "partially") {
      for (let i = 0; i < cartData.length; i++) {
        let obj = {
          product_id: cartData[i].product_id._id,
          quantity: cartData[i].quantity,
          payment_type: cartData[i].product_id.cod ? "cod" : "online",
        };
        newArr.push(obj);
      }
    } else if (pay === "cod") {
      for (let i = 0; i < cartData.length; i++) {
        let obj = {
          product_id: cartData[i].product_id._id,
          quantity: cartData[i].quantity,
          payment_type: "cod",
        };
        newArr.push(obj);
      }
    }

    let body = {
      address: selectedAdd,
      cart: newArr,
    };
    let response = await initiateOrders(body);

    if (response.status_code === 200 && pay !== "cod") {
      if (response?.data?.online_payment_link) {
        var win = await window.open(response?.data?.online_payment_link);
        win && win.focus();
      }
    } else if (response.status_code === 200 && pay === "cod") {
      toConfirmation();
      toast.success("Order Placed Successfully");
    } else {
      toast.error(response.message);
    }
  };

  return (
    <div>
      <MachaHeader />
      <div className="xl:px-16 lg:px-12 md:px-8 sm:px-6 max-sm:px-4 flex max-sm:flex-col justify-center pt-8">
        <div className="w-2/3 max-sm:w-full max-sm:pr-0 xl:pr-12 lg:pr-8 sm:pr-5">
          <div className="shadow-xl px-6 py-6 rounded-[5px] mt-6">
            <div className="flex justify-between items-center ">
              <div className="text-[25px] font-[700]">Shipping Address</div>
              <div>
                <button className="text-[20px]" onClick={() => setPop(true)}>
                  + Add
                </button>
              </div>
            </div>
            <div className="pt-6 grid grid-rows-1  sm:grid-cols-2 lg:grid-cols-3 gap-2">
              {addData &&
                addData.length > 0 &&
                addData.map((item, index) => {
                  return (
                    <div
                      className={
                        "py-6 border-2  px-6 " +
                        (selectedAdd === item._id
                          ? "bg-gray-200 border-black"
                          : "")
                      }
                      onClick={() => {
                        setSelectedAdd(item._id);
                      }}
                    >
                      <div className="flex justify-between">
                        <div className="font-[600] ">{item?.name}</div>
                        <AiFillDelete
                          className="text-[20px]"
                          onClick={() => deleteAdd(item?._id, index)}
                        />
                      </div>

                      <div className="text-gray-700">{item?.main_address}</div>
                      <div className="text-gray-700">{item?.postal_code}</div>
                      <div className="text-gray-700">{item?.state}</div>
                      <div className="text-gray-700">{item?.phone_number}</div>
                      <div className="text-gray-700">{item?.type}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="w-1/3 max-sm:w-full">
          <div className="text-[30px] font-[600] pb-6">Your Order</div>
          <div className="border border-gray-300 rounded-[10px]">
            <div className="py-3 flex items-center justify-between text-[20px] font-[600] border-b border-gray-300 bg-gray-200 ">
              <div className="pl-6">Product</div>
              <div className="pr-6">Sub Total</div>
            </div>
            <div>
              {cartData &&
                cartData.length > 0 &&
                cartData.map((item) => {
                  return <Product item={item} />;
                })}
            </div>
            <div className="flex items-center justify-between text-[20px] px-6 py-4 border-t border-gray-200">
              <div className="font-[600] text-gray-600">SubTotal</div>

              <div className="flex items-center pl-1 font-[700]">
                <BiRupee /> {total}
              </div>
            </div>
            <div className="flex items-center justify-between text-[20px] px-6 py-4 border-t border-gray-200">
              <div className="font-[600] text-gray-600">Tax </div>
              <div className="font-[600]">Calculated at checkout</div>
            </div>
            <div className="flex items-center justify-between text-[20px] px-6 py-4 border-t border-gray-200">
              <div className="font-[600] text-gray-600">Shipping </div>
              <div className="font-[600]">Calculated at checkout</div>
            </div>
            {checkoutDetails &&
            Object.keys(checkoutDetails?.onlinePaymentOrders?.orders).length !==
              0 ? (
              <div className="py-6 px-4">
                <div className="font-[500] text-lg text-center">
                  Some Items In your Cart are not available for COD So do you
                  want to
                </div>
                <div className="flex justify-between  pt-2">
                  <div className="w-1/2">
                    <button
                      // onClick={() => handleOrder("online")}
                      onClick={console.log("Feature coming soon")}
                      className="bg-black text-white text-[15px] font-[600] w-[95%] py-6 rounded-[10px]"
                    >
                      Pay Full Amount Online
                    </button>
                    <div className="flex items-center pl-1 font-[700]">
                      Online : <BiRupee /> {total}
                    </div>
                  </div>
                  {/* {checkCod !== undefined && (
                    <div className="w-1/2">
                      <button
                        onClick={() => handleOrder("partially")}
                        className="bg-black text-white text-[15px] font-[600] w-[95%] py-3 rounded-[10px]"
                      >
                        Pay Partial Amount Online and Pay Rest on Delivery
                      </button>
                      <div className="flex items-center pl-1 font-[700]">
                        Online : <BiRupee />{" "}
                        {checkoutDetails?.onlinePaymentOrders?.amount}
                      </div>
                      <div className="flex items-center pl-1 font-[700]">
                        COD : <BiRupee /> {checkoutDetails?.codOrders?.amount}
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
            ) : (
              <div className="py-3 px-4">
                {/* <div className="font-[500] text-lg text-center">
                  Some Items In your Cart are not available for COD So do you
                  want to
                </div> */}
                <div className="flex justify-between  pt-2">
                  {/* <div className="w-1/2">
                    <button
                      onClick={() => handleOrder("online")}
                      
                      className="bg-black text-white text-[15px] font-[600] w-[95%] py-6 rounded-[10px]"
                    >
                      Pay Full Amount Online
                    </button>
                  
                  </div> */}

                  <div className="w-full">
                    <button
                      onClick={() => handleOrder("cod")}
                      className="bg-black text-white text-[15px] font-[600] w-[95%] py-6 rounded-[10px]"
                    >
                      Pay Full Amount In COD
                    </button>
                    {/* <div className="flex items-center pl-1 font-[700]">
                      Online : <BiRupee />{" "}
                      {checkoutDetails?.onlinePaymentOrders?.amount}
                    </div>
                    <div className="flex items-center pl-1 font-[700]">
                      COD : <BiRupee /> {checkoutDetails?.codOrders?.amount}
                    </div> */}
                  </div>
                </div>
              </div>
            )}

            {/* <div className="px-6 py-4">
              <button
                onClick={handleOrder}
                className="bg-black text-white text-[20px] font-[600] w-full py-3 rounded-[10px]"
              >
                Order
              </button>
            </div> */}
          </div>
        </div>
      </div>
      {pop === true && (
        <div
          style={{ zIndex: 100 }}
          className="fixed w-screeen h-screen top-0 left-0 right-0 bg-gray-800 bg-opacity-50 flex items-center justify-center"
        >
          <div
            ref={ref}
            className="  drop-shadow-lg rounded-[20px] w-full  sm:w-[50%] overflow-y-scroll overflow-hidden h-[90%] bg-white flex   "
          >
            <ImCross
              className="absolute top-0 right-2 text-[20px]"
              onClick={() => setPop(false)}
            />
            <AddAddress
              addtype={addtype}
              setAddType={setAddType}
              city={city}
              state={state}
              pin={pin}
              address={address}
              name={name}
              setCity={setCity}
              setState={setState}
              setPin={setPin}
              setAddress={setAddress}
              setName={setName}
              addAddress={addAddress}
              phone={phone}
              setPhone={setPhone}
            />
          </div>
        </div>
      )}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Temporarily Unavailable
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Due to fresh RBI guidelines and policy restructuring
                      online payment has been stopped for sometime. We will get
                      it started as soon as it is resolved by RBI. You can
                      continue with COD in the meantime. Thank you
                    </p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default CheckoutPage;
