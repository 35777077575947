import React, { useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import Carousel from "nuka-carousel/lib/carousel";
import StoriesSlider from "../Components/StoriesSlider";
import { getAllCategories } from "../Helper/product";
import {
  getAllStores,
  getRandomCategoryStoreForHomepage,
} from "../Helper/store";
import useWindowDimension from "../hooks/useWindowDimension";
import MachaHeader from "../Components/Base/MachaHeader";
import { APIURL } from "../backend";
import toast from "react-hot-toast";
import Footer from "../Components/Base/Footer";
import SellerBlock from "../Components/SellerBlock";
import ShowCategories from "../Components/homePage/showCategories";
import slide1 from "../images/homepage/slide1.png";
import slide2 from "../images/homepage/slide2.png";

const Discover = () => {
  const [categories, setCategories] = useState([]);
  const [stores, setStores] = useState([]);
  const { width } = useWindowDimension();
  const [viewStories, setViewStories] = useState([]);
  const [viewedStories, setViewedStories] = useState([]);
  const [categoryStores1, setCategoryStores1] = useState();
  const [categoryStores2, setCategoryStores2] = useState();
  const [categoryStores3, setCategoryStores3] = useState();

  async function getStores() {
    await getAllStores().then((data, err) => {
      if (data.status_code === 200) {
        setStores(data.data);
      } else {
        console.log(err, "error");
      }
    });
  }

  async function getRandomStores() {
    await getRandomCategoryStoreForHomepage().then((data, err) => {
      console.log(data, "data stores");
      if (data.status_code === 200) {
        setCategoryStores1(data.data[0]);
        setCategoryStores2(data.data[1]);
        setCategoryStores3(data.data[2]);
      } else {
        toast.error("Error fetching stores at the moment");
      }
    });
  }

  useEffect(() => {
    getRandomStores();
  }, []);

  async function getCategories() {
    await getAllCategories().then((data, err) => {
      if (data?.status_code === 200) {
        setCategories(data.data);
      } else {
        console.log(err, "error");
      }
    });
  }
  // useEffect(() => {
  //   getCategories();
  //   getStores();
  // }, []);

  // const stories = Array(20).fill({
  //   story: {
  //     _id: {
  //       store_id: {
  //         storeLogo: { imgURL: storelogo },
  //       },
  //     },
  //   },
  // });

  async function getStories() {
    try {
      const res = await fetch(`${APIURL}/story/get-stories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("macha-user"))?.token,
        },
      });
      const response = await res.json();
      if (response.status_code === 200) {
        setViewStories(response.data);
      } else {
        console.log(response);
        toast.error("Error fetching stories at the moment");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  }

  async function getViewedStories() {
    if (localStorage.getItem("macha-user")) {
      try {
        const res = await fetch(`${APIURL}/story/get-viewed-stories`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("macha-user"))
              ?.token,
          },
        });
        const response = await res.json();
        if (response.status_code === 200) {
          setViewedStories(response.data);
        } else {
          console.log(response);
          toast.error("Error fetching stories at the moment");
        }
      } catch (error) {
        toast.error("Something went wrong");
      }
    } else {
      return;
    }
  }

  async function setUp() {
    await getStories();
    await getCategories();
    await getStores();
    await getViewedStories();
  }

  useEffect(() => {
    setUp();
  }, []);

  return (
    <div>
      <MachaHeader />
      <StoriesSlider stories={viewStories} viewedStories={viewedStories} />
      <div className=" md:px-10 xl:px-20 bg-gray-100 pb-12">
        <div className=" max-xl:flex-col-reverse py-4 flex   justify-center h-max">
          <div className="w-full xl:w-1/3  flex flex-nowrap max-xl:hidden xl:flex-col xl:space-y-3 mr-10">
            {categories &&
              categories.map((category) => {
                return <ShowCategories category={category} />;
              })}
          </div>
          {/* <div className="xl:hidden w-full pt-3 show-cat">
            <Carousel
              wrapAround={true}
              slidesToShow={window.innerWidth <= 742 ? 2 : 3}
              renderCenterLeftControls={({ previousSlide }) => (
                <button
                  onClick={previousSlide}
                  className=" drop-shadow-xl text-[30px] max-sm:text-[15px] max-sm:w-6 max-sm:h-6 w-12 h-12 bg-white ml-3   flex jusitfy-center items-center rounded-full hover:bg-gray-900 hover:text-white focus:outline-none"
                >
                  <p className=" flex jusitfy-center items-center mx-auto my-auto font-[700 ] ">
                    <span> {"<"}</span>
                  </p>
                </button>
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <button
                  onClick={nextSlide}
                  className="drop-shadow-xl max-sm:text-[15px] max-sm:w-6 max-sm:h-6 text-[30px] w-12 h-12 bg-white ml-3   flex jusitfy-center items-center rounded-full hover:bg-gray-900 hover:text-white focus:outline-none"
                >
                  <p className=" flex jusitfy-center items-center mx-auto my-auto font-[700 ] ">
                    <span> {">"}</span>
                  </p>
                </button>
              )}
              pageDots={false}
            >
              {categories &&
                categories.map((category) => {
                  return <ShowCategories category={category} />;
                })}
            </Carousel>
          </div> */}
          <div className="w-full xl:w-2/3 ">
            <div className="w-full shadow-sm">
              <Carousel
                renderCenterLeftControls={() => <div></div>}
                renderCenterRightControls={() => <div></div>}
                pageDots={false}
              >
                <img alt="" src={slide1} className="w-full" />
                <img alt="" src={slide2} className="w-full" />
              </Carousel>
            </div>
          </div>
        </div>
        <div className="px-6 md:px-0">
          <div className="flex justify-between bg-white items-center">
            <div className="py-6  px-6 rounded-t-lg text-heading text-lg md:text-xl lg:text-2xl 2xl:text-2xl xl:leading-10 font-[600]">
              Recently Added Stores
            </div>
            <a href="/shop">
              <button className="px-3 font-[400]">View All</button>
            </a>
          </div>

          <div className="seller-carousal">
            <Carousel
              wrapAround={true}
              cellSpacing={15}
              slidesToShow={width > 768 ? 6 : width > 632 ? 3 : 2}
              renderCenterLeftControls={({ previousSlide }) => (
                <button
                  onClick={previousSlide}
                  className="max-sm:text-[15px] max-sm:w-6 max-sm:h-6  text-[22px] w-8 h-8     flex jusitfy-center items-center rounded-full  hover:text-[#454598] focus:outline-none"
                >
                  <p className=" flex jusitfy-center  items-center mx-auto my-auto hover:font-[1000] ">
                    <span className=""> {"<"}</span>
                  </p>
                </button>
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <button
                  onClick={nextSlide}
                  className="max-sm:text-[15px] max-sm:w-6 max-sm:h-6  text-[22px] w-8 h-8     flex jusitfy-center items-center rounded-full  hover:text-[#454598] focus:outline-none"
                >
                  <p className=" flex jusitfy-center items-center mx-auto my-auto hover:font-[1000] ">
                    <span> {">"}</span>
                  </p>
                </button>
              )}
            >
              {stores?.length > 0 &&
                stores?.map((store, index) => {
                  return <SellerBlock store={store} key={index} />;
                })}
            </Carousel>
          </div>
          <div className="pt-6 mt-4  px-6  rounded-t-lg  bg-white text-heading text-lg md:text-xl lg:text-2xl 2xl:text-2xl xl:leading-10 font-[600]">
            {categoryStores1?._id?.categoryName}
          </div>
          <div className="seller-carousal py-6">
            <Carousel
              slidesToShow={width > 768 ? 6 : width > 632 ? 3 : 2}
              renderCenterLeftControls={({ previousSlide }) => (
                <button
                  onClick={previousSlide}
                  className="max-sm:text-[15px] max-sm:w-6 max-sm:h-6  text-[22px] w-8 h-8     flex jusitfy-center items-center rounded-full  hover:text-[#454598] focus:outline-none"
                >
                  <p className=" flex jusitfy-center  items-center mx-auto my-auto hover:font-[1000] ">
                    <span className=""> {"<"}</span>
                  </p>
                </button>
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <button
                  onClick={nextSlide}
                  className="max-sm:text-[15px] max-sm:w-6 max-sm:h-6  text-[22px] w-8 h-8     flex jusitfy-center items-center rounded-full  hover:text-[#454598] focus:outline-none"
                >
                  <p className=" flex jusitfy-center items-center mx-auto my-auto hover:font-[1000] ">
                    <span> {">"}</span>
                  </p>
                </button>
              )}
            >
              {categoryStores1 &&
                categoryStores1?.stores.map((i, index) => (
                  <SellerBlock store={i} key={index} />
                ))}
            </Carousel>
          </div>
          <div className="pt-6 mt-4  px-6  rounded-t-lg  bg-white text-heading text-lg md:text-xl lg:text-2xl 2xl:text-2xl xl:leading-10 font-[600]">
            {categoryStores2?._id?.categoryName}
          </div>
          <div className="seller-carousal py-6">
            <Carousel
              slidesToShow={width > 768 ? 6 : width > 632 ? 3 : 2}
              renderCenterLeftControls={({ previousSlide }) => (
                <button
                  onClick={previousSlide}
                  className="max-sm:text-[15px] max-sm:w-6 max-sm:h-6  text-[22px] w-8 h-8     flex jusitfy-center items-center rounded-full  hover:text-[#454598] focus:outline-none"
                >
                  <p className=" flex jusitfy-center  items-center mx-auto my-auto hover:font-[1000] ">
                    <span className=""> {"<"}</span>
                  </p>
                </button>
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <button
                  onClick={nextSlide}
                  className="max-sm:text-[15px] max-sm:w-6 max-sm:h-6  text-[22px] w-8 h-8     flex jusitfy-center items-center rounded-full  hover:text-[#454598] focus:outline-none"
                >
                  <p className=" flex jusitfy-center items-center mx-auto my-auto hover:font-[1000] ">
                    <span> {">"}</span>
                  </p>
                </button>
              )}
            >
              {categoryStores2 &&
                categoryStores2?.stores.map((i, index) => (
                  <SellerBlock store={i} key={index} />
                ))}
            </Carousel>
          </div>
          <div className="pt-6 mt-4  px-6  rounded-t-lg  bg-white text-heading text-lg md:text-xl lg:text-2xl 2xl:text-2xl xl:leading-10 font-[600]">
            {categoryStores3?._id?.categoryName}
          </div>
          <div className="seller-carousal py-6">
            <Carousel
              slidesToShow={width > 768 ? 6 : width > 632 ? 3 : 2}
              renderCenterLeftControls={({ previousSlide }) => (
                <button
                  onClick={previousSlide}
                  className="max-sm:text-[15px] max-sm:w-6 max-sm:h-6  text-[22px] w-8 h-8     flex jusitfy-center items-center rounded-full  hover:text-[#454598] focus:outline-none"
                >
                  <p className=" flex jusitfy-center  items-center mx-auto my-auto hover:font-[1000] ">
                    <span className=""> {"<"}</span>
                  </p>
                </button>
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <button
                  onClick={nextSlide}
                  className="max-sm:text-[15px] max-sm:w-6 max-sm:h-6  text-[22px] w-8 h-8     flex jusitfy-center items-center rounded-full  hover:text-[#454598] focus:outline-none"
                >
                  <p className=" flex jusitfy-center items-center mx-auto my-auto hover:font-[1000] ">
                    <span> {">"}</span>
                  </p>
                </button>
              )}
            >
              {categoryStores3 &&
                categoryStores3?.stores.map((i, index) => (
                  <SellerBlock store={i} key={index} />
                ))}
            </Carousel>
          </div>
        </div>

        {/* <div className="w-2/4 max-xl:w-full h-full flex-grow xl:hidden">
          <img
            src=" https://chawkbazar-laravel-shop.vercel.app/_next/image?url=%2Fassets%2Fimages%2Fbanner%2Fbanner-sale-offer-half.jpg&w=1920&q=100"
            alt=""
            className="w-full h-full"
          />
        </div> */}
        {/* <div className="pt-12  text-heading text-lg md:text-xl lg:text-2xl 2xl:text-3xl xl:leading-10 font-bold">
          All stores
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 sm:grid-cols-3 xl:grid-cols-8  gap-2">
          {newstores.map((store, i) => (
            <SellerBlock store={store} />
          ))}
        </div> */}
      </div>
      <Footer />
    </div>
  );
};

export default Discover;
