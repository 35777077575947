import React from "react";

const AddAddress = ({
  name,
  setName,
  pin,
  setPin,
  address,
  setAddress,
  state,
  setState,
  city,
  setCity,
  addAddress,
  addtype,
  setAddType,
  phone,
  setPhone,
}) => {
  return (
    <div className="px-6 py-3 w-full">
      <div className="py-3 text-[20px] font-[600] text-black">Name</div>
      <div className="w-full">
        <input
          className="w-full shadow-lg border-none py-3 "
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="flex items-center py-3">
        <div className="w-full pl-3">
          <div className="py-3 text-[20px] font-[600] text-black">State</div>
          <input
            className="w-full shadow-lg border-none py-3 "
            value={state}
            onChange={(e) => setState(e.target.value)}
          ></input>
        </div>
      </div>
      <div className="flex items-center py-3">
        <div className="w-1/2 pr-3">
          <div className="py-3 text-[20px] font-[600] text-black">City</div>
          <input
            className="w-full shadow-lg border-none py-3 "
            value={city}
            onChange={(e) => setCity(e.target.value)}
          ></input>
        </div>
        <div className="w-1/2 pl-3">
          <div className="py-3 text-[20px] font-[600] text-black">Pin Code</div>
          <input
            className="w-full shadow-lg border-none py-3 "
            value={pin}
            onChange={(e) => setPin(e.target.value)}
          ></input>
        </div>
      </div>
      <div className="flex items-center py-3">
        <div className="w-1/2 pr-3">
          <div className="py-3 text-[20px] font-[600] text-black">
            Address Type
          </div>
          <div className="flex items-center justify-between px-3">
            <div className="flex items-center">
              <input
                className="w-full shadow-lg border-none py-3 "
                type="radio"
                value="home"
                checked={addtype === "home"}
                onClick={() => setAddType("home")}
              ></input>
              <div className="text-[20px] font-[600] text-black">Home</div>
            </div>
            <div className="flex items-center">
              <input
                className="w-full shadow-lg border-none py-3 "
                type="radio"
                value="office"
                checked={addtype === "office"}
                onClick={() => setAddType("office")}
              ></input>
              <div className="text-[20px] font-[600] text-black">Office</div>
            </div>
          </div>
        </div>
        <div className="w-1/2 pl-3">
          <div className="py-3 text-[20px] font-[600] text-black">
            PhoneNumber
          </div>
          <input
            className="w-full shadow-lg border-none py-3 "
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          ></input>
        </div>
      </div>
      <div className="py-3 text-[20px] font-[600]">Street Address</div>
      <textarea
        rows={5}
        className="w-full shadow-lg border-none  "
        value={address}
        onChange={(e) => setAddress(e.target.value)}
      ></textarea>
      <button
        className="bg-black text-[20px] text-white text-center py-3 my-6 w-full rounded-[10px]"
        onClick={addAddress}
      >
        Save Address
      </button>
    </div>
  );
};

export default AddAddress;
