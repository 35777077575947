import React from "react";

const StoriesSlider = ({ stories, viewedStories }) => {
  return (
    <div className="flex bg-gray-100 border-b-2 border-white items-center space-x-8 px-4  max-w-full relative overflow-x-scroll noscrollbar w-[100%] mx-auto">
      {stories.map((story) => {
        return (
          <a
            href={`/stories/?storeId=${story?._id?.store_id?._id}`}
            className="rounded-full bg-white overflow-hidden hover:shadow-xl cursor-pointer xl:min-w-[3rem] md:min-w-[4rem] min-w-[3rem] xl:min-h-[3rem] my-3 md:min-h-[4rem] min-h-[3rem] flex items-center justify-center"
          >
            <img
              className="xl:h-16 xl:w-16 md:h-12 md:w-12 w-12 h-12 rounded-full  "
              alt=""
              src={story?._id?.store_id?.storeLogo}
            />
          </a>
        );
      })}
      {viewedStories.map((story) => {
        return (
          <a
            href={`/stories/?storeId=${story?._id?.store_id?._id}`}
            className="rounded-full bg-white overflow-hidden hover:shadow-xl cursor-pointer xl:min-w-[7rem] md:min-w-[6rem] min-w-[5rem] xl:min-h-[7rem] md:min-h-[6rem] min-h-[5rem] flex items-center justify-center"
          >
            <img
              className="xl:h-16 xl:w-16 md:h-12 md:w-12 w-8 h-8 opacity-30 "
              alt=""
              src={story?._id?.store_id?.storeLogo}
            />
          </a>
        );
      })}
    </div>
  );
};

export default StoriesSlider;
