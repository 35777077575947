import React, { useEffect } from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { BsPersonCheckFill, BsDashLg } from "react-icons/bs";
import { MdManageAccounts } from "react-icons/md";
import { GoDashboard } from "react-icons/go";
import { BiStore } from "react-icons/bi";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import { GiSpiderWeb } from "react-icons/gi";

const Option = ({ chosenMenu, value, option, setChosenMenu, icon }) => {
  async function changeLocation(value) {
    await localStorage.setItem("gotovalue", value);
    window.location.assign("/");
  }

  return (
    <div className="py-3 px-3 ">
      <button
        onClick={() =>
          setChosenMenu ? setChosenMenu(value) : changeLocation(value)
        }
        className={
          "flex text-left items-center justify-between space-x-1  hover:text-blue-500 " +
          (value === chosenMenu ? "text-blue-600" : "text-gray-600")
        }
      >
        {icon}
        <p>{option}</p>
      </button>
    </div>
  );
};

const Layout = ({ children, chosenMenu, setChosenMenu }) => {
  useEffect(() => {
    let gotovalue = localStorage.getItem("gotovalue");
    if (gotovalue) {
      setChosenMenu(gotovalue);
      localStorage.removeItem("gotovalue");
    }
  }, []);

  return (
    <div className="flex flex-col h-screen">
      <div className="w-full h-full grow bg-gray-100 flex divide-x-2 divide-gray-300 overflow-y-hidden">
        <div className="w-2/12 h-full bg-white flex flex-col overflow-y-auto">
          <Option
            chosenMenu={chosenMenu}
            icon={<GoDashboard className="text-2xl" />}
            setChosenMenu={setChosenMenu}
            value="dashboard"
            option={"Dashboard"}
          />

          <Option
            chosenMenu={chosenMenu}
            icon={<GiSpiderWeb className="text-2xl" />}
            setChosenMenu={setChosenMenu}
            value="createweb"
            option={"Website Builder"}
          />
          <Option
            chosenMenu={chosenMenu}
            icon={<GiSpiderWeb className="text-2xl" />}
            setChosenMenu={setChosenMenu}
            value="updateweb"
            option={"Update Website"}
          />

          <label className="ml-3 mt-3 mb-3 font-bold font-mono">
            STORES & PRODUCTS
          </label>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button
                  className={
                    "py-2 px-3 w-full text-left flex justify-between items-center " +
                    (open && "bg-purple-100")
                  }
                >
                  <div className="flex items-center">
                    <BiStore className="mr-1 text-2xl" />
                    <span>Stores</span>
                  </div>
                  {open ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel static className="text-gray-500">
                    <Option
                      chosenMenu={chosenMenu}
                      icon={<BsDashLg className="text-xs ml-4" />}
                      setChosenMenu={setChosenMenu}
                      value="managestores"
                      option={"Stores"}
                    />
                    <Option
                      chosenMenu={chosenMenu}
                      icon={<BsDashLg className="text-xs ml-4" />}
                      setChosenMenu={setChosenMenu}
                      value="storesrequest"
                      option={"Store Requests"}
                    />
                    {/* <Option
                      chosenMenu={chosenMenu}
                      icon={<BsDashLg className="text-xs ml-4" />}
                      setChosenMenu={setChosenMenu}
                      value="storeshomepage"
                      option={"Stores Homepage"}
                    /> */}
                    <Option
                      chosenMenu={chosenMenu}
                      icon={<BsDashLg className="text-xs ml-4" />}
                      setChosenMenu={setChosenMenu}
                      value="storesstory"
                      option={"Stores Story"}
                    />
                    {/* <Option
                      chosenMenu={chosenMenu}
                      icon={<BsDashLg className="text-xs ml-4" />}
                      setChosenMenu={setChosenMenu}
                      value="addteammembers"
                      option={"Add Team Members"}
                    /> */}
                    {/* <Option
                      chosenMenu={chosenMenu}
                      icon={<BsDashLg className="text-xs ml-4" />}
                      setChosenMenu={setChosenMenu}
                      value="addsupports"
                      option={"Add Supports"}
                    /> */}
                    {/* <Option
                      chosenMenu={chosenMenu}
                      icon={<BsDashLg className="text-xs ml-4" />}
                      setChosenMenu={setChosenMenu}
                      value="storesrequest"
                      option={"Stores Request"}
                    /> */}
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>

          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button
                  className={
                    "py-2 px-3 w-full text-left flex justify-between items-center " +
                    (open && "bg-purple-100")
                  }
                >
                  <div className="flex items-center">
                    <MdManageAccounts className="mr-1 text-2xl" />
                    <span>Products Management</span>
                  </div>
                  {open ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel static className="text-gray-500">
                    <Option
                      chosenMenu={chosenMenu}
                      icon={<BsDashLg className="text-xs ml-4" />}
                      setChosenMenu={setChosenMenu}
                      value="simpleproducts"
                      option={"Products"}
                    />
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
          <label className="ml-3 mt-3 mb-3 font-bold font-mono">Orders</label>
          <Option
            chosenMenu={chosenMenu}
            icon={<GiSpiderWeb className="text-2xl" />}
            setChosenMenu={setChosenMenu}
            value="sellerorders"
            option={"Order Management"}
          />
          {/* <label className="ml-3 mt-3 mb-3 font-bold font-mono">
            FINANCIAL
          </label>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button
                  className={
                    "py-2 px-3 w-full text-left flex justify-between items-center " +
                    (open && "bg-purple-100")
                  }
                >
                  <div className="flex items-center">
                    <BiPackage className="mr-1 text-2xl" />
                    <span>Orders & Invoices</span>
                  </div>
                  {open ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel static className="text-gray-500">
                    <Option
                      chosenMenu={chosenMenu}
                      icon={<BsDashLg className="text-xs ml-4" />}
                      setChosenMenu={setChosenMenu}
                      value="orders"
                      option={"Orders"}
                    />
                    <Option
                      chosenMenu={chosenMenu}
                      icon={<BsDashLg className="text-xs ml-4" />}
                      setChosenMenu={setChosenMenu}
                      value="pendingorders"
                      option={"Pending Orders"}
                    />
                    <Option
                      chosenMenu={chosenMenu}
                      icon={<BsDashLg className="text-xs ml-4" />}
                      setChosenMenu={setChosenMenu}
                      value="cancelledorders"
                      option={"Cancelled Orders"}
                    />
                    <Option
                      chosenMenu={chosenMenu}
                      icon={<BsDashLg className="text-xs ml-4" />}
                      setChosenMenu={setChosenMenu}
                      value="returnedorders"
                      option={"Returned Orders"}
                    />
                    <Option
                      chosenMenu={chosenMenu}
                      icon={<BsDashLg className="text-xs ml-4" />}
                      setChosenMenu={setChosenMenu}
                      value=""
                      option={"Invoice Settings"}
                    />
                    <Option
                      chosenMenu={chosenMenu}
                      icon={<BsDashLg className="text-xs ml-4" />}
                      setChosenMenu={setChosenMenu}
                      value=""
                      option={"Invoice Designs"}
                    />
                    <Option
                      chosenMenu={chosenMenu}
                      icon={<BsDashLg className="text-xs ml-4" />}
                      setChosenMenu={setChosenMenu}
                      value=""
                      option={"Return Reasons"}
                    />
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure> */}
        </div>
        <div className="w-10/12 px-8 py-10 overflow-y-scroll flex flex-col space-y-4 relative ">
          {children}
          <div className="w-full flex items-center justify-center text-gray-500 bg-white py-2 ">
            © 2023 | RIGHTS BY MACHAPOINT
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
