import React from "react";
import { BiRupee } from "react-icons/bi";

const Product = ({ item }) => {
  console.log(item);
  return (
    <a
      href={`/product/${item?.product_id?.productName?.replace(/\s+/g, "-")}/${
        item?.product_id?._id
      }`}
      target={"_blank"}
      rel="noreferrer"
    >
      <div className="flex items-center justify-center py-6 px-6">
        <div className="w-[40%] flex justify-center">
          <img
            src={item?.product_id?.productImageUrls[0]?.imgURL}
            alt=""
            className="rounded-[10px] h-[100px] "
          />
        </div>
        <div className="w-[40%] text-[15px] font-[300] text-center">
          <div className="pb-2">
            {item?.product_id?.productName.substring(0, 40)}..
          </div>
          <div className="font-[500]">
            {item?.product_id?.cod === true
              ? "Cod Available"
              : "Cod Not Available"}
          </div>

          <div className="font-[500]">{item?.quantity} pc</div>
        </div>
        <div className="w-[20%] text-[16px] font-[700] text-center">
          <div className="flex items-center pl-1">
            <BiRupee /> {item?.product_id?.offerPrice * item?.quantity}
          </div>
        </div>
      </div>
    </a>
  );
};

export default Product;
