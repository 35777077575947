import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { APIURL } from "../../backend";
import Footer from "../../Components/WebsiteView/SellerPage/Footer";
import OrganisationHeader from "../../Components/Base/OrganisationHeader";
import SellerBlock from "../../Components/SellerBlock";
import StickyIcons from "../../Components/SellerPage/StickyIcons";
import StoriesSlider from "../../Components/StoriesSlider";
import { getOrganisation } from "../../Helper/organisation";
import {
  getAllStores,
  getStoresByOrganisation,
  getStoresofOrganisation,
} from "../../Helper/store";
import { getStoriesByOrganisation } from "../../Helper/story";
import useWindowDimensions from "../../hooks/useWindowDimension";
import smallicon from "../../images/smallicon.png";
import Carousel from "nuka-carousel/lib/carousel";

const OrganisationPage = () => {
  const { organisation_id } = useParams();
  const [template, setTemplate] = useState({});
  const [loading, setLoading] = useState(true);
  const [stores, setStores] = useState([]);
  const [stories, setStories] = useState([]);
  const [viewStories, setViewStories] = useState([]);
  const [viewedStories, setViewedStories] = useState([]);
  const [categoryStores1, setCategoryStores1] = useState();
  const [categoryStores2, setCategoryStores2] = useState();
  const [categoryStores3, setCategoryStores3] = useState();

  async function fetchOrganisation() {
    setLoading(true);
    const res = await getOrganisation(organisation_id);

    if (res.status_code === 200) {
      setTemplate(res.data);
      setLoading(false);
    } else {
      toast.error("Something went wrong");
      // setTimeout(() => {
      //   router("/");
      // }, 2000);
    }
  }

  async function getStoresByOrganisationHandler() {
    const res = await getStoresByOrganisation(organisation_id);

    if (res.status_code === 200) {
      // setStores(res.data);
      setCategoryStores1(res.data[0]);
      setCategoryStores2(res.data[1]);
      setCategoryStores3(res.data[2]);
    } else {
      toast.error("Error fetching stores");
    }
  }

  async function fetchStories() {
    const response = await getStoriesByOrganisation(organisation_id);
    if (response.status_code === 200) {
      setStories(response.data);
    } else {
      toast.error("Error fetching stories at the moment");
    }
  }

  async function getStories() {
    try {
      const res = await fetch(`${APIURL}/story/get-stories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("macha-user"))?.token,
        },
      });
      const response = await res.json();
      if (response.status_code === 200) {
        setViewStories(response.data);
      } else {
        toast.error("Error fetching stories at the moment");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  }

  async function getViewedStories() {
    if (localStorage.getItem("macha-user")) {
      try {
        const res = await fetch(`${APIURL}/story/get-viewed-stories`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("macha-user"))
              ?.token,
          },
        });
        const response = await res.json();
        if (response.status_code === 200) {
          setViewedStories(response.data);
        } else {
          toast.error("Error fetching stories at the moment");
        }
      } catch (error) {
        toast.error("Something went wrong");
      }
    } else {
      return;
    }
  }

  async function getStores(id) {
    await getStoresofOrganisation(id).then((data, err) => {
      if (data.status_code === 200) {
        setStores(data.data);
      } else {
        toast.error("Error fetching stores");
      }
    });
  }

  useEffect(() => {
    if (organisation_id) {
      getStores(organisation_id);
    }
  }, []);

  async function setUp2() {
    await getStories();

    await getViewedStories();
  }
  const { width } = useWindowDimensions();

  useEffect(() => {
    setUp2();
  }, []);

  async function setUp() {
    fetchOrganisation();
    fetchStories();
    getStoresByOrganisationHandler();
  }

  useEffect(() => {
    setUp();
  }, []);

  return (
    <>
      <div className="w-full max-w-screen relative   ">
        <OrganisationHeader
          organisationId={organisation_id}
          page={"home"}
          logo={template !== null && template?.organisation_logo}
        />
        <StoriesSlider stories={viewStories} viewedStories={viewedStories} />

        <StickyIcons icon={smallicon} />

        <div className="lg:px-10 px-6 xl:px-20 bg-gray-100 pt-4 xl:pb-20 lg:pb-10 pb-6">
          <div className="py-6 bg-white px-6 rounded-t-lg text-heading text-lg md:text-xl lg:text-2xl 2xl:text-2xl xl:leading-10 font-[600]">
            Recently Added Stores
          </div>
          <div className="seller-carousal">
            <Carousel
              wrapAround={true}
              cellSpacing={15}
              slidesToShow={width > 768 ? 6 : width > 632 ? 3 : 2}
              renderCenterLeftControls={({ previousSlide }) => (
                <button
                  onClick={previousSlide}
                  className="max-sm:text-[15px] max-sm:w-6 max-sm:h-6  text-[22px] w-8 h-8     flex jusitfy-center items-center rounded-full  hover:text-[#454598] focus:outline-none"
                >
                  <p className=" flex jusitfy-center  items-center mx-auto my-auto hover:font-[1000] ">
                    <span className=""> {"<"}</span>
                  </p>
                </button>
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <button
                  onClick={nextSlide}
                  className="max-sm:text-[15px] max-sm:w-6 max-sm:h-6  text-[22px] w-8 h-8     flex jusitfy-center items-center rounded-full  hover:text-[#454598] focus:outline-none"
                >
                  <p className=" flex jusitfy-center items-center mx-auto my-auto hover:font-[1000] ">
                    <span> {">"}</span>
                  </p>
                </button>
              )}
            >
              {stores?.length > 0 &&
                stores?.map((store, index) => {
                  return <SellerBlock store={store} key={index} />;
                })}
            </Carousel>
          </div>

          <div className="pt-6 mt-4  px-6  rounded-t-lg  bg-white text-heading text-lg md:text-xl lg:text-2xl 2xl:text-2xl xl:leading-10 font-[600]">
            {categoryStores1?._id?.categoryName}
          </div>
          <div className="seller-carousal py-6">
            <Carousel
              slidesToShow={width > 768 ? 6 : width > 632 ? 3 : 2}
              renderCenterLeftControls={({ previousSlide }) => (
                <button
                  onClick={previousSlide}
                  className="max-sm:text-[15px] max-sm:w-6 max-sm:h-6  text-[22px] w-8 h-8     flex jusitfy-center items-center rounded-full  hover:text-[#454598] focus:outline-none"
                >
                  <p className=" flex jusitfy-center  items-center mx-auto my-auto hover:font-[1000] ">
                    <span className=""> {"<"}</span>
                  </p>
                </button>
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <button
                  onClick={nextSlide}
                  className="max-sm:text-[15px] max-sm:w-6 max-sm:h-6  text-[22px] w-8 h-8     flex jusitfy-center items-center rounded-full  hover:text-[#454598] focus:outline-none"
                >
                  <p className=" flex jusitfy-center items-center mx-auto my-auto hover:font-[1000] ">
                    <span> {">"}</span>
                  </p>
                </button>
              )}
            >
              {categoryStores1 &&
                categoryStores1?.stores.map((i, index) => (
                  <SellerBlock store={i} key={index} />
                ))}
            </Carousel>
          </div>
          <div className="pt-6 mt-4  px-6  rounded-t-lg  bg-white text-heading text-lg md:text-xl lg:text-2xl 2xl:text-2xl xl:leading-10 font-[600]">
            {categoryStores2?._id?.categoryName}
          </div>
          <div className="seller-carousal py-6">
            <Carousel
              slidesToShow={width > 768 ? 6 : width > 632 ? 3 : 2}
              renderCenterLeftControls={({ previousSlide }) => (
                <button
                  onClick={previousSlide}
                  className="max-sm:text-[15px] max-sm:w-6 max-sm:h-6  text-[22px] w-8 h-8     flex jusitfy-center items-center rounded-full  hover:text-[#454598] focus:outline-none"
                >
                  <p className=" flex jusitfy-center  items-center mx-auto my-auto hover:font-[1000] ">
                    <span className=""> {"<"}</span>
                  </p>
                </button>
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <button
                  onClick={nextSlide}
                  className="max-sm:text-[15px] max-sm:w-6 max-sm:h-6  text-[22px] w-8 h-8     flex jusitfy-center items-center rounded-full  hover:text-[#454598] focus:outline-none"
                >
                  <p className=" flex jusitfy-center items-center mx-auto my-auto hover:font-[1000] ">
                    <span> {">"}</span>
                  </p>
                </button>
              )}
            >
              {categoryStores2 &&
                categoryStores2?.stores.map((i, index) => (
                  <SellerBlock store={i} key={index} />
                ))}
            </Carousel>
          </div>
          <div className="pt-6 mt-4  px-6  rounded-t-lg  bg-white text-heading text-lg md:text-xl lg:text-2xl 2xl:text-2xl xl:leading-10 font-[600]">
            {categoryStores3?._id?.categoryName}
          </div>
          <div className="seller-carousal py-6">
            <Carousel
              slidesToShow={width > 768 ? 6 : width > 632 ? 3 : 2}
              renderCenterLeftControls={({ previousSlide }) => (
                <button
                  onClick={previousSlide}
                  className="max-sm:text-[15px] max-sm:w-6 max-sm:h-6  text-[22px] w-8 h-8     flex jusitfy-center items-center rounded-full  hover:text-[#454598] focus:outline-none"
                >
                  <p className=" flex jusitfy-center  items-center mx-auto my-auto hover:font-[1000] ">
                    <span className=""> {"<"}</span>
                  </p>
                </button>
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <button
                  onClick={nextSlide}
                  className="max-sm:text-[15px] max-sm:w-6 max-sm:h-6  text-[22px] w-8 h-8     flex jusitfy-center items-center rounded-full  hover:text-[#454598] focus:outline-none"
                >
                  <p className=" flex jusitfy-center items-center mx-auto my-auto hover:font-[1000] ">
                    <span> {">"}</span>
                  </p>
                </button>
              )}
            >
              {categoryStores3 &&
                categoryStores3?.stores.map((i, index) => (
                  <SellerBlock store={i} key={index} />
                ))}
            </Carousel>
          </div>
        </div>
      </div>
      <Footer
        email={template?.template?.footer?.footerData?.emailData}
        phone={template?.template?.footer?.footerData?.phoneNumberData}
        sellername={template?.organisation_name}
        insta={template?.template?.footer?.socialLinksData?.instagramData}
        facebooklink={template?.template?.footer?.socialLinksData?.facebookData}
      />
    </>
  );
};

export default OrganisationPage;
