import { APIURL } from "../backend";

export const getMyCart = () => {
  return fetch(`${APIURL}/cart/get-cart`, {
    method: "GET",
    headers: {
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getMyCartByStore = (id) => {
  return fetch(`${APIURL}/cart/get-cart-store/?store_id=${id}`, {
    method: "GET",
    headers: {
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getMyCartByOrganisation = (id) => {
  return fetch(`${APIURL}/cart/get-cart-org/?store_id=${id}`, {
    method: "GET",
    headers: {
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const addToCart = (data) => {
  return fetch(`${APIURL}/cart/add-to-cart`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",

      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      console.log(response.json);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const removeFromCart = (data) => {
  return fetch(`${APIURL}/cart/remove-from-cart`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",

      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      console.log(response.json);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const updateCartProductQuantity = (data) => {
  return fetch(`${APIURL}/cart/update-cart`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",

      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      console.log(response.json);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const initiateOrders = (data) => {
  return fetch(`${APIURL}/order/checkout`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",

      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      console.log(response.json);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const generateCheckoutDetails = (data) => {
  return fetch(`${APIURL}/order/generate-checkout`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",

      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      console.log(response.json);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const checkOrderPayment = (order_id) => {
  return fetch(
    `${APIURL}/order/check-order-payment-status/?order_id=${order_id}`,
    {
      method: "GET",
      headers: {
        Authorization: ` ${
          JSON.parse(localStorage.getItem("macha-user"))?.token
        }`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getMyOrders = () => {
  return fetch(`${APIURL}/order/get-my-orders`, {
    method: "GET",
    headers: {
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const generateInvoice = (order_id) => {
  return fetch(`${APIURL}/order/generate-invoice/?order_id=${order_id}`, {
    method: "GET",
    headers: {
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getInvoicePage = (order_id) => {
  return fetch(`${APIURL}/order/get-invoice-page/?order_id=${order_id}`, {
    method: "GET",
    headers: {
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
  })
    .then((response) => {
      console.log(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getOrdersForStore = (store_id) => {
  return fetch(`${APIURL}/order/get-orders-for-store/?store_id=${store_id}`, {
    method: "GET",
    headers: {
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getOrdersForOrganisation = (store_id) => {
  return fetch(
    `${APIURL}/order/get-orders-for-organisation/?org_id=${store_id}`,
    {
      method: "GET",
      headers: {
        Authorization: ` ${
          JSON.parse(localStorage.getItem("macha-user"))?.token
        }`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const changeOrderStatus = (data) => {
  return fetch(`${APIURL}/order/change-order-status`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",

      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      console.log(response.json);
      return response.json();
    })
    .catch((err) => console.log(err));
};
