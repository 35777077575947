import React, { useEffect, useState } from "react";
import { EditorState, convertFromRaw } from "draft-js";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";

const FirstInfoSection = ({ image, heading, subheading }) => {
  const [convertedContent, setConvertedContent] = useState(null);
  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  useEffect(() => {
    if (subheading !== null) {
      const blocks = subheading && subheading.blocks;

      const entityMap = {};

      const contentState = convertFromRaw({ blocks, entityMap });
      const editorState = EditorState.createWithContent(contentState);
      let html = convertToHTML(editorState.getCurrentContent());
      setConvertedContent(html);
    }
  }, [subheading]);

  console.log(convertedContent, "convertedContent");

  return (
    <div className="w-full flex-col md:flex-row flex items-center  md::h-[500px] my-20 px-4 md:px-28">
      <img
        className="min-h-[300px] md:h-full mr-10 w-4/5 mx-auto md:min-w-[30%] md:max-w-[40%] object-fill"
        src={image}
        alt=""
        crossOrigin="anonymous"
      />
      <div className="md:w-1/2 w-full   p-6 grow  text-center flex flex-col items-center justify-center space-y-6">
        {heading && (
          <h1 className="capitalize lg:text-3xl md:text-3xl text-2xl xl:text-4xl  font-bold text-black">
            {heading}
          </h1>
        )}

        <div
          className="p-6 "
          dangerouslySetInnerHTML={createMarkup(convertedContent)}
        ></div>
      </div>
    </div>
  );
};

export default FirstInfoSection;
