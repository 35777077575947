import React, { useState, useEffect } from "react";

import { Helmet } from "react-helmet";
import FirstInfo from "../Components/SellerAboutPage/FirstInfo";
import Intro from "../Components/SellerAboutPage/Intro";
import MainColumn from "../Components/SellerAboutPage/MainColumn";
import Team from "../Components/SellerAboutPage/Team";
import TypeWriterColumn from "../Components/SellerAboutPage/TypeWriterColumn";
import Footer from "../Components/SellerPage/Footer";
import Header from "../Components/SellerPage/Header";
import StickyIcons from "../Components/SellerPage/StickyIcons";
import smallicon from "../images/smallicon.png";
import tarang from "../images/tarang.png";
import { getStore } from "../Helper/store";
import { useParams } from "react-router-dom";

const SellerAboutPage = () => {
  const [template, setTemplate] = useState(null);

  const { id } = useParams();

  async function getStoreData() {
    if (id !== undefined) {
      await getStore(id)
        .then((data, err) => {
          if (data.status_code === 200) {
            console.log(`data`, data);
            setTemplate(data.data);
          } else {
            console.log(err);
          }
        })
        .catch((err) => console.log(err));
    }
  }

  useEffect(() => {
    getStoreData();
  }, []);

  const strings = template?.template?.aboutPage?.typeWriterData;

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>{template?.storeName}</title>
        <meta name="description" content={template?.storeDescription} />
      </Helmet>
      <Header
        storeName={template?.storeName}
        selected={
          template?.template !== null &&
          template?.template?.chosenColor !== null
            ? template?.template?.chosenColor
            : "#730dc9"
        }
        page={"story"}
        logo={template !== null && template.storeLogo}
        id={id !== undefined && id}
        template={template?.template}
      />
      <StickyIcons
        icon={smallicon}
        organisation={template?.organisation ? template?.organisation : null}
        orgicon={tarang}
      />
      {template && template.template.aboutPage.headingEnabled && (
        <TypeWriterColumn
          strings={strings}
          heading={template?.template?.aboutPage?.mainHeadingData}
        />
      )}
      {template && template.template.aboutPage.section1Enabled && (
        <MainColumn
          text={
            template && template?.template?.aboutPage?.section1Data?.sectionText
          }
          heading={
            template &&
            template?.template?.aboutPage?.section1Data?.sectionHeading?.text
          }
          backgroundImage={
            template &&
            template?.template?.aboutPage?.section1Data?.sectionImage?.imgURL
          }
        />
      )}
      {template?.template?.aboutPage?.headingData?.blocks[0]?.text && (
        <Intro
          selected={
            template?.template !== null &&
            template?.template?.chosenColor !== null
              ? template?.template?.chosenColor
              : "#730dc9"
          }
          introwords={template && template?.template?.aboutPage?.headingData}
        />
      )}
      {template && template.template.aboutPage.section2Enabled && (
        <FirstInfo
          infoImage={
            template &&
            template?.template?.aboutPage?.section2Data?.sectionImage?.imgURL
          }
          infoData={
            template && template?.template?.aboutPage?.section2Data?.sectionText
          }
          heading={
            template &&
            template?.template?.aboutPage?.section2Data?.sectionHeading?.text
          }
        />
      )}
      {template && template.template.aboutPage.teamEnabled && (
        <Team
          selected={
            template?.template !== null &&
            template?.template?.chosenColor !== null
              ? template?.template?.chosenColor
              : "#730dc9"
          }
          team={
            template && template?.template?.aboutPage?.teamData?.teamMembers
          }
          startupName={template?.storeName}
          teamData={
            template && template?.template?.aboutPage?.teamData?.teamHeading
          }
        />
      )}
      {template && template.template.footer.footerEnabled && (
        <Footer
          email={template?.template?.footer?.footerData?.emailData}
          enabled={template && template.template.footer.footerEnabled}
          phone={template?.template?.footer?.footerData?.phoneNumberData}
          orgname={template?.organisation !== null && "WeTarang"}
          sellername={template.storeName}
          insta={template?.template?.footer?.socialLinksData?.instagramData}
          facebooklink={
            template?.template?.footer?.socialLinksData?.facebookData
          }
        />
      )}
    </div>
  );
};

export default SellerAboutPage;
