import React, { useEffect, useState } from "react";
import { EditorState, convertFromRaw } from "draft-js";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import useWindowDimensions from "../../hooks/useWindowDimension";

const TeamMember = ({ name, position, image }) => {
  return (
    <div className="flex relative flex-col items-center justify-center mx-8 w-full">
      <img src={image?.imgURL} className="w-full object-fill" alt="" />
      <div className="w-full absolute bottom-0 left-0 bg-black bg-opacity-70 py-4 flex flex-col items-center justify-center space-y-1">
        <p className="text-white text-md font-semibold capitalize">{name}</p>
        <p className="text-gray-100 text-sm font-thin capitalize">{position}</p>
      </div>
    </div>
  );
};

const Team = ({ team, startupName, teamData, selected = "blue-500" }) => {
  const { width } = useWindowDimensions();

  const [convertedContent, setConvertedContent] = useState(null);
  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  useEffect(() => {
    if (teamData !== null) {
      const blocks = teamData && teamData.blocks;

      const entityMap = {};

      const contentState = convertFromRaw({ blocks, entityMap });
      const editorState = EditorState.createWithContent(contentState);
      let html = convertToHTML(editorState.getCurrentContent());
      setConvertedContent(html);
    }
  }, [teamData]);

  return (
    <div className={`w-full   bg-${selected} py-16 mt-24`}>
      <div className="flex w-4/5 mx-auto flex-col xl:space-y-9 lg:space-y-8 md:space-y-6 space-y-4 text-white">
        <p className="text-center lg:text-3xl md:text-3xl text-2xl xl:text-4xl font-bold ">
          Team behind {startupName}
        </p>

        <div className="text-center pb-3 ">
          <div
            className="p-6"
            dangerouslySetInnerHTML={createMarkup(convertedContent)}
          ></div>
        </div>

        {team && width > 640 && team.length < 4 ? (
          <div className="flex  items-center justify-center w-full mx-auto xl:space-x-12 lg:space-x-10 md:space-x-8 space-x-4">
            {team.map((member, index) => {
              console.log(`member`, member);
              return (
                <div className="lg:max-w-[25%] md:max-w-[35%] max-w-[50%]">
                  <TeamMember
                    key={index}
                    name={member.name}
                    // position={member.position}
                    image={member.image}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <Swiper
            spaceBetween={width > 640 ? 50 : 0}
            slidesPerView={width > 640 ? 3 : 1}
            modules={[Autoplay]}
            className="w-full flex items-center justify-center w-full mx-auto"
            loop={true}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
          >
            {team &&
              team.map((member, index) => {
                return (
                  <SwiperSlide className="w-full h-full px-4" key={index}>
                    <TeamMember
                      key={index}
                      name={member.name}
                      position={member.position}
                      image={member.image}
                    />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default Team;
