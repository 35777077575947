import React from "react";
import { BsReverseBackspaceReverse } from "react-icons/bs";
const ManageHeader = ({
  heading,
  setProductUpdate,
  display,
  productUpdate,
}) => {
  return (
    <div className="flex items-center justify-between w-full bg-white sm:px-6 sm:py-6 py-3 rounded-md">
      <div className="flex items-center">
        <p className="text-lg font-semibold text-gray-800">{heading}</p>
        {display === "Manage" && productUpdate !== "" && (
          <BsReverseBackspaceReverse
            className="text-[25px] ml-4"
            onClick={() => setProductUpdate(null)}
          />
        )}
      </div>

      {/* <div className="w-1/2 relative">
        <input
          placeholder="Type your query and press enter"
          className="px-3 py-3 font-light border rounded-md w-full text-sm"
        />
      </div> */}
    </div>
  );
};

export default ManageHeader;
