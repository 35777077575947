import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import OrganisationHeader from "../../Components/Base/OrganisationHeader";
import FirstInfo from "../../Components/OrganisationAboutPage/FirstInfo";
import Intro from "../../Components/OrganisationAboutPage/Intro";
import MainColumn from "../../Components/OrganisationAboutPage/MainColumn";
import Team from "../../Components/OrganisationAboutPage/Team";
import TypeWriterColumn from "../../Components/OrganisationAboutPage/TypeWriterColumn";
import Footer from "../../Components/WebsiteView/SellerPage/Footer";
import StickyIcons from "../../Components/SellerPage/StickyIcons";
import { getOrganisation } from "../../Helper/organisation";
import smallicon from "../../images/smallicon.png";

const OrganisationAboutPage = ({
  founderData,
  createMarkup,
  convertedContent,
  convertedContent2,
  convertedContent3,
  convertedContent4,
  convertedContent5,
  contentImage,
  contentImage2,
  typeWriterData,
}) => {
  const { organisation_id } = useParams();
  const [template, setTemplate] = useState({});
  const [loading, setLoading] = useState(true);

  async function fetchOrganisation() {
    setLoading(true);
    const res = await getOrganisation(organisation_id);
    console.log(res);
    if (res.status_code === 200) {
      setTemplate(res.data);
      setLoading(false);
    } else {
      toast.error("Something went wrong");
      // setTimeout(() => {
      //   router("/");
      // }, 2000);
    }
  }

  useEffect(() => {
    fetchOrganisation();
  }, []);

  console.log(template);

  return (
    !loading && (
      <div className="flex flex-col">
        <OrganisationHeader
          organisationId={organisation_id}
          page={"story"}
          logo={template !== null && template?.organisation_logo}
        />
        <TypeWriterColumn
          strings={template?.template?.aboutPage?.typeWriterData}
          heading={template?.template?.aboutPage?.mainHeadingData}
        />
        <MainColumn
          text={
            template && template?.template?.aboutPage?.section1Data?.sectionText
          }
          backgroundImage={
            template &&
            template?.template?.aboutPage?.section1Data?.sectionImage?.imgURL
          }
        />
        <StickyIcons icon={smallicon} />

        <Intro
          introwords={template && template?.template?.aboutPage?.headingData}
        />
        <FirstInfo
          infoImage={
            template &&
            template?.template?.aboutPage?.section2Data?.sectionImage?.imgURL
          }
          infoData={
            template && template?.template?.aboutPage?.section2Data?.sectionText
          }
        />
        <Team
          team={
            template && template?.template?.aboutPage?.teamData?.teamMembers
          }
          startupName={template?.organisation_name}
          teamData={
            template && template?.template?.aboutPage?.teamData?.teamHeading
          }
        />
        <Footer
          email={template?.template?.footer?.footerData?.emailData}
          phone={template?.template?.footer?.footerData?.phoneNumberData}
          sellername={template?.organisation_name}
          insta={template?.template?.footer?.socialLinksData?.instagramData}
          facebooklink={
            template?.template?.footer?.socialLinksData?.facebookData
          }
        />
      </div>
    )
  );
};

export default OrganisationAboutPage;
