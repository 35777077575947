import React from "react";
import { BiUser, BiCartAlt } from "react-icons/bi";
import { GiCancel } from "react-icons/gi";
import { FiTruck } from "react-icons/fi";
import { IoMdPeople } from "react-icons/io";
import { CgOrganisation } from "react-icons/cg";
import { BsCreditCard } from "react-icons/bs";

const DataCard = ({ value, text, logo }) => {
  return (
    <div className="flex border bg-white rounded-md items-center justify-between py-5 px-4">
      <div className="flex flex-col">
        <div className="font-bold text-xl">{value}</div>
        <div className="text-[#8A98AC] font-normal text-sm">{text}</div>
      </div>
      <div>{logo}</div>
    </div>
  );
};

const Dashboard = () => {
  return (
    <div>
      <p className="font-semibold text-3xl mb-6">Dashboard</p>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6">
        {/* <DataCard
          value={33}
          text={"Total Users"}
          logo={<BiUser className="text-6xl text-green-500" />}
        /> */}
        <DataCard
          value={2}
          text={"Total Orders"}
          logo={<BiCartAlt className="text-6xl text-yellow-500" />}
        />
        <DataCard
          value={0}
          text={"Total Cancelled Orders"}
          logo={<GiCancel className="text-6xl text-red-500" />}
        />
        <DataCard
          value={2}
          text={"Total Products"}
          logo={<FiTruck className="text-6xl text-indigo-500" />}
        />
        <DataCard
          value={2}
          text={"Total Sellers"}
          logo={<IoMdPeople className="text-6xl text-blue-500" />}
        />

        <DataCard
          value={2}
          text={"Pending Payouts"}
          logo={<BsCreditCard className="text-6xl text-red-600" />}
        />
      </div>
    </div>
  );
};

export default Dashboard;
