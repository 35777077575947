import React from "react";

const ProductCard2 = ({ setPop, item }) => {
  console.log(item, "item");

  return (
    item !== undefined && (
      <div className=" drop-shadow-xl pb-3 " onClick={() => setPop(true)}>
        <div className="overflow-hidden">
          <img
            className="max-h-80 w-80 hover:scale-110 ease-in-out duration-300	"
            alt=""
            src={item?.productImageUrls[0]?.imgURL}
          />
        </div>
        <div className="py-3 text-heading text-[18px] lg:text-[25px] font-bold">
          {item?.productName}
        </div>
        <div className="text-heading text-lg md:text-xl lg:text-2xl 2xl:text-3xl xl:leading-10 font-bold">
          ${item?.productPrice}
        </div>
      </div>
    )
  );
};

export default ProductCard2;
