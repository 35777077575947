import { Link, Navigate } from "react-router-dom";
import React from "react";
import "@fontsource/dancing-script";

import { signout } from "../../Helper/Auth";

async function setLogin(user = null) {
  localStorage.removeItem("macha-user");
  window.location.reload();
}
const Dashboard = () => {
  if (localStorage.getItem("macha-user") === null)
    return <Navigate to="/" replace={false} />;

  return (
    <div>
      <div className="sm:py-16 pb-3 lg:py-20 px-0 xl:max-w-screen-xl mx-auto flex  md:flex-row w-full mr-32">
        <div className="lg:w-4/6 2xl:w-8/12 mt-6 lg:mt-0 ml-6">
          <h2 className="text-lg md:text-xl xl:text-2xl font-bold text-heading mb-3 xl:mb-5">
            Dashboard
          </h2>
          <div className="text-sm leading-7 md:text-base md:leading-loose">
            <p className="capitalize">
              Hello
              <span className="font-bold"> </span> (not{" "}
              <span className="font-bold"> Customer</span>?
              <span
                onClick={() => {
                  signout().then((res) => {
                    window.location.reload();
                  });
                }}
                className="font-bold underline cursor-pointer focus:outline-none"
              >
                {" "}
                Logout
              </span>
              )
            </p>
            From your account dashboard you can view your
            <span className="text-heading font-semibold"> recent orders</span>,
            manage your
            <span className="text-heading font-semibold"> Address</span> and
            <span className="text-heading font-semibold">
              {" "}
              Contact Number
            </span>{" "}
            and
            <span className="text-heading font-semibold">
              {" "}
              change your password
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
