import { Link, Navigate } from "react-router-dom";
import React from "react";
import "@fontsource/dancing-script";
import { useRef, useState, useEffect, useContext } from "react";
import { IoAddOutline } from "react-icons/io5";
import { RxCrossCircled } from "react-icons/rx";

import axios from "axios";
import { APIURL } from "../../backend";

const addURL = `${APIURL}/user/add-new-address`;
const getURL = `${APIURL}/user/get-address`;
const delURL = `${APIURL}/user/remove-address`;
const Address = () => {
  const [address, setAddress] = useState("");
  const [modal, setModal] = useState(0);
  const [check, setCheck] = useState(0);
  const [open, setOpen] = useState(0);
  const [name, setName] = useState("");
  const [state, setState] = useState("");
  const [pin, setPin] = useState("");

  const [city, setCity] = useState("");

  const [addData, setData] = useState({});
  useEffect(() => {
    console.log("hi");
    if (!check) {
      axios
        .get(getURL, {
          headers: {
            Authorization:
              localStorage.getItem("macha-user") &&
              JSON.parse(localStorage.getItem("macha-user")).token,
          },
        })
        .then((response) => {
          console.log("res = ", response);
          setData(response.data.data);
          console.log(addData);
          setCheck(1);
        });
    }
  }, []);
  const handleAdd = async (e) => {
    e.preventDefault();
    console.log("hi = ", address);
    setModal(0);
    const response = await axios.post(
      addURL,
      {
        main_address: address,
        postal_code: pin,
        name: name,
        city: city,
        state: state,
      },
      {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("macha-user"))[
            "token"
          ],
        },
      }
    );
    axios
      .get(getURL, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("macha-user"))[
            "token"
          ],
        },
      })
      .then((response) => {
        console.log("res = ", response.data.data);
        setData(response.data.data);
        console.log(addData);
        setCheck(1);
      });
    console.log("resp = ", response);
  };
  const deleteAdd = async (e, index) => {
    e.preventDefault();
    console.log("hlo = ", typeof e.target);
    setData(addData.filter((v, i) => i !== index));
    const response = await axios.post(
      delURL,
      { id: e.target.value },
      {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("macha-user"))[
            "token"
          ],
        },
      }
    );
  };
  if (localStorage.getItem("macha-user") === null)
    return <Navigate to="/" replace={false} />;

  return (
    <div>
      <div>
        <div class="flex items-center justify-between mb-5 lg:mb-6 xl:mb-7 px-3 xl:-mt-2 sm:ml-6 ">
          <div class="flex items-center space-x-3 md:space-x-4 rtl:space-x-reverse text-lg lg:text-xl xl:text-2xl text-heading capitalize font-bold">
            Address
          </div>
          <button
            onClick={() => setModal(true)}
            className="flex items-center text-sm font-semibold text-heading transition-colors duration-200 focus:outline-none focus:opacity-70 hover:opacity-70 mt-1"
          >
            <span className="flex align-center">
              <IoAddOutline className="h-6" />
              Add
            </span>
          </button>
        </div>
        {modal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold">Add Address</h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setModal(false)}
                    ></button>
                  </div>
                  <div className="relative p-6 flex-auto">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                          type="text"
                          className="w-full block border-black border-t-2 text-sm px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>Postal Code</label>
                        <input
                          type="text"
                          className="w-full block border-black border-t-2 text-sm px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                          value={pin}
                          onChange={(e) => setPin(e.target.value)}
                        />
                      </div>
                    </div>
                    <label>Address</label>
                    <input
                      type="text"
                      className="w-full block border-black border-t-2 text-sm px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />

                    <div className="grid grid-cols-2 gap-4">
                      <div className="form-group">
                        <label className="flex-none">State</label>
                        <input
                          type="text"
                          className=" w-full border-black border-t-2 text-sm px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label className="flex-none">City</label>
                        <input
                          type="text"
                          className=" w-full border-black border-t-2 text-sm px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setModal(false)}
                    >
                      Close
                    </button>
                    <button
                      className="bg-slate-500 text-white active:bg-slate-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={handleAdd}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}

        <div class="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          {check === 1 &&
            addData.map((data, index) => (
              <div
                onMouseOver={(e) => setOpen(data._id)}
                onMouseOut={(e) => setOpen(0)}
                class="relative p-4 lg:p-5 xl:p-6 h-full rounded border cursor-pointer group hover:border-accent bg-gray-200 border-gray-100"
              >
                {open !== data._id ? (
                  <div></div>
                ) : (
                  <button
                    className="flex-row flex-end text-right items-end ml-32 mb-8 text-red-500"
                    title="delete"
                    value={data._id}
                    onClick={(e) => deleteAdd(e, index)}
                  >
                    __
                  </button>
                )}
                <p class="text-base text-heading font-semibold mb-2 md:mb-3 capitalize">
                  {data.name}
                </p>
                <p class="text-sm text-body leading-6">
                  {data.main_address}, {data.state} , {data.city} ,{" "}
                  {data.postal_code}{" "}
                </p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
  // </div>
  // {/* <div className="px-5 sm:px-8 md:px-16 2xl:px-24 bg-gray-200 py-10 md:py-14 lg:py-16">
  // </div> */}
  // </div>
  // </div>
};

export default Address;
