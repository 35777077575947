import React, { Fragment, useEffect, useState } from "react";
import HomePageTemplate1 from "../../Components/updateTemplateSeller/HomePage";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import AboutPage from "../../Components/updateTemplateSeller/AboutPage";
import Block from "../../Components/Builder/Block";
import Headline from "../../Components/Builder/Headline";
import FooterData from "../../Components/updateTemplateSeller/FooterData";
import { BlockPicker } from "react-color";
import { BaseColors } from "../../Components/updateTemplateSeller/basecolors";
import { convertToHTML } from "draft-convert";
import { Dialog, Transition } from "@headlessui/react";
import SellerAboutPage from "../UpdateSellerPages/SellerAboutPage";
import DOMPurify from "dompurify";
import SellerPage from "../UpdateSellerPages/SellerPage";
import { MdCancel } from "react-icons/md";
import {
  updateStoreWebsiteImages,
  updateStoreWebsite,
} from "../../Helper/store";
import toast from "react-hot-toast";
import Loader from "../../Components/Builder/Loader";
import Pop from "../../Components/Base/Pop";

const UpdateSellerWebsite = ({ selected }) => {
  //busy state
  const [busy, setBusy] = useState(false);
  console.log(selected);
  //Page Enables
  const [homePageEnabled, setHomePageEnabled] = useState(
    typeof selected?.template?.homepageEnabled === "boolean"
      ? selected?.template?.homepageEnabled
      : true
  );
  const [aboutPageEnabled, setAboutPageEnabled] = useState(
    typeof selected?.template?.aboutPageEnabled === "boolean"
      ? selected?.template?.aboutPageEnabled
      : true
  );
  const [productsPageEnabled, setProductsPageEnabled] = useState(
    typeof selected?.template?.productPageEnabled === "boolean"
      ? selected?.template?.productPageEnabled
      : true
  );

  //Preview Website state

  const [showPreview, setShowPreview] = useState(false);
  const [showTemplatePreview, setShowTemplatePreview] = useState(false);

  //Website Theme color
  const [chosenColor, setChosenColor] = useState(
    selected?.template?.chosenColor
  );
  const [showPicker, setShowPicker] = useState(false);
  //Builder option
  const [openOption, setopenOption] = useState("home");
  //HomePage Enables
  const [enabled, setEnabled] = useState(
    selected?.template?.homepage?.sliderEnabled
  );
  const [enabled2, setEnabled2] = useState(
    selected?.template?.homepage?.section1Enabled
  );
  useEffect(() => {
    if (selected && selected?.template?.aboutPage?.section1Data?.sectionText) {
      let blocks =
        selected?.template?.aboutPage?.section1Data?.sectionText.blocks;
      const entityMap = {};
      const formatted = convertFromRaw({ blocks, entityMap });

      setEditorState2(EditorState.createWithContent(formatted));
    }
  }, []);

  const [enabled3, setEnabled3] = useState(
    selected?.template?.homepage?.headingEnabled
  );
  const [enabled4, setEnabled4] = useState(
    selected?.template?.homepage?.section2Enabled
  );
  const [statsEnabled, setStatsEnabled] = useState(
    selected?.template?.homepage?.keyMetricsEnabled
  );
  const [testimonialsEnabled, setTestimonialsEnabled] = useState(
    selected?.template?.homepage?.testimonialsEnabled
  );

  //HomePageData
  const [sliderImages, setSliderImages] = useState(
    selected && selected?.template?.homepage?.sliderImages
  );

  const [homeImage, setHomeImage] = useState(
    selected?.template?.homepage?.section1Data?.sectionImage
  );

  const [homeImage2, setHomeImage2] = useState(
    selected?.template?.homepage?.section2Data?.sectionImage
  );
  let newStats = selected?.template?.homepage?.keyMetrics;

  const [statistics, setStatistics] = useState(newStats);

  const [testimonials, setTestimonials] = useState(
    selected?.template?.homepage?.testimonials
  );

  //HomePageEditors

  const [editorState6, setEditorState6] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (
      selected &&
      selected?.template?.homepage?.section1Data?.sectionDescription
    ) {
      let blocks =
        selected?.template?.homepage?.section1Data?.sectionDescription.blocks;
      const entityMap = {};
      const formatted = convertFromRaw({ blocks, entityMap });

      setEditorState6(EditorState.createWithContent(formatted));
    }
  }, []);

  const [editorState7, setEditorState7] = useState(() =>
    EditorState.createEmpty()
  );
  useEffect(() => {
    if (
      selected &&
      selected?.template?.homepage?.section1Data?.sectionDescription
    ) {
      let blocks = selected?.template?.homepage?.headingData?.blocks;
      const entityMap = {};
      const formatted = convertFromRaw({ blocks, entityMap });

      setEditorState7(EditorState.createWithContent(formatted));
    }
  }, []);

  const [editorState8, setEditorState8] = useState(() =>
    EditorState.createEmpty()
  );

  //Homepage Headings
  const [Heading1, setHeading1] = useState(
    selected?.template?.homepage?.section1Data?.sectionHeading?.text
  );
  const [Heading2, setHeading2] = useState(
    selected?.template?.homepage?.section2Data?.sectionHeading?.text
  );

  //AboutPage Enables
  const [enabled5, setEnabled5] = useState(
    selected?.template?.aboutPage?.typeWriterEnabled
  );

  const [removedFounder, setRemovedFounder] = useState([]);

  const [enabled6, setEnabled6] = useState(
    selected?.template?.aboutPage?.headingEnabled
  );
  const [enabled7, setEnabled7] = useState(
    selected?.template?.aboutPage?.section1Enabled
  );
  const [enabled8, setEnabled8] = useState(true);
  useEffect(() => {
    if (
      selected &&
      selected?.template?.homepage?.section2Data?.sectionDescription
    ) {
      let blocks =
        selected?.template?.homepage?.section2Data?.sectionDescription.blocks;
      const entityMap = {};
      const formatted = convertFromRaw({ blocks, entityMap });

      setEditorState8(EditorState.createWithContent(formatted));
    }
  }, []);

  const [enabled9, setEnabled9] = useState(
    selected?.template?.aboutPage?.teamEnabled
  );
  const [enabled10, setEnabled10] = useState(true);

  //AboutPageData
  const [contentImage, setContentImage] = useState(
    selected?.template?.aboutPage?.section1Data?.sectionImage
  );
  const [contentImage2, setContentImage2] = useState(
    selected?.template?.aboutPage?.section2Data?.sectionImage
  );
  console.log(
    selected?.template?.aboutPage?.teamData?.teamMembers,
    "teamMembers"
  );
  const [founderData, setFounderData] = useState(
    selected?.template?.aboutPage?.teamData?.teamMembers
  );

  let newTypeArray = [];

  for (
    let i = 0;
    i < selected?.template?.aboutPage?.typeWriterData?.length;
    i++
  ) {
    newTypeArray.push({
      string: selected?.template?.aboutPage?.typeWriterData[i],
    });
  }
  const [typewriter, setTypewriter] = useState(newTypeArray);

  //AboutPage Editors
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  useEffect(() => {
    if (selected && selected?.template?.aboutPage?.headingData) {
      let blocks = selected?.template?.aboutPage?.headingData?.blocks;
      const entityMap = {};
      const formatted = convertFromRaw({ blocks, entityMap });

      setEditorState(EditorState.createWithContent(formatted));
    }
  }, []);

  const [editorState2, setEditorState2] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorState3, setEditorState3] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (selected && selected?.template?.aboutPage?.mainHeadingData) {
      let blocks = selected?.template?.aboutPage?.mainHeadingData?.blocks;
      const entityMap = {};
      const formatted = convertFromRaw({ blocks, entityMap });

      setEditorState3(EditorState.createWithContent(formatted));
    }
  }, []);

  const [editorState4, setEditorState4] = useState(() =>
    EditorState.createEmpty()
  );
  useEffect(() => {
    if (selected && selected?.template?.aboutPage?.section2Data) {
      let blocks =
        selected?.template?.aboutPage?.section2Data?.sectionText?.blocks;
      const entityMap = {};
      const formatted = convertFromRaw({ blocks, entityMap });

      setEditorState4(EditorState.createWithContent(formatted));
    }
  }, []);

  const [editorState5, setEditorState5] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (selected && selected?.template?.aboutPage?.teamData) {
      let blocks = selected?.template?.aboutPage?.teamData?.teamHeading?.blocks;
      const entityMap = {};
      const formatted = convertFromRaw({ blocks, entityMap });

      setEditorState5(EditorState.createWithContent(formatted));
    }
  }, []);
  //AboutPage Headings
  const [Heading3, setHeading3] = useState(
    selected?.template?.aboutPage?.section1Data?.sectionHeading?.text
  );
  const [Heading4, setHeading4] = useState(
    selected?.template?.aboutPage?.section2Data?.sectionHeading?.text
  );

  //Enable Function and Values modularised
  const EnableFunction = {
    setEnabled,
    setEnabled2,
    setEnabled3,
    setEnabled4,
    setStatsEnabled,
    setTestimonialsEnabled,
    setEnabled5,
    setEnabled6,
    setEnabled7,
    setEnabled8,
    setEnabled9,
    setEnabled10,
  };
  const EnableValues = {
    enabled,
    enabled2,
    enabled3,
    enabled4,
    statsEnabled,
    testimonialsEnabled,
    enabled5,
    enabled6,
    enabled7,
    enabled8,
    enabled9,
    enabled10,
  };

  //Note: The Functions and Values are passed as props to the HomePageTemplate1 and AboutPageTemplate1 component
  //HomePageFunctions and Value modularised
  const HomeFunctions = {
    setSliderImages,
    setHomeImage,
    setHomeImage2,
    setStatistics,
    setTestimonials,
  };
  const HomeValues = {
    sliderImages,
    homeImage,
    homeImage2,
    statistics,
    testimonials,
  };

  //AboutPageFunction and Value modularised
  const AboutFunctions = {
    setContentImage,
    setContentImage2,
    setFounderData,
    setTypewriter,
  };

  const AboutValues = {
    contentImage,
    contentImage2,
    founderData,
    typewriter,
  };

  //Editor Functions and Value modularised
  const EditorFunctions = {
    setEditorState6,
    setEditorState7,
    setEditorState8,
    setEditorState,
    setEditorState2,
    setEditorState3,
    setEditorState4,
    setEditorState5,
  };
  const EditorValues = {
    editorState6,
    editorState7,
    editorState8,
    editorState,
    editorState2,
    editorState3,
    editorState4,
    editorState5,
  };

  //Heading Function and Value modularised
  const HeadingFunctions = {
    setHeading1,
    setHeading2,
    setHeading3,
    setHeading4,
  };
  const HeadingValues = { Heading1, Heading2, Heading3, Heading4 };

  //Footer Data
  const [footerData, setFooterData] = useState({
    email: selected?.template?.footer?.footerData?.emailData,
    phone: selected?.template?.footer?.footerData?.phoneNumberData,
    facebookLink: selected?.template?.footer?.socialLinksData?.facebookData,
    instagramLink: selected?.template?.footer?.socialLinksData?.instagramData,
  });

  const handleFooterData = (event, thing) => {
    event.preventDefault();

    let value = event.target.value;
    setFooterData({ ...footerData, [thing]: value });
  };

  async function handleImages(e, setFunction, oldValue, array = false) {
    if (array) {
      let filesArray = [...oldValue];

      for (let i = 0; i < e.target.files.length; i++) {
        await filesArray.push({ image: e.target.files[i] });
      }
      await setFunction(filesArray);
    } else {
      await setFunction(e.target.files[0]);
    }
  }

  async function handArrayObjectChange(
    e,
    index,
    key,
    setFunction,
    oldValue,
    type = "text"
  ) {
    e.preventDefault();
    if (oldValue[index]) {
      let newArray = [...oldValue];
      newArray[index][key] =
        type === "text" ? e.target.value : e.target.files[0];
      await setFunction(newArray);
    }
  }

  console.log(founderData, "founderData");
  async function removeTeamMemberImage(e, index, setFunction, oldValue, key) {
    e.preventDefault();
    console.log(oldValue[index], "oldValue[index]");
    if (oldValue[index]) {
      let newArray = [...oldValue];
      newArray[index][key] = "";
      await setFunction(newArray);
    }
  }

  //Converted content for preview of sites
  const [convertedContent, setConvertedContent] = useState(null);
  const [convertedContent2, setConvertedContent2] = useState(null);
  const [convertedContent3, setConvertedContent3] = useState(null);
  const [convertedContent4, setConvertedContent4] = useState(null);
  const [convertedContent5, setConvertedContent5] = useState(null);
  const [convertedContent6, setConvertedContent6] = useState(null);
  const [convertedContent7, setConvertedContent7] = useState(null);
  const [convertedContent8, setConvertedContent8] = useState(null);
  const [selectedHandler, setSelectedHandler] = useState();
  const [isOpen2, setIsOpen2] = useState(false);

  console.log(selectedHandler, "selectedHandler");
  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
  }, [editorState]);
  useEffect(() => {
    let html = convertToHTML(editorState2.getCurrentContent());
    setConvertedContent2(html);
  }, [editorState2]);
  useEffect(() => {
    let html = convertToHTML(editorState3.getCurrentContent());
    setConvertedContent3(html);
  }, [editorState3]);
  useEffect(() => {
    let html = convertToHTML(editorState4.getCurrentContent());
    setConvertedContent4(html);
  }, [editorState4]);
  useEffect(() => {
    let html = convertToHTML(editorState5.getCurrentContent());
    setConvertedContent5(html);
  }, [editorState5]);
  useEffect(() => {
    let html = convertToHTML(editorState6.getCurrentContent());
    setConvertedContent6(html);
  }, [editorState6]);
  useEffect(() => {
    let html = convertToHTML(editorState7.getCurrentContent());
    setConvertedContent7(html);
  }, [editorState7]);
  useEffect(() => {
    let html = convertToHTML(editorState8.getCurrentContent());
    setConvertedContent8(html);
  }, [editorState8]);

  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  async function createStore() {
    if (busy) {
      toast("Update request already in process");
      return;
    }
    setBusy(true);
    let formData = new FormData();

    formData.append("sliderEnabled", enabled);
    let newSliderImages = [];
    let founderDataToSend = [];
    if (enabled) {
      for (let i = 0; i < sliderImages.length; i++) {
        if (!sliderImages[i].imgURL) {
          console.log(sliderImages[i].image, "image");
          formData.append("sliderImages", sliderImages[i].image);
        } else {
          newSliderImages.push(sliderImages[i]);
        }
      }
    }

    formData.append("homesection1Enabled", enabled2);
    if (enabled2 && !homeImage?.imgURL) {
      formData.append("section1Image", homeImage);
    }
    formData.append("homesection2Enabled", enabled4);
    if (enabled4 && !homeImage2?.imgURL) {
      formData.append("section2Image", homeImage2);
    }

    formData.append("aboutsection1Enabled", enabled7);
    if (enabled7 && !contentImage?.imgURL) {
      formData.append("aboutsection1Image", contentImage);
    }

    formData.append("aboutsection2Enabled", enabled8);
    if (enabled8) {
      formData.append("aboutsection2Image", contentImage2);
    }

    formData.append("teamEnabled", enabled9);
    for (let i = 0; i < founderData.length; i++) {
      if (founderData[i]?.image?.imgURL || founderData[i]?.image) {
        if (!founderData[i].image.imgURL) {
          formData.append("teamImages", founderData[i].image);
        } else {
          founderDataToSend.push(founderData[i]);
        }
      }
    }

    let response = await updateStoreWebsiteImages(formData);

    if (response.status === 200) {
      for (let i = 0; i < response.data.sliderImages.length; i++) {
        newSliderImages.push(response.data.sliderImages[i]);
      }
      newSliderImages.forEach(function (v) {
        delete v._id;
      });

      for (let i = 0; i < response.data.teamImages.length; i++) {
        founderDataToSend.push({
          name: founderData[i].name,
          image: response.data.teamImages[i],
        });
      }
      founderDataToSend.forEach(function (v) {
        delete v._id;
      });

      let newTypewriter = typewriter?.map((item) => item.string);

      let templateData = {
        template: {
          homepageEnabled: homePageEnabled,
          aboutPageEnabled: aboutPageEnabled,
          productPageEnabled: productsPageEnabled,
          chosenColor: chosenColor,
          homepage: {
            sliderEnabled: enabled,

            sliderImages: newSliderImages,
            section1Enabled: enabled2,
            section1Data: {
              sectionImage: !response?.data?.homesection1Image.imgURL
                ? HomeValues.homeImage
                : response.data.homesection1Image,
              sectionHeading: {
                font: 16,
                text: Heading1,
              },
              sectionDescription: convertToRaw(
                editorState6.getCurrentContent()
              ),
            },
            section2Enabled: enabled4,
            section2Data: {
              sectionImage: !response?.data?.homesection2Image.imgURL
                ? HomeValues.homeImage2
                : response.data.homesection2Image,
              sectionHeading: {
                font: 16,
                text: Heading2,
              },
              sectionDescription: convertToRaw(
                editorState8.getCurrentContent()
              ),
            },
            headingEnabled: enabled3,
            headingData: convertToRaw(editorState7.getCurrentContent()),
            testimonialsEnabled: testimonialsEnabled,
            testimonials: testimonials,
            keyMetricsEnabled: statsEnabled,
            keyMetrics: statistics,
          },
          aboutPage: {
            typeWriterEnabled: enabled5,
            mainHeadingData: convertToRaw(editorState3.getCurrentContent()),
            typeWriterData: newTypewriter,
            section1Enabled: enabled7,
            section1Data: {
              sectionImage: !response?.data?.aboutsection1Image.imgURL
                ? AboutValues.contentImage
                : response.data.aboutsection1Image,
              sectionText: convertToRaw(editorState2.getCurrentContent()),
              sectionHeading: {
                font: 16,
                text: Heading3,
              },
            },
            section2Enabled: enabled8,
            section2Data: {
              sectionImage: !response?.data?.aboutsection2Image.imgURL
                ? AboutValues.contentImage2
                : response.data.aboutsection2Image,
              sectionText: convertToRaw(editorState4.getCurrentContent()),
              sectionHeading: {
                font: 16,
                text: Heading4,
              },
            },
            headingEnabled: enabled6,
            headingData: convertToRaw(editorState.getCurrentContent()),
            teamEnabled: enabled9,
            teamData: {
              teamHeading: convertToRaw(editorState5.getCurrentContent()),
              teamSubheading: "Our Team",
              teamMembers: founderDataToSend,
            },
          },
          footer: {
            footerEnabled: true,
            footerData: {
              phoneNumberEnabled: true,
              phoneNumberData: footerData.phone,
              emailEnabled: true,
              emailData: footerData.email,
            },
            socialLinksEnabled: true,
            socialLinksData: {
              facebookData: footerData.facebookLink,
              instagramData: footerData.instagramLink,
            },
            partOfOrganisation: true,
            organisationData: {
              logo: "sdsd",
              name: "sdsds",
            },
          },
        },
        store_id: selected?._id,
      };

      let response2 = await updateStoreWebsite(templateData);

      if (response2.status_code === 200) {
        toast.success("Website Update");
        setBusy(false);
        setTimeout(() => {
          window.location.reload(false);
        }, 2500);
      } else {
        toast.error(response2.message);
      }
    } else {
      setBusy(false);
      toast.error("Error in Adding Images");
    }
  }

  return (
    <>
      <div className="flex items-center justify-center pb-4">
        <div className="flex items-center space-x-4 fixed ">
          <button
            onClick={() => setShowPreview(true)}
            style={{ zIndex: 1000 }}
            className=" px-3 py-1 rounded-md hover:bg-amber-500 hover:text-white bg-amber-300"
          >
            Preview
          </button>
          <button
            onClick={() => setShowTemplatePreview(true)}
            style={{ zIndex: 1000 }}
            className=" px-3 py-1 rounded-md hover:bg-amber-500 hover:text-white bg-amber-300"
          >
            Template Preview
          </button>
        </div>
      </div>
      <Transition
        appear
        show={showPreview || showTemplatePreview}
        as={Fragment}
      >
        <Dialog
          style={{ zIndex: 20000 }}
          as="div"
          className="relative z-10"
          onClose={() =>
            showPreview ? setShowPreview(false) : setShowTemplatePreview(false)
          }
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-11/12 mx-auto cursor-pointer   h-[80%] relative">
                  <div
                    style={{ zIndex: 21000 }}
                    className=" flex items-center relative fixed justify-end "
                  >
                    <MdCancel
                      style={{ zIndex: 21000 }}
                      size={30}
                      onClick={() =>
                        showPreview
                          ? setShowPreview(false)
                          : setShowTemplatePreview(false)
                      }
                    />
                  </div>
                  <div className="overflow-y-scroll bg-white h-full w-full">
                    {openOption === "home" ? (
                      <SellerPage
                        template={
                          selected &&
                          !showTemplatePreview &&
                          selected?.template?.homepage
                        }
                        showSample={showTemplatePreview}
                        sliderImages={sliderImages}
                        convertedContent6={convertedContent6}
                        convertedContent7={convertedContent7}
                        convertedContent8={convertedContent8}
                        createMarkup={createMarkup}
                        homeImage={homeImage}
                        homeImage2={homeImage2}
                        footerData={footerData}
                        headings={HeadingValues}
                        testimonialState={testimonials}
                        statisticsState={statistics}
                        basecolor={chosenColor}
                        selected={!showTemplatePreview && selected}
                        EnableValues={EnableValues}
                      />
                    ) : (
                      <SellerAboutPage
                        template={selected && selected?.template?.aboutPage}
                        createMarkup={createMarkup}
                        convertedContent={convertedContent}
                        convertedContent2={convertedContent2}
                        convertedContent3={convertedContent3}
                        convertedContent4={convertedContent4}
                        convertedContent5={convertedContent5}
                        founderData={founderData}
                        contentImage={contentImage}
                        contentImage2={contentImage2}
                        typewriter={typewriter}
                        basecolor={chosenColor}
                        footerData={footerData}
                        selected={
                          !showTemplatePreview &&
                          !showTemplatePreview &&
                          selected
                        }
                        showSample={showTemplatePreview}
                        EnableValues={EnableValues}
                      />
                    )}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <div className="w-full bg-white rounded-md">
        <div className="border-b">
          <p className="text-xl font-semibold px-3 pt-3">
            Manage website pages
          </p>
          <Block>
            <Headline
              enabled={homePageEnabled}
              setEnabled={setHomePageEnabled}
              headline={"Home Page "}
            />
            <Headline
              enabled={aboutPageEnabled}
              setEnabled={setAboutPageEnabled}
              headline={"About Page "}
            />
            <Headline
              enabled={productsPageEnabled}
              setEnabled={setProductsPageEnabled}
              headline={"Products Page "}
            />
          </Block>
        </div>
        <div className="border-b">
          <Block>
            <Headline headline={"Choose a theme color"} />
            <div className="flex space-x-2 justify-center">
              <button
                onMouseEnter={() => setShowPicker(true)}
                onMouseLeave={() => setShowPicker(false)}
                className="px-4 py-1 relative rounded-md bg-blue-500 hover:bg-blue-700 text-white"
              >
                Choose color
                {showPicker && (
                  <div
                    className="absolute -translate-x-1/2 left-1/2 top-full"
                    style={{ zIndex: 500 }}
                  >
                    <BlockPicker
                      colors={BaseColors}
                      color={chosenColor}
                      onChange={(e) => setChosenColor(e.hex)}
                    />
                  </div>
                )}
              </button>
              <div className="flex items-center space-x-2">
                <span style={{ color: chosenColor }}>Chosen color:</span>
                <div
                  className={`h-4 w-4 border rounded-full `}
                  style={{ backgroundColor: chosenColor }}
                ></div>
              </div>
            </div>
          </Block>
        </div>
        <div className="w-full items-center">
          <button
            onClick={() => setopenOption("home")}
            className={
              "w-1/2 py-3 font-semibold " +
              (openOption === "home" && "border-b-2 border-black text-blue-500")
            }
          >
            Homepage
          </button>
          <button
            onClick={() => setopenOption("about")}
            className={
              "w-1/2 py-3 font-semibold " +
              (openOption === "about" &&
                "border-b-2 border-black text-blue-500")
            }
          >
            Aboutpage
          </button>
        </div>
        {openOption === "home" && (
          <HomePageTemplate1
            template={selected && selected?.template?.homepage}
            EnableFunctions={EnableFunction}
            EnableValues={EnableValues}
            HomeFunctions={HomeFunctions}
            HomeValues={HomeValues}
            handleImages={handleImages}
            EditorFunctions={EditorFunctions}
            EditorValues={EditorValues}
            HeadingFunctions={HeadingFunctions}
            HeadingValues={HeadingValues}
            handArrayObjectChange={handArrayObjectChange}
            setIsOpen2={setIsOpen2}
            setSelectedHandler={setSelectedHandler}
            isOpen2={isOpen2}
          />
        )}
        {openOption === "about" && (
          <AboutPage
            removedFounder={removedFounder}
            setRemovedFounder={setRemovedFounder}
            EnableFunctions={EnableFunction}
            EnableValues={EnableValues}
            EditorFunctions={EditorFunctions}
            EditorValues={EditorValues}
            handleImages={handleImages}
            AboutFunctions={AboutFunctions}
            AboutValues={AboutValues}
            handArrayObjectChange={handArrayObjectChange}
            HeadingFunctions={HeadingFunctions}
            HeadingValues={HeadingValues}
            removeTeamMemberImage={removeTeamMemberImage}
            setIsOpen2={setIsOpen2}
            setSelectedHandler={setSelectedHandler}
          />
        )}
        <div className="border-t">
          <FooterData
            handleFooterData={handleFooterData}
            footerData={footerData}
          />
        </div>
        <div className="border-t w-full mt-4">
          <button
            onClick={() => !busy && createStore()}
            className={
              "py-3 w-full bg-black text-white " +
              (!busy && "hover:bg-blue-600")
            }
          >
            {busy ? <Loader size={"24px"} /> : "Update website"}
          </button>
        </div>
        <Pop isOpen={isOpen2} setIsOpen={setIsOpen2}>
          <div className="text-[20px] font-[600] ">
            Are you sure you want remove the Image ?
          </div>
          <div className="flex items-center justify-between pt-4">
            <button
              className="bg-red-600 px-3 py-1 rounded-lg text-white"
              onClick={() => {
                if (selectedHandler === "setHomeImage") {
                  HomeFunctions.setHomeImage("");
                } else if (selectedHandler === "setHomeImage2") {
                  HomeFunctions.setHomeImage2("");
                } else if (selectedHandler === "setContentImage") {
                  AboutFunctions.setContentImage("");
                } else if (selectedHandler === "setContentImage2") {
                  AboutFunctions.setContentImage2("");
                }
                setTimeout(() => {
                  setIsOpen2(false);
                }, 1000);
              }}
            >
              Yes
            </button>
            <button
              className="bg-green-600 px-3 py-1 rounded-lg text-white"
              onClick={() => setIsOpen2(false)}
            >
              NO
            </button>
          </div>
        </Pop>
      </div>
    </>
  );
};

export default UpdateSellerWebsite;
