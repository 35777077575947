import React from "react";

const StickyIcons = ({ icon, orgicon, organisation = null }) => {
  return (
    <div style={{ zIndex: 10000 }} className="sticky top-[80%] right-0">
      <div
        style={{ zIndex: 10000 }}
        className="flex flex-col absolute w-fit  space-y-3  top-[80%] right-0"
      >
        {orgicon && organisation !== null && (
          <div className=" bg-gray-400 p-2  w-max rounded-l-md hover:bg-[#ffbb56]">
            <a
              href={`/organisation/${organisation?.organisation_name}/${organisation?._id}`}
              target={"_blank"}
              rel="noreferrer"
            >
              <img className="w-4" alt="" src={orgicon} />
            </a>
          </div>
        )}
        <div className=" bg-gray-400 p-2  w-max rounded-l-md hover:bg-[#ffbb56]">
          <a href={"/"} target={"_blank"} rel="noreferrer">
            <img className="w-4" alt="" src={icon} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default StickyIcons;
