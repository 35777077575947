import { APIURL } from "../backend";

export const createStoreRequest = (data) => {
  return fetch(`${APIURL}/store/create-store`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      console.log(response.json);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const updateStore = (data) => {
  return fetch(`${APIURL}/store/update-store`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      console.log(response.json);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getFilteredStores = (filters) => {
  const data = {
    filters,
  };

  return fetch(`${APIURL}/store/get-filtered-stores`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const InitiateStoreRequest = (data) => {
  return fetch(`${APIURL}/store/store-request-images`, {
    method: "POST",
    headers: {
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: data,
  })
    .then((response) => {
      console.log(response.json);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const updateStoreWebsiteImages = (data) => {
  console.log(data);
  return fetch(`${APIURL}/store/update-store-website-images`, {
    method: "POST",
    headers: {
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: data,
  })
    .then((response) => {
      console.log(response.json);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const updateStoreWebsite = (data) => {
  return fetch(`${APIURL}/store/update-store-website`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",

      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      console.log(response.json);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const createTemplate = (data, storeId) => {
  console.log(storeId, "storeId");
  return fetch(`${APIURL}/store/create-store-website/${storeId}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",

      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      console.log(response.json);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const uploadStoreImage = (data) => {
  return fetch(`${APIURL}/store/upload-store-logo`, {
    method: "POST",
    headers: {
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: data,
  })
    .then((response) => {
      console.log(response.json);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const addNewStory = (data) => {
  return fetch(`${APIURL}/story/add-new-story`, {
    method: "POST",
    headers: {
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: data,
  })
    .then((response) => {
      console.log(response.json);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getStories = () => {
  return fetch(`${APIURL}/story/get-stories`, {
    method: "GET",
    headers: {
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getStoreCategories = () => {
  return fetch(`${APIURL}/store-category/get-store-categories`, {
    method: "GET",
    headers: {
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
      Accept: "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getRandomCategoryStoreForHomepage = () => {
  return fetch(`${APIURL}/store/get-stores-by-random-category`, {
    method: "GET",
    headers: {
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
      Accept: "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getAllStores = () => {
  return fetch(`${APIURL}/store/get-all-stores`, {
    method: "GET",
    headers: {
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getStoresByUser = (id) => {
  console.log(id, "id");
  return fetch(`${APIURL}/store/get-store-user/?id=${id}`, {
    method: "GET",

    headers: {
      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
      Accept: "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const deleteStory = (data) => {
  return fetch(`${APIURL}/story/delete-story`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",

      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      console.log(response.json);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const leaveOrganisation = (data) => {
  return fetch(`${APIURL}/store/leave-organisation`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",

      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      console.log(response.json);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getStore = (storeID) => {
  return fetch(`${APIURL}/store/get-store/?storeID=${storeID}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",

      Authorization: ` ${
        JSON.parse(localStorage.getItem("macha-user"))?.token
      }`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getStoresByOrganisation = (id) => {
  console.log(id, "id");
  return fetch(
    `${APIURL}/store/get-stores-by-random-organisation/?organisation_id=${id}`,
    {
      method: "GET",
      headers: {
        Authorization: ` ${
          JSON.parse(localStorage.getItem("macha-user"))?.token
        }`,
        Accept: "application/json",
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getStoresofOrganisation = (id) => {
  return fetch(
    `${APIURL}/store/get-stores-by-organisation/?organisation_id=${id}`,
    {
      method: "GET",
      headers: {
        Authorization: ` ${
          JSON.parse(localStorage.getItem("macha-user"))?.token
        }`,
        Accept: "application/json",
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
