import { APIURL } from "../backend";

export const addToWishlist = (data) => {
  console.log(data, "data");

  try {
    return fetch(`${APIURL}/wishlist/add-to-wishlist`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${
          JSON.parse(localStorage.getItem("macha-user"))?.token
        }`,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      return response.json();
    });
  } catch (error) {
    return { error: "Something went wrong", success: false };
  }
};

export const removeFromWishlist = (data) => {
  console.log(data, "data");
  try {
    return fetch(`${APIURL}/wishlist/remove-from-wishlist`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${
          JSON.parse(localStorage.getItem("macha-user"))?.token
        }`,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      return response.json();
    });
  } catch (error) {
    return { error: "Something went wrong", success: false };
  }
};
