import { useRef, useState, useEffect, useContext } from "react";
import logo from "../images/logo.png";

import waLogo from "../img/whatsapp.png";
import { FaMobile } from "react-icons/fa";
import axios from "../api/Axios";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import OtplessSdk from "otpless-js-sdk";
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import MachaHeader from "../Components/Base/MachaHeader";
import Footer from "../Components/Base/Footer";
import { APIURL, FRONTENDAPIURL } from "../backend";
import google48 from "../images/google48.png";
import { PasswordResetRequest, resendPasswordResetLink } from "../Helper/Auth";
import OtpComp from "../Components/Registration/OtpComp";

const sdkIntance = new OtplessSdk({
  appId: "OTPLess:MBVZXOYJPGHZZKHRWVWIMYVSXKMNVCME",
  enableErrorLogging: true,
});
const LOGIN_URL = `${APIURL}/auth/signin`;

const Login = (props) => {
  const userRef = useRef();
  const [isLogin, setLogin] = useState(false);
  const [isValidLogin, setValidLogin] = useState("pending");
  const [user, setUser] = useState("");
  const [success, setSuccess] = useState(false);
  const [screen, setScreen] = useState("login");
  const [forgotPassword, setForgotPassword] = useState("");
  const [emailSend, setEmailSend] = useState(false);
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const [notVerified, setNotVerified] = useState(false);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const [path, setPath] = useSearchParams();

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  async function forgotPasswordRequest() {
    const response = await PasswordResetRequest({ email: forgotPassword });

    await localStorage.setItem("forgot-password-request-email", forgotPassword);
    if (response.status_code === 200) {
      setEmailSend(true);

      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  }

  async function resendforgotPasswordRequest() {
    const response = await resendPasswordResetLink({ email: forgotPassword });

    await localStorage.setItem("forgot-password-request-email", forgotPassword);
    if (response.status_code === 200) {
      setEmailSend(true);

      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(LOGIN_URL, { email: user, password: values.password })
      .then(async (response) => {
        console.log(response);
        if (response.data.status_code === 401) {
          setNotVerified(true);
          setUserData(response.data.data);
        }
        if (response.data.status_code === 200) {
          await localStorage.setItem(
            "macha-user",
            JSON.stringify(response.data.data)
          );
          await setUser("");
          await setSuccess(true);
          toast.success("Login Successful");

          if (path.get("path") === null) {
            window.location.assign("/");
          } else {
            if (path.get("redirectFromId") === null) {
              window.location.assign(path.get("path"));
            } else {
              let redirectFromId = path.get("redirectFromId");
              let redirectUrl = `${path.get(
                "path"
              )}&redirectFromId=${redirectFromId}`;
              window.location.assign(redirectUrl);
            }
          }
        } else {
          console.log(response);
          toast.error(response.data.message);
        }
      });
  };

  async function signinWithGoogle() {
    window.open(`${APIURL}/auth/signin-google`, "_self");
  }

  useEffect(() => {
    axios
      .post(
        `${APIURL}/auth/check-login`,
        {},
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("macha-user"))
              ?.token,
          },
        }
      )
      .then((res) => {
        if (res.data.status_code === 400) {
          setValidLogin(false);
        } else {
          setValidLogin(true);
          // localStorage.setItem('macha-user',JSON.stringify(res.data.data));
        }
      })
      .catch((err) => {
        setValidLogin(false);
      });
  }, []);
  if (isLogin) {
    return <Navigate to="/" replace={true} />;
  }

  if (isValidLogin === "pending") {
    return <span>Loading...</span>;
  }

  if (isValidLogin && props?.pathname === undefined)
    return <Navigate to="/" replace={true} />;
  if (isValidLogin && props !== null)
    return <Navigate to={path} replace={true} />;

  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
      <div className="flex-grow">
        {/* <MachaHeader /> */}
        {screen === "login" && (
          <div className="w-full p-6 mt-6 m-auto bg-white rounded-md shadow-sm max-w-xl">
            {/* <img className="mx-auto w-40" src={logo} alt="img" /> */}

            <div className="text-center text-[35px] font-semibold text-gray-800">
              Welcome
            </div>

            {success ? (
              <section>
                <h1 className="text-center">You are logged in!</h1>
                <br />
                <p>{/* <a href="#">Go to Home</a> */}</p>
              </section>
            ) : (
              <div className="text-sm text-center mt-4">
                Login with your email & password
              </div>
            )}
            {!notVerified ? (
              <>
                <form className="mt-6" onSubmit={handleSubmit}>
                  <div className="mb-2">
                    <label
                      for="email"
                      className=" block text-sm font-semibold text-gray-800"
                    >
                      Email*
                    </label>
                    <Input
                      type="text"
                      id="username"
                      ref={userRef}
                      autoComplete="off"
                      onChange={(e) => setUser(e.target.value)}
                      value={user}
                      required
                      className="w-full block text-sm px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      for="password"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Password
                    </label>
                    <Input
                      className="w-full block  text-sm px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                      type={values.showPassword ? "text" : "password"}
                      onChange={handlePasswordChange("password")}
                      value={values.password}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </div>

                  <div className="flex items-center justify-end">
                    {/* <label class="inline-flex relative cursor-pointer">
             <input type="checkbox" value="" class="sr-only peer" />
             <div class="w-10 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

             <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
               Remember me
             </span>
           </label> */}

                    <div
                      className="block text-sm font-semibold text-gray-800"
                      onClick={() => setScreen("forgotPassword")}
                    >
                      Forget Password?
                    </div>
                  </div>

                  <div className="mt-6">
                    <button className="text-sm w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-black rounded-md hover:bg-slate-700 focus:outline-none focus:bg-slate-700">
                      Login
                    </button>
                  </div>
                </form>

                <div className="relative flex items-center justify-center  mt-6 border border-t">
                  <div className="absolute px-5 bg-white">Or</div>
                </div>
                <div className="mt-4 gap-x-2">
                  {/* <button
                 type="button"
                 className="text-sm text-white bg-blue-600 w-full flex items-center justify-center  p-2 border border-gray-600 rounded-md focus:ring-2 focus:ring-offset-1 focus:ring-violet-600"
             ><FaGoogle className='mr-1'/> Login With Google
             </button> */}
                  {success ? (
                    <div>
                      {/* <img src={profile.imageUrl} alt="user image" /> */}
                      {/* <h3>User Logged in</h3>
                   <p>Name: {profile.name}</p>
                   <p>Email Address: {profile.email}</p>
                   <br />
                   <br />
                   <GoogleLogout clientId={clientId} buttonText="Log out" onLogoutSuccess={logOut} /> */}
                    </div>
                  ) : (
                    <div></div>
                    // <GoogleLogin
                    //     clientId={clientId}
                    //     buttonText="Sign in with Google"
                    //     onSuccess={onSuccess}
                    //     onFailure={onFailure}
                    //     cookiePolicy={'single_host_origin'}
                    //     isSignedIn={true}
                    //     className="text-xs text-white bg-blue-600 w-full flex items-center justify-center  p-2 border border-gray-600 rounded-md focus:ring-2 focus:ring-offset-1 focus:ring-slate-7s00"
                    // />
                  )}
                  <Link to="/phoneAuth">
                    <button className="text-sm mt-4 flex bg-black w-full text-white items-center justify-center  p-2 border border-gray-600 rounded-md focus:ring-2 focus:ring-offset-1 focus:ring-slate-700">
                      <FaMobile className="mr-1" />
                      Login With Mobile Number
                    </button>
                  </Link>
                </div>
                {/* <div>
                  <button
                    onClick={signinWithGoogle}
                    className="text-sm mt-4 flex bg-black w-full text-white items-center justify-center  p-2 border border-gray-600 rounded-md focus:ring-2 focus:ring-offset-1 focus:ring-slate-700"
                  >
                    <img src={google48} alt="" className="mr-1 h-6" />
                    Login using google
                  </button>
                </div> */}
                {/* <div>
                  <button
                    onClick={sdkIntance.createGetIntentOnClick({
                      redirectionURL: `${FRONTENDAPIURL}/register`,
                    })}
                    className="text-sm mt-4 flex bg-green-500 w-full text-white items-center justify-center  p-2 border border-gray-600 rounded-md focus:ring-2 focus:ring-offset-1 focus:ring-slate-700"
                  >
                    <img className="mr-2 w-10" src={waLogo} alt="img" />
                    Login with WhatsApp
                  </button>
                </div> */}
                <p className="mt-8 text-xs font-light text-center text-gray-700">
                  {" "}
                  Don't have an account?{" "}
                  <Link
                    to="/register"
                    className="font-medium text-black hover:underline"
                  >
                    Register
                  </Link>
                </p>
              </>
            ) : (
              <OtpComp
                email={userData.email}
                user_id={userData._id}
                type={"signin"}
              />
            )}
          </div>
        )}
        {screen === "forgotPassword" && (
          <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl max-w-xl">
            <div className="mt-6">
              <div className="mb-2">
                <label
                  for="email"
                  className=" block text-sm font-semibold text-gray-800"
                >
                  Email*
                </label>
                <Input
                  type="text"
                  id="username"
                  ref={userRef}
                  autoComplete="off"
                  onChange={(e) => setForgotPassword(e.target.value)}
                  value={forgotPassword}
                  required
                  className="w-full block text-sm px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>

              <div className="flex items-center justify-end">
                <div className="">Resend Email</div>
              </div>

              <div className="mt-6">
                {emailSend === true ? (
                  <button
                    onClick={resendforgotPasswordRequest}
                    className="text-sm w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-black rounded-md hover:bg-slate-700 focus:outline-none focus:bg-slate-700"
                  >
                    Resend Email
                  </button>
                ) : (
                  <button
                    onClick={forgotPasswordRequest}
                    className="text-sm w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-black rounded-md hover:bg-slate-700 focus:outline-none focus:bg-slate-700"
                  >
                    Send Email
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-center items-center pt-5">
        <div className="pr-1  font-[300]">Powered by</div>
        <div className="  lg:h-5   h-3 ">
          <img src={logo} alt="" className=" h-full " />
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Login;
