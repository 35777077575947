import React from "react";
import { AiOutlineShop, AiOutlineHome } from "react-icons/ai";
import { FaAddressCard } from "react-icons/fa";

const SellerBlock = ({ store }) => {
  console.log(store, "store");
  return (
    <a
      href={
        store?.template?.homepageEnabled
          ? `/profile/${store?.storeName?.replace(/\s+/g, "-")}/${store._id}`
          : store?.template?.aboutPageEnabled
          ? `/profile/story/${store?.storeName?.replace(/\s+/g, "-")}/${
              store._id
            }`
          : `/profile/product/${store?.storeName?.replace(/\s+/g, "-")}/${
              store._id
            }`
      }
    >
      <div className="flex flex-col space-y-2 mx-auto hover:shadow-xl transition duration-200 ease-in-out transform hover:-translate-y-1 md:hover:-translate-y-1.5">
        <div className="  rounded-md  cursor-pointer ">
          <div className="flex flex-col mx-auto overflow-hidden relative  xl:w-40 md:w-24 w-20 items-center justify-center space-y-3">
            <img
              className="object-cover	xl:h-40 md:h-24  h-16 rounded-sm "
              src={store?.storeLogo}
              alt=""
            />
            <div className="w-full line-clamp-1">{store?.storeName}</div>
            {store?.template && (
              <div className="flex justify-between w-full">
                {store?.template?.homepageEnabled && (
                  <a
                    href={`/profile/${store.storeName?.replace(/\s+/g, "-")}/${
                      store._id
                    }`}
                  >
                    {" "}
                    <button
                      type="button"
                      className="inline-block  bg-primary   text-xs font-medium uppercase "
                      data-te-toggle="tooltip"
                      data-te-placement="bottom"
                      data-te-ripple-init
                      data-te-ripple-color="light"
                      title="Home Page"
                    >
                      <AiOutlineHome className="xl:text-2xl lg:text-xl sm:text-lg text-sm text-gray-600 hover:text-red-400 cursor-pointer" />
                    </button>
                  </a>
                )}
                {store?.template?.productPageEnabled && (
                  <a
                    href={`/profile/product/${store.storeName.replace(
                      /\s+/g,
                      "-"
                    )}/${store._id}`}
                  >
                    {" "}
                    <button
                      type="button"
                      className="inline-block rounded bg-primary   text-xs font-medium uppercase "
                      data-te-toggle="tooltip"
                      data-te-placement="bottom"
                      data-te-ripple-init
                      data-te-ripple-color="light"
                      title="Store Products"
                    >
                      <AiOutlineShop className="xl:text-2xl lg:text-xl sm:text-lg text-sm text-gray-600 hover:text-blue-400 cursor-pointer" />
                    </button>
                  </a>
                )}
                <a href={`/stories/?storeId=${store._id}`}>
                  {" "}
                  <button
                    type="button"
                    className="inline-block rounded bg-primary   text-xs font-medium uppercase "
                    data-te-toggle="tooltip"
                    data-te-placement="bottom"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    title="Card"
                  >
                    <FaAddressCard className="xl:text-2xl lg:text-xl sm:text-lg text-sm text-gray-600 hover:text-green-400 cursor-pointer" />
                  </button>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </a>
  );
};

export default SellerBlock;
