import React, { useState } from "react";
import { MdCancel } from "react-icons/md";
import { BiRupee } from "react-icons/bi";

const PopupProduct = ({
  product,
  setPop,
  addToCartHandler,
  addOnRedirect,
  addOnRedirectValue,
}) => {
  const [toggle, setToggle] = useState(1);
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="rounded-[20px] h-full w-full relative">
      <div className=" absolute right-0 top-0">
        <MdCancel size={30} onClick={() => setPop(false)} />
      </div>
      <div className="flex  justify-center h-full">
        <div className="w-[40%] h-full">
          <img
            src={
              product?.productImageUrls?.length > 0 &&
              product?.productImageUrls[0]?.imgURL
                ? product?.productImageUrls[0]?.imgURL
                : "https://chawkbazar-laravel-shop.vercel.app/_next/image?url=https%3A%2F%2Fchawkbazarlaravel.s3.ap-southeast-1.amazonaws.com%2F230%2FWatches-4-1.jpg&w=640&q=75"
            }
            alt="/s"
            className="w-full h-full rounded-l-[20px]"
          />
        </div>
        <div className="w-[60%] textcolor pl-6 pr-6 h-full max-h-full grow overflow-y-scroll noscrollbar">
          <div className="text-[25px] font-[600] pt-6">
            {product?.productName}
          </div>
          <div className="text-[18px] font-[500] text-bg-grey-200 pt-3">
            {showMore
              ? product?.productDescription?.split("\n").map((c, index) => {
                  return <p key={index}> {c} </p>;
                })
              : product?.productDescription
                  ?.slice(0, 300)
                  ?.split("\n")
                  .map((c, index) => {
                    return <p key={index}> {c} </p>;
                  })}
            {product?.productDescription?.length > 300 && (
              <button
                onClick={() => setShowMore(!showMore)}
                className="text-[18px]  text-white bg-black  ml-2 rounded-md px-1"
              >
                {showMore ? "Show Less" : "Show More"}
              </button>
            )}
          </div>
          <div className="flex items-center">
            <div className="text-[18px] font-[700] pt-3 pb-6 flex items-center pr-3">
              <BiRupee /> {product?.offerPrice}
            </div>
            <div className="text-[18px] font-[700] pt-3 pb-6 flex items-center ltr:pl-2.5 rtl:pr-2.5 -mt-0.5 md:mt-0 relative text-gray-400">
              <span className="w-full bg-black border border-gray-400 absolute "></span>
              <BiRupee /> {product?.productPrice}
            </div>
          </div>

          <div className="flex items-center justify-center">
            <div className="w-1/2 flex border border-grey-200 rounded-[10px]">
              <button
                onClick={() => {
                  if (toggle > 1) {
                    setToggle(toggle - 1);
                  }
                }}
                className="p-4 w-1/4 text-[20px] hover:bg-black hover:text-white hover:rounded-l-[10px] font-[600]"
              >
                -
              </button>
              <button className="p-4 w-2/4 border-x-2 border-grey-200 text-[20px] font-[600]">
                {toggle}
              </button>

              <button
                onClick={() => setToggle(toggle + 1)}
                className="p-4 w-1/4 hover:bg-black hover:text-white hover:rounded-r-[10px] "
              >
                +
              </button>
            </div>
            <div className="w-1/2 pl-3">
              <button
                onClick={() => addToCartHandler(product?._id, toggle)}
                className="backgroundcolor text-white rounded-[10px] w-full p-4 text-[20px] font-[600] hover:bg-gray-200 hover:text-black"
              >
                Add to Cart
              </button>
            </div>
          </div>
          <div className="pt-6 text-center">
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`/product/${product?.productName?.replace(/\s+/g, "-")}/${
                product?._id
              }${
                addOnRedirect
                  ? `/?redirectFrom=${addOnRedirect}&redirectFromId=${addOnRedirectValue}`
                  : ""
              }`}
            >
              <button className="backgroundcolor text-white rounded-[10px] w-[100%] p-2 text-[20px] font-[600]  hover:bg-gray-200 hover:text-black ">
                View details
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupProduct;
