import React from "react";

const SecondLineInfoSection = ({
  infoLine,
  createMarkup,
  convertedContent,
  basecolor = "blue-500",
}) => {
  return (
    <div
      style={{ backgroundColor: basecolor }}
      className={`text-white lg:text-3xl md:text-3xl text-xl xl:text-3xl  font-bold mb-10 w-full py-8 text-center`}
    >
      <p className="text-center tracking-wide">
        {" "}
        {!convertedContent ||
        createMarkup(convertedContent).__html.toString() === "<p></p>" ? (
          <div>{infoLine}</div>
        ) : (
          <div
            className="p-6"
            dangerouslySetInnerHTML={createMarkup(convertedContent)}
          ></div>
        )}
      </p>
    </div>
  );
};

export default SecondLineInfoSection;
