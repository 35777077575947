import React from "react";
import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
import useWindowDimensions from "../../hooks/useWindowDimension";

const Footer = () => {
  const { width } = useWindowDimensions();

  return (
    <div className="flex w-full mt-auto lg:flex-row flex-col items-center justify-between px-6 md:px-10 xl:px-20 bg-[#252525] text-[#ECEFF1] max-w-screen overflow-x-hidden max-lg:space-y-2 2xl:py-10 xl:py-8 md:py-8 py-8">
      <div className="flex flex-col items-center space-y-1 flex-nowrap	 ">
        <div className="flex  max-lg:justify-center items-center md:space-x-2 md:flex-row flex-col md:space-y-0">
          <a
            href="tel:7020699817"
            className="flex items-center space-x-1 flex-nowrap	"
          >
            <AiOutlinePhone className="text-2xl" />
            <p className="md:text-lg text-sm break-normal	">+917020699817</p>
          </a>
          {width > 767 && <div>|</div>}
          <div className="flex items-center space-x-1">
            <AiOutlineMail className="text-2xl" />
            <p className="md:text-lg text-sm">
              <a href="mailto:machapoint@gmail.com">machapoint22@gmail.com</a>
            </p>
          </div>
        </div>
        <div className="flex items-center sm:text-lg text-sm  justify-center text-center">
          Centrosis Tech, AICGIM, Poriem, Goa 403505
          <br />
          2023 © All Rights Reserved @ Centrosis Tech
        </div>
      </div>
      <div className="flex flex-col items-center space-y-1 flex-nowrap	 ">
        <a
          target="_blank"
          href="https://docs.google.com/document/d/e/2PACX-1vR4-dDC3vwhFDgpjAA88rM6oKfHwxxivp5G0x8zmXFJR4g8mSVAJasqGuk_C6_BZXF_JverQ1NPci_U/pub"
          rel="noreferrer"
          className="sm:text-lg text-sm"
        >
          Privacy policy
        </a>
        <a
          target="_blank"
          href="https://docs.google.com/document/d/e/2PACX-1vRa15KhCzylYy_CEuR1lBo5qVBR_eAYyP-LaJe5mK7-AhZdoYMkQ_nT1mMf8e0bUn57BI5Rivw_9pYk/pub"
          rel="noreferrer"
          className="sm:text-lg text-sm"
        >
          Terms & conditions
        </a>
        <a
          target="_blank"
          href="https://docs.google.com/document/d/e/2PACX-1vT4ZPGkA2qWbKg8bRht0yAoZEe8H5bXhqei5dM1Q5itB4TXWHjc5IgbOImwd2qlUHGboRzcndfQ-z4O/pub"
          rel="noreferrer"
          className="sm:text-lg text-sm"
        >
          Cancellation policy
        </a>
      </div>
    </div>
  );
};

export default Footer;
