import React, { useEffect, useState } from "react";
import { EditorState, convertFromRaw } from "draft-js";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";

const Intro = ({ introwords, selected = "blue-500" }) => {
  const [convertedContent, setConvertedContent] = useState(null);
  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  useEffect(() => {
    if (introwords !== null) {
      const blocks = introwords && introwords.blocks;

      const entityMap = {};

      const contentState = convertFromRaw({ blocks, entityMap });
      const editorState = EditorState.createWithContent(contentState);
      let html = convertToHTML(editorState.getCurrentContent());
      setConvertedContent(html);
    }
  }, [introwords]);

  return (
    <div
      className={`w-full py-8 px-4 md:mt-28 bg-${selected}   flex items-end justify-between`}
    >
      <p className=" lg:text-3xl md:text-3xl text-lg font-bold text-white text-center w-full md:w-3/5 mx-auto tracking-wide">
        <div
          className="p-6"
          dangerouslySetInnerHTML={createMarkup(convertedContent)}
        ></div>
      </p>
    </div>
  );
};

export default Intro;
