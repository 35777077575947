import React from "react";

const Intro = ({ introwords, selected = "[#454598]" }) => {
  return (
    <div
      className={`w-full py-12 px-4 md:mt-24  bg-${selected}   flex items-end justify-between`}
    >
      <p className=" lg:text-3xl md:text-3xl text-2xl font-bold text-white text-center w-full md:w-3/5 mx-auto tracking-wide">
        {introwords}
      </p>
    </div>
  );
};

export default Intro;
