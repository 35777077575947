import React, { useState, useEffect } from "react";
import { EditorState, convertFromRaw } from "draft-js";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import Typewriter from "typewriter-effect";

const TypeWriterColumn = ({ strings, heading }) => {
  const [convertedContent, setConvertedContent] = useState(null);
  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }
  console.log(heading, "heading");

  useEffect(() => {
    if (heading !== null) {
      const blocks = heading && heading.blocks;
      const entityMap = {};
      const contentState = convertFromRaw({ blocks, entityMap });
      const editorState = EditorState.createWithContent(contentState);
      let html = convertToHTML(editorState.getCurrentContent());
      console.log(html);
      setConvertedContent(html);
    }
  }, [heading]);

  console.log(convertToHTML);
  return (
    <div className="w-[100%]  pt-10 lg:pt-20 origin-right  bg-white  lg:text-3xl md:text-3xl text-2xl xl:text-4xl  h-full flex flex-col space-y-4 items-center justify-center">
      <div
        className="px-3"
        dangerouslySetInnerHTML={createMarkup(convertedContent)}
      ></div>
      <div className="font-bold capitalize">
        <Typewriter
          options={{
            strings: strings,
            autoStart: true,
            loop: true,
          }}
        />
      </div>
    </div>
  );
};

export default TypeWriterColumn;
