import React, { useState } from "react";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import PhoneAuth from "./Pages/phoneSignUp";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { UserAuthContextProvider } from "./Pages/UserAuthContext";
import { Toaster } from "react-hot-toast";

import "./index.css";
import RoutesFile from "./RoutesFile";

export default function App() {
  return (
    <>
      <Toaster />
      <RoutesFile />
    </>
  );
}
