import React from "react";

const StickyIcons = ({ icon, orgicon }) => {
  return (
    <div style={{ zIndex: 10000 }} className="sticky top-[80%] right-0">
      <div
        style={{ zIndex: 10000 }}
        className="flex flex-col absolute w-fit  space-y-8  top-[80%] right-0"
      >
        {orgicon && (
          <div className=" bg-gray-400 p-3  w-max rounded-l-md">
            <img className="w-8 max-sm:w-5" alt="" src={orgicon} />
          </div>
        )}
        <div className=" bg-gray-400 p-3  w-max rounded-l-md">
          <img className="w-8 max-sm:w-5" alt="" src={icon} />
        </div>
      </div>
    </div>
  );
};

export default StickyIcons;
