import React, { useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import { BsTelephoneFill } from "react-icons/bs";
import { BiArrowBack } from "react-icons/bi";
import { AiFillPrinter } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { TfiEmail } from "react-icons/tfi";
import { changeOrderStatus } from "../Helper/Order";
import { toast } from "react-hot-toast";

const options = ["pending", "cancelled", "delivered", "confirmed"];

const DataLine = ({ line, data }) => {
  return (
    <div className="flex text-[12px] items-center space-x-1 font-normal">
      <div className="text-gray-600">{line}</div>
      <span className="text-gray-500">{data}</span>
    </div>
  );
};

const Address = ({ addresslines, name, contact, label }) => {
  return (
    <div className="flex-auto max-sm:w-[50%]">
      <div className="flex flex-col">
        <p className="font-semibold px-2 py-2  bg-gray-200">{label}</p>
        <div className="flex flex-col space-y-1 px-2 py-3">
          <span className="text-gray-800 font-medium">
            {name},{contact}
          </span>
          {addresslines.map((address, index) => {
            return (
              <p className="text-gray-500" key={index}>
                {address}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const OrderProduct = ({
  invoiceNo,
  itemImage,
  itemName,
  soldBy,
  qty,
  status,
  price,
  tax,
  shippingCharge,
  couponDiscount,
  canEdit,
  handlingCharge,
}) => {
  console.log(canEdit);

  return (
    <tr className="text-center w-full">
      {/* <td className="text-sm text-gray-500 py-4 border max-sm:hidden">
        {invoiceNo}
      </td> */}
      <td className="text-sm text-gray-500 py-4 border ">
        <div className="sm:flex  justify-around ">
          <img src={itemImage} alt="" className="h-12 mx-auto" />
          <div className="flex flex-col justify-start items-start">
            <p className="text-blue-500 text-xs sm:text-sm  ">{itemName}</p>
            <p className="text-gray-500 text-xs">Sold by:{soldBy}</p>
          </div>
        </div>
      </td>
      <td className="text-sm text-gray-500 py-4 border">{qty}</td>
      {/* <td className="text-sm text-white py-4 border ">
        <div className="flex flex-col items-center sm:w-[120px] h-full">
          <span className="bg-blue-500 rounded-full px-2 py-1 capitalize text-xs">
            {status}
          </span>
        </div>
      </td> */}
      <td className="border">
        <div className="flex flex-col space-y-1">
          <DataLine line={"Total Price:"} data={price} />
          <DataLine line={"Total Tax:"} data={tax} />
          <DataLine line={"Shipping Charges:"} data={shippingCharge} />
        </div>
      </td>
      <td className="text-lg text-gray-500 py-4 font-bold border">{price}</td>
    </tr>
  );
};

const EndItem = ({ title, data }) => {
  return (
    <tr className="max-sm:hidden">
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td className="border py-3 font-semibold text-gray-600">{title}</td>
      <td className="border py-3 font-semibold text-gray-500 text-center">
        {data}
      </td>
    </tr>
  );
};
const EndItem2 = ({ title, data }) => {
  return (
    <div className="flex jusitify-between w-full sm:hidden">
      <div className="text-gray-600 w-1/2">{title}:</div>
      <div className="text-gray-500 w-1/2 font-bold">{data}</div>
    </div>
  );
};

const SellerPanelOrder = ({
  setOrderSelected,
  orderSelected,
  handleInvoice,
}) => {
  const params = useParams();
  const [canEdit, setCanEdit] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(
    orderSelected?.order_status
  );

  async function handleOrderStatus() {
    let body = {
      order_id: orderSelected._id,
      order_status: selectedStatus,
    };

    let response = await changeOrderStatus(body);

    if (response.status_code === 200) {
      toast.success("Order Status Changed");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      toast.error("Something went wrong");
    }
  }

  // useEffect(() => {
  //   if (orderSelected) {
  //     handleInvoice(orderSelected._id);
  //   }
  // }, []);

  return (
    <div>
      <h3 className="font-bold pb-4 text-xl">View Order</h3>
      <div className="flex flex-col space-y-8 bg-white rounded-md px-4 py-6">
        <div className="flex max-sm:flex-col-reverse items-center justify-between">
          <p className="font-semibold px-2">
            Viewing Order #{orderSelected._id}
          </p>
          <div className="flex items-center space-x-4 max-sm:w-full">
            <button
              onClick={async () => {
                setOrderSelected();
              }}
              className="flex items-center space-x-2 border text-gray-600 hover:bg-blue-500 hover:border-white hover:text-white font-medium rounded-md px-2 py-1"
            >
              <BiArrowBack /> Back
            </button>
            <button
              onClick={() => handleInvoice(orderSelected._id)}
              className="flex items-center space-x-2 border text-gray-600 hover:bg-blue-500 hover:border-white hover:text-white font-medium rounded-md px-2 py-1"
            >
              <AiFillPrinter /> Print
            </button>
            <button
              onClick={() => setCanEdit(!canEdit)}
              className="flex items-center space-x-2 border text-gray-600 hover:bg-blue-500 hover:border-white hover:text-white font-medium rounded-md px-2 py-1"
            >
              <FaEdit /> Edit
            </button>
          </div>
        </div>
        <div className="font-[600] text-[20px] font-sans">
          Order Status : {orderSelected?.order_status}
        </div>
        {canEdit && (
          <div className="sm:w-[30%] w-[60%] py-3">
            <div className="font-[600] text-[20px] font-sans">
              Change Order Status
            </div>
            <Listbox value={selectedStatus} onChange={setSelectedStatus}>
              <div className="relative mt-1 w-full mx-auto text-black">
                <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3  text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                  <span className="block truncate">{selectedStatus}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    Z-Index="100"
                    className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  >
                    {options.map((option, personIdx) => (
                      <Listbox.Option
                        key={personIdx}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 ${
                            active
                              ? "bg-amber-100 text-amber-900"
                              : "text-gray-900"
                          }`
                        }
                        value={option}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate text-left ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {option}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
            <div className="pt-3 ">
              <button
                className="text-white bg-black rounded-lg p-3"
                onClick={handleOrderStatus}
              >
                Change Status
              </button>
            </div>
          </div>
        )}
        <div className="flex divide-x border border-gray-300 rounded-md divide-gray-300">
          <div className="flex-auto max-sm:w-[50%]">
            <div className="flex flex-col">
              <p className="font-semibold px-2 py-2  bg-gray-200">
                Customer Info
              </p>
              <div className="flex flex-col space-y-1 px-2 py-3">
                <span className="text-gray-800 font-medium">
                  {orderSelected?.customer_id?.name}
                </span>
                <DataLine
                  line={<TfiEmail />}
                  data={orderSelected?.customer_id?.email}
                />
                <DataLine
                  line={<BsTelephoneFill />}
                  data={orderSelected?.customer_id?.phone_number}
                />
              </div>
            </div>
          </div>
          {console.log(orderSelected)}
          <Address
            label={"Shipping Address"}
            name={orderSelected?.address_id?.name}
            contact={orderSelected?.address_id?.phone_number}
            addresslines={[
              orderSelected?.address_id?.main_address,
              orderSelected?.address_id?.city,
              orderSelected?.address_id?.state,
              orderSelected?.address_id?.postal_code,
            ]}
          />
          {/* <Address
            label={"Billing Address"}
            name={"Vasudeo Hajare"}
            contact={"9552748308"}
            addresslines={[
              `H.No.357, Near Sesa Resources Office, Dignem Cudnem Sankhalim Goa, 403505,`,
              `Sanquelim, Goa, Sanquelim`,
            ]}
          /> */}
        </div>
        <div className="flex flex-col rounded-md">
          <p className="font-semibold px-2 py-2 rounded-t-md  bg-gray-200 border-t border-l border-r border-gray-300 r divide-gray-300">
            Order Summary
          </p>
          <div className="flex-auto sm:flex rounded-b-md divide-x border-b border-l border-r border-gray-300 r divide-gray-300">
            <div className="flex-auto flex-col  flex justify-center space-y-1 px-2 py-3">
              <DataLine
                line={"Total Qty:"}
                data={orderSelected?.products?.length}
              />
              <DataLine
                line={"Order Total:"}
                data={orderSelected?.total_amount}
              />
              <DataLine
                line={"Payment Recieved:"}
                data={orderSelected?.payment_type === "online" ? "Yes" : "No"}
              />
            </div>
            <div className="flex-auto flex-col  flex justify-center sm:space-y-1 px-2 sm:py-3">
              <DataLine
                line={"Payment Method:"}
                data={orderSelected?.payment_type}
              />
              {/* <DataLine line={"Transcation ID:"} data="EMARTiu6MnzZ6TqCOD" /> */}
            </div>
            <div className="flex-auto flex-col  flex justify-center sm:space-y-1 px-2 sm:py-3">
              <DataLine
                line={"Order Date:"}
                data={orderSelected?.order_date?.substring(0, 10)}
              />
            </div>
          </div>
        </div>
        <p className="font-semibold text-sm">Order Details</p>
        <table className="table-auto border w-full bg-[#f7f8f9] rounded-md border-collapse">
          <thead>
            <tr className="text-sm font-normal ">
              {/* <th className="py-3 max-sm:hidden">Invoice No</th> */}
              <th className="py-3">Item Name</th>
              <th className="py-3">Qty</th>
              {/* <th className="py-3">Status</th> */}
              <th className="py-3">Pricing & Tax</th>
              <th className="py-3">Total</th>
            </tr>
          </thead>
          <tbody className="bg-white  ">
            {orderSelected &&
              orderSelected.products.map((product) => {
                return (
                  <OrderProduct
                    invoiceNo={product?._id}
                    itemImage={product?.product_id?.productImageUrls[0]?.imgURL}
                    itemName={product?.product_id?.productName}
                    soldBy={orderSelected?.store_id?.storeName}
                    qty={product?.quantity}
                    status={
                      product?.product_id?.status === true
                        ? "Active"
                        : "Inactive"
                    }
                    price={product?.product_id?.offerPrice}
                    tax="₹0"
                    shippingCharge={"₹0"}
                    canEdit={canEdit}
                  />
                );
              })}

            <EndItem title={"Subtotal"} data={orderSelected?.total_amount} />
            <EndItem title={"Coupon Discount"} data="-₹0" />
            <EndItem title={"Handling Charge"} data="+₹0" />
            <EndItem title={"Grand Total"} data={orderSelected?.total_amount} />
          </tbody>
        </table>
        <EndItem2 title={"Subtotal"} data={orderSelected?.total_amount} />
        <EndItem2 title={"Coupon Discount"} data="-₹0" />
        <EndItem2 title={"Handling Charge"} data="+₹0" />
        <EndItem2 title={"Grand Total"} data={orderSelected?.total_amount} />
        {/* <div>
          <p className="font-semibold px-2">Order Activity Logs</p>
          <div className="flex px-2 flex-col space-y-1 text-xs text-gray-400">
            <p>
              21-12-2022 | 12:57:pm • For Order Baby Hospital and Home wear(
              null ) [EMRT102MRTE2019]:{" "}
              <span className="text-red-500">Raghavendra (Admin)</span> changed
              status to Pending
            </p>
            <p>
              21-12-2022 | 12:57:pm • For Order Baby Hospital and Home wear(
              null ) [EMRT102MRTE2019]:{" "}
              <span className="text-red-500">Raghavendra (Admin)</span> changed
              status to Pending
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default SellerPanelOrder;
