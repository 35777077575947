import React, { Fragment, useEffect, useState } from "react";

import { AiOutlineHome, AiOutlineShop } from "react-icons/ai";
import { BsFileEarmarkImageFill } from "react-icons/bs";
import { isAuthenticated, signout } from "../../Helper/Auth";
import {
  getMyCart,
  getMyCartByStore,
  removeFromCart,
} from "../../Helper/Order";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { RxCross1, RxCross2 } from "react-icons/rx";
import Drawer from "../Base/Drawer";
import CartProduct from "../Cart/CartProduct";
import { getUser } from "../../Helper/story";
import { FaShoppingCart, FaUserAlt } from "react-icons/fa";
import { Menu, Transition } from "@headlessui/react";

const Header = ({ page, logo, id, selected, storeName, template }) => {
  const [cartData, setCartData] = useState([]);
  const [cartOpen, setCartOpen] = React.useState(false);
  const [isAuth, setIsAuth] = useState(false);

  async function getMyCartData() {
    let user = await isAuthenticated();
    console.log(user.token);
    if (user.token && cartOpen && id) {
      const response = await getMyCartByStore(id);
      console.log(response);
      if (response?.status_code === 200) {
        // console.log(response.data, "response carts");

        setCartData(response.data);
      } else {
        toast.error("error fetching cart data");
      }
    }
  }

  const navigator = useNavigate();

  const toCheckout = () => {
    navigator("/checkout", { state: cartData });
  };

  async function removeFromCartHandler(id) {
    const response = await removeFromCart({ productID: id });

    if (response.status_code === 200) {
      toast.success("Product removed from cart");
      getMyCartData();
    } else {
      toast.error("Something went wrong");
    }
  }

  useEffect(() => {
    getMyCartData();
  }, [cartOpen]);

  async function getUserData() {
    if (localStorage.getItem("macha-user")) {
      let userRes = await getUser();

      if (userRes?.status_code === 401) {
        localStorage.removeItem("macha-user");
        window.location.reload(false);
      }
    }
  }

  async function handleLogout() {
    await isAuthenticated().then((data) => {
      setIsAuth(data);
    });
  }

  async function handleSignout() {
    await signout().then((data, err) => {
      setIsAuth(false);
      navigator("/");
    });
  }

  useEffect(() => {
    handleLogout();
  }, []);

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div
      style={{ zIndex: 1000 }}
      className="w-full sticky bg-white shadow-xl top-0 flex items-center px-3 py-2 justify-between"
    >
      <img
        src={logo}
        alt=""
        className="xl:h-18 lg:h-16  h-14"
        crossOrigin="anonymous"
      />
      <div className=" flex items-center lg:space-x-12 md:space-x-8 space-x-4 text-indigo-800 font-medium">
        {template?.homepageEnabled && (
          <a
            href={`/profile/${storeName?.replace(/\s+/g, "-")}/${id}`}
            style={{ backgroundColor: page === "home" && selected }}
            className={
              "group hover:text-black rounded-md lg:px-3 sm:px-2 px-1 py-1 " +
              (page === "home" && `text-white border  hover:text-white `)
            }
          >
            <span className="hidden md:block">Home</span>
            <AiOutlineHome className="block md:hidden text-xl" />
            <span
              class={
                "block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-black " +
                (page === "home" && "w-0")
              }
            ></span>
          </a>
        )}
        {template?.aboutPageEnabled && (
          <a
            href={`/profile/story/${storeName?.replace(/\s+/g, "-")}/${id}`}
            style={{ backgroundColor: page === "story" && selected }}
            className={
              "group hover:text-black  rounded-md lg:px-4 sm:px-2 px-1 py-2 " +
              (page === "story" && `text-white border hover:text-white`)
            }
          >
            <span className="hidden md:block">About</span>
            <BsFileEarmarkImageFill className="block md:hidden text-xl" />
            <span
              class={
                "block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-black " +
                (page === "story" && "w-0")
              }
            ></span>{" "}
          </a>
        )}
        {template?.productPageEnabled && (
          <a
            href={`/profile/product/${storeName?.replace(/\s+/g, "-")}/${id}`}
            style={{ backgroundColor: page === "products" && selected }}
            className={
              "group hover:text-black rounded-md lg:px-4 sm:px-2 px-1 py-2 " +
              (page === "products" && `text-white border  hover:text-white`)
            }
          >
            <span className="hidden md:block">Products</span>
            <AiOutlineShop className="block md:hidden text-xl" />

            <span
              class={
                "block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-black " +
                (page === "products" && "w-0")
              }
            ></span>
          </a>
        )}
        {isAuth === false ? (
          <div className="  text-right">
            <a href="/login">
              <button className="w-full text-center">SignIn</button>
            </a>
          </div>
        ) : (
          cartOpen === false && (
            <div className="  text-right">
              <Menu
                style={{ zIndex: 10000 }}
                as="div"
                className="relative inline-block text-left"
              >
                <div>
                  <Menu.Button className="inline-flex w-full justify-center rounded-md  px-4 py-2 text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                    <FaUserAlt
                      size={20}
                      onMouseOver={({ target }) =>
                        (target.style.color = "#454598")
                      }
                      onMouseOut={({ target }) =>
                        (target.style.color = "black")
                      }
                      className="text-red hover:text-[#454598]"
                      style={{ color: "#3D3C3C" }}
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 ">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href={`/user-panel/?redirectFrom=seller&redirectFromId=${id}`}
                            className={`${
                              active
                                ? "text-white hover:bg-[#454598]"
                                : "text-gray-900"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          >
                            My Profile
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                    <div className="px-1 py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={handleSignout}
                            className={`${
                              active
                                ? "hover:bg-[#454598] text-white"
                                : "text-gray-900"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          >
                            Logout
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          )
        )}
        <button className="pr-6 md:text-2xl text-xl py-2  rounded-md text-gray-600 hover:text-gray-900 lg:px-4 sm:px-2 px-1">
          <FaShoppingCart
            className="text-indigo-800"
            onClick={() => setCartOpen(true)}
          />
        </button>
      </div>
      <Drawer isOpen={cartOpen} setIsOpen={setCartOpen} lg={true} clip={"full"}>
        <div className=" h-full  overflow-hidden">
          <div className="flex items-center justify-between mx-6">
            <div className="font-bold text-xl md:text-2xl m-0 text-heading">
              Shopping Cart
            </div>
            <RxCross2
              size={20}
              className=""
              onClick={() => setCartOpen(false)}
            />
          </div>
          {cartData.length === 0 && (
            <div className="text-[20px] font-sans px-6 pt-6">
              No Products Added to Cart
            </div>
          )}
          <div className="pt-6 overflow-y-scroll h-[562px]">
            {cartData &&
              cartData.length > 0 &&
              cartData.map((item, index) => {
                return (
                  <CartProduct
                    item={item}
                    IN={index}
                    cartData={cartData}
                    setCartData={setCartData}
                    removeFromCartHandler={removeFromCartHandler}
                  />
                );
              })}
          </div>
        </div>
        <div className="py-8 w-full relative   botton-0 px-6  ">
          {cartData.length > 0 && (
            <button
              className=" bg-black text-white hover:bg-gray-600 w-full py-4 rounded-lg"
              onClick={() => {
                toCheckout();
              }}
            >
              <div>Proceed to Checkout</div>
            </button>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default Header;
