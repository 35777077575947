import React from "react";
import Layout from "../Components/OrganisationLayout";
import OrderDetails from "../Components/ViewOrder/OrderDetails";

const ViewOrder = () => {
  return (
    <Layout>
      <OrderDetails />
    </Layout>
  );
};

export default ViewOrder;
