import React, { useEffect, useState } from "react";
import { AiFillPrinter, AiFillEye } from "react-icons/ai";
import { getOrdersForOrganisation, getOrdersForStore } from "../Helper/Order";
import { getUserInfo } from "../Helper/Auth";
import { toast } from "react-hot-toast";

const OrderBlock = ({
  orderId,
  customerName,
  id,
  orderType,
  sellerId,
  totalQty,
  toatalAmt,
  orderDate,
  setOrderSelected,
  order,
  handleInvoice,
}) => {
  return (
    <tr className="text-center ">
      <td className="text-sm text-gray-500 py-3">{id}</td>
      <td className="text-sm text-gray-500 ">
        <span className="bg-blue-400 rounded-md px-2 py-1 text-white">
          {orderType}
        </span>
      </td>
      <td className="text-sm text-gray-500 max-sm:hidden">#{orderId} </td>
      <td className="text-sm text-gray-500 max-sm:hidden">
        {order.order_status}{" "}
      </td>
      <td className="text-sm text-gray-500 max-sm:hidden">{sellerId}</td>
      <td className="text-sm text-gray-500 max-sm:hidden">{totalQty}</td>
      <td className="text-sm text-gray-500">INR {toatalAmt} </td>
      <td className="text-sm text-gray-500">{orderDate}</td>

      <td>
        <div className="flex items-center space-x-1 justify-center">
          <AiFillPrinter
            className="text-xl text-green-500 hover:text-green-600 cursor-pointer"
            onClick={() =>
              order?.order_status !== "pending"
                ? handleInvoice(orderId)
                : toast.error("Order is currently in pending state")
            }
          />

          <AiFillEye
            className="text-2xl text-gray-500 hover:text-gray-700 cursor-pointer"
            onClick={() => {
              setOrderSelected(order);
            }}
          />
        </div>
      </td>
    </tr>
  );
};

const SellerOrderManagement = ({ setOrderSelected, type, handleInvoice }) => {
  const [orders, setOrders] = useState([]);

  async function getOrders() {
    const res = await getUserInfo();

    if (res.status_code === 200) {
      if (type === "organisation") {
        const res1 = await getOrdersForOrganisation(res.data._id);

        if (res1.status_code === 200) {
          setOrders(res1.data);
        } else {
          toast.error(res1.message);
        }
      } else {
        const res1 = await getOrdersForStore(res.data._id);
        if (res1.status_code === 200) {
          setOrders(res1.data);
        } else {
          toast.error(res1.message);
        }
      }
    }
  }
  console.log(orders, "orders");
  useEffect(() => {
    getOrders();
  }, []);

  return (
    <div>
      <div className=" sm:my-6 w-full rounded-md border border-gray-200">
        {orders.length === 0 ? (
          <div>No Orders Found</div>
        ) : (
          <table className="table-auto  w-full  bg-[#f7f8f9] rounded-lg">
            <thead>
              <tr className="text-sm font-thin">
                <th className="font-normal py-3">ID</th>
                <th className="font-normal py-3">Order Type</th>
                <th className="font-normal py-3 max-sm:hidden">Order Id</th>
                <th className="font-normal py-3 max-sm:hidden">Status</th>
                <th className="font-normal py-3 max-sm:hidden">Seller Id</th>
                <th className="font-normal py-3 max-sm:hidden">Total QTY</th>
                <th className="font-normal py-3">Total Amt</th>
                <th className="font-normal py-3">Order Date</th>
                <th className="font-normal py-3">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y">
              {orders &&
                orders.length > 0 &&
                orders.map((order, index) => {
                  return (
                    <OrderBlock
                      order={order}
                      id={index + 1}
                      orderType={order.payment_type}
                      orderId={order._id}
                      customerName={order.customer_id.name}
                      sellerId={order.seller_id}
                      totalQty={order.products.length}
                      toatalAmt={order.total_amount}
                      orderDate={order.order_date.substring(0, 10)}
                      setOrderSelected={setOrderSelected}
                      handleInvoice={handleInvoice}
                    />
                  );
                })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default SellerOrderManagement;
