import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const SlideShow = ({ images }) => {
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      modules={[Pagination, Autoplay, Navigation]}
      navigation={true}
      className="w-full 2xl:h-[800px] xl:h-[750px] lg:h-[700px] md:h-[600px] sm:h-[400px] h-[300px]"
      loop={true}
      autoplay={{
        delay: 1500,
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true }}
    >
      {images &&
        images.map((image, index) => {
          return (
            <SwiperSlide className="w-full h-full bg-black" key={index}>
              <img
                src={image.imgURL}
                alt=""
                className="w-full object-fill h-full"
                crossOrigin="anonymous"
              />
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
};

export default SlideShow;
