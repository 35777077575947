import React from "react";
import testimonials from "../../images/test/thoughts.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

const Testimonials = ({ thoughts }) => {
  return (
    <div className="w-full mb-10 py-20  flex flex-col items-center space-y-8">
      <p className="text-center lg:text-3xl md:text-3xl text-2xl xl:text-4xl font-bold mb-2">
        Words from customers
      </p>
      <img src={testimonials} alt="" className="lg:w-24 md:w-20 w-16" />
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        modules={[Autoplay, Navigation]}
        className="lg:w-1/2 md:w-2/3 w-full "
        navigation={true}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        {thoughts?.map((thought, index) => {
          return (
            <SwiperSlide
              className="w-full relative  justify-center flex  items-center h-full cursor-pointer"
              key={index}
            >
              <div className="flex flex-col h-full justify-center my-auto w-4/5 mx-auto items-center space-y-6">
                <p className="md:w-3/5 w-4/5 mx-auto text-center md:text-lg text-sm text-black">
                  {thought.description}
                </p>
                <div className="flex items-center justify-center space-x-2">
                  -
                  <p className="md:text-sm text-xs text-black capitalize">
                    {thought.name}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Testimonials;
