import React, { useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import Block from "../Builder/Block";
import EditorInput from "../Builder/EditorInput";
import Headline from "../Builder/Headline";
import ImageInput from "../Builder/ImageInput";
import SplitBox from "../Builder/SplitBox";
import TextInput from "../Builder/TextInput";
import Pop from "../Base/Pop";

const HomePageTemplate1 = ({
  HomeFunctions,
  HomeValues,
  EnableFunctions,
  EnableValues,
  handleImages,
  EditorFunctions,
  EditorValues,
  HeadingFunctions,
  HeadingValues,
  handArrayObjectChange,
  template,
  setIsOpen2,
  setSelectedHandler,
}) => {
  let [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState();

  return (
    <div className="py-4 flex flex-col items-center space-y-4 divide-y w-full">
      <Block>
        <Headline
          enabled={EnableValues.enabled}
          setEnabled={EnableFunctions.setEnabled}
          headline={"Section 1 * (Select Images for the Slider)"}
        />
        <div className="flex items-center gap-4 flex-wrap">
          <div className="h-36 aspect-square	">
            <ImageInput
              handleChange={handleImages}
              handlerfunction={HomeFunctions.setSliderImages}
              handlerValue={HomeValues.sliderImages}
              multiple={true}
              text={"Add Slider Images"}
            />
          </div>
          {HomeValues.sliderImages.length > 0 &&
            HomeValues.sliderImages.map((image, index) => (
              <div className="h-36 aspect-square relative" key={index}>
                <div
                  onClick={() => {
                    setIsOpen(true);
                    setIndex(index);
                  }}
                  className="absolute -top-2 -right-2 "
                >
                  <AiFillCloseCircle className="text-red-500 text-2xl cursor-pointer hover:text-red-700" />
                </div>
                <img
                  src={
                    image.imgURL
                      ? image.imgURL
                      : URL.createObjectURL(image.image)
                  }
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            ))}
        </div>
      </Block>
      <Block>
        <Headline
          enabled={EnableValues.enabled2}
          setEnabled={EnableFunctions.setEnabled2}
          headline={
            "Section 2 * (Select An Image and Content for Your Website)"
          }
        />
        <SplitBox
          editorState={EditorValues.editorState6}
          setEditorState={EditorFunctions.setEditorState6}
          handleImageChange={handleImages}
          handlerfunction={HomeFunctions.setHomeImage}
          handlerValue={HomeValues.homeImage}
          textValue={HeadingValues.Heading1}
          handleTextChange={HeadingFunctions.setHeading1}
          isOpen={isOpen}
          setIsOpen={setIsOpen2}
          setIndex={setIndex}
          setSelectedHandler={setSelectedHandler}
          text="setHomeImage"
        />
      </Block>

      <Block>
        <Headline
          enabled={EnableValues.enabled4}
          setEnabled={EnableFunctions.setEnabled4}
          headline={
            "Section 3 * (Select An Image and Content for Your Website)"
          }
        />
        <SplitBox
          editorState={EditorValues.editorState8}
          setEditorState={EditorFunctions.setEditorState8}
          handleImageChange={handleImages}
          handlerfunction={HomeFunctions.setHomeImage2}
          handlerValue={HomeValues.homeImage2}
          textValue={HeadingValues.Heading2}
          handleTextChange={HeadingFunctions.setHeading2}
          rowReversed={true}
          isOpen={isOpen}
          setIsOpen={setIsOpen2}
          setIndex={setIndex}
          setSelectedHandler={setSelectedHandler}
          text="setHomeImage2"
        />
      </Block>
      <Block>
        <Headline
          enabled={EnableValues.enabled3}
          setEnabled={EnableFunctions.setEnabled3}
          headline={"Section 4 * (Write A Catchy Line For Your Business )"}
        />
        <EditorInput
          editorState={EditorValues.editorState7}
          setEditorState={EditorFunctions.setEditorState7}
        />
      </Block>
      <Block>
        <Headline
          enabled={EnableValues.statsEnabled}
          setEnabled={EnableFunctions.setStatsEnabled}
          headline={"Section 5 * (Write Your Business Statistics Here)"}
        />
        {HomeValues?.statistics?.length > 0 &&
          HomeValues?.statistics?.map((stats, index) => {
            return (
              <div className="flex w-full items-center space-x-3 my-2">
                <div className="w-[45%]">
                  <TextInput
                    tagname={"Sales"}
                    label={"Value"}
                    placeholder={"Enter stats value"}
                    handleChange={handArrayObjectChange}
                    value={stats.number}
                    type={"arrayobj"}
                    index={index}
                    thing={"number"}
                    setFunction={HomeFunctions.setStatistics}
                    oldValue={HomeValues.statistics}
                  />
                </div>
                <div className="w-[45%]">
                  <TextInput
                    tagname={"Type"}
                    label={"Stats Type"}
                    placeholder={"Enter stats type"}
                    handleChange={handArrayObjectChange}
                    value={stats.title}
                    type={"arrayobj"}
                    index={index}
                    thing={"title"}
                    setFunction={HomeFunctions.setStatistics}
                    oldValue={HomeValues.statistics}
                  />
                </div>
                {index > 0 && (
                  <div className="flex items-center justify-center grow">
                    <div
                      onClick={() => {
                        HomeFunctions.setStatistics(
                          HomeValues.statistics.filter((item, i) => i !== index)
                        );
                      }}
                      className="flex items-center justify-center"
                    >
                      <AiFillCloseCircle className="text-red-500 text-2xl cursor-pointer hover:text-red-700" />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        <div className="flex items-center justify-between">
          <button
            // disabled={HomeValues?.statistics?.length > 3 ? true : false}
            onClick={() => {
              let newStats = [...HomeValues?.statistics];
              newStats.push({ title: "", number: "" });

              HomeFunctions.setStatistics(newStats);
            }}
            className="my-2 border bg-blue-500 text-white hover:bg-blue-700 rounded-md py-2 px-6 mx-auto disabled:bg-gray-400"
          >
            + Add Statistic
          </button>
        </div>
      </Block>
      <Block>
        <Headline
          enabled={EnableValues.testimonialsEnabled}
          setEnabled={EnableFunctions.setTestimonialsEnabled}
          headline={"Section 6 * (Add Your Testimonials)"}
        />
        {HomeValues?.testimonials?.length > 0 &&
          HomeValues?.testimonials?.map((testimonial, index) => {
            return (
              <div className="flex w-full items-center space-x-3 my-2">
                <div className="w-[45%]">
                  <TextInput
                    tagname={"Sales"}
                    label={"Reviewer name"}
                    placeholder={"Enter Reviewer name"}
                    handleChange={handArrayObjectChange}
                    value={testimonial.name}
                    type={"arrayobj"}
                    index={index}
                    thing={"name"}
                    setFunction={HomeFunctions.setTestimonials}
                    oldValue={HomeValues.testimonials}
                  />
                </div>
                <div className="w-[45%]">
                  <TextInput
                    tagname={"Type"}
                    label={"Review"}
                    placeholder={"Enter Review"}
                    handleChange={handArrayObjectChange}
                    value={testimonial.description}
                    type={"arrayobj"}
                    index={index}
                    thing={"description"}
                    setFunction={HomeFunctions.setTestimonials}
                    oldValue={HomeValues.testimonials}
                  />
                </div>
                {index > 0 && (
                  <div className="flex items-center justify-center grow">
                    <div
                      onClick={() => {
                        HomeFunctions.setTestimonials(
                          HomeValues.testimonials.filter(
                            (item, i) => i !== index
                          )
                        );
                      }}
                      className="flex items-center justify-center"
                    >
                      <AiFillCloseCircle className="text-red-500 text-2xl cursor-pointer hover:text-red-700" />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        <div className="flex items-center justify-between">
          <button
            disabled={HomeValues?.testimonials?.length > 3 ? true : false}
            onClick={() =>
              HomeFunctions.setTestimonials(() => [
                ...HomeValues.testimonials,
                { name: "", description: "" },
              ])
            }
            className="my-2 border bg-blue-500 text-white hover:bg-blue-700 rounded-md py-2 px-6 mx-auto disabled:bg-gray-400"
          >
            + Add Testimonial
          </button>
        </div>
      </Block>
      <Pop isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="text-[20px] font-[600] ">
          Are you sure you want remove the Image?
        </div>
        <div className="flex items-center justify-between pt-4">
          <button
            className="bg-red-600 px-3 py-1 rounded-lg text-white"
            onClick={() => {
              console.log(index, "index");
              HomeFunctions.setSliderImages(
                HomeValues.sliderImages.filter((item, i) => i !== index)
              );
              setTimeout(() => {
                setIsOpen(false);
              }, 1000);
            }}
          >
            Yes
          </button>
          <button
            className="bg-green-600 px-3 py-1 rounded-lg text-white"
            onClick={() => setIsOpen(false)}
          >
            NO
          </button>
        </div>
      </Pop>
    </div>
  );
};

export default HomePageTemplate1;
