export const BaseColors = [
  "#ff3131",
  "#5e17eb",
  "#f44ab2",
  "#05bbdb",
  "#1469db",
  "#00bf63",
  "#ff914d",
  "#ffde59",
  "#696868",
  "#7ed957",
  "#ffbd59",
];
